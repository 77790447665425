import * as React from "react";

const SvgFinish = (props) => (
  <svg
    width={44}
    height={44}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.644 37.586 7.294 8.676M8.025 10.718s4.02-4.358 11.001-4.358c4.25 0 6.192 2.77 9.7 2.77 3.505 0 6.873-2.77 6.873-2.77v18s-2.532 1.385-5.54 1.385c-3.006 0-4.233-1.385-8.307-1.385-3.865 0-7.497 3.751-7.497 3.751l-6.23-17.393Z"
      stroke="#fff"
      strokeWidth={6}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.644 37.586 7.294 8.676"
      stroke="#000"
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.025 10.718s4.02-4.358 11.001-4.358c4.25 0 6.192 2.77 9.7 2.77 3.505 0 6.873-2.77 6.873-2.77v18s-2.532 1.385-5.54 1.385c-3.006 0-4.233-1.385-8.307-1.385-3.865 0-7.497 3.751-7.497 3.751l-6.23-17.393Z"
      fill="#fff"
      stroke="#000"
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.025 10.718s2.085-2.209 4.9-3.117l2.152 5.524s-2.778 1-5.047 3.132l-1.934-5.335"
      fill="#000"
    />
    <path
      d="m15.077 13.125 2.32 5.956s1.825-.648 3.08-.648c1.256 0 2.502.184 2.502.184l-1.685-6.334s-.476-.067-1.063-.067c-2.812 0-5.154.91-5.154.91ZM12.29 22.528l1.965 5.582s2.718-2.605 5.306-3.338l-2.163-5.69c-2.493.96-5.108 3.446-5.108 3.446ZM19.74 6.435l1.555 5.847c1.258 0 4.756 1.714 4.756 1.714L24.67 7.96c-.79-.296-2.86-1.426-4.931-1.524ZM22.979 18.616l1.6 6.019c.807 0 3.12.985 4.125.985l-1.352-5.917c-1.301-.339-3.408-.951-4.373-1.087ZM26.05 13.997l1.303 5.707s1.303.24 2.197.24c.894 0 2.008-.202 2.008-.202l-.423-5.225s-.863.268-1.909.268c-1.633 0-3.175-.788-3.175-.788ZM30.678 8.856l.458 5.66s2.128-.535 4.464-2.301l-.001-5.855c-2.683 2.055-4.92 2.496-4.92 2.496ZM31.559 19.742s2.05-.38 4.04-1.536v6.154c-2.12 1.018-3.578 1.202-3.578 1.202l-.462-5.82Z"
      fill="#000"
    />
  </svg>
);

export default SvgFinish;
