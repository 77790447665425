import {
  increaseLimitFilter,
  setPointFoundByTable,
} from "app/store/reducer/logistic-coordinates";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useListPointsLogisticCoordinatesQuery } from "shared/api/logistic-coordinates";

import { configColumns } from "./config";
import Footer from "./footer";
import { TableUI } from "./ui";

const Table = () => {
  const dispatch = useDispatch();
  const { projectInfo } = useSelector(
    (state) => state.rootReducer.currentProject,
  );
  const {
    frontendFilters,
    selectedPoints,
    highlightedPointId,
    changeCoordsFormData,
    allPointsChecked,
  } = useSelector((state) => state.rootReducer.logisticCoordinates);
  const { data: points, isFetching } = useListPointsLogisticCoordinatesQuery(
    {
      projectId: projectInfo.id,
      filters: frontendFilters,
    },
    {
      skip: !projectInfo.id,
      refetchOnMountOrArgChange: true,
    },
  );

  const onInfinityScroll = () => {
    dispatch(increaseLimitFilter());
  };

  const onRowClick = (row) => {
    const point = row.original;

    if (selectedPoints.includes(point.id)) return;

    dispatch(
      setPointFoundByTable({
        pointId: point.id,
        latitude: point.latitude,
        longitude: point.longitude,
      }),
    );
  };

  const data = React.useMemo(() => points, [points]);
  const columns = React.useMemo(() => configColumns, []);

  return (
    <>
      <TableUI
        data={
          changeCoordsFormData?.point?.pointCode
            ? [changeCoordsFormData?.point]
            : data?.content
        }
        columns={columns}
        totalElements={data?.totalElements}
        isFooterVisible={selectedPoints?.length || allPointsChecked}
        onRowClick={onRowClick}
        onInfinityScroll={onInfinityScroll}
        highlightedPointId={highlightedPointId}
        isFetching={isFetching}
        isHeadDisabled={changeCoordsFormData.isVisible}
      />
      <Footer />
    </>
  );
};

export default Table;
