import React from "react";
import { Button, Flex, Input, Select } from "shared/ui";

export const SetFrequencyInputs = ({
  register,
  frequenciesOptions,
  errors,
  onSubmit,
  watch,
}) => (
  <>
    <Flex mt="20px">
      <Select
        {...register("visitFrequency")}
        title="Частота визита"
        options={frequenciesOptions}
        errorText={errors?.frequency?.message}
        placeholder="Разное"
      />
    </Flex>
    <Flex mt="12px">
      <Input
        {...register("duration", {
          valueAsNumber: true,
        })}
        title="Время визита"
        errorText={errors?.duration?.message}
        placeholder="Разное"
      />
    </Flex>
    <Flex mt="20px">
      <Button
        width="100%"
        text="Применить"
        onClick={onSubmit}
        disabled={!watch("visitFrequency") && !watch("duration")}
      />
    </Flex>
  </>
);
