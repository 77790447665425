import { Cross, Maximize, Minimize, Refresh } from "app/assets/icons";
import { resetOpenedReport } from "app/store/reducer/shedule";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useListConsolidatedReportQuery } from "shared/api/areas";
import { useListAnalyzePeriodQuery } from "shared/api/logistic";
import { Button, Flex, Text } from "shared/ui";
import styled from "styled-components";

import { configColumns } from "./config";
import Table from "./ui/table";

const ConsolidatedReport = () => {
  const dispatch = useDispatch();
  const [isExpand, setIsExpand] = useState(false);
  const { projectInfo } = useSelector(
    (state) => state.rootReducer.currentProject,
  );
  const { openedReport } = useSelector((state) => state.rootReducer.shedule);
  const { data: analyzePeriod } = useListAnalyzePeriodQuery(
    {
      projectId: projectInfo.id,
    },
    { skip: !projectInfo.id },
  );
  const {
    data: report,
    isFetching,
    refetch,
  } = useListConsolidatedReportQuery(
    {
      projectId: projectInfo.id,
      areaId: openedReport.areaId,
      monthFrom: analyzePeriod?.monthFrom,
      monthTo: analyzePeriod?.monthTo,
      yearFrom: analyzePeriod?.yearFrom,
      yearTo: analyzePeriod?.yearTo,
    },
    {
      skip:
        !projectInfo.id ||
        !openedReport.areaId ||
        !analyzePeriod?.monthFrom ||
        !analyzePeriod?.monthTo ||
        !analyzePeriod?.yearFrom ||
        !analyzePeriod?.yearTo,
      refetchOnMountOrArgChange: true,
    },
  );

  const data = React.useMemo(() => report, [report]);
  const columns = React.useMemo(() => configColumns, []);

  if (isFetching) {
    return null;
  }

  return (
    <Root>
      <Header>
        <Flex mr="10px">
          <Text variant="title1">Отчёт сводный {openedReport.areaName}</Text>
        </Flex>
      </Header>
      <Table data={data} columns={columns} isExpand={isExpand} />
      <Flex flexDirection="row" mt="20px">
        <Button text="ОБНОВИТЬ" icon={<Refresh />} onClick={() => refetch()} />
        <CustomButton
          className="expandButton"
          onClick={() => setIsExpand(!isExpand)}
        >
          {isExpand ? <Minimize /> : <Maximize />}
        </CustomButton>
        <CustomButton
          className="closeButton"
          onClick={() => dispatch(resetOpenedReport())}
        >
          <Cross />
        </CustomButton>
      </Flex>
    </Root>
  );
};

const Root = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 20px;
  background: #fff;
  z-index: 9999;
  box-shadow: 0px -8px 10px -4px rgba(0, 0, 0, 0.1);
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const CustomButton = styled.button`
  background: #ecf4fe;
  border-radius: 12px;
  width: 56px;
  height: 48px;
  border: none;
  outline: none;
  color: #2d8af6;

  &.expandButton {
    margin-left: auto;
  }

  &.closeButton {
    margin-left: 8px;
  }
`;

export default ConsolidatedReport;
