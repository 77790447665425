import { Tab, Tabs } from "@material-ui/core";
import React from "react";
import styled from "styled-components";

const TableTabs = ({ currentTable, handleChangeTable }) => (
  <StyledTabs
    value={currentTable}
    onChange={handleChangeTable}
    textColor="inherit"
  >
    <Tab label="Маршруты" value="routeList" />
    <Tab label="Точки" value="pointList" />
    <Tab label="График посещений" value="routePointList" />
  </StyledTabs>
);

const StyledTabs = styled(Tabs)`
  .MuiTab-textColorInherit {
    font-size: 16px;

    &.Mui-selected {
      color: #2d8af6;
    }
  }

  .MuiTabs-indicator {
    background-color: #2d8af6;
  }
`;

export default TableTabs;
