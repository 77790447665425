import { SORT } from "shared/config";

import { AreaCell, HeaderCellSort, PointCell } from "../model";

export const configColumns = [
  {
    id: "0",
    Header: <HeaderCellSort text="Точка" sortBy={SORT.POINT} />,
    accessor: (data) => <PointCell data={data} />,
    w: "40%",
    textAlign: "left",
  },
  {
    id: "1",
    Header: "Контракт",
    accessor: "contractName",
    w: "10%",
    textAlign: "center",
  },
  {
    id: "2",
    Header: <HeaderCellSort text="Продажи, т.р." sortBy={SORT.MARGIN} />,
    accessor: "margin",
    w: "15%",
    textAlign: "center",
  },
  {
    id: "3",
    Header: <HeaderCellSort text="Объём, м3" sortBy={SORT.VOLUME} />,
    accessor: "volume",
    w: "10%",
    textAlign: "center",
  },
  {
    id: "4",
    Header: <HeaderCellSort text="Вес, тонн" sortBy={SORT.WEIGHT} />,
    accessor: "weight",
    w: "10%",
    textAlign: "center",
  },
  {
    id: "5",
    Header: "Зона/график",
    accessor: (data) => <AreaCell data={data} />,
    w: "15%",
    textAlign: "left",
  },
];
