/* eslint-disable no-unused-expressions */
import { whiteFilledCheckboxIcon } from "icons/icons";
import React from "react";
import { Dropdown } from "react-bootstrap";
import { Flex, Text } from "shared/ui";
import styled from "styled-components";

import { Item, Search, Toggle as SearchDropdownToggle } from "./ui";

const { Toggle, Menu } = Dropdown;

const SearchDropdown = ({
  title = "",
  isDropdownOpen = false,
  toggleText = "Выбрать",
  searchValue = "",
  onToggle = () => {},
  onSearch = () => {},
  onResetSearch = () => {},
  onResetAllCheckboxes = () => {},
  multiselect = false,
  selectedText = false,
  selectedCount = 0,
  errorText = "",
  long = false,
  children,
}) => (
  <Root>
    {title && (
      <Flex flexDirection="row" mb="4px">
        <Text variant="body3" color="color3">
          {title}
        </Text>
      </Flex>
    )}
    <Dropdown onToggle={(isOpen) => onToggle(isOpen)} show={isDropdownOpen}>
      <Toggle
        id="dropdown-custom-components"
        as={SearchDropdownToggle}
        toggleText={toggleText}
      />
      <Menu>
        <Flex p="10px">
          <Search
            value={searchValue}
            onChange={(e) => onSearch(e.target.value)}
            onReset={() => onResetSearch()}
          />
        </Flex>
        <Flex maxHeight={long ? 'none' : '250px'} overflowY="scroll" overflowX="hidden">
          {children}
        </Flex>
        {selectedText && (
          <Flex
            display={selectedCount ? "flex" : "none"}
            flexDirection="row"
            justifyContent="flex-start"
            alignItems="center"
            bg="color2"
            padding="12px 8px"
            cursor="pointer"
            onClick={() => onResetAllCheckboxes()}
          >
            {multiselect ? (
              <>
                <Flex cursor="pointer">{whiteFilledCheckboxIcon}</Flex>
                <Flex ml="7px">
                  <Text variant="body3" color="color1" ml="7px">
                    ВЫБРАНО {selectedCount}
                  </Text>
                </Flex>
              </>
            ) : (
              <Flex ml="7px">
                <Text variant="body1" color="color1">
                  Не выбрано
                </Text>
              </Flex>
            )}
          </Flex>
        )}
      </Menu>
    </Dropdown>
    {errorText && (
      <Flex mt="4px">
        <Text variant="body3" color="color5">
          {errorText}
        </Text>
      </Flex>
    )}
  </Root>
);

const Root = styled.div`
  width: 100%;

  .dropdown-menu {
    width: 100%;
    min-width: 0;
    padding: 0;
    margin: 0;
    border: none;
    box-sizing: border-box;
  }

  .dropdown-menu.show {
    width: 100%;
    box-sizing: border-box;
    border-radius: 0;
    border: 2px solid #2d8af6;
    transform: translate(0px, 0px) !important;
  }
`;

SearchDropdown.Item = Item;

export default SearchDropdown;
