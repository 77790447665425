import * as React from "react";

const SvgCheckedMarker = ({ color = "#000000", ...props }) => (
  <svg
    width={52}
    height={56}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M22 6C11.785 6 3.5 14.285 3.5 24.5c0 5.924 3.267 12.304 6.503 17.236 3.29 5.014 6.81 8.917 7.777 9.965.051.055.107.117.166.184C18.615 52.634 19.836 54 22 54c2.165 0 3.385-1.366 4.054-2.115l.165-.184c.967-1.046 4.488-4.95 7.778-9.965C37.233 36.804 40.5 30.424 40.5 24.5 40.5 14.285 32.215 6 22 6Zm0 27.5a9 9 0 1 1 0-18 9 9 0 0 1 0 18ZM22 17c-4.14 0-7.5 3.36-7.5 7.5 0 4.14 3.36 7.5 7.5 7.5 4.14 0 7.5-3.36 7.5-7.5 0-4.14-3.36-7.5-7.5-7.5Z"
      fill={color}
      stroke="#fff"
      strokeWidth={4}
    />
    <rect
      x={30}
      y={2}
      width={20}
      height={20}
      rx={6}
      fill={color}
      stroke="#fff"
      strokeWidth={4}
    />
    <path
      d="M38 14.78 35.22 12l-.947.94L38 16.667l8-8-.94-.94L38 14.78Z"
      fill="#fff"
    />
  </svg>
);

export default SvgCheckedMarker;
