import React from "react";

const ArrowIos = ({direction = 'right'}) => {

  const getTransform = () => {
    if (direction === 'right') {
      return 'rotate(90deg)'
    }
    if (direction === 'down') {
      return 'rotate(180deg)'
    }
    if (direction === 'left') {
      return 'rotate(270deg)'
    }
    return ''
  }

  return (
    <svg
      width="13"
      height="13"
      viewBox="0 0 13 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        transform: getTransform(),
        transition: "transform 0.1s"
      }}
    >
      <g id="arrow">
        <path
          id="Vector"
          d="M11.4332 8L6.47771 3.05533L1.52225 8L-2.09808e-05 6.47773L6.47771 0L12.9554 6.47773L11.4332 8Z"
          fill="black"
        />
      </g>
    </svg>
  );
};

export default ArrowIos;
