import * as React from "react";

const SvgDelete = (props) => (
  <svg
    width={24}
    height={25}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#delete_svg__a)">
      <path
        d="M6 21.5h12v-14H6v14Zm13-17h-3.5l-1-1h-5l-1 1H5v2h14v-2Z"
        fill="#F57200"
      />
    </g>
    <defs>
      <clipPath id="delete_svg__a">
        <path fill="#fff" transform="translate(0 .5)" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgDelete;
