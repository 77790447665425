import { api } from "shared/api";

const logisticApi = api.injectEndpoints({
  endpoints: (builder) => ({
    listLogisticPoints: builder.query({
      query: ({ projectId, filters, monthFrom, monthTo, yearFrom, yearTo }) =>
        `projects/${projectId}/logistic-points?page=${filters.page}&limit=${filters.limit}&search=${filters.address}&sortBy=${filters.sort}&areaIds=${filters.areaIds}&branchIds=${filters.branchIds}&contractIds=${filters.contractIds}&groupIds=${filters.groupIds}&monthFrom=${monthFrom}&monthTo=${monthTo}&yearFrom=${yearFrom}&yearTo=${yearTo}`,
      providesTags: ["Logistic"],
    }),
    listLogisticPointsMap: builder.query({
      query: ({
        projectId,
        areaIds,
        branchIds,
        contractIds,
        groupIds,
        monthFrom,
        monthTo,
        yearFrom,
        yearTo,
      }) =>
        `projects/${projectId}/logistic-points/maps?areaIds=${areaIds}&branchIds=${branchIds}&contractIds=${contractIds}&groupIds=${groupIds}&monthFrom=${monthFrom}&monthTo=${monthTo}&yearFrom=${yearFrom}&yearTo=${yearTo}`,
    }),
    listAnalyzePeriod: builder.query({
      query: ({ projectId }) => `projects/${projectId}/logistic-points/periods`,
      providesTags: ["Areas"],
    }),
    setAnalyzePeriod: builder.mutation({
      query: ({ projectId, ...body }) => ({
        url: `projects/${projectId}/logistic-points/periods`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Areas"],
    }),
  }),
});

export const {
  useListLogisticPointsQuery,
  useListLogisticPointsMapQuery,
  useListAnalyzePeriodQuery,
  useSetAnalyzePeriodMutation,
} = logisticApi;
