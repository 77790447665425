import { useEffect } from "react";
import { useMap } from "react-leaflet";

const ResizeObserver = ({ expanded }) => {
  const map = useMap();

  useEffect(() => {
    setTimeout(() => {
      map.invalidateSize(true);
    }, 0);
  }, [expanded]);

  return null;
};

export default ResizeObserver;
