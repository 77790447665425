/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  projectInfo: {
    id: null,
    name: "",
    status: "",
  },
};

const currentProject = createSlice({
  name: "currentProject",
  initialState,
  reducers: {
    resetState: () => initialState,
    setInfo: (state, action) => {
      state.projectInfo = action.payload;
    },
  },
});

export const { resetState, setInfo } = currentProject.actions;

export default currentProject.reducer;
