import Switch from "@material-ui/core/Switch";
import { PopupContext } from "app/context/PopupContext";
import {
  resetSelectedRoutePoints,
  setAllPointsChecked,
} from "app/store/reducer/routes";
import { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useListFiltersRoutesUsersDuplicatesQuery } from "shared/api/filters";
import {
  useListMapRoutePointsQuery,
  useSetRouteMutation,
} from "shared/api/routes";
import { sortByField } from "shared/helpers";
import {
  Button,
  Checkbox,
  CustomRadio,
  MultilineErrorText,
  Popup,
} from "shared/ui";
import styled from "styled-components";

const SetRoute = () => {
  const dispatch = useDispatch();
  const { hidePopup } = useContext(PopupContext);
  const [serverErrors, setServerErrors] = useState([]);
  const [isMultiple, setIsMultiple] = useState(false);
  const [selectedRoutes, setSelectedRoutes] = useState([]);

  const { projectInfo } = useSelector(
    (state) => state.rootReducer.currentProject,
  );
  const { frontendFilters, selectedRoutePoints, allPointsChecked } =
    useSelector((state) => state.rootReducer.routes);
  const { data: filters, isSuccess } = useListFiltersRoutesUsersDuplicatesQuery(
    {
      projectId: projectInfo.id,
      managers: frontendFilters.managerCodes,
    },
    { skip: !projectInfo.id, refetchOnMountOrArgChange: true },
  );
  const { data: allRoutePoints } = useListMapRoutePointsQuery(
    {
      projectId: projectInfo.id,
      managerCodes: frontendFilters?.managerCodes,
      commands: frontendFilters?.commands,
      periodicityList: frontendFilters?.periodicityList,
      routeCodes: frontendFilters?.routeCodes,
      daysOfWeek: frontendFilters?.daysOfWeek,
      attributeCodes1: frontendFilters?.attributeCodes1,
      attributeCodes2: frontendFilters?.attributeCodes2,
    },
    { skip: !projectInfo.id, refetchOnMountOrArgChange: true },
  );
  const [setRoute, { isLoading }] = useSetRouteMutation();

  const handleSwitch = () => {
    setIsMultiple((prev) => !prev);
    setSelectedRoutes([]);
  };

  const handleSelectRoutes = (routeCode) => {
    if (isMultiple) {
      if (selectedRoutes.includes(routeCode)) {
        setSelectedRoutes([
          ...selectedRoutes.filter((item) => item !== routeCode),
        ]);
      } else {
        setSelectedRoutes([...selectedRoutes, routeCode]);
      }
    } else {
      setSelectedRoutes([routeCode]);
    }
  };

  const onSubmit = async () => {
    let response;
    if (allPointsChecked) {
      const allRoutePointsIdList = allRoutePoints.map((point) => point.id);
      response = await setRoute({
        projectId: projectInfo.id,
        routePointIdList: allRoutePointsIdList,
        routeCodes: selectedRoutes,
      });
    } else {
      response = await setRoute({
        projectId: projectInfo.id,
        routePointIdList: selectedRoutePoints,
        routeCodes: selectedRoutes,
      });
    }

    if (response?.data?.errorMessages.length) {
      setServerErrors(response?.data?.errorMessages);
      return;
    }

    dispatch(
      allPointsChecked
        ? setAllPointsChecked(false)
        : resetSelectedRoutePoints(),
    );
    hidePopup();
  };

  if (isSuccess) {
    return (
      <Popup title="Задать маршрут" isCenter={false} positionLeft="130px">
        <SwitchRow isMultiple={isMultiple}>
          <SwitchText>Выбрать несколько</SwitchText>
          <div>
            <Switch
              checked={isMultiple}
              onChange={handleSwitch}
              size="small"
              color="default"
            />
          </div>
        </SwitchRow>
        {!isMultiple && (
          <ListWrapper>
            {sortByField(filters?.routes, "routeName").map(
              ({ routeCode, routeName, color }) => (
                <CustomRadio
                  key={routeCode}
                  className="customRadio"
                  filledColor={color}
                  emptyColor={color}
                  label={routeName}
                  name="dayOfWeek"
                  checked={selectedRoutes.includes(routeCode)}
                  onChange={() => handleSelectRoutes(routeCode)}
                />
              ),
            )}
          </ListWrapper>
        )}
        {isMultiple && (
          <ListWrapper>
            {sortByField(filters?.routes, "routeName").map(
              ({ routeCode, routeName, color }) => (
                <CheckboxRow key={routeCode}>
                  <Checkbox
                    name="dayOfWeek"
                    filledColor={color}
                    emptyColor={color}
                    checked={selectedRoutes.includes(routeCode)}
                    onChange={() => handleSelectRoutes(routeCode)}
                  />
                  <CheckboxesText color={color}>{routeName}</CheckboxesText>
                </CheckboxRow>
              ),
            )}
          </ListWrapper>
        )}
        <Button
          width="100%"
          text="Применить"
          disabled={!selectedRoutes.length || isLoading}
          onClick={onSubmit}
        />
        <MultilineErrorText errorsArr={serverErrors} />
      </Popup>
    );
  }

  return null;
};

const SwitchRow = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 0;

  .MuiSwitch-thumb {
    color: ${({ isMultiple }) => (isMultiple ? "#000" : "#8c8c8c")};
  }

  .MuiSwitch-track {
    opacity: ${({ isMultiple }) => (isMultiple ? "0.5" : "0.15")};
  }
`;

const ListWrapper = styled.div`
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-height: 290px;
  overflow-y: auto;

  .customRadio {
    margin-bottom: 12px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const SwitchText = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  color: #787878;
  margin-right: 7px;
`;

const CheckboxRow = styled.label`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  cursor: pointer;

  &:last-child {
    margin-bottom: 0;
  }
`;

const CheckboxesText = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  margin: 0 0 0 6px;
  color: ${({ color }) => color};
`;

export default SetRoute;
