import { PopupContext } from "app/context/PopupContext";
import {
  LoadContractGroupsPopup,
  ProjectOptimization,
  RoutesCalculation,
} from "pages/projects/popups";
import { useContext } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useUpdateProjectPointsMutation } from "shared/api/current-project";
import { APP_ROUTES, USER_TYPE } from "shared/config";
import { getUserRole } from "shared/libs/jwt-decode";
import { Loader, PageShadow } from "shared/ui";

import {
  ClearExcelData,
  DaysReport, DefineContractAreas,
  DefineDeliveryDaysOff,
  DeliveryReport,
  EditExcelRoutes, ExportExcelData,
  RoutesReport
} from "../../../popups";
import { NavigationDropdown, RouteEngNavigationDropdown } from "../../ui";

const ProjectDropdown = () => {
  const navigate = useNavigate();
  const { showPopup } = useContext(PopupContext);
  const { projectInfo } = useSelector(
    (state) => state.rootReducer.currentProject,
  );
  const role = getUserRole();
  const [updateProjectPoints, { isLoading: isUpdateProjectPointsLoading }] =
    useUpdateProjectPointsMutation();

  const navigateToProject = () => {
    if (role === USER_TYPE.LOGISTIC_USER) {
      navigate(`/areas/${projectInfo.id}`);
    } else navigate(`/routes/${projectInfo.id}`);
  };

  const updatePoints = async () => {
    await updateProjectPoints({ projectId: projectInfo.id });
  };

  const navigateToExcelImport = () => {
    navigate(`/import-excel-data/${projectInfo.id}`);
  };

  const userItems = [
    {
      id: 100,
      text: "Параметры расчета маршрутов",
      onClick: () =>
        showPopup(RoutesCalculation, {
          project: projectInfo,
        }),
    },
    {
      id: 101,
      text: "Оптимизиция проекта",
      onClick: () => showPopup(ProjectOptimization, { project: projectInfo }),
    },
    {
      id: 102,
      text: "Обновление точек",
      onClick: () => updatePoints(),
    },
    {
      id: 105,
      text: "Выбрать другой проект",
      onClick: () => navigate(APP_ROUTES.PROJECTS),
    },
  ];

  const logisticUserItems = [
    {
      id: 200,
      text: "Обновление точек",
      onClick: () => updatePoints(),
    },
    {
      id: 201,
      text: "Редактирование групп зон контрактов",
      onClick: () =>
        showPopup(LoadContractGroupsPopup, {
          project: projectInfo,
          navigateToProject,
        }),
    },
    {
      id: 202,
      text: "Выбрать другой проект",
      onClick: () => navigate(APP_ROUTES.PROJECTS),
    },
  ];

  // NEW SECTIONS
  const reportItems = [
    {
      id: 400,
      text: "Маршруты",
      onClick: () => {
        showPopup(RoutesReport, { project: projectInfo });
      },
    },
    {
      id: 401,
      text: "Дни недели",
      onClick: () => {
        showPopup(DaysReport, { project: projectInfo });
      },
    },
    {
      id: 402,
      text: "Доставка",
      onClick: () => {
        showPopup(DeliveryReport, { project: projectInfo });
      },
    },
  ];

  const optimizationItems = [
    {
      id: 500,
      text: "Параметры расчета маршрутов",
      onClick: () =>
        showPopup(RoutesCalculation, {
          project: projectInfo,
        }),
    },
    {
      id: 501,
      text: "Оптимизиция проекта",
      onClick: () => showPopup(ProjectOptimization, { project: projectInfo }),
    },
  ];

  const dataProcessingItems = [
    {
      id: 600,
      text: "Задать выходные дни доставки",
      onClick: () => {
        showPopup(DefineDeliveryDaysOff, { project: projectInfo });
      },
    },
    {
      id: 601,
      text: "Задать отображаемые контракты",
      onClick: () => {
        showPopup(DefineContractAreas, { project: projectInfo });
      },
    },
  ];

  const excelDataProcessingItems = [
    {
      id: 700,
      text: "Догрузить данные в проект",
      onClick: () => navigateToExcelImport(),
    },
    {
      id: 701,
      text: "Редактировать таблицу маршрутов",
      onClick: () =>
        showPopup(EditExcelRoutes, {
          project: projectInfo,
        }),
    },
    {
      id: 702,
      text: "Удалить данные",
      onClick: () =>
        showPopup(ClearExcelData, {
          project: projectInfo,
        }),
    },
    {
      id: 703,
      text: "Экспорт в Excel",
      onClick: () =>
        showPopup(ExportExcelData, {
          project: projectInfo,
        }),
    },
  ];

  // AS - учетная система, УС
  const ASDataProcessingItems = [
    {
      id: 800,
      text: "Обновление точек",
      onClick: () => updatePoints(),
    },
  ];

  const getDropdownItems = () => {
    if (role === USER_TYPE.LOGISTIC_USER) {
      if (projectInfo.name === "Срочные точки") {
        return logisticUserItems.map((item) => ({
          ...item,
          disabled: item.id !== 202,
        }));
      }
      return logisticUserItems;
    }
    if (role === USER_TYPE.ROUTE_ENG) {
      const isExcel = projectInfo.status === "Загружен из Excel";
      const empty = projectInfo.status === "Создан";
      const dropdownSections = [
        {
          sectionName: "Отчеты",
          items: reportItems,
        },
        {
          sectionName: "Оптимизация",
          items: optimizationItems,
        },
        {
          sectionName: "Работа с данными",
          items: [],
        },
        {
          sectionName: "Другое",
          items: [
            {
              id: 101,
              text: "Выбрать другой проект",
              onClick: () => navigate(APP_ROUTES.PROJECTS),
            },
          ],
        },
      ];

      if (empty) {
        dropdownSections[2].items = [
          ...dataProcessingItems,
          ...excelDataProcessingItems,
          ...ASDataProcessingItems,
        ];
        return dropdownSections;
      }
      if (isExcel) {
        dropdownSections[2].items = [
          ...dataProcessingItems,
          ...excelDataProcessingItems,
        ];
        return dropdownSections;
      }
      // else - 'Загружен из УС'
      dropdownSections[2].items = [
        ...dataProcessingItems,
        ...ASDataProcessingItems,
      ];
      return dropdownSections;
    }
    return userItems;
  };

  return (
    <>
      {role === USER_TYPE.ROUTE_ENG ? (
        <RouteEngNavigationDropdown
          toggleText={projectInfo.name}
          items={getDropdownItems()}
        />
      ) : (
        <NavigationDropdown
          toggleText={projectInfo.name}
          items={getDropdownItems()}
        />
      )}
      {isUpdateProjectPointsLoading && ( // TODO: make page shadow for all requests on page level ??
        <PageShadow>
          <Loader isLoading />
        </PageShadow>
      )}
    </>
  );
};

export default ProjectDropdown;
