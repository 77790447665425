import Flex from "../Flex";
import Text from "../Text";

const MultilineErrorText = ({ errorsArr = [] }) => {
  if (errorsArr?.length) {
    return (
      <Flex m="10px 0px">
        {errorsArr?.map((error) => (
          <Text key={error} variant="body3" textAlign="center" color="color5">
            {error}
          </Text>
        ))}
      </Flex>
    );
  }
  return null;
};

export default MultilineErrorText;
