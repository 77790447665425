import * as React from "react";

const SvgDragIndicator = (props) => (
  <svg
    width={8}
    height={12}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1.833 11.333c-.323 0-.598-.114-.825-.341a1.124 1.124 0 0 1-.342-.825c0-.322.114-.597.342-.825.227-.228.502-.342.825-.342.322 0 .597.114.825.342.227.228.341.503.341.825 0 .322-.114.597-.341.825a1.124 1.124 0 0 1-.825.341Zm4.333 0c-.322 0-.597-.114-.825-.341a1.124 1.124 0 0 1-.342-.825c0-.322.114-.597.342-.825.228-.228.503-.342.825-.342.322 0 .597.114.825.342.228.228.342.503.342.825 0 .322-.114.597-.342.825a1.124 1.124 0 0 1-.825.341ZM1.833 7.167c-.323 0-.598-.114-.825-.342A1.124 1.124 0 0 1 .666 6c0-.322.114-.597.342-.825.227-.228.502-.342.825-.342.322 0 .597.114.825.342.227.228.341.503.341.825 0 .322-.114.597-.341.825a1.124 1.124 0 0 1-.825.342Zm4.333 0c-.322 0-.597-.114-.825-.342A1.124 1.124 0 0 1 4.999 6c0-.322.114-.597.342-.825.228-.228.503-.342.825-.342.322 0 .597.114.825.342.228.228.342.503.342.825 0 .322-.114.597-.342.825a1.124 1.124 0 0 1-.825.342ZM1.833 3c-.323 0-.598-.114-.825-.342a1.124 1.124 0 0 1-.342-.825c0-.322.114-.597.342-.825.227-.227.502-.341.825-.341.322 0 .597.114.825.341.227.228.341.503.341.825 0 .323-.114.598-.341.825A1.124 1.124 0 0 1 1.833 3Zm4.333 0c-.322 0-.597-.114-.825-.342a1.124 1.124 0 0 1-.342-.825c0-.322.114-.597.342-.825.228-.227.503-.341.825-.341.322 0 .597.114.825.341.228.228.342.503.342.825 0 .323-.114.598-.342.825A1.124 1.124 0 0 1 6.166 3Z"
      fill="#787878"
    />
  </svg>
);

export default SvgDragIndicator;
