import { resetExpandedMapPopupState } from "app/store/reducer/expanded-map-popup";
import {
  expandMap,
  switchClustering,
} from "app/store/reducer/project-settings";
import {
  setSelectedByLassoRoutePoints,
  switchMapRoutePointsVisible,
} from "app/store/reducer/routes";
import { ChangeViewNew } from "features";
import ResizeObserver from "features/map-resize-observer";
import RememberBounds from "features/return-map-to-prev-bounds";
import useLasso from "features/use-lasso";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useListAreasMapQuery } from "shared/api/areas";
import { useListMapRoutePointsQuery } from "shared/api/routes";
import { PROJECT_MODE } from "shared/config";
import { Loader, Map as MapUI } from "shared/ui";
import Lasso from "shared/ui/lasso";
import { DayBasedPolygons } from "widgets";

import TableFooter from "../table/footer";
import { Cluster, Footer, Polygons } from "./model";
import { ExpandedPointPopup } from "./ui";

const Map = () => {
  const dispatch = useDispatch();
  const { projectInfo } = useSelector(
    (state) => state.rootReducer.currentProject,
  );

  // Map data
  const {
    frontendFilters,
    mapCenterPoint,
    isMapRoutePointsVisible,
    isAreasVisible,
    highlightedPointId,
  } = useSelector((state) => state.rootReducer.routes);

  const {
    data: mapRoutePoints,
    isSuccess: isPointsSuccess,
    isFetching: isPointsFetching,
  } = useListMapRoutePointsQuery(
    {
      projectId: projectInfo.id,
      managerCodes: frontendFilters?.managerCodes,
      commands: frontendFilters?.commands,
      periodicityList: frontendFilters?.periodicityList,
      routeCodes: frontendFilters?.routeCodes,
      daysOfWeek: frontendFilters?.daysOfWeek,
      attributeCodes1: frontendFilters?.attributeCodes1,
      attributeCodes2: frontendFilters?.attributeCodes2,
    },
    { skip: !projectInfo.id, refetchOnMountOrArgChange: true },
  );

  const {
    data: areas,
    isSuccess: isAreasSuccess,
    isFetching: isAreasFetching,
  } = useListAreasMapQuery(
    {
      projectId: projectInfo.id,
    },
    { skip: !projectInfo.id, refetchOnMountOrArgChange: true },
  );

  // Map settings
  const { expandMode, clustering } = useSelector(
    (state) => state.rootReducer.projectSettings,
  );

  // Fullscreen map controls
  const expanded = expandMode === "map";
  const handleSwitchMapExpand = () => {
    dispatch(expandMap());
  };
  useEffect(() => {
    dispatch(resetExpandedMapPopupState());
  }, [mapRoutePoints]);

  // Toggle clustering
  const handleSwitchClustering = () => {
    dispatch(switchClustering());
  };

  // Lasso
  const { isLassoActive, handleSwitchLasso, handleFinishLasso } = useLasso(
    mapRoutePoints,
    setSelectedByLassoRoutePoints,
  );

  // Day based polygons
  const [isDayBasedPolygonsVisible, setIsDayBasedPolygonsVisible] =
    useState(false);
  const handleSwitchDayBasedPolygonsVisibility = () => {
    setIsDayBasedPolygonsVisible((prevState) => !prevState);
  };

  if (isAreasFetching || isPointsFetching) {
    return <Loader isLoading />;
  }

  if (isAreasSuccess && isPointsSuccess) {
    return (
      <>
        <MapUI
          enableLasso
          enableDayBasedPolygons
          enableClusteringDisableButton
          isLassoActive={isLassoActive}
          switchIsLassoActive={handleSwitchLasso}
          expanded={expanded}
          switchMapExpand={handleSwitchMapExpand}
          isMapPointsVisible={isMapRoutePointsVisible}
          switchMapPointsVisible={() => dispatch(switchMapRoutePointsVisible())}
          clustering={clustering}
          switchClustering={handleSwitchClustering}
          dayBasedPolygonsVisible={isDayBasedPolygonsVisible}
          switchDayBasedPolygonsVisibility={
            handleSwitchDayBasedPolygonsVisibility
          }
        >
          <ChangeViewNew mapCenterPoint={mapCenterPoint} />
          <RememberBounds />
          <ResizeObserver expanded={expanded} />
          {isMapRoutePointsVisible && (
            <Cluster
              data={mapRoutePoints}
              highlightedPointId={highlightedPointId}
            />
          )}
          {isAreasVisible && (
            <Polygons areas={areas} mapCenterPoint={mapCenterPoint} />
          )}
          <Lasso
            isLassoActive={isLassoActive}
            onLassoFinished={handleFinishLasso}
          />
          {isDayBasedPolygonsVisible && (
            <DayBasedPolygons
              data={mapRoutePoints}
              projectMode={PROJECT_MODE.ROUTES}
            />
          )}
        </MapUI>
        {expanded && <ExpandedPointPopup />}
        {expanded && <TableFooter inMap />}
        <Footer expanded={expanded} />
      </>
    );
  }

  return null;
};

export default Map;
