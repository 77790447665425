const lightTheme = {
  name: "light",
  colors: {
    color0: "#2D8AF6", // Blue
    color1: "#FFFFFF", // White
    color2: "#000000", // Black
    color3: "#595959", // Grey
    color4: "#CCCCCC", // Grey Light
    color5: "#FF3131", // Red
    color6: "#FFB342", // Yellow
    color7: "#787878", // Grey Dark
    color8: "#377F15", // Green
  },
  typography: {
    title1: `
      font-weight: 500;
      font-style: normal;
      font-size: 28px;
      line-height: 34px;
      color: #000000;
    `,
    title1bold: `
      font-weight: 600;
      font-style: normal;
      font-size: 28px;
      line-height: 34px;
      color: #000000;
    `,
    body1: `
      font-weight: 400;
      font-style: normal;
      font-size: 18px;
      line-height: 24px;
      color: #000000;
    `,
    body1bold: `
      font-weight: 600;
      font-style: normal;
      font-size: 18px;
      line-height: 24px;
      color: #000000;
    `,
    body2: `
      font-weight: 400;
      font-style: normal;
      font-size: 16px;
      line-height: 21px;
      color: #000000;
    `,
    body2bold: `
      font-weight: 600;
      font-style: normal;
      font-size: 16px;
      line-height: 21px;
      color: #000000;
    `,
    body3: `
      font-weight: 400;
      font-style: normal;
      font-size: 14px;
      line-height: 18px;
      color: #000000;
    `,
    body3bold: `
      font-weight: 600;
      font-style: normal;
      font-size: 14px;
      line-height: 18px;
      color: #000000;
    `,
  },
};

export default lightTheme;
