import { yupResolver } from "@hookform/resolvers/yup";
import { SignInBackground, SignInLogo } from "app/assets/icons";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useSignInMutation } from "shared/api/auth";
import { SERVER_ERROR_TEXT } from "shared/config";
import { setAuthToken } from "shared/libs/local-storage";
import { Button, EmailInput, ErrorText, Flex, PasswordInput } from "shared/ui";
import styled from "styled-components";

import { SignInSchema, defaultValues } from "./config";

const SignIn = () => {
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(SignInSchema),
    defaultValues,
  });
  const [serverError, setServerError] = useState("");
  const [passwordType, setPasswordType] = useState("password");
  const [signIn, { isLoading }] = useSignInMutation();

  const onSignInButtonClick = async (values) => {
    const response = await signIn(values);

    if (response.error) {
      setServerError(response.error?.data?.message || SERVER_ERROR_TEXT);
      return;
    }

    setAuthToken(response.data.token);
    window.location.reload();
  };

  const onClear = () => {
    reset(defaultValues);
    if (serverError) setServerError("");
    if (passwordType === "text") setPasswordType("password");
  };

  const switchPasswordType = () => {
    if (passwordType === "password") {
      setPasswordType("text");
    } else setPasswordType("password");
  };

  return (
    <PageWrapper>
      <Flex position="absolute">
        <SignInBackground />
      </Flex>
      <Form>
        <SignInLogo />
        <EmailContainer>
          <EmailInput
            {...register("username", {
              onChange: () => serverError && setServerError(""),
            })}
            title="Логин"
            onClear={onClear}
            disabled={isLoading}
            errorText={errors?.username?.message}
          />
        </EmailContainer>
        <PasswordContainer>
          <PasswordInput
            {...register("password", {
              onChange: () => serverError && setServerError(""),
            })}
            title="Пароль"
            type={passwordType}
            switchPasswordType={switchPasswordType}
            disabled={isLoading}
            errorText={errors?.password?.message}
          />
        </PasswordContainer>
        <Button
          width="275px"
          height="56px"
          color="#2D8AF6"
          text="ВОЙТИ"
          disabled={isLoading}
          onClick={handleSubmit(onSignInButtonClick)}
        />
        <ErrorText errorText={serverError} />
      </Form>
    </PageWrapper>
  );
};

const PageWrapper = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
`;

const Form = styled.div`
  width: 339px;
  background-color: #ffffff;
  padding: 32px;
  border-radius: 20px;
  box-sizing: border-box;
  z-index: 1;
`;

const EmailContainer = styled.div`
  margin-top: 20px;
`;

const PasswordContainer = styled.div`
  margin-top: 12px;
  margin-bottom: 20px;
`;

export default SignIn;
