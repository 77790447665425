import { useTable } from "react-table";
import { isOSX, sortByField } from "shared/helpers";
import styled from "styled-components";

const Table = ({ data = [], columns = [] }) => {
  const tableInstance = useTable({
    columns,
    data: sortByField(data, "routeName"),
  });

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;
  return (
    <TableContent {...getTableProps()}>
      <TableHead>
        {headerGroups.map((headerGroup) => (
          <TableHeaderRow {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <HeaderCell
                {...column.getHeaderProps()}
                width={column.w}
                bgColor={column.bgColor}
              >
                {column.render("Header")}
              </HeaderCell>
            ))}
          </TableHeaderRow>
        ))}
      </TableHead>
      <TableBody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <Row id={row.original.routeCode} {...row.getRowProps()}>
              {!row.original.message &&
                row.cells.map((cell) => (
                  <BodyCell
                    {...cell.getCellProps()}
                    width={cell.column.w}
                    bgColor={cell.column.bgColor}
                  >
                    {cell.render("Cell")}
                  </BodyCell>
                ))}
              {/* {row.original.message && (
                <>
                  <BodyCell width="12.5%">
                    <Text>{row.original.routeName}</Text>
                  </BodyCell>
                  <TableMessageCell width="12.5%">
                    <BodyCell background="transparent">
                      {row.original.message}
                    </BodyCell>
                  </TableMessageCell>
                </>
              )} */}
            </Row>
          );
        })}
      </TableBody>
    </TableContent>
  );
};

const TableContent = styled.table`
  border-collapse: collapse;
  height: auto;
  width: 100%;
`;

const TableHead = styled.thead`
  width: 100%;
`;

const TableHeaderRow = styled.tr`
  display: flex;
  border: 1px solid #cccccc;
  overflow-y: scroll;
  background: rgba(89, 89, 89, 0.08);

  ${!isOSX && "::-webkit-scrollbar {opacity: 0;}"}
`;

const HeaderCell = styled.th`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 24px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  border-right: 1px solid #cccccc;
  background-color: ${({ bgColor }) => bgColor};

  &:last-child {
    border: none;
  }
`;

const Row = styled.tr`
  height: 48px;
  background: #ffffff;
  border-bottom: 1px solid #cccccc;
  border-left: 1px solid #cccccc;
  border-right: 1px solid #cccccc;
  display: flex;

  &:hover {
    background: #ecf4fe;
  }
`;

const BodyCell = styled.td`
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  border-right: 1px solid #cccccc;
  background-color: ${({ bgColor }) => bgColor};

  &:last-child {
    border: none;
  }
`;

// const TableMessageCell = styled.td`
//   width: ${(p) => p.width};
//   padding: 0;
//   flex: 1;
//   font-size: 14px;
// `;

const TableBody = styled.tbody`
  display: block;
  height: 100%;
  max-height: 200px;
  transition: 0.3s;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
`;

export default Table;
