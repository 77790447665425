import * as React from "react";

const SvgCenteredMarker = ({ color = "#000000", ...props }) => (
  <svg
    width={57}
    height={68}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x={1}
      y={1}
      width={55}
      height={66}
      rx={11}
      fill={color}
      fillOpacity={0.24}
    />
    <path
      d="M28.5 10C18.285 10 10 18.285 10 28.5c0 5.924 3.267 12.304 6.503 17.236 3.29 5.014 6.81 8.917 7.777 9.965.051.055.107.117.166.184.669.749 1.89 2.115 4.054 2.115 2.165 0 3.385-1.366 4.054-2.115l.165-.184c.967-1.046 4.488-4.95 7.778-9.965C43.733 40.804 47 34.424 47 28.5 47 18.285 38.715 10 28.5 10Zm0 27.5a9 9 0 1 1 0-18 9 9 0 0 1 0 18Zm0-16.5c-4.14 0-7.5 3.36-7.5 7.5 0 4.14 3.36 7.5 7.5 7.5 4.14 0 7.5-3.36 7.5-7.5 0-4.14-3.36-7.5-7.5-7.5Z"
      fill={color}
      stroke="#fff"
      strokeWidth={4}
    />
    <rect
      x={1}
      y={1}
      width={55}
      height={66}
      rx={11}
      stroke="#fff"
      strokeWidth={2}
    />
  </svg>
);

export default SvgCenteredMarker;
