import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { PROJECT_MODE, USER_TYPE } from "shared/config";
import { getUserRole } from "shared/libs/jwt-decode";

import { NavigationDropdown } from "../../ui";

const ModeDropdown = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { projectInfo } = useSelector(
    (state) => state.rootReducer.currentProject,
  );
  const role = getUserRole();
  const mode = location?.pathname?.split("/")[1];

  const items = [
    {
      id: 0,
      text: PROJECT_MODE.ROUTES,
      onClick: () => navigate(`/routes/${projectInfo.id}`),
    },
    {
      id: 1,
      text: PROJECT_MODE.DAYS,
      onClick: () => navigate(`/days/${projectInfo.id}`),
    },
    {
      id: 2,
      text: PROJECT_MODE.COORDINATES,
      onClick: () => navigate(`/coordinates/${projectInfo.id}`),
    },
  ];

  const logisticUserItems = [
    {
      id: 0,
      text: PROJECT_MODE.LOGISTIC_COORDINATES,
      onClick: () => navigate(`/logistic-coordinates/${projectInfo.id}`),
    },
    {
      id: 1,
      text: PROJECT_MODE.AREAS,
      onClick: () => navigate(`/areas/${projectInfo.id}`),
    },
    {
      id: 2,
      text: PROJECT_MODE.SHEDULE,
      onClick: () => navigate(`/shedule/${projectInfo.id}`),
    },
  ];

  const getModeItems = () => {
    if (role === USER_TYPE.LOGISTIC_USER) {
      if (projectInfo.name === "Срочные точки") {
        return logisticUserItems.map((item) => ({
          ...item,
          disabled: item.id !== 0,
        }));
      }
      return logisticUserItems;
    }
    return items;
  };

  return (
    <NavigationDropdown
      toggleText={
        PROJECT_MODE[mode.toUpperCase().replace(/-/g, "_")] || "Выберите режим"
      }
      items={getModeItems()}
    />
  );
};

export default ModeDropdown;
