import { PopupContext } from "app/context/PopupContext";
import moment from "moment-timezone";
import { useContext, useEffect, useState } from "react";
import { Calendar } from "react-calendar";
import { useSelector } from "react-redux";
import {
  useListAnalyzePeriodQuery,
  useSetAnalyzePeriodMutation,
} from "shared/api/logistic";
import { SERVER_ERROR_TEXT } from "shared/config";
import { calendarFiltersToString } from "shared/helpers";
import { Button, ErrorText, Flex, Popup, Text } from "shared/ui";
import styled from "styled-components";

const AnalysisPeriod = () => {
  const { hidePopup } = useContext(PopupContext);
  const [serverError, setServerError] = useState("");
  const [datesRange, setDatesRange] = useState([new Date(), new Date()]);
  const { projectInfo } = useSelector(
    (state) => state.rootReducer.currentProject,
  );
  const {
    data: { monthFrom, monthTo, yearFrom, yearTo },
    isSuccess,
  } = useListAnalyzePeriodQuery(
    {
      projectId: projectInfo.id,
    },
    { skip: !projectInfo.id },
  );
  const [setAnalyzePeriod] = useSetAnalyzePeriodMutation();

  const onSubmit = async () => {
    const response = await setAnalyzePeriod({
      projectId: projectInfo.id,
      monthFrom: datesRange[0].getMonth() + 1,
      monthTo: datesRange[1].getMonth() + 1,
      yearFrom: datesRange[0].getFullYear(),
      yearTo: datesRange[1].getFullYear(),
    });

    if (response.error) {
      setServerError(response.error?.data?.message || SERVER_ERROR_TEXT);
      return;
    }

    hidePopup();
  };

  const onMonthRangeSelected = (value) => {
    setDatesRange(value);
  };

  const onResetRange = () => {
    setDatesRange([new Date(), new Date()]);
  };

  useEffect(() => {
    if (isSuccess) {
      setDatesRange([
        new Date(yearFrom, monthFrom - 1),
        new Date(yearTo, monthTo - 1),
      ]);
    }
  }, [isSuccess]);

  return (
    <Popup isCenter title="Период анализа">
      <Flex m="20px 0px">
        <Text variant="body1">Усредненная статистика в неделю за период:</Text>
      </Flex>
      <Flex m="20px 0px">
        <Text variant="body1" color="color7">
          {calendarFiltersToString(monthFrom, monthTo, yearFrom, yearTo)}
        </Text>
      </Flex>
      <CalendarWrapper>
        <Calendar
          value={datesRange}
          minDate={new Date(moment().add("month", -14))}
          maxDate={new Date()}
          onChange={onMonthRangeSelected}
          view="year"
          maxDetail="year"
          showDoubleView
          selectRange
          returnValue="range"
        />
      </CalendarWrapper>
      <Flex mt="20px" flexDirection="row" justifyContent="center">
        <Flex mr="12px">
          <Button
            width="144px"
            text="СОХРАНИТЬ"
            onClick={onSubmit}
            // disabled={isLoading}
          />
        </Flex>
        <Flex width="133px">
          <Button
            text="СБРОСИТЬ"
            color="#ECF4FE"
            textColor="#2D8AF6"
            onClick={onResetRange}
          />
        </Flex>
      </Flex>
      <ErrorText errorText={serverError} />
    </Popup>
  );
};

const CalendarWrapper = styled.div`
  .react-calendar {
    border: none;
  }
`;

export default AnalysisPeriod;
