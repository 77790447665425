/* eslint-disable consistent-return */
import { setDaysFilter } from "app/store/reducer/routes";
import { isEqual } from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DAYS_OF_WEEK_COLOR, DAYS_OF_WEEK_RU } from "shared/config";

import { Days } from "../../../../ui";

const VisitDays = () => {
  const dispatch = useDispatch();
  const [selectedDays, setSelectedDays] = useState([]);
  const [isMounted, setIsMounted] = useState(false);

  const { daysOfWeek } = useSelector(
    (state) => state.rootReducer.routes.frontendFilters,
  );

  const onSelect = (day) => {
    if (selectedDays.includes(day)) {
      setSelectedDays((prev) => prev.filter((v) => v !== day));
    } else {
      setSelectedDays((prev) => [...prev, day]);
    }
  };

  useEffect(() => {
    if (isMounted && !isEqual(selectedDays, daysOfWeek)) {
      const setFilterWithDelay = setTimeout(() => {
        dispatch(setDaysFilter(selectedDays));
      }, 300);

      return () => {
        clearTimeout(setFilterWithDelay);
      };
    }
  }, [selectedDays]);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    setSelectedDays(daysOfWeek);
  }, [daysOfWeek]);

  const days = [
    {
      name: DAYS_OF_WEEK_RU.MONDAY,
      color: DAYS_OF_WEEK_COLOR.MONDAY,
    },
    {
      name: DAYS_OF_WEEK_RU.TUESDAY,
      color: DAYS_OF_WEEK_COLOR.TUESDAY,
    },
    {
      name: DAYS_OF_WEEK_RU.WEDNESDAY,
      color: DAYS_OF_WEEK_COLOR.WEDNESDAY,
    },
    {
      name: DAYS_OF_WEEK_RU.THURSDAY,
      color: DAYS_OF_WEEK_COLOR.THURSDAY,
    },
    {
      name: DAYS_OF_WEEK_RU.FRIDAY,
      color: DAYS_OF_WEEK_COLOR.FRIDAY,
    },
    {
      name: DAYS_OF_WEEK_RU.SATURDAY,
      color: DAYS_OF_WEEK_COLOR.SATURDAY,
    },
    {
      name: DAYS_OF_WEEK_RU.SUNDAY,
      color: DAYS_OF_WEEK_COLOR.SUNDAY,
    }
  ];

  return <Days days={days} selectedDays={selectedDays} onSelect={onSelect} />;
};

export default VisitDays