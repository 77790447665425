import { api } from "shared/api";

const filtersApi = api.injectEndpoints({
  endpoints: (builder) => ({
    listFiltersBranchesContracts: builder.query({
      query: ({ projectId }) =>
        `projects/${projectId}/filters/branches/contracts`,
      providesTags: ["Filters"],
    }),
    listFiltersRoutesUsersDuplicates: builder.query({
      query: ({ projectId, managers }) =>
        `projects/${projectId}/filters?managers=${managers}`,
      providesTags: ["Routes"],
    }),
    listFiltersRoutesCommandsDuplicates: builder.query({
      query: ({ projectId, commands }) =>
        `projects/${projectId}/filters/commands/routes?commands=${commands}`,
      providesTags: ["Routes"],
    }),
  }),
});

export const {
  useListFiltersBranchesContractsQuery,
  useListFiltersRoutesUsersDuplicatesQuery,
  useListFiltersRoutesCommandsDuplicatesQuery,
} = filtersApi;
