import { api } from "shared/api";

const logisticCoordinatesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    listPointsLogisticCoordinates: builder.query({
      query: ({ projectId, filters }) =>
        `projects/${projectId}/logistic-points/mode-coordinates?limit=${filters.limit}&page=${filters.page}&branchIds=${filters.branchIds}&contractIds=${filters.contractIds}&search=${filters.address}&distance=${filters.distance}&sortBy=${filters.sort}`,
      providesTags: ["Logistic-Coordinates"],
    }),
    listMapPointsLogisticCoordinates: builder.query({
      query: ({ projectId, branchIds, contractIds, distance }) =>
        `projects/${projectId}/logistic-points/mode-coordinates/maps?branchIds=${branchIds}&contractIds=${contractIds}&distance=${distance}`,
      providesTags: ["Logistic-Coordinates"],
    }),
    changeCurrentCoordsToCalculatedLogisticCoordinates: builder.mutation({
      query: ({ projectId, body }) => ({
        url: `projects/${projectId}/logistic-points/coordinates`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["Logistic-Coordinates", "States"],
    }),
    changeCurrentCoordsToCalculatedForAllPointsLogisticCoordinates:
      builder.mutation({
        query: ({ projectId, ...body }) => ({
          url: `projects/${projectId}/logistic-points/filters/coordinates`,
          method: "PATCH",
          body,
        }),
        invalidatesTags: ["Logistic-Coordinates", "States"],
      }),
    changeCurrentCoordsToNewLogisticCoordinates: builder.mutation({
      query: ({ projectId, pointId, ...body }) => ({
        url: `projects/${projectId}/logistic-points/${pointId}/coordinates`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["Logistic-Coordinates", "States"],
    }),
  }),
});

export const {
  useListPointsLogisticCoordinatesQuery,
  useListMapPointsLogisticCoordinatesQuery,
  useChangeCurrentCoordsToCalculatedLogisticCoordinatesMutation,
  useChangeCurrentCoordsToCalculatedForAllPointsLogisticCoordinatesMutation,
  useChangeCurrentCoordsToNewLogisticCoordinatesMutation,
} = logisticCoordinatesApi;
