/* eslint-disable consistent-return */
import { setAddressFilter } from "app/store/reducer/routes";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Search } from "shared/ui";

const AddressSearch = () => {
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [isMounted, setIsMounted] = useState(false);
  const { frontendFilters: { address } } = useSelector((state) => state.rootReducer.routes);

  useEffect(() => {
    if (isMounted && search !== address) {
      const setFilterWithDelay = setTimeout(() => {
        dispatch(setAddressFilter(search));
      }, 300);

      return () => {
        clearTimeout(setFilterWithDelay);
      };
    }
  }, [search]);

  useEffect(() => {
    setSearch(address);
  }, [address]);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  return (
    <Search
      value={search}
      onChange={(e) => setSearch(e.target.value)}
      onReset={() => setSearch("")}
    />
  );
};

export default AddressSearch;
