import { Eye, EyeCrossed } from "app/assets/icons";
import { switchAreasVisible } from "app/store/reducer/coordinates";
import { useDispatch, useSelector } from "react-redux";
import { Button, Flex } from "shared/ui";
import styled from "styled-components";

const Footer = ({ expanded }) => {
  const dispatch = useDispatch();
  const { isAreasVisible } = useSelector(
    (state) => state.rootReducer.coordinates,
  );

  return (
    <Buttons>
      <Flex
        flexDirection="row"
        justifyContent={expanded ? "end" : "space-between"}
        gridColumnGap={expanded ? "20px" : "0"}
      >
        <Button
          text="ЗОНЫ ДОСТАВКИ"
          icon={isAreasVisible ? <EyeCrossed /> : <Eye />}
          onClick={() => dispatch(switchAreasVisible())}
        />
      </Flex>
    </Buttons>
  );
};

const Buttons = styled.div`
  width: 90%;
  position: absolute;
  z-index: 999;
  bottom: 12px;
  left: 50%;
  transform: translateX(-50%);
`;

export default Footer;
