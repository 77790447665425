import { SignInPage } from "pages";
import React from "react";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import { APP_ROUTES } from "shared/config";
import { isAuthorized } from "shared/libs/jwt-decode";

import { AUTH_PAGES } from "./config";

const Routing = () => {
  if (!isAuthorized()) {
    return (
      <Router>
        <Routes>
          <Route path={APP_ROUTES.SIGN_IN} element={<SignInPage />} exact />
          <Route
            path={APP_ROUTES.ALL}
            element={<Navigate to={APP_ROUTES.SIGN_IN} />}
          />
        </Routes>
      </Router>
    );
  }

  return (
    <Router>
      <Routes>
        {AUTH_PAGES.map((item) => (
          <Route key={item.id} path={item.path} element={item.element} exact />
        ))}
        <Route
          path={APP_ROUTES.ALL}
          element={<Navigate to={APP_ROUTES.PROJECTS} />}
        />
      </Routes>
    </Router>
  );
};

export default Routing;
