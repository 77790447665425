import { api } from "shared/api";

const pointsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    listPointsFrequencies: builder.query({
      query: ({ projectId }) => `projects/${projectId}/points/frequencies`,
      transformResponse: (response) =>
        Object.entries(response)
          .map((tuple) => ({
            value: tuple[0],
            name: tuple[1],
            id: tuple[0],
          }))
          .sort((a, b) => b.id - a.id),
    }),
    changePointsFrequencies: builder.mutation({
      query: ({ projectId, ...body }) => ({
        url: `projects/${projectId}/points/duration-frequency`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["Routes", "Days"],
    }),
    getRoutePointInfo: builder.query({
      query: ({ projectId, pointId }) =>
        `projects/${projectId}/points/${pointId}/route-points`,
    }),
  }),
});

export const {
  useListPointsFrequenciesQuery,
  useChangePointsFrequenciesMutation,
  useGetRoutePointInfoQuery,
} = pointsApi;
