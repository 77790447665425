import { combineReducers } from "@reduxjs/toolkit";

import areasReducer from "./areas";
import coordinatesReducer from "./coordinates";
import currentProjectReducer from "./current-project";
import daysReducer from "./days";
import excelDataReducer from "./excel-data";
import expandedMapPopupReducer from "./expanded-map-popup";
import excelFileMappingReducer from "./import-excel-file";
import logisticCoordinatesReducer from "./logistic-coordinates";
import projectSettingsReducer from "./project-settings";
import projectsReducer from "./projects";
import routesReducer from "./routes";
import sheduleReducer from "./shedule";

export const rootReducer = combineReducers({
  projects: projectsReducer,
  currentProject: currentProjectReducer,
  projectSettings: projectSettingsReducer,
  routes: routesReducer,
  areas: areasReducer,
  days: daysReducer,
  coordinates: coordinatesReducer,
  shedule: sheduleReducer,
  logisticCoordinates: logisticCoordinatesReducer,
  excelFileMapping: excelFileMappingReducer,
  excelData: excelDataReducer,
  expandedMapPopup: expandedMapPopupReducer,
});
