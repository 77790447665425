import { SORT } from "shared/config";
import { beautifyPointAddress, beautifyWeekDay } from "shared/helpers";
import { Text } from "shared/ui";

import {
  ActionsCell,
  ActionsHeader,
  HeaderCellSort,
  PointCell,
  RouteCell,
} from "../model";

export const configColumns = [
  {
    id: "ROUTE",
    Header: <HeaderCellSort withAllPointsCheckbox text="Маршрут" sortBy={SORT.ROUTE} />,
    accessor: (data) => <RouteCell data={data} />,
    hiddenByDefault: false,
  },
  {
    id: "POINT_COMMAND",
    Header: "Ком-да",
    name: "Команда",
    accessor: (data) => <Text>{data.point.command}</Text>,
    hiddenByDefault: true,
  },
  {
    id: "DAY",
    Header: <HeaderCellSort text="День" sortBy={SORT.DAY} />,
    accessor: (data) => <Text>{beautifyWeekDay(data.dayWeek)}</Text>,
    name: "День",
    hiddenByDefault: false,
  },
  {
    id: "POINT",
    Header: <HeaderCellSort text="Точка" sortBy={SORT.POINT} />,
    accessor: (data) => <PointCell text={beautifyPointAddress(data.point)} />,
    hiddenByDefault: false,
    style: {
      width: '100%'
    }
  },
  {
    id: "CYCLE",
    Header: "Цикл",
    name: "Цикл",
    accessor: (data) => <Text>{data.cyclePoint}</Text>,
    hiddenByDefault: true,
  },
  {
    id: "VISIT_FREQUENCY",
    Header: "Част-а",
    name: "Частота",
    accessor: (data) => <Text>{data.point.visitFrequency}</Text>,
    hiddenByDefault: true,
  },
  {
    id: "DURATION",
    Header: "Длит.",
    name: "Длительность",
    accessor: (data) => <Text>{data.point.duration}</Text>,
    hiddenByDefault: true,
  },
  {
    id: "ATTRIBUTE_CODE_1",
    Header: "Призн1",
    name: "Признак1",
    accessor: (data) => <Text>{data.point.attributeCode1}</Text>,
    hiddenByDefault: false,
  },
  {
    id: "ATTRIBUTE_CODE_2",
    Header: "Призн2",
    name: "Признак2",
    accessor: (data) => <Text>{data.point.attributeCode2}</Text>,
    hiddenByDefault: false,
  },
  {
    id: "ADDITIONALLY_TABLE_1",
    Header: "ДИ1",
    name: "Доп. инф-я1",
    accessor: (data) => <Text>{data.point.additionallyTable1}</Text>,
    hiddenByDefault: true,
  },
  {
    id: "ADDITIONALLY_TABLE_2",
    Header: "ДИ2",
    name: "Доп. инф-я2",
    accessor: (data) => <Text>{data.point.additionallyTable2}</Text>,
    hiddenByDefault: true,
  },
  {
    id: "ADDITIONALLY_TABLE_3",
    Header: "ДИ3",
    name: "Доп. инф-я3",
    accessor: (data) => <Text>{data.point.additionallyTable3}</Text>,
    hiddenByDefault: true,
  },
  {
    id: "actions",
    Header: ({ allColumns, toggleHideColumn }) => (
      <ActionsHeader
        allColumns={allColumns}
        toggleHideColumn={toggleHideColumn}
      />
    ),
    accessor: (data) => <ActionsCell data={data} />,
    hiddenByDefault: false,
  },
  // {
  //   id: "settings",
  //   Header: ({ allColumns, toggleHideColumn }) => (
  //     <ActionsHeader
  //       allColumns={allColumns}
  //       toggleHideColumn={toggleHideColumn}
  //     />
  //   ),
  //   accessor: null,
  //   hiddenByDefault: false,
  // },
];
