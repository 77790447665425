import { PopupContext } from "app/context/PopupContext";
import { setMapCenterPoint } from "app/store/reducer/areas";
import {
  resetExpandedMapPopupState,
  setExpandedMapPopupData,
} from "app/store/reducer/expanded-map-popup";
import L from "leaflet";
import { useContext } from "react";
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
import { FeatureGroup, Polygon } from "react-leaflet";
import { EditControl } from "react-leaflet-draw";
import { useDispatch, useSelector } from "react-redux";
import { useEditSeveralAreasCoordinatesMutation } from "shared/api/areas";
import { scrollInTable } from "shared/helpers";

import { AreaPopup } from "../../ui";
import CreateArea from "./create-area";

L.Draw.Polygon.prototype._onTouch = L.Util.falseFn; // allows to drag map when drawing polygon

const Polygons = ({ areas = [], mapCenterPoint = {} }) => {
  const dispatch = useDispatch();
  const { showPopup } = useContext(PopupContext);
  const { projectInfo } = useSelector(
    (state) => state.rootReducer.currentProject,
  );

  const [editSeveralAreasCoordinates] =
    useEditSeveralAreasCoordinatesMutation();

  const onPolygonCreated = (e) => {
    e.layer.remove(); // remove polygon from leaflet layer
    const leafletCoordsArray = e.layer.getLatLngs()[0];
    const coordinates = leafletCoordsArray.map((object) =>
      Object.values(object),
    );
    showPopup(CreateArea, { coordinates });
  };

  const onPolygonEdited = async (e) => {
    const editedPolygons = [];
    const {
      layers: { _layers },
    } = e;
    for (const key in _layers) {
      const { _latlngs, options } = _layers[key];
      const editablePolygon = {
        id: options.polygon.id,
        coordinates: _latlngs[0].map((object) => [object.lat, object.lng]),
      };
      editedPolygons.push(editablePolygon);
    }
    await editSeveralAreasCoordinates({
      projectId: projectInfo.id,
      body: editedPolygons,
    });
  };

  const onPolygonClick = (area) => {
    dispatch(
      setMapCenterPoint({
        id: area.id,
        clickedIn: "map",
      }),
    );
    scrollInTable(area.id);
  };

  const onPopupOpen = (targetArea) => {
    dispatch(
      setExpandedMapPopupData({
        area: targetArea,
      }),
    );
  };

  const onPopupClosed = () => {
    dispatch(resetExpandedMapPopupState());
  };

  return (
    <FeatureGroup>
      <EditControl
        position="topright"
        onCreated={(e) => onPolygonCreated(e)}
        onEdited={(e) => onPolygonEdited(e)}
        edit={{ remove: false }}
        draw={{
          marker: false,
          circlemarker: false,
          circle: false,
          rectangle: false,
          polyline: false,
        }}
      />
      {areas?.map((item) => (
        <Polygon
          key={item.id}
          positions={item.coordinates}
          polygon={item}
          pathOptions={{
            color: item.id === mapCenterPoint.id ? "black" : item.color.hexCode,
            fillColor: item.color.hexCode,
            fillOpacity: item.id === mapCenterPoint.id ? 0.5 : 0.2,
            dashArray: item.id === mapCenterPoint.id && [10, 10],
          }}
          eventHandlers={{
            click: () => onPolygonClick(item),
            popupopen: () => onPopupOpen(item),
            popupclose: () => onPopupClosed(),
          }}
        >
          <AreaPopup area={item} />
        </Polygon>
      ))}
    </FeatureGroup>
  );
};

export default Polygons;
