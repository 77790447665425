/* eslint-disable no-console */
import React from "react";
import { useGetExcelRoutesQuery } from "shared/api/routes";
import { Flex, Popup } from "shared/ui";
import styled from "styled-components";

import Table from "./ui";

const EditExcelRoutes = ({ project }) => {
  // const { hidePopup } = useContext(PopupContext);

  const { data } = useGetExcelRoutesQuery({ projectId: project.id });

  return (
    <StyledCommonPopup
      isCenter
      title="Редактирование таблицы маршрутов"
      className="edit-excel-routes-popup__common-popup"
    >
      <Flex mt="20px">
        <Table data={data} projectId={project.id} />
      </Flex>
    </StyledCommonPopup>
  );
};

const StyledCommonPopup = styled(Popup)`
  &.edit-excel-routes-popup__common-popup {
    min-width: 80vw;
    max-width: 90vw;
  }
`;

export default EditExcelRoutes;
