import { api } from "shared/api";

const contractGroupApi = api.injectEndpoints({
  endpoints: (builder) => ({
    listBranchesContracts: builder.query({
      query: () => `branches/contract-groups`,
      providesTags: ["Contract-Group"],
    }),
    listContractGroupsByBranch: builder.query({
      query: ({ projectId, branchId }) =>
        `projects/${projectId}/branches/${branchId}/contract-groups`,
      providesTags: ["Contract-Group"],
    }),
    saveContractGroups: builder.mutation({
      query: ({ branchId, body }) => ({
        url: `branches/${branchId}/contract-groups`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Contract-Group"],
    }),
  }),
});

export const {
  useListBranchesContractsQuery,
  useListContractGroupsByBranchQuery,
  useSaveContractGroupsMutation,
} = contractGroupApi;
