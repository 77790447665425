import { useContext } from "react";
import { Text, TextButton } from "shared/ui";

import GroupsContext from "../../context";

const AccordionCell = ({ data = {} }) => {
  const {
    selectedBranches,
    setSelectedBranches,
    editedBranches,
    setEditedBranches,
  } = useContext(GroupsContext);
  const isBranchSelected = selectedBranches.includes(data?.branchId);
  const isBranchEdited = editedBranches.includes(data?.branchId);

  const closeBranch = () => {
    if (isBranchEdited) {
      setEditedBranches(
        editedBranches.filter((item) => item !== data?.branchId),
      );
    }

    if (isBranchSelected) {
      setSelectedBranches(
        selectedBranches.filter((item) => item !== data?.branchId),
      );
    }
  };

  if (isBranchEdited || isBranchSelected) {
    return (
      <TextButton onClick={() => closeBranch()}>
        <Text variant="body3" color="color0">
          Свернуть
        </Text>
      </TextButton>
    );
  }

  return null;
};

export default AccordionCell;
