import { dropdownArrowBottomIcon, dropdownArrowTopIcon } from "icons/icons";
import { Fragment } from "react";
import { Dropdown as BootstrapDropdown } from "react-bootstrap";
import { Flex, Text } from "shared/ui";
import styled from "styled-components";

const { Toggle, Menu, ItemText, Divider, Item } = BootstrapDropdown;

const RouteEngDropdown = ({ toggleText = "Выбрать", items = [] }) => (
  <Root>
    <BootstrapDropdown>
      <Toggle>
        <Flex flexDirection="row">
          <Flex mr="5px">
            <Text variant="body1">{toggleText}</Text>
          </Flex>
          <Flex justifyContent="center">{dropdownArrowBottomIcon}</Flex>
        </Flex>
      </Toggle>
      <Menu>
        <ItemText>
          {toggleText}
          <Flex ml="5px" justifyContent="center">
            {dropdownArrowTopIcon}
          </Flex>
        </ItemText>
        {items.map((section) => (
          <Fragment key={section.sectionName}>
            <Flex flexDirection="row" mt="12px" mb="2px">
              {section.sectionName && (
                <SectionName variant="body3" color="color4">
                  {section.sectionName}
                </SectionName>
              )}
              <Divider />
            </Flex>
            {section.items.map((item) => (
              <Item
                key={item.id}
                onClick={item.onClick}
                disabled={item.disabled}
              >
                {item.text}
              </Item>
            ))}
          </Fragment>
        ))}
      </Menu>
    </BootstrapDropdown>
  </Root>
);

const Root = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  margin-right: 5px;

  .btn {
    border-radius: 0;
  }

  .btn-primary {
    width: 100%;
    padding: 0;
    background: #ecf4fe;
    color: #000000;
    border: none;
    box-shadow: none;
  }

  .show > .btn-primary.dropdown-toggle {
    width: 100%;
    padding: 0;
    background-color: #fff;
    color: #000000;
    border: none;
  }

  .show > .btn-primary.dropdown-toggle:focus {
    box-shadow: none;
    background: #ecf4fe;
    color: #000000;
  }

  .btn-primary:not(:disabled):not(.disabled).active,
  .btn-primary:not(:disabled):not(.disabled):active,
  .show > .btn-primary.dropdown-toggle {
    background: #ecf4fe;
    color: #000000;
  }

  .dropdown-toggle::after {
    content: none;
  }

  .dropdown-menu {
    width: auto;
    padding: 10px 20px;
    border: 0;
    z-index: 10000;
  }

  .dropdown-menu.show {
    border-radius: 0;
    transform: translate(-20px, -18px) !important;
  }

  .dropdown-item {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    padding: 10px 0;
  }

  .dropdown-item-text {
    display: flex;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    padding: 10px 0;
    white-space: nowrap;
  }

  .dropdown-divider {
    border-top: 1px solid #cccccc;
    flex: 1;
  }
`;

const SectionName = styled(Text)`
  text-transform: uppercase;
  padding-right: 16px;
`;

export default RouteEngDropdown;
