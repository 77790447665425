import React from "react";
import styled from "styled-components";

import Flex from "../Flex";
import Text from "../Text";

const ColorPicker = ({
  title = "",
  errorText = "",
  colors = [],
  selectedId = null,
  onSelect = () => {},
}) => (
  <Flex>
    <Flex>
      <Text variant="body3" color="color7">
        {title}
      </Text>
    </Flex>
    <ColorsList>
      {colors.map((item) => (
        <Item
          key={item.id}
          color={item.hexCode}
          isSelected={item.id === selectedId}
          onClick={() => onSelect(item)}
        />
      ))}
    </ColorsList>
    {errorText && (
      <Flex mt="4px">
        <Text variant="body3" color="color5">
          {errorText}
        </Text>
      </Flex>
    )}
  </Flex>
);

const ColorsList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 4px;
`;

const Item = styled.div`
  height: 30px;
  width: 30px;
  outline: ${({ isSelected }) => (isSelected ? "2px solid black" : "none")};
  outline-offset: ${({ isSelected }) => (isSelected ? "2px" : "none")};
  background-color: ${({ color }) => color};
  cursor: pointer;

  &: hover {
    box-shadow: 2px 2px 8px #888888;
  }
`;

export default ColorPicker;
