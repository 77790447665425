import { ArrowDownward } from "app/assets/icons";
import {
  resetSelectedPoints,
  setAllPointsChecked,
  setSelectedPoints,
  setSortFilter,
} from "app/store/reducer/coordinates";
import Highlighter from "react-highlight-words";
import { useDispatch, useSelector } from "react-redux";
import { SORT } from "shared/config";
import { beautifyPointAddress } from "shared/helpers";
import { Checkbox, Flex, IconButton, Text } from "shared/ui";

export const HeaderCellSort = ({
  text = "",
  sortBy = SORT.ROUTE,
  withAllPointsCheckbox = false,
}) => {
  const dispatch = useDispatch();
  const { frontendFilters, allPointsChecked } = useSelector(
    (state) => state.rootReducer.coordinates,
  );

  return (
    <Flex flexDirection="row" alignItems="center">
      {withAllPointsCheckbox && (
        <Flex mr="7px">
          <Checkbox
            checked={allPointsChecked}
            onChange={() => {
              dispatch(setAllPointsChecked(!allPointsChecked));
              dispatch(resetSelectedPoints());
            }}
          />
        </Flex>
      )}
      <Text>{text}</Text>
      <IconButton onClick={() => dispatch(setSortFilter(sortBy))}>
        <ArrowDownward
          fill={frontendFilters.sort === sortBy ? "#000000" : "#CCCCCC"}
        />
      </IconButton>
    </Flex>
  );
};

export const RouteCell = ({ data = {} }) => {
  const dispatch = useDispatch();
  const { selectedPoints } = useSelector(
    (state) => state.rootReducer.coordinates,
  );

  const onRouteSelect = () => {
    dispatch(setSelectedPoints(data.id));
    dispatch(setAllPointsChecked(false));
  };

  return (
    <Flex flexDirection="row" alignItems="center">
      <Checkbox
        filledColor={data.color}
        emptyColor={data.color}
        checked={selectedPoints.includes(data.id)}
        onChange={() => onRouteSelect()}
      />
      <Flex ml="7px">
        <Text>{data.routeName}</Text>
      </Flex>
    </Flex>
  );
};

export const PointCell = ({ data = {} }) => {
  const { frontendFilters } = useSelector(
    (state) => state.rootReducer.coordinates,
  );

  return (
    <Highlighter
      highlightClassName="YourHighlightClass"
      searchWords={[frontendFilters.address]}
      autoEscape
      textToHighlight={beautifyPointAddress(data)}
      highlightStyle={{ background: "#CEE3FD", padding: 0 }}
    />
  );
};
