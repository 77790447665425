import InfiniteScroll from "react-infinite-scroll-component";
import { useTable } from "react-table";
import {
  TABLE_HEAD_HEIGHT,
  TABLE_HEIGHT,
  TABLE_HEIGHT_WITH_FOOTER,
} from "shared/config";
import { hexToRgba } from "shared/helpers";
import { Flex, Loader, Text } from "shared/ui";
import styled from "styled-components";

const Table = ({
  data = [],
  columns = [],
  totalElements = 0,
  isFooterVisible = false,
  onRowClick = () => {},
  onInfinityScroll = () => {},
  highlightedPointId = null,
  isFetching = false,
  isHeadDisabled = false,
}) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ data, columns, defaultColumn: { width: "100%" } });

  if (!data.length) {
    return (
      <Flex
        width="70%"
        height="70%"
        alignItems="center"
        justifyContent="center"
        margin="auto"
      >
        <Text variant="title1" color="color3">
          Нет данных
        </Text>
      </Flex>
    );
  }

  return (
    <Root id="scrollableDiv" isFooterVisible={isFooterVisible}>
      <InfiniteScroll
        scrollableTarget="scrollableDiv"
        dataLength={rows.length}
        next={() => onInfinityScroll()}
        hasMore={rows.length < totalElements}
        scrollThreshold={1}
        loader={<Loader isLoading={isFetching} />}
        style={{ display: "inline" }}
      >
        <StyledTable {...getTableProps()}>
          <TableHead disabled={isHeadDisabled}>
            {headerGroups.map((headerGroup) => (
              <TableHeaderRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <HeaderCell
                    {...column.getHeaderProps({
                      style: column.style,
                    })}
                  >
                    {column.render("Header")}
                  </HeaderCell>
                ))}
              </TableHeaderRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()} isFetching={isFetching}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <Row
                  {...row.getRowProps()}
                  id={row.original.id}
                  isRowHighlighted={highlightedPointId === row.original.id}
                  onClick={() => onRowClick(row)}
                >
                  {row.cells.map((cell) => (
                    <BodyCell
                      {...cell.getCellProps({
                        style: cell.column.style,
                      })}
                    >
                      {cell.render("Cell")}
                    </BodyCell>
                  ))}
                </Row>
              );
            })}
          </TableBody>
        </StyledTable>
      </InfiniteScroll>
    </Root>
  );
};

const Root = styled.div`
  height: ${({ isFooterVisible }) =>
    isFooterVisible ? TABLE_HEIGHT_WITH_FOOTER : TABLE_HEIGHT};
  min-height: ${({ isFooterVisible }) =>
    isFooterVisible ? TABLE_HEIGHT_WITH_FOOTER : TABLE_HEIGHT};
  overflow-y: scroll;
  overflow-x: auto;
`;

const StyledTable = styled.table`
  display: inline-table;
  border-collapse: collapse;
`;

const TableHead = styled.thead`
  opacity: ${({ disabled }) => disabled && "0.3"};
  pointer-events: ${({ disabled }) => disabled && "none"};
  position: sticky;
  top: 0;
  background: white;
  z-index: 2;
`;

const TableHeaderRow = styled.tr`
  height: ${TABLE_HEAD_HEIGHT};
  outline: 0.5px solid #ccc;
  outline-offset: 0.5px;
`;

const HeaderCell = styled.th`
  padding: 12px 8px;
  border-right: 0.5px solid #ddd;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #595959;
  background-color: #ffffff;
  white-space: nowrap;

  :first-of-type {
    padding-left: 16px;
  }

  :last-of-type {
    padding: 0 8px;
    border-right: none;
  }
`;

const TableBody = styled.tbody`
  pointer-events: ${({ isFetching }) => isFetching && "none"};
`;

const Row = styled.tr`
  min-height: 96px;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
  background-color: ${({ isRowHighlighted }) =>
    isRowHighlighted ? hexToRgba("#000000", 0.1) : "#FFFFFF"};

  &:hover {
    background: #ecf4fe;
  }
`;

const BodyCell = styled.td`
  padding: 10px 8px;
  border-right: 0.5px solid #eee;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #000000;

  :first-of-type {
    padding-left: 16px;
  }

  :last-of-type {
    border-right: none;
  }
`;

export default Table;
