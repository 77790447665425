import {
  CenteredMarker,
  CheckedMarker,
  DefaultMarker,
  Finish,
  Start,
} from "app/assets/icons";
import L from "leaflet";
import ReactDOMServer from "react-dom/server";
import { Marker } from "react-leaflet";

const MarkerIcon = ({
  point = {},
  color = "#000000",
  selectedRoutePoints = [],
  highlightedPointId = null,
  onMarkerClicked = () => {},
  onMarkerCtrlClick = () => {},
  onPopupClosed = () => {},
  children,
}) => {
  const eventHandlers = {
    preclick: (e) => {
      if (e.originalEvent.ctrlKey || e.originalEvent.metaKey) {
        onMarkerCtrlClick();
        e.originalEvent.preventDefault();
      }
    },
    click: (e) => onMarkerClicked(e),
    popupclose: () => onPopupClosed(),
  };

  if (selectedRoutePoints.includes(point.id)) {
    return (
      <Marker
        position={[point.latitude, point.longitude]}
        icon={L.divIcon({
          className: "custom-icon",
          html: ReactDOMServer.renderToString(<CheckedMarker color={color} />),
        })}
        zIndexOffset={5000}
        eventHandlers={eventHandlers}
      >
        {children}
      </Marker>
    );
  }

  if (point.id === highlightedPointId) {
    return (
      <Marker
        position={[point.latitude, point.longitude]}
        icon={L.divIcon({
          className: "custom-icon",
          html: ReactDOMServer.renderToString(<CenteredMarker color={color} />),
        })}
        zIndexOffset={10000}
        eventHandlers={eventHandlers}
      >
        {children}
      </Marker>
    );
  }

  if (point.typePoint === "START") {
    return (
      <Marker
        position={[point.latitude, point.longitude]}
        icon={L.divIcon({
          className: "custom-icon",
          html: ReactDOMServer.renderToString(<Start />),
        })}
        zIndexOffset={1000}
      >
        {children}
      </Marker>
    );
  }

  if (point.typePoint === "FINISH") {
    return (
      <Marker
        position={[point.latitude, point.longitude]}
        icon={L.divIcon({
          className: "custom-icon",
          html: ReactDOMServer.renderToString(<Finish />),
        })}
        zIndexOffset={1000}
      >
        {children}
      </Marker>
    );
  }

  return (
    <Marker
      position={[point.latitude, point.longitude]}
      icon={L.divIcon({
        className: "custom-icon",
        html: ReactDOMServer.renderToString(<DefaultMarker color={color} />),
      })}
      zIndexOffset={1000}
      eventHandlers={eventHandlers}
    >
      {children}
    </Marker>
  );
};

export default MarkerIcon;
