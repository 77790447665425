import * as React from "react";

const SvgFile = ({ fill = "#fff" }, props) => (
  <svg
    width={18}
    height={18}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m18 6-6-6H0v18h18V6zM4 4h7v2H4V4zm10 10H4v-2h10v2zm0-4H4V8h10v2z"
      fill={fill}
    />
  </svg>
);

export default SvgFile;
