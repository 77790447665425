import { api } from "shared/api";

const ExcelDataApi = api.injectEndpoints({
  endpoints: (builder) => ({
    checkExcelData: builder.mutation({
      query: ({ projectId, ...body }) => ({
        url: `projects/${projectId}/import?checkOnly=true&additionalLoading=true`,
        method: "POST",
        body,
      }),
    }),
    uploadExcelData: builder.mutation({
      query: ({ projectId, ...body }) => ({
        url: `projects/${projectId}/import?additionalLoading=true`,
        // url: `projects/${projectId}/import`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Routes", "States"],
    }),
  }),
});

export const { useCheckExcelDataMutation, useUploadExcelDataMutation } =
  ExcelDataApi;
