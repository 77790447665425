import { DragIndicator } from "app/assets/icons";
import { includes } from "lodash";
import GroupsContext from "pages/projects/popups/load-contract-groups/context";
import { useContext } from "react";
import { Checkbox, Flex, Text } from "shared/ui";
import styled from "styled-components";

const Group = ({ group = {}, branchCode = "" }) => {
  const { selectedObject, onContractCheckboxClick } = useContext(GroupsContext);

  return (
    <Flex width="270px">
      <Flex flexDirection="row" alignItems="center" mb="20px">
        <Flex mr="7px">
          <Checkbox
            checked={includes(selectedObject[branchCode], group.id)}
            onChange={(e) =>
              onContractCheckboxClick(e.target.checked, branchCode, group)
            }
          />
        </Flex>
        <Flex>
          <Text>{group.name}</Text>
        </Flex>
      </Flex>
      <StyledContractsList>
        {group?.contractCodes?.map((contract) => (
          <StyledContract key={contract}>
            <Text variant="body3">{contract}</Text>
            <DragIndicator />
          </StyledContract>
        ))}
      </StyledContractsList>
    </Flex>
  );
};

const StyledContractsList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5px;
`;

const StyledContract = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 60px;
  height: 24px;
  padding: 4px;
  background: rgba(0, 0, 0, 0.04);
`;

export default Group;
