import { Draggable, Droppable } from "react-beautiful-dnd";
import { useTable } from "react-table";
import {
  TABLE_HEAD_HEIGHT,
  TABLE_HEIGHT,
  TABLE_HEIGHT_WITH_FOOTER,
} from "shared/config";
import { hexToRgba } from "shared/helpers";
import { Flex, Text } from "shared/ui";
import styled from "styled-components";
import { useLocation } from 'react-router-dom'

const Table = ({
  data = [],
  columns = [],
  isFooterVisible = false,
  onRowClick = () => {},
  mapCenterPoint = {},
  isFetching = false,
}) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ data, columns, defaultColumn: { width: "100%" } });

  if (!data.length) {
    return (
      <Flex
        width="70%"
        height="70%"
        alignItems="center"
        justifyContent="center"
        margin="auto"
      >
        <Text variant="title1" color="color3">
          Нет данных
        </Text>
      </Flex>
    );
  }
  const location = useLocation()
 

  return (
    <Root isFooterVisible={isFooterVisible} isDayRoute={ location.pathname.split('/')[1]!== undefined && location.pathname.split('/')[1] === 'days'}>
      <StyledTable {...getTableProps()}>
        <TableHeader>
          {headerGroups.map((headerGroup) => (
            <TableHeaderRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <TableHeaderCell
                  {...column.getHeaderProps({
                    style: column.style,
                  })}
                >
                  {column.render("Header")}
                </TableHeaderCell>
              ))}
            </TableHeaderRow>
          ))}
        </TableHeader>
        <Droppable droppableId="droppable">
          {(providedDroppable) => (
            <TableBody
              ref={providedDroppable.innerRef}
              {...getTableBodyProps()}
              {...providedDroppable.droppableProps}
              isFetching={isFetching}
            >
              {rows.map((row, index) => {
                prepareRow(row);
                return (
                  <Draggable
                    draggableId={`draggable-${row.original.id}`}
                    key={row.original.id}
                    index={index}
                  >
                    {(providedDraggable) => (
                      <TableBodyRow
                        ref={providedDraggable.innerRef}
                        {...row.getRowProps()}
                        {...providedDraggable.draggableProps}
                        {...providedDraggable.dragHandleProps}
                        data={row.original}
                        isRowHighlighted={mapCenterPoint.id === row.original.id}
                        onClick={() => onRowClick(row)}
                      >
                        {row.cells.map((cell) => (
                          <TableBodyCell
                            {...cell.getCellProps({
                              style: cell.column.style,
                            })}
                          >
                            {cell.render("Cell")}
                          </TableBodyCell>
                        ))}
                      </TableBodyRow>
                    )}
                  </Draggable>
                );
              })}
              {providedDroppable.placeholder}
            </TableBody>
          )}
        </Droppable>
      </StyledTable>
    </Root>
  );
};

const Root = styled.div`
  height: ${({ isFooterVisible, isDayRoute }) =>
    (isFooterVisible ? () => (isDayRoute ? 'auto' : TABLE_HEIGHT_WITH_FOOTER) : TABLE_HEIGHT)};
  min-height: ${({ isFooterVisible, isDayRoute }) =>
  (isFooterVisible ? () => (isDayRoute ? 'auto' : TABLE_HEIGHT_WITH_FOOTER) : TABLE_HEIGHT)};
  overflow-y: scroll;
  overflow-x: auto;
`;

const StyledTable = styled.table`
  display: inline-table;
  border-collapse: collapse;
`;

const TableHeader = styled.thead`
  position: sticky;
  top: 0;
  background: white;
  z-index: 2;
`;

const TableHeaderRow = styled.tr`
  height: ${TABLE_HEAD_HEIGHT};
  outline: .5px solid #ccc;
  outline-offset: .5px;
`;

const TableHeaderCell = styled.th`
  padding: 12px 8px;
  border-right: .5px solid #ddd;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #595959;
  background-color: #ffffff;
  white-space: nowrap;

  :first-of-type {
    padding-left: 16px;
  }

  :last-of-type {
    padding: 0 8px;
    border-right: none;
  }
`;

const TableBody = styled.tbody`
  pointer-events: ${({ isFetching }) => isFetching && "none"};
`;

const TableBodyRow = styled.tr`
  min-height: 96px;
  border-bottom: 1px solid #cccccc;
  cursor: pointer;
  background-color: ${({ data, isRowHighlighted }) =>
    isRowHighlighted ? hexToRgba(data.color, 0.1) : "#FFFFFF"};

  &:hover {
    background: #ecf4fe;
  }
`;

const TableBodyCell = styled.td`
  padding: 10px 8px;
  border-right: .5px solid #eee;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #000000;

  :first-of-type {
    padding-left: 16px;
  }

  :last-of-type {
    border-right: none;
  }
`;

export default Table;
