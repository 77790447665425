import React from "react";
import { Popup as LeafletPopup } from "react-leaflet";
import { Flex, Text } from "shared/ui";
import styled from "styled-components";
import { declensionPoint } from "../model";

const DayBasedPolygonPopup = React.memo(({ polygon }) => (
  <LeafletPopup autoPan={false}>
    <Root color={polygon?.color}>
      <Flex width="130px" mr='20px'>
        <Text variant="body3bold">{polygon?.pointsAmount} {declensionPoint(polygon?.pointsAmount)}</Text>
        <Flex flexDirection="row" alignItems="center">
          <Dot square color={polygon?.routeColor} />
          <Text variant="body3">{polygon?.routeName}</Text>
        </Flex>
        <Flex flexDirection="row" alignItems="center">
          <Dot color={polygon?.dayColor} />
          <Text variant="body3">{polygon?.dayWeek}</Text>
        </Flex>
      </Flex>
    </Root>
  </LeafletPopup>
));

const Root = styled.div`
  border: 2px solid ${(p) => p.color};
  border-radius: 20px;
  padding: 16px;
`;

const Dot = styled.div`
  width: 8px;
  height: 8px;
  background-color: ${({ color }) => color};
  border-radius: ${({ square }) => (square ? "none" : "100%")};
  margin-right: 8px;
`;

export default DayBasedPolygonPopup;
