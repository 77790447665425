import { api } from "shared/api";

const daysApi = api.injectEndpoints({
  endpoints: (builder) => ({
    listRoutePointsDays: builder.query({
      query: ({ projectId, filters }) =>
        `projects/${projectId}/route-points/mode-days-filters?limit=${filters.limit}&page=${filters.page}&managerCode=${filters.managerCodes}&commands=${filters.commands}&routeCodes=${filters.routeCodes}&daysOfWeek=${filters.daysOfWeek}&address=${filters.address}&cyclePoint=${filters.cyclePoint}`,
      providesTags: ["Days"],
    }),
    listMapRoutePointsDays: builder.query({
      query: ({
        projectId,
                managerCodes, commands, routeCodes, daysOfWeek, cyclePoint
      }) =>
        `projects/${projectId}/route-points/maps/mode-days-filters?&managerCode=${managerCodes}&commands=${commands}&routeCodes=${routeCodes}&daysOfWeek=${daysOfWeek}&cyclePoint=${cyclePoint}`,
      providesTags: ["Days"],
    }),
    listPointsReport: builder.query({
      query: ({ projectId, routeId, dayOfWeek }) =>
        `projects/${projectId}/routes/${routeId}/reports/points?getGoogleMapData=${true}&dayOfWeek=${dayOfWeek}`,
    }),
    reorderRoutePoints: builder.mutation({
      query: ({ projectId, routeId, ...body }) => ({
        url: `projects/${projectId}/routes/${routeId}/routePoints/orders`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Days", "States"],
    }),
    setCycle: builder.mutation({
      query: ({ projectId, routeId, ...body }) => ({
        url: `projects/${projectId}/routes/${routeId}/routePoints/cycles`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Days", "States"],
    }),
  }),
});

export const {
  useListRoutePointsDaysQuery,
  useListMapRoutePointsDaysQuery,
  useListPointsReportQuery,
  useReorderRoutePointsMutation,
  useSetCycleMutation,
} = daysApi;
