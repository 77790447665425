import React, { useMemo, useState } from "react";
import { useRowSelect, useTable } from "react-table";
import {
  useCheckNewRouteMutation,
  useDeleteRoutesMutation,
  useSaveNewRouteMutation,
} from "shared/api/routes";
import { Button, Flex, Text } from "shared/ui";
import styled from "styled-components";

import { getStatusData, routeColumns } from "../../config";
import EditableCell from "../editable-cell";
import IndeterminateCheckbox from "../indeterminate-checkbox";

const Table = ({ data = [], projectId }) => {
  if (data.length === 0)
    return (
      <TableWrapper>
        <Text>Данных нет</Text>
      </TableWrapper>
    );

  const [checkNewRoute] = useCheckNewRouteMutation();
  const [saveNewRoute] = useSaveNewRouteMutation();
  const [deleteRoutes] = useDeleteRoutesMutation();
  const [errors, setErrors] = useState([]);
  const [status, setStatus] = useState(null); // null, loading, success, warning, error

  const tableData = useMemo(() => data, [data, errors]);
  const columns = useMemo(() => routeColumns, [data]);

  const updateCellValue = async (row, column, value) => {
    if (data[row][column] === value) return;

    setStatus("loading");
    const newRoute = { ...data[row], [column]: value };
    try {
      const { data: checkErrors } = await checkNewRoute({
        projectId,
        newRoute,
      });
      if (checkErrors.length !== 0) {
        // don't update the value, show the error message
        setErrors(checkErrors);
        setStatus("warning");
      } else {
        await saveNewRoute({
          projectId,
          newRoute,
        });
        setStatus("success");
        setErrors([]);
      }
    } catch (_) {
      setStatus("error");
    }
  };

  const tableInstance = useTable(
    {
      columns,
      data: tableData,
      defaultColumn: { Cell: EditableCell },
      updateCellValue,
    },
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((cols) => [
        {
          id: "selection",
          // eslint-disable-next-line react/no-unstable-nested-components
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
          ),
          // eslint-disable-next-line react/no-unstable-nested-components
          Cell: ({ row }) => (
            <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
          ),
        },
        ...cols,
      ]);
    },
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
  } = tableInstance;

  const handleDeleteRoutes = async () => {
    const routeIds = selectedFlatRows.map((r) => r.original.routeCode);
    await deleteRoutes({
      projectId,
      routeIds,
    });
  };

  return (
    <>
      <TableWrapper>
        <TableContainer {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <TableColumnsNamesRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <TableHeadCell {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </TableHeadCell>
                ))}
              </TableColumnsNamesRow>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              // eslint-disable-next-line no-param-reassign
              row.errors = errors.filter((err) => err.rowNumber === row.index);
              return (
                <TableRow {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    const error = row.errors.find(
                      (err) => err.columnName === cell.column.key,
                    );

                    return (
                      <TableCell {...cell.getCellProps()} error={error}>
                        <TableCellContent>
                          {cell.render("Cell")}
                        </TableCellContent>
                        {error && <TableCellHint>{error.text}</TableCellHint>}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </tbody>
        </TableContainer>
      </TableWrapper>
      {selectedFlatRows.length > 0 && (
        <AccentFlex mt="20px" flexDirection="row">
          <Text variant="body1" color="color2">
            {`Выделено строк (${selectedFlatRows.length})`}
          </Text>
          <Button
            height="48px"
            color="#2D8AF6"
            textColor="#FFF"
            text="УДАЛИТЬ СТРОКИ"
            onClick={handleDeleteRoutes}
            style={{ marginLeft: "16px" }}
          />
        </AccentFlex>
      )}
      <Flex mt="20px">
        <Text variant="body1" color={getStatusData(status).color}>
          {status && getStatusData(status).text}{" "}
        </Text>
      </Flex>
    </>
  );
};

const TableWrapper = styled.div`
  overflow-x: auto;
  width: 100%;
  height: 60vh;

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar {
    width: 4px;
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: #cee3fd;
    width: 4px;
    border-radius: 8px;
  }
`;

const TableContainer = styled.table`
  min-width: 100%;
  white-space: nowrap;
`;

const TableColumnsNamesRow = styled.tr`
  border-bottom: 1px solid #cbcbcb;
`;

const TableHeadCell = styled.th`
  border-right: 1px solid #cbcbcb;
  padding: 4px 8px;

  &:last-child {
    border-right: none;
  }
`;

const TableRow = styled.tr`
  max-height: 80px;
`;

const TableCell = styled.td`
  outline: ${({ error }) => (error ? "1px solid #a80000" : "none")};
  border-right: 1px solid #cbcbcb;
  padding: 6px 8px;
  max-width: 150px;

  &.status {
    text-align: center;
  }

  &:last-child {
    border-right: none;
  }

  input {
    font-size: 1rem;
    padding: 0;
    margin: 0;
    border: 0;
    width: 100%;
  }

  position: relative;

  :hover span {
    transition-delay: 0.7s;
    width: auto;
    height: auto;
    clip: initial;
    opacity: 1;
    visibility: visible;
  }
`;

const TableCellContent = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
`;

const TableCellHint = styled.span`
  position: absolute;
  font-size: 12px;
  width: 1px;
  height: 1px;
  clip: rect(0, 0, 0, 0);
  white-space: break-spaces;
  border: 1px solid #a80000;
  background: white;
  color: black;
  line-height: normal;
  top: 100%;
  transform: translateY(-10%);
  left: 10%;
  padding: 3px 8px;
  border-radius: 4px;
  display: inline-block;
  opacity: 0;
  visibility: hidden;
  transition: 0.2s;
  z-index: 99999;

  ::before {
    content: "";
    left: 25%;
    top: 0;
    transform: translateY(-100%);
    position: absolute;
    z-index: 1;
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-bottom: 5px solid #a80000;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
  }
`;

const AccentFlex = styled(Flex)`
  background: #cee3fd;
  padding: 12px 16px;
  align-items: center;
`;

export default Table;
