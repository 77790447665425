import { ArrowDownward } from "app/assets/icons";
import { PopupContext } from "app/context/PopupContext";
import {
  setOpenedReport,
  setSelectedPoints,
  setSortFilter,
  setTableActionsId,
} from "app/store/reducer/shedule";
import {
  EditAreaPopup,
  EditIndividualAreaPopup,
} from "pages/current-project/areas/table/popups";
import { useContext } from "react";
import Highlighter from "react-highlight-words";
import { useDispatch, useSelector } from "react-redux";
import { beautifyPointAddress } from "shared/helpers";
import { ActionsDropdown, Checkbox, Flex, IconButton, Text } from "shared/ui";

import { DeletePointPopup } from "../popups";

export const HeaderCellSort = ({ text = "", sortBy }) => {
  const dispatch = useDispatch();
  const { frontendFilters } = useSelector((state) => state.rootReducer.shedule);

  return (
    <Flex flexDirection="row" alignItems="center">
      <Text>{text}</Text>
      <IconButton onClick={() => dispatch(setSortFilter(sortBy))}>
        <ArrowDownward
          fill={frontendFilters.sort === sortBy ? "#000000" : "#CCCCCC"}
        />
      </IconButton>
    </Flex>
  );
};

export const PointCell = ({ data }) => {
  const dispatch = useDispatch();
  const { frontendFilters, selectedPoints } = useSelector(
    (state) => state.rootReducer.shedule,
  );

  return (
    <Flex flexDirection="row" alignItems="center">
      <Flex mr="7px">
        <Checkbox
          className="checkbox"
          checked={selectedPoints.includes(data.id)}
          onChange={() => {
            dispatch(setSelectedPoints(data.id));
          }}
        />
      </Flex>
      <Highlighter
        highlightClassName="YourHighlightClass"
        searchWords={[frontendFilters.address]}
        autoEscape
        textToHighlight={beautifyPointAddress(data)}
        highlightStyle={{ background: "#CEE3FD", padding: 0 }}
      />
    </Flex>
  );
};

export const AreaCell = ({ data }) => {
  const dispatch = useDispatch();
  const { showPopup } = useContext(PopupContext);

  const onToggle = (show) => {
    if (show) {
      dispatch(setTableActionsId(data?.id));
    } else {
      dispatch(setTableActionsId(null));
    }
  };

  const onReportOpen = (e, reportName, area) => {
    e.stopPropagation();
    dispatch(
      setOpenedReport({
        name: reportName,
        areaId: area?.id,
        areaName: area?.name,
      }),
    );
  };

  if (data?.areas?.length) {
    return (
      <Flex>
        {data.areas.map((item) => (
          <Flex key={item?.id} flexDirection="row" alignItems="center">
            <Text color="color7">{item.name}</Text>
            <ActionsDropdown
              list={
                item?.individualArea
                  ? [
                      {
                        id: 0,
                        onClick: (e) => onReportOpen(e, "consolidated", item),
                        text: "Сводный отчёт",
                      },
                      {
                        id: 1,
                        onClick: (e) => onReportOpen(e, "days", item),
                        text: "Отчёт по дням недели",
                      },
                      {
                        id: 2,
                        onClick: (e) => {
                          e.stopPropagation();
                          showPopup(EditIndividualAreaPopup, {
                            areaId: item?.id,
                          });
                        },
                        text: "Изменить зону",
                      },
                      {
                        id: 3,
                        onClick: (e) => {
                          e.stopPropagation();
                          showPopup(DeletePointPopup, {
                            point: data,
                            individualAreaId: item?.id,
                          });
                        },
                        text: "Удалить точку из зоны",
                      },
                    ]
                  : [
                      {
                        id: 0,
                        onClick: (e) => onReportOpen(e, "consolidated", item),
                        text: "Сводный отчёт",
                      },
                      {
                        id: 1,
                        onClick: (e) => onReportOpen(e, "days", item),
                        text: "Отчёт по дням недели",
                      },
                      {
                        id: 2,
                        onClick: (e) => {
                          e.stopPropagation();
                          showPopup(EditAreaPopup, {
                            areaId: item?.id,
                          });
                        },
                        text: "Изменить зону",
                      },
                    ]
              }
              onToggle={onToggle}
            />
          </Flex>
        ))}
      </Flex>
    );
  }

  return <Text color="color7">Зона не установлена</Text>;
};
