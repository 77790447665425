import { Flex, Text } from "shared/ui";
import styled from "styled-components";

import { AnalysisDropdown, GroupDropdown } from "./model";

const Filters = () => (
  <Root>
    <Flex width="200px" mr="12px">
      <Flex flexDirection="row" mb="4px">
        <Text variant="body3" color="color3">
          Группы контрактов
        </Text>
      </Flex>
      <GroupDropdown />
    </Flex>
    <Flex width="250px" mr="12px">
      <Flex flexDirection="row" mb="4px">
        <Text variant="body3" color="color3">
          Период анализа
        </Text>
      </Flex>
      <AnalysisDropdown />
    </Flex>
  </Root>
);

const Root = styled.div`
  display: flex;
  align-items: center;
`;

export default Filters;
