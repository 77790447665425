/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-shadow */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable consistent-return */
import { PopupContext } from "app/context/PopupContext";
import { includes, pull } from "lodash";
import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  useCreateRoutesProjectJobMutation,
  useLazyGetProjectJobQuery,
  useListRoutesQuery,
} from "shared/api/projects";
import {
  Button,
  Checkbox,
  Flex,
  Loader,
  PageShadow,
  Popup,
  Text,
} from "shared/ui";
import styled from "styled-components";

import { Table } from "./ui";

const LoadRoutes = ({ project, navigateToProject = () => {} }) => {
  const { hidePopup } = useContext(PopupContext);
  const [selectedObject, setSelectedObject] = useState({});
  const [checkboxToggleArr, setCheckboxToggleArr] = useState([]);
  const [selectButtonMode, switchSelectButtonMode] = useState("selectAll");
  const [isErrorText, setErrorText] = useState(false);
  const { data: routes, isFetching } = useListRoutesQuery({
    id: project.id,
  });
  const [createProjectJob, { isLoading: isCreateProjectJobLoading }] =
    useCreateRoutesProjectJobMutation();
  const [getProjectJob, projectState] = useLazyGetProjectJobQuery({
    pollingInterval: 3000,
  });

  const onSelectAllButtonClick = () => {
    const newSelectedObject = { ...selectedObject };
    routes.forEach((element) => {
      if (Object.keys(element.routeNameCodeMap).length > 0) {
        newSelectedObject[element.fullNameCode] = Object.keys(
          element.routeNameCodeMap,
        );
      }
    });
    switchSelectButtonMode("throwAll");
    setSelectedObject(newSelectedObject);
  };

  const onThrowAllButtonClick = () => {
    switchSelectButtonMode("selectAll");
    setSelectedObject({});
  };

  const onAllCheckboxClick = useCallback(
    (checked, data) => {
      const { fullNameCode, routeNameCodeMap } = data;
      const newSelectedObject = { ...selectedObject };
      if (checked) {
        newSelectedObject[fullNameCode] = Object.keys(routeNameCodeMap);
      } else {
        delete newSelectedObject[fullNameCode];
      }
      setSelectedObject(newSelectedObject);
    },
    [selectedObject],
  );

  const onRouteCheckboxClick = useCallback(
    (checked, fullNameCode, routeNameCode) => {
      const newSelectedObject = { ...selectedObject };
      if (checked) {
        if (newSelectedObject[fullNameCode] === undefined) {
          newSelectedObject[fullNameCode] = [];
        }
        newSelectedObject[fullNameCode].push(routeNameCode);
      } else if (newSelectedObject[fullNameCode].length === 1) {
        delete newSelectedObject[fullNameCode];
      } else pull(newSelectedObject[fullNameCode], routeNameCode);
      setSelectedObject(newSelectedObject);
    },
    [selectedObject],
  );

  const onChooseRouteButtonClick = useCallback(
    (fullNameCode) => {
      setCheckboxToggleArr([...checkboxToggleArr, fullNameCode]);
    },
    [checkboxToggleArr],
  );

  const onGetRoutesButtonClick = async () => {
    const managerCodes = Object.keys(selectedObject);
    const routeCodes = [...new Set(Object.values(selectedObject).flat())];
    if (managerCodes.length < 1 || routeCodes.length < 1) {
      return setErrorText(true);
    }

    const response = await createProjectJob({
      projectId: project.id,
      managerCodes,
      routeCodes,
    });
    if (response.error) return;

    await getProjectJob({
      projectId: project.id,
      jobId: response.data?.id,
    });
  };

  useEffect(() => {
    if (Object.keys(selectedObject).length > 0 && isErrorText) {
      setErrorText(false);
    }
  }, [selectedObject, isErrorText]);

  useEffect(() => {
    if (projectState?.data?.status === "DONE") {
      hidePopup();
      navigateToProject();
    }
  }, [projectState?.data?.status, navigateToProject]);

  const data = React.useMemo(() => routes, [routes]);
  const columns = React.useMemo(
    () => [
      {
        id: "0",
        accessor: (data) => (
          <Flex minWidth="200px">
            <Text variant="body1">{data.fullName}</Text>
          </Flex>
        ),
      },
      {
        id: "1",
        accessor: (data) =>
          Object.keys(data.routeNameCodeMap).length > 0 ? (
            <Flex flexDirection="row" alignItems="center" minWidth="200px">
              <Flex mr="7px">
                <Checkbox
                  checked={
                    Object.keys(data.routeNameCodeMap).length ===
                    selectedObject[data.fullNameCode]?.length
                  }
                  onChange={(e) => onAllCheckboxClick(e.target.checked, data)}
                  filledColor="#000000"
                  emptyColor="#787878"
                />
              </Flex>
              <Text variant="body1">Все маршруты</Text>
            </Flex>
          ) : (
            <Flex>
              <Text variant="body1">Нет маршрутов</Text>
            </Flex>
          ),
      },
      {
        id: "2",
        accessor: (data) =>
          includes(checkboxToggleArr, data.fullNameCode) ? (
            <Flex flexDirection="row" flexWrap="wrap" minWidth="700px">
              {Object.keys(data.routeNameCodeMap).map((routeNameCode) => (
                <Flex
                  key={routeNameCode}
                  flexDirection="row"
                  alignItems="center"
                  m="5px"
                  mr="24px"
                >
                  <Flex mr="7px">
                    <Checkbox
                      checked={includes(
                        selectedObject[data.fullNameCode],
                        routeNameCode,
                      )}
                      onChange={(e) =>
                        onRouteCheckboxClick(
                          e.target.checked,
                          data.fullNameCode,
                          routeNameCode,
                        )
                      }
                      filledColor="#000000"
                      emptyColor="#787878"
                    />
                  </Flex>
                  <Text variant="body1">{routeNameCode}</Text>
                </Flex>
              ))}
            </Flex>
          ) : (
            <Flex
              minWidth="200px"
              cursor="pointer"
              onClick={() => onChooseRouteButtonClick(data.fullNameCode)}
            >
              {selectedObject[data.fullNameCode]?.length > 0 ? (
                <Text variant="body1" color="color0">
                  Выбрано {selectedObject[data.fullNameCode]?.length} из{" "}
                  {Object.keys(data.routeNameCodeMap).length}
                </Text>
              ) : (
                <>
                  {Object.keys(data.routeNameCodeMap).length > 0 && (
                    <Text variant="body1" color="color0">
                      Выбрать маршруты
                    </Text>
                  )}
                </>
              )}
            </Flex>
          ),
      },
    ],
    [
      onAllCheckboxClick,
      onRouteCheckboxClick,
      onChooseRouteButtonClick,
      selectedObject,
      checkboxToggleArr,
    ],
  );

  if (
    isFetching ||
    isCreateProjectJobLoading ||
    projectState?.endpointName === "getProjectJob"
  ) {
    return (
      <PageShadow>
        <Loader isLoading />
      </PageShadow>
    );
  }

  if (!routes) {
    return (
      <Popup isCenter title="Выберите маршруты для загрузки из УС">
        <Flex height="1px" bg="color4" mt="20px" />
        <Flex mt="20px">
          <Text variant="body1" color="color0" textAlign="center">
            Маршруты временно недоступны
          </Text>
        </Flex>
      </Popup>
    );
  }

  return (
    <Popup isCenter title="Выберите маршруты для загрузки из УС">
      <PopupWrapper>
        <Flex height="1px" bg="color4" mt="20px" />
        <Table data={data} columns={columns} />
        {Object.keys(selectedObject).length > 0 && (
          <Flex mb="24px">
            <Text variant="body3" color="color6" textAlign="center">
              Имейте в виду, более 700 точек доставки влияет на скорость работы
              АРТЕМИДЫ
            </Text>
          </Flex>
        )}
        <Flex flexDirection="row" justifyContent="center">
          <Flex mr="12px">
            <Button
              width="157px"
              height="48px"
              color="#ECF4FE"
              textColor="#2D8AF6"
              text={
                selectButtonMode === "selectAll"
                  ? "ВЫБРАТЬ ВСЁ"
                  : "СБРОСИТЬ ВСЁ"
              }
              onClick={
                selectButtonMode === "selectAll"
                  ? onSelectAllButtonClick
                  : onThrowAllButtonClick
              }
            />
          </Flex>
          <Flex>
            <Button
              width="253px"
              height="48px"
              color="#2D8AF6"
              text="ЗАГРУЗИТЬ МАРШРУТЫ"
              onClick={onGetRoutesButtonClick}
            />
          </Flex>
        </Flex>
        {isErrorText && (
          <Flex mt="24px">
            <Text variant="body3" color="color5" textAlign="center">
              Выберите маршруты для загрузки
            </Text>
          </Flex>
        )}
      </PopupWrapper>
    </Popup>
  );
};

const PopupWrapper = styled.div`
  max-height: 800px;
`;

export default LoadRoutes;
