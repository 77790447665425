import React from "react";
import { Popup as LeafletPopup } from "react-leaflet";
import { CYCLES_RU, PERIODICITY } from "shared/config";
import { beautifyDeliveryDays } from "shared/helpers";
import { Flex, Text } from "shared/ui";
import styled from "styled-components";

const AreaPopup = React.memo(({ area }) => (
  <LeafletPopup autoPan={false}>
    <Root color={area?.color?.hexCode}>
      <Flex width="290px" mr="20px" overflowY="auto">
        <Flex mb="4px">
          <Text variant="body3bold">{area?.name}</Text>
        </Flex>
        <Flex>
          <Text variant="body3">{area?.branch?.branchName}</Text>
        </Flex>
        <Flex>
          <Text variant="body3">{area?.branch?.name}</Text>
        </Flex>
        <Flex>
          <Text variant="body3">
            {beautifyDeliveryDays(area?.deliveryDays)}
          </Text>
        </Flex>
        <Flex>
          <Text variant="body3">{CYCLES_RU[area?.cyclePoint]}</Text>
        </Flex>
        <Flex>
          <Text variant="body3">{PERIODICITY[area?.periodicity]}</Text>
        </Flex>
      </Flex>
    </Root>
  </LeafletPopup>
));

const Root = styled.div`
  display: flex;
  max-height: 200px;
  position: relative;
  border: 2px solid ${(p) => p.color};
  border-radius: 20px;
  padding: 16px;
`;

export default AreaPopup;
