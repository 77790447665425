/* eslint-disable react/no-array-index-key */
import React from "react";
import { useSelector } from "react-redux";
import Select from "react-select";
import { Text } from "shared/ui";
import styled from "styled-components";

import { pointColumns, routeColumns, routePointColumns } from "../config";

const SearchableSelect = ({ options, onChange, value }) => {
  const customStyles = {
    control: (provided) => ({
      ...provided,
      height: "40px",
      borderWidth: 0,
      boxShadow: "none",
      backgroundColor: "transparent",
      paddingLeft: 0,
      paddingRight: 0,
    }),
    valueContainer: (provided) => ({
      ...provided,
      paddingLeft: 0,
      paddingRight: 0,
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      display: "none",
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "white" : "black",
      backgroundColor: state.isSelected ? "rgba(45,138,246,0.7)" : "white",
      padding: "5px 10px",
      fontSize: "14px",
      fontWeight: "normal",
    }),
    singleValue: (provided) => ({
      ...provided,
      fontSize: "12px",
      marginLeft: 0,
      marginRight: 0,
      whiteSpace: "normal",
      wordBreak: "keep-all",
      wordWrap: "break-word",
    }),
    input: (provided) => ({
      ...provided,
      fontSize: "12px",
      marginLeft: 0,
      marginRight: 0,
    }),
    placeholder: (provided) => ({
      ...provided,
      fontSize: "12px",
      fontWeight: "normal",
    }),
    menu: (provided) => ({
      ...provided,
      width: "300px",
      height: "60vh",
    }),
    menuList: (provided) => ({
      ...provided,
      minHeight: "60vh",
    }),
  };

  return (
    <Select
      isSearchable
      styles={customStyles}
      options={options}
      onChange={onChange}
      placeholder="..."
      defaultValue={value}
      value={value}
      menuPosition="fixed"
    />
  );
};

const TableUI = ({ data, onColumnNameSelect, headers, sheetIndex }) => {
  const [columns, ...rows] = data;

  if (!columns) return <Text>Нет данных</Text>;

  const { headers: allHeaders } = useSelector(
    (state) => state.rootReducer.excelFileMapping,
  );

  const getFilteredOptions = () => {
    const usedHeaders = allHeaders[sheetIndex].filter((header) => header.value);

    const filteredRouteColumns = routeColumns.filter(
      (opt) =>
        !usedHeaders.some(
          (header) => header.table === opt.table && header.value === opt.value,
        ),
    );
    const filteredPointColumns = pointColumns.filter(
      (opt) =>
        !usedHeaders.some(
          (header) => header.table === opt.table && header.value === opt.value,
        ),
    );
    const filteredRoutePointColumns = routePointColumns.filter(
      (opt) =>
        !usedHeaders.some(
          (header) => header.table === opt.table && header.value === opt.value,
        ),
    );

    return [
      {
        id: 0,
        table: "",
        value: "",
        label: "...",
      },
      { label: "Маршрут", options: filteredRouteColumns },
      { label: "Точка", options: filteredPointColumns },
      { label: "График посещений", options: filteredRoutePointColumns },
    ];
  };

  const filteredOptions = getFilteredOptions();

  return (
    <TableContainer>
      <Table>
        <thead>
          <TableSelectRow>
            {columns.map((column, index) => (
              <TableHeadCell key={`select-${column.toString()}-${index}`}>
                <SearchableSelect
                  options={filteredOptions}
                  onChange={(selectedOption) =>
                    onColumnNameSelect(index, selectedOption)
                  }
                  value={headers[index]}
                />
              </TableHeadCell>
            ))}
          </TableSelectRow>
          <TableColumnsNamesRow>
            {columns.map((column, index) => (
              <TableHeadCell key={`header-${column.toString()}-${index}`}>
                <Text variant="body3" color="color7">
                  {column}
                </Text>
              </TableHeadCell>
            ))}
          </TableColumnsNamesRow>
        </thead>
        <tbody>
          {rows.splice(0, 15).map((row, index) => (
            <tr key={row.toString() + Math.random().toString()}>
              {row.map((cell) => (
                <TableCell key={cell.toString() + Math.random().toString()}>
                  {index === 14 ? "..." : cell}
                </TableCell>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
    </TableContainer>
  );
};

const TableContainer = styled.div`
  overflow-x: auto;
  overflow-y: visible;
  max-width: 100%;
  min-height: 50vh;
  max-height: 70vh;
`;

const Table = styled.table`
  min-width: 100%;
  white-space: nowrap;
`;

const TableSelectRow = styled.tr`
  background: #eaeaea;
`;

const TableColumnsNamesRow = styled.tr`
  border-bottom: 1px solid #cbcbcb;
`;

const TableHeadCell = styled.th`
  border-right: 1px solid #cbcbcb;
  padding: 4px 8px;

  &:last-child {
    border-right: none;
  }
`;

const TableCell = styled.td`
  border-right: 1px solid #cbcbcb;
  padding: 6px 8px;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;

  &:last-child {
    border-right: none;
  }
`;

export default TableUI;
