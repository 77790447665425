import { PopupContext } from "app/context/PopupContext";
import { crossIcon } from "icons/icons";
import { useContext } from "react";
import styled from "styled-components";

const Popup = ({
  title,
  children,
  positionLeft,
  positionTop,
  isCenter = true,
  scrolled = false,
  className,
  onClose = () => {},
  isLoading = false,
  width = "auto",
  height = "auto",
}) => {
  const { hidePopup } = useContext(PopupContext);

  const closePopup = () => {
    if (!isLoading) {
      hidePopup();
      onClose();
    }
  };

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      closePopup();
    }
  };

  return (
    <Wrapper onClick={handleOverlayClick} scrolled={scrolled}>
      <Content
        scrolled={scrolled}
        positionLeft={positionLeft}
        positionTop={positionTop}
        isCenter={isCenter}
        className={className}
        width={width}
        height={height}
      >
        <TitleWrapper>
          <Title>{title}</Title>
          <IconWrapper onClick={closePopup}>{crossIcon}</IconWrapper>
        </TitleWrapper>
        {children}
      </Content>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 10000;
  ${(p) => `${p.scrolled ? "display:flex; align-items:flex-end;" :  ""}`}
`;

const Content = styled.div`
  
  ${(p) => `${p.scrolled ? "max-height: 100%;overflow: auto; margin-left:70px; margin-bottom:70px" :  "position: absolute;"}`}
  bottom: ${(p) => `${p.isCenter ? "50%" : p.positionTop || "70px"}`};
  left: ${(p) => `${p.isCenter ? "50%" : p.positionLeft || "0px"}`};
  transform: ${(p) => `${p.isCenter ? "translate(-50%, 50%)" : "none"}`};
  display: block;
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  padding: 32px;
  border-radius: 20px;
  box-sizing: border-box;
  background: #ffffff;
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 34px;
  margin-right: 25px;
  text-align: center;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 14px;
  height: 14px;
  cursor: pointer;
`;

export default Popup;
