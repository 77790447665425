import { Dropdown } from "react-bootstrap";
import { Text } from "shared/ui";
import styled from "styled-components";

import { Toggle as DifferenceDropdownToggle, Item } from "./ui";

const { Toggle, Menu } = Dropdown;

const DifferenceDropdown = ({
  isDropdownOpen = false,
  items = [],
  distance = "",
  onToggle = () => {},
  onSelect = () => {},
  onReset = () => {},
}) => (
  <Root>
    <Dropdown onToggle={(isOpen) => onToggle(isOpen)} show={isDropdownOpen}>
      <Toggle
        id="dropdown-custom-components"
        as={DifferenceDropdownToggle}
        distance={distance}
        onReset={onReset}
      />
      <Menu>
        {items.map((item) => (
          <Item
            key={item.id}
            isSelected={item.distance === distance}
            onClick={() => onSelect(item.distance)}
          >
            <Text>{item.text}</Text>
          </Item>
        ))}
      </Menu>
    </Dropdown>
  </Root>
);

const Root = styled.div`
  .dropdown-menu {
    width: 100%;
    min-width: 0;
    padding: 0;
    margin: 0;
    border: none;
    box-sizing: border-box;
  }
  .dropdown-menu.show {
    width: 100%;
    box-sizing: border-box;
    border-radius: 0;
    border: 2px solid #000000;
    transform: translate(0px, 0px) !important;
  }
`;

export default DifferenceDropdown;
