/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

const expandedMapPopup = createSlice({
  name: "expandedMapPopup",
  initialState,
  reducers: {
    resetExpandedMapPopupState: () => initialState,
    setExpandedMapPopupData: (state, action) => action.payload,
    setShownPoint: (state, action) => {
      state.point = action.payload;
    },
  },
});

export const {
  resetExpandedMapPopupState,
  setExpandedMapPopupData,
  setShownPoint,
} = expandedMapPopup.actions;

export default expandedMapPopup.reducer;
