import {
  increaseLimitFilter,
  setPointFoundByTable,
} from "app/store/reducer/routes";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useListRoutePointsQuery } from "shared/api/routes";

import { configColumns } from "./config";
import Footer from "./footer";
import { TableUI } from "./ui";

const Table = () => {
  const dispatch = useDispatch();
  const { projectInfo } = useSelector(
    (state) => state.rootReducer.currentProject,
  );
  const { frontendFilters, selectedRoutePoints, highlightedPointId, allPointsChecked } =
    useSelector((state) => state.rootReducer.routes);
  const { data: routePoints, isFetching } = useListRoutePointsQuery(
    {
      projectId: projectInfo.id,
      filters: frontendFilters,
    },
    {
      skip: !projectInfo.id,
      refetchOnMountOrArgChange: true,
    },
  );

  const onInfinityScroll = () => {
    dispatch(increaseLimitFilter());
  };

  const onRowClick = (row) => {
    const routePoint = row.original;

    if (selectedRoutePoints.includes(routePoint.id)) return;

    dispatch(
      setPointFoundByTable({
        pointId: routePoint.id,
        latitude: routePoint.point.latitude,
        longitude: routePoint.point.longitude,
      }),
    );
  };

  const data = React.useMemo(() => routePoints, [routePoints]);
  const columns = React.useMemo(() => configColumns, []);

  return (
    <>
      <TableUI
        data={data?.content}
        columns={columns}
        totalElements={data?.totalElements}
        isFooterVisible={selectedRoutePoints.length > 0 || allPointsChecked}
        onRowClick={onRowClick}
        onInfinityScroll={onInfinityScroll}
        highlightedPointId={highlightedPointId}
        isFetching={isFetching}
      />
      <Footer />
    </>
  );
};

export default Table;
