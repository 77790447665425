import { PopupContext } from "app/context/PopupContext";
import { useContext, useState } from "react";
import { useExportDataMutation } from "shared/api/projects";
import { EXPORT_LIST, SERVER_ERROR_TEXT } from "shared/config";
import {
  Button,
  Checkbox,
  Divider,
  ErrorText,
  Flex,
  Loader,
  PageShadow,
  Popup,
  Text,
} from "shared/ui";

const Export = ({ project }) => {
  const { hidePopup } = useContext(PopupContext);
  const [exportList, setExportList] = useState([]);
  const [changedDataCheck, setChangedDataCheck] = useState(true);
  const [serverError, setServerError] = useState("");
  const [exportData, { isLoading }] = useExportDataMutation();

  const onExportListChange = (checked, exportItem) => {
    if (checked) setExportList([...exportList, exportItem]);
    else {
      const newArr = exportList.filter((item) => item !== exportItem);
      setExportList(newArr);
    }
  };

  const onSetRoutesButtonClick = async () => {
    const response = await exportData({
      id: project.id,
      exportList,
      exportModifiedData: changedDataCheck,
    });

    if (response.error) {
      setServerError(response.error?.data?.message || SERVER_ERROR_TEXT);
      return;
    }

    hidePopup();
  };

  if (isLoading) {
    return (
      <PageShadow>
        <Loader isLoading />
      </PageShadow>
    );
  }

  return (
    <Popup isCenter title="Выгрузка в УС">
      <Flex flexDirection="row" mt="20px">
        <Flex flexDirection="row" alignItems="center" mr="35px">
          <Flex mr="7px">
            <Checkbox
              checked={exportList.includes(EXPORT_LIST.ROUTES)}
              onChange={(e) =>
                onExportListChange(e.target.checked, EXPORT_LIST.ROUTES)
              }
            />
          </Flex>
          <Text variant="body1">Маршруты</Text>
        </Flex>
        <Flex flexDirection="row" alignItems="center" mr="35px">
          <Flex mr="7px">
            <Checkbox
              checked={exportList.includes(EXPORT_LIST.COORDINATES)}
              onChange={(e) =>
                onExportListChange(e.target.checked, EXPORT_LIST.COORDINATES)
              }
            />
          </Flex>
          <Text variant="body1">Координаты</Text>
        </Flex>
      </Flex>
      <Divider />
      <Flex flexDirection="row" alignItems="center">
        <Flex mr="7px">
          <Checkbox
            checked={changedDataCheck}
            onChange={(e) => setChangedDataCheck(e.target.checked)}
          />
        </Flex>
        <Text variant="body1">Выгружать только изменённые данные</Text>
      </Flex>
      <Flex mt="20px">
        <Button
          width="100%"
          height="48px"
          color="#2D8AF6"
          text="ВЫГРУЗИТЬ В УС"
          onClick={() => onSetRoutesButtonClick()}
          disabled={exportList.length < 1}
        />
      </Flex>
      <ErrorText errorText={serverError} />
    </Popup>
  );
};

export default Export;
