import * as React from "react";

const SvgReportProblem = ({ fill, ...props }) => (
  <svg
    width={24}
    height={25}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#report_problem_svg__a)">
      <path
        d="M1 21.5h22l-11-19-11 19Zm12-3h-2v-2h2v2Zm0-4h-2v-4h2v4Z"
        fill={fill}
      />
    </g>
    <defs>
      <clipPath id="report_problem_svg__a">
        <path fill="#fff" transform="translate(0 .5)" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgReportProblem;
