import React, { memo } from "react";
import { CYCLES_RU, PERIODICITY } from "shared/config";
import { beautifyDeliveryDays } from "shared/helpers";
import { Flex, Text } from "shared/ui";

const AreaInfo = ({ area }) => (
  <Flex width="270px" overflowY="auto">
    <Flex mb="4px">
      <Text variant="body3bold">{area?.name}</Text>
    </Flex>
    <Flex>
      <Text variant="body3">{area?.branch?.branchName}</Text>
    </Flex>
    <Flex>
      <Text variant="body3">{area?.branch?.name}</Text>
    </Flex>
    <Flex>
      <Text variant="body3">{beautifyDeliveryDays(area?.deliveryDays)}</Text>
    </Flex>
    <Flex>
      <Text variant="body3">{CYCLES_RU[area?.cyclePoint]}</Text>
    </Flex>
    <Flex>
      <Text variant="body3">{PERIODICITY[area?.periodicity]}</Text>
    </Flex>
  </Flex>
);

export default memo(AreaInfo);
