export function createDefaultProjectName(projects) {
  const projectsNamesArr = projects.map((item) => item.name);
  const defaultName = "Новый проект";
  let increment = 1;

  function checkIfProjectNameExists(name) {
    if (projectsNamesArr.includes(name)) {
      increment += 1;
      return checkIfProjectNameExists(`${defaultName}(${increment})`);
    }

    return name;
  }

  return checkIfProjectNameExists(defaultName);
}
