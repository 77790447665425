import { ArrowForward } from "app/assets/icons";
import { useTable } from "react-table";
import { hexToRgba } from "shared/helpers";
import { Flex, Text } from "shared/ui";
import styled, { css } from "styled-components";

const Table = ({
  data = [],
  columns = [],
  onRowClick = () => {},
  mapCenterPoint = {},
  tableReportAreaId = null,
}) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ data, columns });

  if (!data.length) {
    return (
      <Flex
        width="70%"
        height="70%"
        alignItems="center"
        justifyContent="center"
        margin="auto"
      >
        <Flex>
          <Text variant="title1" color="color3">
            Создайте зону на карте
          </Text>
        </Flex>
        <Flex mt="12px">
          <Text variant="body1" color="color3" textAlign="center">
            Чтобы создать зону нажмите на кнопку в верхнем правом углу карты
          </Text>
          <Text variant="body1" color="color3" textAlign="center">
            Чтобы редактировать зону нажмите на кнопку в верхнем правом углу
            карты
          </Text>
        </Flex>
        <Flex mt="24px">
          <ArrowForward />
        </Flex>
      </Flex>
    );
  }

  return (
    <TableWrapper>
      <StyledTable {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <TableHeaderRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <HeaderCell
                  width={column.w}
                  textAlign={column.textAlign}
                  {...column.getHeaderProps()}
                >
                  {column.render("Header")}
                </HeaderCell>
              ))}
            </TableHeaderRow>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <Row
                {...row.getRowProps()}
                id={row.original.id}
                data={row.original}
                mapCenterPoint={mapCenterPoint}
                tableReportAreaId={tableReportAreaId}
                onClick={() => onRowClick(row)}
              >
                {row.cells.map((cell) => (
                  <BodyCell
                    width={cell.column.w}
                    textAlign={cell.column.textAlign}
                    {...cell.getCellProps()}
                  >
                    {cell.render("Cell")}
                  </BodyCell>
                ))}
              </Row>
            );
          })}
        </tbody>
      </StyledTable>
    </TableWrapper>
  );
};

const TableWrapper = styled.div`
  overflow-y: auto;
`;

const StyledTable = styled.table`
  position: relative;
  width: 100%;
  border-collapse: collapse;
`;

const TableHeaderRow = styled.tr`
  border-bottom: 1px solid #cccccc;
`;

const HeaderCell = styled.th`
  position: sticky;
  top: 0;
  left: 0;
  padding: 12px 24px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #595959;
  text-align: ${(p) => p.textAlign};
  background-color: #ffffff;
`;

const Row = styled.tr`
  border-bottom: 1px solid #cccccc;
  cursor: pointer;

  &:hover {
    background: #ecf4fe;
  }

  ${({ data, mapCenterPoint, tableReportAreaId }) => {
    if (data.id === mapCenterPoint.id && !tableReportAreaId) {
      return css`
        background: ${hexToRgba(data.color.hexCode, 0.1)};
      `;
    }

    if (data.id === tableReportAreaId) {
      return css`
        position: relative;
        z-index: 20000;
        pointer-events: none;
        background: #ffffff;

        &:hover {
          background: #ffffff;
        }
      `;
    }

    return "";
  }}
`;

const BodyCell = styled.td`
  padding: 12px 24px;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  text-align: ${(p) => p.textAlign};
  color: #000000;
`;

export default Table;
