/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { MARKER_COLOR_BY, SORT } from "shared/config";

const resetStateByFilters = (state) => {
  state.selectedRoutePoints = [];
  state.frontendFilters.limit = 25;
  state.mapCenterPoint = {};
  state.highlightedPointId = null;
  state.allPointsChecked = false;
};

const initialState = {
  frontendFilters: {
    page: 0,
    limit: 25,
    managerCodes: [],
    commands: [],
    routeCodes: [],
    routeIds: [],
    address: "",
    daysOfWeek: [],
    periodicityList: [],
    attributeCodes1: [],
    attributeCodes2: [],
    duplicates: false,
    sort: SORT.ROUTE,
  },
  selectedRoutePoints: [],
  mapMarkersColoredBy: MARKER_COLOR_BY.ROUTE,
  mapCenterPoint: {},
  highlightedPointId: null,
  isMapRoutePointsVisible: true,
  isAreasVisible: true,
  allPointsChecked: false
};

const routes = createSlice({
  name: "routes",
  initialState,
  reducers: {
    resetState: () => initialState,
    increaseLimitFilter: (state) => {
      state.frontendFilters.limit += 10;
    },
    setManagerFilter: (state, action) => {
      state.frontendFilters.routeCodes = [];
      state.frontendFilters.routeIds = [];
      resetStateByFilters(state);
      if (
        state.frontendFilters.managerCodes.includes(action.payload.fullNameCode)
      ) {
        state.frontendFilters.managerCodes =
          state.frontendFilters.managerCodes.filter(
            (item) => item !== action.payload.fullNameCode,
          );
      } else {
        state.frontendFilters.managerCodes = [
          ...state.frontendFilters.managerCodes,
          action.payload.fullNameCode,
        ];
      }
    },
    resetManagerFilter: (state) => {
      state.frontendFilters.managerCodes = [];
      resetStateByFilters(state);
    },
    setCommandFilter: (state, action) => {
      state.frontendFilters.routeCodes = [];
      state.frontendFilters.routeIds = [];
      resetStateByFilters(state);
      if (state.frontendFilters.commands.includes(action.payload)) {
        state.frontendFilters.commands = state.frontendFilters.commands.filter(
          (item) => item !== action.payload,
        );
      } else {
        state.frontendFilters.commands = [
          ...state.frontendFilters.commands,
          action.payload,
        ];
      }
    },
    resetCommandFilter: (state) => {
      state.frontendFilters.commands = [];
      resetStateByFilters(state);
    },
    setRouteFilter: (state, action) => {
      resetStateByFilters(state);
      if (state.frontendFilters.routeCodes.includes(action.payload.routeCode)) {
        state.frontendFilters.routeCodes =
          state.frontendFilters.routeCodes.filter(
            (item) => item !== action.payload.routeCode,
          );
        state.frontendFilters.routeIds = state.frontendFilters.routeIds.filter(
          (item) => item !== action.payload.routeId,
        );
      } else {
        state.frontendFilters.routeCodes = [
          ...state.frontendFilters.routeCodes,
          action.payload.routeCode,
        ];
        state.frontendFilters.routeIds = [
          ...state.frontendFilters.routeIds,
          action.payload.routeId,
        ];
      }
    },
    resetRouteFilter: (state) => {
      resetStateByFilters(state);
      state.frontendFilters.routeCodes = [];
      state.frontendFilters.routeIds = [];
    },
    setAddressFilter: (state, action) => {
      state.frontendFilters.address = action.payload;
    },
    setDaysFilter: (state, action) => {
      resetStateByFilters(state);
      state.frontendFilters.daysOfWeek = action.payload;
    },
    setAttributeFilter: (state, action) => {
      resetStateByFilters(state);
      const { attribute, newItem } = action.payload;

      if (state.frontendFilters[attribute].includes(newItem)) {
        state.frontendFilters[attribute] =
          state.frontendFilters[attribute].filter(
            (item) => item !== newItem,
          );
      } else {
        state.frontendFilters[attribute] = [
          ...state.frontendFilters[attribute],
          newItem,
        ];
      }
    },
    resetAttributeFilter: (state, action) => {
      resetStateByFilters(state);
      state.frontendFilters[action.payload] = [];
    },
    setDuplicateFilter: (state) => {
      resetStateByFilters(state);
      state.frontendFilters.duplicates = !state.frontendFilters.duplicates;
    },
    setSortFilter: (state, action) => {
      if (action.payload !== state.frontendFilters.sort) {
        resetStateByFilters(state);
        state.frontendFilters.sort = action.payload;
      }
    },
    setSelectedRoutePoints: (state, action) => {
      if (state.selectedRoutePoints.includes(action.payload)) {
        state.selectedRoutePoints = state.selectedRoutePoints.filter(
          (item) => item !== action.payload,
        );
      } else {
        state.selectedRoutePoints = [
          ...state.selectedRoutePoints,
          action.payload,
        ];
      }
    },
    setSelectedByLassoRoutePoints: (state, action) => {
      if (action.payload instanceof Array) {
        state.selectedRoutePoints = [
          ...new Set([...state.selectedRoutePoints, ...action.payload]),
        ];
      } else if (!state.selectedRoutePoints.includes(action.payload)) {
        state.selectedRoutePoints = [
          ...state.selectedRoutePoints,
          action.payload,
        ];
      }
    },
    resetSelectedRoutePoints: (state) => {
      state.selectedRoutePoints = [];
    },
    setMapMarkersColoredBy: (state, action) => {
      state.mapMarkersColoredBy = action.payload;
    },
    setMapCenterPoint: (state, action) => {
      state.mapCenterPoint = action.payload;
    },
    setPointFoundByTable: (state, action) => {
      state.mapCenterPoint = {
        latitude: action.payload.latitude,
        longitude: action.payload.longitude,
      };
      state.highlightedPointId = action.payload.pointId;
    },
    setPointFoundByMarker: (state, action) => {
      state.frontendFilters.address = action.payload.pointCode;
      state.highlightedPointId = action.payload.highlightedPointId;
    },
    switchMapRoutePointsVisible: (state) => {
      state.isMapRoutePointsVisible = !state.isMapRoutePointsVisible;
    },
    switchAreasVisible: (state) => {
      state.isAreasVisible = !state.isAreasVisible;
    },
    setAllPointsChecked: (state, action) => {
      state.allPointsChecked = action.payload;
    },
    resetSelectedPoints: (state) => {
      state.selectedRoutePoints = [];
    },
  },
});

export const {
  resetState,
  increaseLimitFilter,
  setManagerFilter,
  resetManagerFilter,
  setCommandFilter,
  resetCommandFilter,
  setRouteFilter,
  resetRouteFilter,
  setAddressFilter,
  setDaysFilter,
  setAttributeFilter,
  resetAttributeFilter,
  setDuplicateFilter,
  setSortFilter,
  setSelectedRoutePoints,
  setSelectedByLassoRoutePoints,
  resetSelectedRoutePoints,
  setMapMarkersColoredBy,
  setMapCenterPoint,
  setPointFoundByTable,
  setPointFoundByMarker,
  switchMapRoutePointsVisible,
  switchAreasVisible,
  setAllPointsChecked,
  resetSelectedPoints
} = routes.actions;

export default routes.reducer;
