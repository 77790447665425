import * as yup from "yup";

export const EditIndividualAreaSchema = yup.object().shape({
  name: yup.string().required("Обязательное поле"),
  branchId: yup
    .number()
    .required("Обязательное поле")
    .typeError("Обязательное поле"),
  groupId: yup
    .number()
    .required("Обязательное поле")
    .typeError("Обязательное поле"),
  cyclePoint: yup.string(),
  deliveryDays: yup.array(),
  periodicity: yup.string().required("Обязательное поле"),
});
