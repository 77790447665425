/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { DISTANCE_DIFFERENCE, SORT } from "shared/config";

const resetStateByFilters = (state) => {
  state.selectedPoints = [];
  state.frontendFilters.limit = 10;
  state.mapCenterPoint = {};
  state.highlightedPointId = null;
  state.changeCoordsFormData = {
    isVisible: false,
    point: {},
    newPoint: {},
  };
  state.allPointsChecked = false;
};

const initialState = {
  frontendFilters: {
    page: 0,
    limit: 10,
    branchIds: [],
    contractIds: [],
    groupIds: [],
    address: "",
    distance: DISTANCE_DIFFERENCE.EMPTY,
    sort: SORT.POINT,
  },
  selectedPoints: [],
  mapCenterPoint: {},
  highlightedPointId: null,
  isMapPointsVisible: true,
  isAreasVisible: true,
  changeCoordsFormData: {
    isVisible: false,
    point: {},
    newPoint: {},
  },
  allPointsChecked: false,
};

const logisticCoordinates = createSlice({
  name: "logistic-coordinates",
  initialState,
  reducers: {
    resetState: () => initialState,
    increaseLimitFilter: (state) => {
      state.frontendFilters.limit += 10;
    },
    setBranchFilter: (state, action) => {
      resetStateByFilters(state);
      if (state.frontendFilters.branchIds.includes(action.payload.id)) {
        state.frontendFilters.branchIds =
          state.frontendFilters.branchIds.filter(
            (item) => item !== action.payload.id,
          );
      } else {
        state.frontendFilters.branchIds = [
          ...state.frontendFilters.branchIds,
          action.payload.id,
        ];
      }
    },
    resetBranchFilter: (state) => {
      state.frontendFilters.branchIds = [];
      resetStateByFilters(state);
    },
    setContractFilter: (state, action) => {
      resetStateByFilters(state);
      if (state.frontendFilters.contractIds.includes(action.payload.id)) {
        state.frontendFilters.contractIds =
          state.frontendFilters.contractIds.filter(
            (item) => item !== action.payload.id,
          );
      } else {
        state.frontendFilters.contractIds = [
          ...state.frontendFilters.contractIds,
          action.payload.id,
        ];
      }
    },
    resetContractFilter: (state) => {
      state.frontendFilters.contractIds = [];
      resetStateByFilters(state);
    },
    setAddressFilter: (state, action) => {
      state.frontendFilters.address = action.payload;
    },
    setDistanceFilter: (state, action) => {
      resetStateByFilters(state);
      state.frontendFilters.distance = action.payload;
    },
    setSortFilter: (state, action) => {
      if (action.payload !== state.frontendFilters.sort) {
        resetStateByFilters(state);
        state.frontendFilters.sort = action.payload;
      }
    },
    setSelectedPoints: (state, action) => {
      if (state.selectedPoints.includes(action.payload)) {
        state.selectedPoints = state.selectedPoints.filter(
          (item) => item !== action.payload,
        );
      } else {
        state.selectedPoints = [...state.selectedPoints, action.payload];
      }
    },
    setSelectedByLassoRoutePoints: (state, action) => {
      if (action.payload instanceof Array) {
        state.selectedPoints = [
          ...new Set([...state.selectedPoints, ...action.payload]),
        ];
      } else if (!state.selectedPoints.includes(action.payload)) {
        state.selectedPoints = [...state.selectedPoints, action.payload];
      }
    },
    resetSelectedPoints: (state) => {
      state.selectedPoints = [];
    },
    setMapCenterPoint: (state, action) => {
      state.mapCenterPoint = action.payload;
    },
    setPointFoundByTable: (state, action) => {
      state.mapCenterPoint = {
        latitude: action.payload.latitude,
        longitude: action.payload.longitude,
      };
      state.highlightedPointId = action.payload.pointId;
    },
    setPointFoundByMarker: (state, action) => {
      state.frontendFilters.address = action.payload.pointCode;
      state.highlightedPointId = action.payload.highlightedPointId;
    },
    switchMapPointsVisible: (state) => {
      state.isMapPointsVisible = !state.isMapPointsVisible;
    },
    switchAreasVisible: (state) => {
      state.isAreasVisible = !state.isAreasVisible;
    },
    setChangeCoordsFormData: (state, action) => {
      state.changeCoordsFormData = action.payload;
    },
    setChangeCoordsFormDataNewPoint: (state, action) => {
      state.changeCoordsFormData.newPoint = action.payload;
    },
    setAllPointsChecked: (state, action) => {
      state.allPointsChecked = action.payload;
    },
  },
});

export const {
  resetState,
  increaseLimitFilter,
  setBranchFilter,
  resetBranchFilter,
  setContractFilter,
  resetContractFilter,
  setAddressFilter,
  setDistanceFilter,
  setSortFilter,
  setSelectedPoints,
  setSelectedByLassoRoutePoints,
  resetSelectedPoints,
  setMapCenterPoint,
  setPointFoundByTable,
  setPointFoundByMarker,
  switchMapPointsVisible,
  switchAreasVisible,
  setChangeCoordsFormData,
  setChangeCoordsFormDataNewPoint,
  setAllPointsChecked,
} = logisticCoordinates.actions;

export default logisticCoordinates.reducer;
