import {
  setSelectedByLassoRoutePoints,
  switchMapPointsVisible,
} from "app/store/reducer/coordinates";
import { resetExpandedMapPopupState } from "app/store/reducer/expanded-map-popup";
import {
  expandMap,
  switchClustering,
} from "app/store/reducer/project-settings";
import { ChangeViewNew } from "features";
import ResizeObserver from "features/map-resize-observer";
import RememberBounds from "features/return-map-to-prev-bounds";
import useLasso from "features/use-lasso";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useListAreasMapQuery } from "shared/api/areas";
import { useListMapPointsCoordinatesQuery } from "shared/api/coordinates";
import { Loader, Map as MapUI } from "shared/ui";
import Lasso from "shared/ui/lasso";

import TableFooter from "../table/footer";
import {
  ChangeCoordsForm,
  ChangeCoordsMarkers,
  Cluster,
  Footer,
  Polygons,
} from "./model";
import { ExpandedPointPopup } from "./ui";

const Map = () => {
  const dispatch = useDispatch();
  const { projectInfo } = useSelector(
    (state) => state.rootReducer.currentProject,
  );

  // Map data
  const {
    frontendFilters,
    mapCenterPoint,
    isMapPointsVisible,
    isAreasVisible,
    changeCoordsFormData,
    highlightedPointId,
  } = useSelector((state) => state.rootReducer.coordinates);
  const {
    data: mapPoints,
    isSuccess: isPointsSuccess,
    isFetching: isPointsFetching,
  } = useListMapPointsCoordinatesQuery(
    {
      projectId: projectInfo.id,
      managerCodes: frontendFilters.managerCodes,
      routeCodes: frontendFilters.routeCodes,
      distance: frontendFilters.distance,
      commands: frontendFilters.commands,
    },
    { skip: !projectInfo.id, refetchOnMountOrArgChange: true },
  );
  const {
    data: areas,
    isSuccess: isAreasSuccess,
    isFetching: isAreasFetching,
  } = useListAreasMapQuery(
    {
      projectId: projectInfo.id,
    },
    { skip: !projectInfo.id, refetchOnMountOrArgChange: true },
  );

  // Map settings
  const { expandMode, clustering } = useSelector(
    (state) => state.rootReducer.projectSettings,
  );

  // Fullscreen map controls
  const expanded = expandMode === "map";
  const handleSwitchMapExpand = () => {
    dispatch(expandMap());
  };
  useEffect(() => {
    dispatch(resetExpandedMapPopupState());
  }, [mapPoints]);

  // Toggle clustering
  const handleSwitchClustering = () => {
    dispatch(switchClustering());
  };

  // Lasso
  const { isLassoActive, handleSwitchLasso, handleFinishLasso } = useLasso(
    mapPoints,
    setSelectedByLassoRoutePoints,
  );

  if (isAreasFetching || isPointsFetching) {
    return <Loader isLoading />;
  }

  if (isAreasSuccess && isPointsSuccess) {
    return (
      <>
        <MapUI
          enableLasso
          switchMapExpand={handleSwitchMapExpand}
          expanded={expanded}
          isLassoActive={isLassoActive}
          switchIsLassoActive={handleSwitchLasso}
          isMapPointsVisible={isMapPointsVisible}
          switchMapPointsVisible={() => dispatch(switchMapPointsVisible())}
          clustering={clustering}
          switchClustering={handleSwitchClustering}
        >
          <ChangeViewNew mapCenterPoint={mapCenterPoint} />
          <RememberBounds />
          <ResizeObserver expanded={expanded} />
          {isMapPointsVisible && !changeCoordsFormData.isVisible && (
            <Cluster data={mapPoints} highlightedPointId={highlightedPointId} />
          )}
          {isAreasVisible && (
            <Polygons areas={areas} mapCenterPoint={mapCenterPoint} />
          )}
          {changeCoordsFormData.isVisible && (
            <ChangeCoordsMarkers changeCoordsFormData={changeCoordsFormData} />
          )}
          <Lasso
            isLassoActive={isLassoActive}
            onLassoFinished={handleFinishLasso}
          />
        </MapUI>
        {changeCoordsFormData.isVisible && (
          <ChangeCoordsForm expanded={expanded} />
        )}
        {expanded && <ExpandedPointPopup />}
        {expanded && <TableFooter inMap />}
        <Footer expanded={expanded} />
      </>
    );
  }

  return null;
};

export default Map;
