import {
  resetSelectedPoints,
  setAllPointsChecked,
} from "app/store/reducer/coordinates";
import { useDispatch, useSelector } from "react-redux";
import {
  useChangeCurrentCoordsToCalculatedCoordinatesMutation,
  useChangeCurrentCoordsToCalculatedForAllPointsCoordinatesMutation,
} from "shared/api/coordinates";
import { TABLE_FOOTER_HEIGHT } from "shared/config";
import { pluralize } from "shared/helpers";
import { Button, Checkbox, Flex, Text } from "shared/ui";
import styled, { css } from "styled-components";

const Footer = ({ inMap = false }) => {
  const dispatch = useDispatch();
  const { projectInfo } = useSelector(
    (state) => state.rootReducer.currentProject,
  );
  const { frontendFilters, selectedPoints, allPointsChecked } = useSelector(
    (state) => state.rootReducer.coordinates,
  );
  const [changeCurrentCoordsToCalculated] =
    useChangeCurrentCoordsToCalculatedCoordinatesMutation();
  const [changeCurrentCoordsToCalculatedForAllPoints] =
    useChangeCurrentCoordsToCalculatedForAllPointsCoordinatesMutation();

  const onSelectedReset = () => {
    dispatch(resetSelectedPoints());
  };

  const onChangeCoords = async () => {
    await changeCurrentCoordsToCalculated({
      projectId: projectInfo.id,
      pointIds: selectedPoints,
    });

    onSelectedReset();
  };

  const onChangeCoordsForAllPoints = async () => {
    await changeCurrentCoordsToCalculatedForAllPoints({
      projectId: projectInfo.id,
      address: frontendFilters.address,
      distance: frontendFilters.distance,
      routeCodes: frontendFilters.routeCodes,
      managerCodes: frontendFilters.managerCodes,
      commands: frontendFilters.commands,
    });

    dispatch(setAllPointsChecked(false));
  };

  if (allPointsChecked) {
    return (
      <Root inMap={inMap}>
        <Flex ml="7px">
          <Text variant="body1">Все точки</Text>
        </Flex>
        <Flex flexDirection="row" alignItems="center" ml="12px">
          <Button
            text="ИЗМЕНИТЬ ТЕКУЩИЕ КООРДИНАТЫ НА РАСЧЁТНЫЕ"
            onClick={onChangeCoordsForAllPoints}
          />
        </Flex>
      </Root>
    );
  }

  if (selectedPoints?.length) {
    return (
      <Root inMap={inMap}>
        <Flex flexDirection="row" alignItems="center">
          <Checkbox checked onChange={onSelectedReset} />
          <Flex ml="7px">
            <Text variant="body1">
              {selectedPoints.length}{" "}
              {pluralize(selectedPoints.length, ["точка", "точки", "точек"])}
            </Text>
          </Flex>
        </Flex>
        <Flex flexDirection="row" alignItems="center" ml="12px">
          <Button
            text="ИЗМЕНИТЬ ТЕКУЩИЕ КООРДИНАТЫ НА РАСЧЁТНЫЕ"
            onClick={onChangeCoords}
          />
        </Flex>
      </Root>
    );
  }

  return null;
};

const Root = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 26px;
  height: ${TABLE_FOOTER_HEIGHT};
  background: #cee3fd;

  ${({ inMap }) =>
    inMap &&
    css`
      position: fixed;
      bottom: 0;
      left: 0;
      z-index: 9999;
      background: transparent;
    `}
`;

export default Footer;
