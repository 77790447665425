import {
  resetState as resetCurrentProjectState,
  setInfo,
} from "app/store/reducer/current-project";
import { resetState } from "app/store/reducer/days";
import { updateExcelData } from "app/store/reducer/excel-data";
import { saveRawData } from "app/store/reducer/import-excel-file";
import { plusIcon } from "icons/icons";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useProjectInfoQuery } from "shared/api/current-project";
import { Button, Flex, Loader, PageShadow, Text } from "shared/ui";
import styled from "styled-components";
import { read, utils } from "xlsx";

import { mergePointDuplicates, mergeRouteDuplicates } from "./model";
import Table from "./table";

const ImportExcelFile = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const fileInputRef = useRef(null);
  const { data: projectInfo, isFetching } = useProjectInfoQuery(
    { projectId: params.id },
    { skip: !params.id },
  );

  const [loading, setLoading] = useState(false);
  const [currentSheet, setCurrentSheet] = useState(0);

  useEffect(() => {
    if (projectInfo) {
      dispatch(
        setInfo({
          id: projectInfo.id,
          name: projectInfo.name,
          status: projectInfo.status,
        }),
      );
    }
  }, [projectInfo]);

  useEffect(
    () => () => {
      dispatch(resetCurrentProjectState());
      dispatch(resetState());
    },
    [],
  );

  const onChooseNewFileButtonClick = async (e) => {
    setCurrentSheet(0);
    const file = e.target.files[0];
    const reader = new FileReader();

    setLoading(true)
    // parse Excel file and save to store
    reader.onload = (event) => {
      const data = new Uint8Array(event.target.result);
      const workbook = read(data, { type: "array" });

      const sheetsData = workbook.SheetNames.map((sheetName) => {
        const worksheet = workbook.Sheets[sheetName];
        const rawData = utils.sheet_to_json(worksheet, {
          header: 1,
          defval: "",
        });
        return rawData.filter((row) => row.some((cell) => cell !== ""));
      });

      dispatch(
        saveRawData({ data: sheetsData, sheetNames: workbook.SheetNames }),
      );
      setLoading(false)
    };
    await reader.readAsArrayBuffer(file);
  };

  // 'ОБРАБОТКА ДАННЫХ' handler
  const { headers, data, sheetNames } = useSelector(
    (state) => state.rootReducer.excelFileMapping,
  );
  const handleToProcessData = async () => {
    setLoading(true);

    const routeList = [];
    const pointList = [];
    const routePointList = [];

    // iterate through each sheet
    data.forEach((sheet, sheetIndex) => {
      // iterate through each row
      sheet.forEach((row, rowIndex) => {
        // skip headers
        if (rowIndex === 0) return;

        const routeRecord = {};
        const pointRecord = {};
        const routePointRecord = {};

        // iterate through each cell and map data depending on what table it should be in
        row.forEach((cell, cellIndex) => {
          const columnName = headers[sheetIndex][cellIndex].value;
          if (!columnName) return;

          switch (headers[sheetIndex][cellIndex].table) {
            case "route": {
              if (columnName === "routeCode") {
                routePointRecord.routeCode = cell;
              }
              routeRecord[columnName] = cell;
              break;
            }
            case "point": {
              if (columnName === "pointCode") {
                routePointRecord.pointCode = cell;
              }
              pointRecord[columnName] = cell;
              break;
            }
            case "route_point": {
              if (columnName === "routeCode") {
                routeRecord.routeCode = cell;
              }
              if (columnName === "pointCode") {
                pointRecord.pointCode = cell;
              }
              routePointRecord[columnName] = cell;
              break;
            }
            default: {
              break;
            }
          }
        });

        // if there are data for relevant table, push it
        if (Object.keys(routeRecord).length > 0)
          routeList.push({ ...routeRecord, status: "" });
        if (Object.keys(pointRecord).length > 0)
          pointList.push({ ...pointRecord, status: "" });
        if (
          Object.keys(routePointRecord).length > 0 &&
          routePointRecord.pointCode
        )
          routePointList.push({
            ...routePointRecord,
            status: "",
          });
      });
    });

    routePointList.forEach((rp, index) => {
      // eslint-disable-next-line no-param-reassign
      rp.rowNumber = index + 1;
    });

    dispatch(
      updateExcelData({
        routeList: mergeRouteDuplicates(routeList),
        pointList: mergePointDuplicates(pointList),
        routePointList,
      }),
    );

    setLoading(false);
    // navigate to project page and edit data before upload
    navigate(`/routes/${projectInfo.id}`, { state: { openEditPopup: true } });
  };

  if (isFetching) {
    return (
      <PageShadow>
        <Loader isLoading />
      </PageShadow>
    );
  }

  return (
    <>
      <Root>
        <Text variant="title1">Загрузить данные из Excel</Text>
        <Flex flexDirection="row" mt="20px">
          <input
            ref={fileInputRef}
            type="file"
            accept=".xls,.xlsx"
            onChange={onChooseNewFileButtonClick}
            style={{ display: "none" }}
          />
          <Button
            height="48px"
            color="#2D8AF6"
            icon={plusIcon}
            text={data.length === 0 ? "ВЫБРАТЬ ФАЙЛ" : "ВЫБРАТЬ ДРУГОЙ ФАЙЛ"}
            disabled={loading}
            onClick={() => {
              fileInputRef.current.click();
            }}
          />
          <Button
            height="48px"
            color="#CEE3FD"
            textColor="#2D8AF6"
            text="ОБРАБОТКА ДАННЫХ"
            disabled={loading || data.length === 0}
            onClick={handleToProcessData}
            style={{ marginLeft: "16px" }}
          />
        </Flex>
        <Flex flexDirection="row" mt="20px">
          <Text variant="body1" color="color7">
            Выберите соответствующие заголовки столбцов для{" "}
          </Text>
          {sheetNames.map((sheet, index) => (
            <SheetNumber
              key={sheet.toString()}
              active={index === currentSheet}
              onClick={() => setCurrentSheet(index)}
            >
              {sheet}
              {index !== sheetNames.length - 1 && ","}
            </SheetNumber>
          ))}
        </Flex>
        <Flex mt="20px">
          <Table currentSheet={currentSheet} />
        </Flex>
      </Root>
      {loading && (
        <PageShadow>
          <Loader isLoading={loading} />
        </PageShadow>
      )}
    </>
  );
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-top: 16px;
  margin-left: 16px;
  margin-right: 16px;
`;

const SheetNumber = styled.span`
  text-decoration: underline;
  padding-right: 4px;
  padding-left: 4px;
  font-size: 18px;
  cursor: pointer;
  color: ${({ active }) => (active ? "#2D8AF6" : "#282828")};

  &:first-of-type {
    margin-left: 4px;
  }
`;

export default ImportExcelFile;
