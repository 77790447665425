/* eslint-disable consistent-return */
import { setDaysFilter } from "app/store/reducer/routes";
import { isEqual } from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DAYS_OF_WEEK_COLOR, DAYS_OF_WEEK_RU, DAYS_OF_WEEK_SHORT_RU } from "shared/config";
import { Checkbox, Flex, SearchDropdown, Text } from "shared/ui";

const VisitDaysRouteEng = () => {
  const dispatch = useDispatch();
  const [selectedDays, setSelectedDays] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const { daysOfWeek } = useSelector(
    (state) => state.rootReducer.routes.frontendFilters,
  );

  const onSelect = (day) => {
    if (selectedDays.includes(day)) {
      setSelectedDays((prev) => prev.filter((v) => v !== day));
    } else {
      setSelectedDays((prev) => [...prev, day]);
    }
  };

  useEffect(() => {
    if (isMounted && !isEqual(selectedDays, daysOfWeek)) {
      const setFilterWithDelay = setTimeout(() => {
        dispatch(setDaysFilter(selectedDays));
      }, 300);

      return () => {
        clearTimeout(setFilterWithDelay);
      };
    }
  }, [selectedDays]);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    setSelectedDays(daysOfWeek);
  }, [daysOfWeek]);

  const days = [
    {
      name: DAYS_OF_WEEK_RU.MONDAY,
      shortName: DAYS_OF_WEEK_SHORT_RU.MONDAY,
      color: DAYS_OF_WEEK_COLOR.MONDAY,
    },
    {
      name: DAYS_OF_WEEK_RU.TUESDAY,
      shortName: DAYS_OF_WEEK_SHORT_RU.TUESDAY,
      color: DAYS_OF_WEEK_COLOR.TUESDAY,
    },
    {
      name: DAYS_OF_WEEK_RU.WEDNESDAY,
      shortName: DAYS_OF_WEEK_SHORT_RU.WEDNESDAY,
      color: DAYS_OF_WEEK_COLOR.WEDNESDAY,
    },
    {
      name: DAYS_OF_WEEK_RU.THURSDAY,
      shortName: DAYS_OF_WEEK_SHORT_RU.THURSDAY,
      color: DAYS_OF_WEEK_COLOR.THURSDAY,
    },
    {
      name: DAYS_OF_WEEK_RU.FRIDAY,
      shortName: DAYS_OF_WEEK_SHORT_RU.FRIDAY,
      color: DAYS_OF_WEEK_COLOR.FRIDAY,
    },
    {
      name: DAYS_OF_WEEK_RU.SATURDAY,
      shortName: DAYS_OF_WEEK_SHORT_RU.SATURDAY,
      color: DAYS_OF_WEEK_COLOR.SATURDAY,
    },
    {
      name: DAYS_OF_WEEK_RU.SUNDAY,
      shortName: DAYS_OF_WEEK_SHORT_RU.SUNDAY,
      color: DAYS_OF_WEEK_COLOR.SUNDAY,
    },
    {
      name: "N/A",
      shortName: "N/A",
      color: DAYS_OF_WEEK_COLOR.NA,
    },
  ];

  const onSearch = (value) => {
    setSearchValue(value);
  };

  const onToggle = (isOpen) => {
    if (isOpen === false) onSearch("");
    setIsDropdownOpen((prev) => !prev);
  };

  const onResetSearch = () => {
    onSearch("");
    setIsDropdownOpen(false);
  };

  const onResetAllCheckboxes = () => {
    setSelectedDays([]);
    setIsDropdownOpen(false);
  };

  return (
    <SearchDropdown
      long
      isDropdownOpen={isDropdownOpen}
      toggleText={
        daysOfWeek?.length ? `Выбрано ${daysOfWeek?.length}` : "Выбрать"
      }
      searchValue={searchValue}
      onToggle={onToggle}
      onSearch={onSearch}
      onResetSearch={onResetSearch}
      onResetAllCheckboxes={onResetAllCheckboxes}
      multiselect
      selectedText
      selectedCount={daysOfWeek?.length}
    >
      {days.map(
        (item) =>
          item.name.toLowerCase().includes(searchValue.toLowerCase()) && (
            <SearchDropdown.Item key={item.name}>
              <Checkbox
                filledColor={item.color}
                emptyColor={item.color}
                checked={selectedDays?.includes(item.name)}
                onChange={() => onSelect(item.name)}
              />
              <Flex ml="7px">
                <Text variant="body1">{item.shortName}</Text>
              </Flex>
            </SearchDropdown.Item>
          ),
      )}
    </SearchDropdown>
  );
};

export default VisitDaysRouteEng;
