import { getUserFullName } from "shared/libs/jwt-decode";
import { clearAuthToken } from "shared/libs/local-storage";

import { NavigationDropdown } from "../../ui";

const UserDropdown = () => {
  const name = getUserFullName();

  const onSignOut = () => {
    clearAuthToken();
    window.location.reload();
  };

  const items = [
    {
      id: 0,
      text: "Выйти",
      onClick: () => onSignOut(),
    },
  ];

  return <NavigationDropdown toggleText={name} items={items} />;
};

export default UserDropdown;
