import { setChangeCoordsFormData } from "app/store/reducer/logistic-coordinates";
import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { Flex, Text } from "shared/ui";

const PointInfo = ({ shownPoint }) => {
  const dispatch = useDispatch();
  const onChangeCoordsButtonClick = useCallback(() => {
    dispatch(
      setChangeCoordsFormData({
        isVisible: true,
        point: shownPoint,
      }),
    );
  }, [dispatch, shownPoint]);

  return (
    <Flex width="290px" overflowY="auto">
      <Flex mb="4px">
        <Text variant="body3bold">{shownPoint?.pointCode}</Text>
      </Flex>
      <Flex mb="4px">
        <Text variant="body3">{shownPoint?.pointName}</Text>
      </Flex>
      <Flex mb="10px">
        <Text variant="body3">{shownPoint?.address}</Text>
      </Flex>
      <Flex mb="10px">
        <Text variant="body3">Разница {shownPoint?.distance}</Text>
      </Flex>
      <Flex
        width="max-content"
        cursor="pointer"
        onClick={onChangeCoordsButtonClick}
      >
        <Text variant="body3" color="color0">
          Изменить координаты
        </Text>
      </Flex>
    </Flex>
  );
};

export default PointInfo;
