import {
  resetExpandedMapPopupState,
  setExpandedMapPopupData,
} from "app/store/reducer/expanded-map-popup";
import { setMapCenterPoint } from "app/store/reducer/logistic-coordinates";
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
import { Polygon } from "react-leaflet";
import { useDispatch } from "react-redux";

import { AreaPopup } from "../../ui";

const Polygons = ({ areas = [], mapCenterPoint = {} }) => {
  const dispatch = useDispatch();

  const onPolygonClick = (area) => {
    dispatch(
      setMapCenterPoint({
        id: area.id,
        clickedIn: "map",
      }),
    );
  };

  const onPopupOpen = (targetArea) => {
    dispatch(
      setExpandedMapPopupData({
        area: targetArea,
      }),
    );
  };

  const onPopupClosed = () => {
    dispatch(resetExpandedMapPopupState());
  };

  return areas?.map((item) => (
    <Polygon
      key={item.id}
      positions={item.coordinates}
      polygon={item}
      pathOptions={{
        color: item.id === mapCenterPoint.id ? "black" : item.color,
        fillColor: item.color,
        fillOpacity: item.id === mapCenterPoint.id ? 0.5 : 0.2,
        dashArray: item.id === mapCenterPoint.id && [10, 10],
      }}
      eventHandlers={{
        click: () => onPolygonClick(item),
        popupopen: () => onPopupOpen(item),
        popupclose: () => onPopupClosed(),
      }}
    >
      <AreaPopup area={item} />
    </Polygon>
  ));
};

export default Polygons;
