import { PopupContext } from "app/context/PopupContext";
import { resetSelectedPoints } from "app/store/reducer/shedule";
import { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useAddPointsToIndividualAreaMutation,
  useIndividualAreasListQuery,
} from "shared/api/shedule";
import { SERVER_ERROR_TEXT } from "shared/config";
import { Button, CustomRadio, ErrorText, Popup } from "shared/ui";
import styled from "styled-components";

const SetIndividualArea = () => {
  const dispatch = useDispatch();
  const { hidePopup } = useContext(PopupContext);
  const [serverError, setServerError] = useState("");
  const { projectInfo } = useSelector(
    (state) => state.rootReducer.currentProject,
  );
  const [selectedIndividualArea, setSelectedIndividualArea] = useState(null);
  const { selectedPoints } = useSelector((state) => state.rootReducer.shedule);
  const { data: individualAreas, isSuccess } = useIndividualAreasListQuery(
    {
      projectId: projectInfo.id,
    },
    { skip: !projectInfo.id, refetchOnMountOrArgChange: true },
  );
  const [addPointsToIndividualArea, { isLoading }] =
    useAddPointsToIndividualAreaMutation();

  const onSubmit = async () => {
    const response = await addPointsToIndividualArea({
      projectId: projectInfo.id,
      deliveryAreaId: selectedIndividualArea,
      body: selectedPoints,
    });

    if (response.error) {
      setServerError(response.error?.data?.message || SERVER_ERROR_TEXT);
      return;
    }

    dispatch(resetSelectedPoints());
    hidePopup();
  };

  if (isSuccess) {
    return (
      <Popup
        title="Добавление точек в график"
        width="400px"
        isCenter={false}
        positionLeft="130px"
      >
        <ListWrapper>
          {individualAreas?.map((item) => (
            <CustomRadio
              key={item.id}
              className="customRadio"
              label={item.name}
              name="cycle"
              onChange={() => setSelectedIndividualArea(item.id)}
            />
          ))}
        </ListWrapper>
        <Button
          text="Применить"
          onClick={onSubmit}
          disabled={!selectedIndividualArea || isLoading}
        />
        <ErrorText errorText={serverError} />
      </Popup>
    );
  }

  return null;
};

const ListWrapper = styled.div`
  margin: 20px 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .customRadio {
    margin-bottom: 12px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export default SetIndividualArea;
