import { PopupContext } from "app/context/PopupContext";
import React, { useCallback, useContext, useMemo, useState } from "react";
import {
  useChangeRoutesOptimizationMutation,
  useListRoutesOptimizationQuery,
} from "shared/api/projects";
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-console */
import { DAYS_OF_WEEK } from "shared/config";
import { Button, Loader, Popup } from "shared/ui";
import styled from "styled-components";

import { configColumns } from "./config";
import RoutesOptimizationContext from "./context";
import { Table } from "./ui";

const ProjectOptimization = ({ project }) => {
  const { hidePopup } = useContext(PopupContext);
  const [daysObject, setDaysObject] = useState({});
  const { data: routesOptimization, isFetching } =
    useListRoutesOptimizationQuery(
      {
        projectId: project.id,
      },
      { skip: !project.id },
    );
  const [changeRoutesOptimization, { isLoading }] =
    useChangeRoutesOptimizationMutation();

  const changeDaysObject = useCallback(
    (routeId, day) => {
      const newDaysObject = { ...daysObject };
      if (newDaysObject[routeId]) {
        if (newDaysObject[routeId].includes(day)) {
          if (newDaysObject[routeId].length === 1) {
            delete newDaysObject[routeId];
          } else {
            newDaysObject[routeId] = newDaysObject[routeId].filter(
              (item) => item !== day,
            );
          }
        } else {
          newDaysObject[routeId].push(day);
        }
      } else {
        newDaysObject[routeId] = [day];
      }

      setDaysObject(newDaysObject);
    },
    [daysObject],
  );

  const selectAll = () => {
    const newDaysObject = {};
    routesOptimization.forEach((item) => {
      newDaysObject[item.id] = [
        DAYS_OF_WEEK.MONDAY,
        DAYS_OF_WEEK.TUESDAY,
        DAYS_OF_WEEK.WEDNESDAY,
        DAYS_OF_WEEK.THURSDAY,
        DAYS_OF_WEEK.FRIDAY,
        DAYS_OF_WEEK.SATURDAY,
        DAYS_OF_WEEK.SUNDAY,
      ];
    });

    setDaysObject(newDaysObject);
  };

  const resetAll = () => {
    setDaysObject({});
  };

  const onCalculateButtonClick = async () => {
    const routePointRequests = Object.keys(daysObject).map((key) => ({
      routeId: key,
      daysOfWeek: daysObject[key],
    }));

    const response = await changeRoutesOptimization({
      projectId: project.id,
      routePointRequests,
    });

    if (response.error) return;

    hidePopup();
  };

  const isDaysSelected = Object.values(daysObject).some(
    (daysOfWeek) => daysOfWeek.length > 0,
  );

  const data = React.useMemo(() => routesOptimization, [routesOptimization]);
  const columns = React.useMemo(() => configColumns, []);

  const contextValue = useMemo(
    () => ({ daysObject, changeDaysObject }),
    [daysObject, changeDaysObject],
  );

  if (isFetching || isLoading) {
    return (
      <Popup title="Загрузка...">
        <Loader isLoading />
      </Popup>
    );
  }

  return (
    <StyledCommonPopup
      isCenter
      title="Оптимизиция проекта"
      className="optimization-popup__common-popup"
    >
      <Container>
        <TableWrapper>
          <RoutesOptimizationContext.Provider value={contextValue}>
            <Table data={data} columns={columns} />
          </RoutesOptimizationContext.Provider>
        </TableWrapper>
        <ButtonsWrapper>
          <ButtonSecondary onClick={selectAll} className="select-all-button">
            Выбрать всё
          </ButtonSecondary>
          <ButtonSecondary onClick={resetAll} className="reset-button">
            Сбросить выбранные
          </ButtonSecondary>

          <Button
            width="200px"
            height="56px"
            color="#CEE3FD"
            text="ОТМЕНА"
            onClick={() => hidePopup()}
            className="cancel-button"
          />
          <Button
            width="200px"
            height="56px"
            color="#2D8AF6"
            text="РАССЧИТАТЬ"
            onClick={onCalculateButtonClick}
            disabled={!isDaysSelected || isLoading || isFetching}
          />
        </ButtonsWrapper>
        {!isDaysSelected && (
          <WarningText>Выберите дни для оптимизации маршрута</WarningText>
        )}
      </Container>
    </StyledCommonPopup>
  );
};

const StyledCommonPopup = styled(Popup)`
  &.optimization-popup__common-popup {
    max-width: 1027px;
    min-height: 468px;
    width: 100%;
  }
`;

const Container = styled.div`
  margin-top: 11px;
`;

const TableWrapper = styled.div`
  overflow: hidden;
  margin-bottom: 20px;
  height: 242px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  .cancel-button {
    color: #2d8af6;
    margin-right: 12px;
  }
`;

const ButtonSecondary = styled.button`
  font-size: 14px;
  color: #595959;
  align-self: flex-start;
  border: none;
  outline: none;
  background: none;

  &.select-all-button {
    margin-right: 24px;
  }

  &.reset-button {
    margin-right: auto;
  }
`;

const WarningText = styled.p`
  font-size: 14px;
  color: #ff3131;
  margin: 20px 0 0 0;
  text-align: end;
`;

export default ProjectOptimization;
