import { yupResolver } from "@hookform/resolvers/yup";
import { PopupContext } from "app/context/PopupContext";
import { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { useUpdateProjectNameMutation } from "shared/api/projects";
import { SERVER_ERROR_TEXT } from "shared/config";
import { Button, ErrorText, Flex, Input, Popup } from "shared/ui";

import { ChangeProjectNameSchema } from "./config";

const ChangeProjectName = ({ project }) => {
  const { hidePopup } = useContext(PopupContext);
  const [serverError, setServerError] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(ChangeProjectNameSchema),
    defaultValues: {
      name: project.name || "",
    },
  });
  const [updateProjectName, { isLoading }] = useUpdateProjectNameMutation();

  const onSaveButtonClick = async (values) => {
    const response = await updateProjectName({
      id: project.id,
      ...values,
    });
    if (response.error) {
      setServerError(response.error?.data?.message || SERVER_ERROR_TEXT);
      return;
    }
    hidePopup();
  };

  return (
    <Popup isCenter title="Изменение названия проекта">
      <Input
        {...register("name", {
          onChange: () => serverError && setServerError(""),
        })}
        title="Проект"
        disabled={isLoading}
        errorText={errors?.name?.message}
      />
      <Flex mt="12px">
        <Button
          width="100%"
          height="56px"
          text="СОХРАНИТЬ НАЗВАНИЕ"
          disabled={isLoading}
          onClick={handleSubmit(onSaveButtonClick)}
        />
      </Flex>
      <ErrorText errorText={serverError} />
    </Popup>
  );
};

export default ChangeProjectName;
