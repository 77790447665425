import { BodyCell, HeaderCell } from "../model";

export const configColumns = [
  {
    id: "0",
    Header: "Маршрут",
    accessor: "routeName",
    w: "12.5%",
  },
  {
    id: "1",
    Header: () => <HeaderCell title="ПН" />,
    accessor: (data) => <BodyCell value={data.report.MONDAY} />,
    w: "12.5%",
    textAlign: "center",
  },
  {
    id: "2",
    Header: () => <HeaderCell title="ВТ" />,
    accessor: (data) => <BodyCell value={data.report.TUESDAY} />,
    w: "12.5%",
    textAlign: "center",
  },
  {
    id: "3",
    Header: () => <HeaderCell title="СР" />,
    accessor: (data) => <BodyCell value={data.report.WEDNESDAY} />,
    w: "12.5%",
    textAlign: "center",
  },
  {
    id: "4",
    Header: () => <HeaderCell title="ЧТ" />,
    accessor: (data) => <BodyCell value={data.report.THURSDAY} />,
    w: "12.5%",
    textAlign: "center",
  },
  {
    id: "5",
    Header: () => <HeaderCell title="ПТ" />,
    accessor: (data) => <BodyCell value={data.report.FRIDAY} />,
    w: "12.5%",
    textAlign: "center",
  },
  {
    id: "6",
    Header: () => <HeaderCell title="СБ+ВС" />,
    accessor: (data) => <BodyCell value={data.report.WEEKEND} />,
    w: "12.5%",
    textAlign: "center",
  },
  {
    id: "7",
    Header: () => <HeaderCell title="∑" />,
    accessor: (data) => <BodyCell value={data.report.SUM} />,
    w: "12.5%",
    textAlign: "center",
  },
];
