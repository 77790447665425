/* eslint-disable import/no-cycle */
import React from "react";
import { Checkbox } from "shared/ui";
import styled from "styled-components";

import { DRIVE_MODE_PARAMETERS } from "..";

const DriveModeDetails = ({
  handleTime,
  time,
  parameters,
  handleParameters,
}) => (
  <>
    <TimeContainer>
      <Time onChange={handleTime} type="time" value={time} />
      <TimeText>— время отправления</TimeText>
    </TimeContainer>

    <Checkboxes>
      <CheckboxLabel>
        <Checkbox
          name="driveModeDetail"
          filledColor="#000000"
          emptyColor="#787878"
          checked={parameters.includes(DRIVE_MODE_PARAMETERS.HIGHWAYS)}
          onChange={() => handleParameters(DRIVE_MODE_PARAMETERS.HIGHWAYS)}
        />
        <CheckboxText
          color={
            parameters.includes(DRIVE_MODE_PARAMETERS.HIGHWAYS)
              ? "#000000"
              : "#787878"
          }
        >
          Избегать автобаны
        </CheckboxText>
      </CheckboxLabel>
      <CheckboxLabel>
        <Checkbox
          name="driveModeDetail"
          filledColor="#000000"
          emptyColor="#787878"
          checked={parameters.includes(DRIVE_MODE_PARAMETERS.TOLLS)}
          onChange={() => handleParameters(DRIVE_MODE_PARAMETERS.TOLLS)}
        />
        <CheckboxText
          color={
            parameters.includes(DRIVE_MODE_PARAMETERS.TOLLS)
              ? "#000000"
              : "#787878"
          }
        >
          Избегать платных трасс
        </CheckboxText>
      </CheckboxLabel>
      <CheckboxLabel>
        <Checkbox
          name="driveModeDetail"
          filledColor="#000000"
          emptyColor="#787878"
          checked={parameters.includes(DRIVE_MODE_PARAMETERS.FERRIES)}
          onChange={() => handleParameters(DRIVE_MODE_PARAMETERS.FERRIES)}
        />
        <CheckboxText
          color={
            parameters.includes(DRIVE_MODE_PARAMETERS.FERRIES)
              ? "#000000"
              : "#787878"
          }
        >
          Избегать паромов
        </CheckboxText>
      </CheckboxLabel>
    </Checkboxes>
  </>
);

const TimeContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 19px;
  color: #787878;
`;

const Time = styled.input`
  outline: none;
  width: 74px;
  height: 48px;
  text-align: center;
  border: 1px solid #cccccc;
  font-size: 18px;
  color: #787878;

  &[type="time"]::-webkit-calendar-picker-indicator {
    background: none;
    display: none;
  }
`;

const TimeText = styled.p`
  margin: 0 0 0 8px;
  font-size: 18px;
`;

const CheckboxLabel = styled.label`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 15px;
  font-size: 18px;

  &:last-of-type {
    margin-right: 0;
  }
`;

const CheckboxText = styled.p`
  margin: 0 0 0 7px;
  color: ${({ color }) => color};
`;

const Checkboxes = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export default DriveModeDetails;
