import React from "react";
import { Text } from "shared/ui";

export const PointsInfo = ({ frequency, duration }) => (
  <>
    <Text color="color3">
      {frequency
        ? `Текущая частота: ${frequency}`
        : "Вы выбрали точки с разными значениями частоты"}
    </Text>
    <Text color="color3">
      {duration !== undefined
        ? `Текущее время: ${duration ?? "-"}`
        : "Вы выбрали точки с разными значениями времени"}
    </Text>
  </>
);
