import {
  setAddressFilter,
  setPointFoundByMarker,
} from "app/store/reducer/coordinates";
import {
  resetExpandedMapPopupState,
  setExpandedMapPopupData,
} from "app/store/reducer/expanded-map-popup";
import L from "leaflet";
import ReactDOMServer from "react-dom/server";
import { Marker } from "react-leaflet";
import { useDispatch } from "react-redux";
import { ClusterMarker as ClusterMarkerUI } from "shared/ui";

import PointPopup from "../point-popup";

const ClusterMarker = ({
  cluster,
  pointCount,
  points,
  supercluster,
  highlightedPointId,
}) => {
  const dispatch = useDispatch();
  const [longitude, latitude] = cluster.geometry.coordinates;
  const pointsInCluster = supercluster
    .getLeaves(cluster.id, "Infinity")
    .map((item) => item.properties.point)
    .sort((a, b) => a.id - b.id);

  const isClusterIncludesPoint = Boolean(
    pointsInCluster.find((item) => item.id === highlightedPointId),
  );

  const icons = {};
  const fetchIcon = (count, size) => {
    const uniqueColors = [...new Set(pointsInCluster.map((p) => p.color))];
    const gapSize = 0.75;
    const gradientValue = uniqueColors
      .map((color, index, array) => {
        const startColor = (index / array.length) * 100;
        const endColor = ((index + 1) / array.length) * 100;
        return `
              #fff ${startColor}% ${startColor + gapSize}%, 
              ${color} ${startColor + gapSize}% ${endColor - gapSize}%,
              #fff ${endColor - gapSize}% ${endColor}%
              `;
      })
      .join(", ");

    if (!icons[count]) {
      icons[count] = L.divIcon({
        html: ReactDOMServer.renderToString(
          <ClusterMarkerUI
            size={`${size}px`}
            highlighted={isClusterIncludesPoint}
            gradient={
              uniqueColors.length === 1
                ? uniqueColors[0]
                : `conic-gradient(${gradientValue})`
            }
            count={count}
          />,
        ),
      });
    }
    return icons[count];
  };

  const onPopupOpen = () => {
    dispatch(
      setExpandedMapPopupData({
        point: pointsInCluster[0],
        multipleList: pointsInCluster,
      }),
    );
    dispatch(
      setPointFoundByMarker({
        pointCode: pointsInCluster[0].pointCode,
        highlightedPointId: pointsInCluster[0].id,
      }),
    );
  };

  const onPopupClosed = () => {
    dispatch(resetExpandedMapPopupState());
    dispatch(setAddressFilter(""));
  };

  return (
    <Marker
      position={[latitude, longitude]}
      icon={fetchIcon(pointCount, 30 + (pointCount / points.length) * 40)}
      eventHandlers={{
        popupopen: () => onPopupOpen(),
        popupclose: () => onPopupClosed(),
      }}
    >
      <PointPopup isCluster multipleList={pointsInCluster} />
    </Marker>
  );
};

export default ClusterMarker;
