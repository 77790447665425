import * as React from "react";

const SvgDefaultMarker = ({ color = "#000000", ...props }) => (
  <svg
    width={33}
    height={44}
    viewBox="0 0 42 52"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M21 2C10.785 2 2.5 10.285 2.5 20.5c0 5.924 3.267 12.304 6.503 17.236 3.29 5.014 6.81 8.917 7.777 9.965.051.055.107.117.166.184C17.615 48.634 18.836 50 21 50c2.165 0 3.385-1.366 4.054-2.115l.165-.184c.967-1.046 4.488-4.95 7.778-9.965C36.233 32.804 39.5 26.424 39.5 20.5 39.5 10.285 31.215 2 21 2Zm0 27.5a9 9 0 1 1 0-18 9 9 0 0 1 0 18ZM21 13c-4.14 0-7.5 3.36-7.5 7.5 0 4.14 3.36 7.5 7.5 7.5 4.14 0 7.5-3.36 7.5-7.5 0-4.14-3.36-7.5-7.5-7.5Z"
      fill={color}
      stroke="white"
      strokeWidth="4"
    />
  </svg>
);

export default SvgDefaultMarker;
