import {
  resetExpandedMapPopupState,
  setExpandedMapPopupData,
} from "app/store/reducer/expanded-map-popup";
/* eslint-disable no-underscore-dangle */
import {
  setAddressFilter,
  setPointFoundByMarker,
  setSelectedPoints,
} from "app/store/reducer/shedule";
import { useDispatch, useSelector } from "react-redux";

import PointPopup from "../point-popup";
import { MarkerIcon } from "./ui";

const PointMarker = ({ data, point, highlightedPointId }) => {
  const dispatch = useDispatch();
  const isIndividualPoint = Boolean(point.individualArea);
  const { selectedPoints } = useSelector((state) => state.rootReducer.shedule);

  const onMarkerClicked = async () => {
    dispatch(
      setExpandedMapPopupData({
        point,
        multipleList: data
          .filter(
            (item) =>
              Math.abs(item.latitude - point.latitude) < 0.00001 &&
              Math.abs(item.longitude - point.longitude) < 0.00001,
          )
          .sort((a, b) => a.id - b.id),
      }),
    );
    dispatch(
      setPointFoundByMarker({
        pointCode: point.pointCode,
        highlightedPointId: point.id,
      }),
    );
  };

  const onMarkerCtrlClick = () => {
    dispatch(setSelectedPoints(point.id));
  };

  const onPopupClosed = () => {
    dispatch(resetExpandedMapPopupState());
    dispatch(setAddressFilter(""));
  };

  return (
    <MarkerIcon
      point={point}
      selectedPoints={selectedPoints}
      highlightedPointId={highlightedPointId}
      onMarkerClicked={onMarkerClicked}
      onMarkerCtrlClick={onMarkerCtrlClick}
      onPopupClosed={onPopupClosed}
    >
      <PointPopup
        isIndividualPoint={isIndividualPoint}
        multipleList={data
          .filter(
            (item) =>
              Math.abs(item.latitude - point.latitude) < 0.00001 &&
              Math.abs(item.longitude - point.longitude) < 0.00001,
          )
          .sort((a, b) => a.id - b.id)}
      />
    </MarkerIcon>
  );
};

export default PointMarker;
