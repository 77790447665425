import {
  increaseLimitFilter,
  setPointFoundByTable,
} from "app/store/reducer/shedule";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useListAnalyzePeriodQuery,
  useListLogisticPointsQuery,
} from "shared/api/logistic";

import { configColumns } from "./config";
import Footer from "./footer";
import { TableUI } from "./ui";

const Table = () => {
  const dispatch = useDispatch();
  const { projectInfo } = useSelector(
    (state) => state.rootReducer.currentProject,
  );
  const {
    frontendFilters,
    selectedPoints,
    highlightedPointId,
    tableActionsId,
  } = useSelector((state) => state.rootReducer.shedule);
  const { data: analyzePeriod } = useListAnalyzePeriodQuery(
    {
      projectId: projectInfo.id,
    },
    { skip: !projectInfo.id },
  );
  const { data: logisticPoints, isFetching } = useListLogisticPointsQuery(
    {
      projectId: projectInfo.id,
      filters: frontendFilters,
      monthFrom: analyzePeriod?.monthFrom,
      monthTo: analyzePeriod?.monthTo,
      yearFrom: analyzePeriod?.yearFrom,
      yearTo: analyzePeriod?.yearTo,
    },
    {
      skip:
        !projectInfo.id ||
        !analyzePeriod?.monthFrom ||
        !analyzePeriod?.monthTo ||
        !analyzePeriod?.yearFrom ||
        !analyzePeriod?.yearTo,
      refetchOnMountOrArgChange: true,
    },
  );

  const onInfinityScroll = () => {
    dispatch(increaseLimitFilter());
  };

  const onRowClick = (row) => {
    const point = row.original;

    if (selectedPoints.includes(point.id)) return;

    dispatch(
      setPointFoundByTable({
        pointId: point.id,
        latitude: point.latitude,
        longitude: point.longitude,
      }),
    );
  };

  const data = React.useMemo(() => logisticPoints, [logisticPoints]);
  const columns = React.useMemo(() => configColumns, []);

  if (isFetching && frontendFilters.limit === 10) return null;

  return (
    <>
      <TableUI
        data={data?.content}
        columns={columns}
        totalElements={data?.totalElements}
        isFooterVisible={selectedPoints.length > 0}
        onRowClick={onRowClick}
        onInfinityScroll={onInfinityScroll}
        highlightedPointId={highlightedPointId}
        tableActionsId={tableActionsId}
        isFetching={isFetching}
      />
      <Footer />
    </>
  );
};

export default Table;
