import { PopupContext } from "app/context/PopupContext";
import moment from "moment-timezone";
import React, { useContext, useState } from "react";
import { Button, Flex, Loader, Popup, Text } from "shared/ui";
import styled from "styled-components";

import { API_URL } from "shared/api/config";
import { SERVER_ERROR_TEXT } from "shared/config";
import { getHeadersWithToken } from "shared/helpers";

export const ExportExcelData = ({ project }) => {
  const { hidePopup } = useContext(PopupContext);
  const [isLoading, setIsLoading] = useState(false);
  const [serverError, setServerError] = useState("");

  const onUploadButtonClick = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `${API_URL}/api/projects/${project.id}/route-points/downloads`,
        {
          mode: "cors",
          credentials: "include",
          withCredentials: true,
          headers: getHeadersWithToken(),
        },
      );
      if (response.status === 200) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `data_${project.id}_${moment()
          .tz("Europe/Moscow")
          .format("DD-MM-YYYY_HH-mm")}(МСК).xlsx`;
        document.body.appendChild(a);
        a.click();
        a.remove();
        hidePopup();
      } else setServerError(SERVER_ERROR_TEXT);
        setIsLoading(false);
    } catch (error) {
      setServerError(SERVER_ERROR_TEXT);
      setIsLoading(false);
    }
  };

  return (
    <StyledCommonPopup
      isCenter
      title="Экспорт в Excel"
      className="export-excel-data-popup__common-popup"
    >
      {isLoading && (
        <Flex height="20vh">
          <Loader isLoading />
        </Flex>
      )}

      <Flex mt="20px">
      {serverError && <Text color="color5">{serverError}</Text>}
        <Button
          height="48px"
          color="#2D8AF6"
          text="Выгрузить"
          onClick={onUploadButtonClick}
          disabled={isLoading}
        />
      </Flex>
    </StyledCommonPopup>
  );
};

const StyledCommonPopup = styled(Popup)`
  &.export-excel-data-popup__common-popup {
    min-width: 30vw;
    max-width: 60vw;
  }
`;

export default ExportExcelData;
