import L from "leaflet";
import { useEffect } from "react";
import { useMap } from "react-leaflet";

const Lasso = ({ isLassoActive, onLassoFinished }) => {
  const map = useMap();

  useEffect(() => {
    const drawHandler = new L.Draw.Polygon(map);
    L.drawLocal.draw.handlers.polygon.tooltip = {
      start: "Кликните в любом месте карты, чтобы начать",
      cont: "Кликните в любом месте карты, чтобы продолжить",
      end: "Кликните на первую точку, чтобы закончить",
    };

    const onDrawCreated = (event) => {
      const { layer } = event;

      const polygonCoords = layer
        .getLatLngs()[0]
        .map((latLng) => ({ lat: latLng.lat, lng: latLng.lng }));

      onLassoFinished(polygonCoords);
    };

    map.on(L.Draw.Event.CREATED, onDrawCreated);

    if (isLassoActive) {
      drawHandler.enable();
    } else {
      drawHandler.disable();
    }

    return () => {
      drawHandler.disable();
      map.off(L.Draw.Event.CREATED, onDrawCreated);
    };
  }, [map, isLassoActive, onLassoFinished]);

  return null;
};

export default Lasso;
