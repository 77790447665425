import styled from "styled-components";

const PageShadow = ({ opacity = "0.8", children }) => (
  <Wrapper opacity={opacity}>{children}</Wrapper>
);

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, ${({ opacity }) => opacity});
  z-index: 10000;
`;

export default PageShadow;
