import "react-calendar/dist/Calendar.css";

import "./index.css";

import { PopupProvider } from "app/context/PopupContext";
import PopupRoot from "app/context/PopupRoot";
import { light } from "app/themes";
import { Provider } from "react-redux";
import { ThemeProvider } from "styled-components";

import Routing from "./routing";
import { store } from "./store";

const App = () => (
  <div className="app">
    <Provider store={store}>
      <ThemeProvider theme={light}>
        <PopupProvider>
          <PopupRoot />
          <Routing />
        </PopupProvider>
      </ThemeProvider>
    </Provider>
  </div>
);

export default App;
