import React from "react";
import styled from "styled-components";

const CheckIcon = ({ className }) => (
  <svg
    className={className}
    viewBox="0 0 18 18"
    xmlns="http://www.w3.org/2000/svg"
    fill="#fff"
  >
    <path
      stroke="null"
      id="Shape"
      d="M5.8 12.9L1.6 8.7.2 10.1l5.6 5.6 12-12-1.4-1.4L5.8 12.9z"
    />
  </svg>
);

const Checkbox = React.forwardRef(
  (
    {
      name,
      filledColor = "#000000",
      emptyColor = "#787878",
      className,
      ...rest
    },
    ref,
  ) => (
    <Wrapper className={className}>
      <HiddenCheckbox
        ref={ref}
        type="checkbox"
        name={name}
        filledColor={filledColor}
        emptyColor={emptyColor}
        onClick={(e) => e.stopPropagation()}
        {...rest}
      />
      <CustomIcon className="iconWrapper">
        <CheckIcon className="checkIcon" />
      </CustomIcon>
    </Wrapper>
  ),
);

const Wrapper = styled.div`
  display: flex;
  position: relative;
  width: 18px;
  height: 18px;
  cursor: pointer;
  flex-shrink: 0;
`;

const HiddenCheckbox = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 18px;
  width: 18px;
  z-index: 100;

  & + .iconWrapper {
    border: 2px solid ${({ emptyColor }) => emptyColor};

    .checkIcon {
      display: none;
    }
  }

  &:checked + .iconWrapper {
    border: 2px solid ${({ filledColor }) => filledColor};
    background: ${({ filledColor }) => filledColor};

    .checkIcon {
      display: flex;
    }
  }
`;

const CustomIcon = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
`;

export default Checkbox;
