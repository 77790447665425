import { PopupContext } from "app/context/PopupContext";
import { resetGroupFilter, setGroupFilter } from "app/store/reducer/areas";
import { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useContractGroupsFilterQuery } from "shared/api/current-project";
import { useListAnalyzePeriodQuery } from "shared/api/logistic";
import { calendarFiltersToString } from "shared/helpers";
import {
  AnalysisDropdown as AnalysisDropdownUI,
  Checkbox,
  Flex,
  SearchDropdown,
  Text,
} from "shared/ui";

import { AnalysisPeriodPopup } from "../popups";

export const GroupDropdown = () => {
  const dispatch = useDispatch();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const { projectInfo } = useSelector(
    (state) => state.rootReducer.currentProject,
  );
  const {
    frontendFilters: { groupIds },
  } = useSelector((state) => state.rootReducer.areas);
  const { data: contractGroups, isSuccess } = useContractGroupsFilterQuery(
    {
      projectId: projectInfo.id,
    },
    { skip: !projectInfo.id, refetchOnMountOrArgChange: true },
  );

  const onSelect = (group) => {
    dispatch(setGroupFilter(group));
  };

  const onSearch = (value) => {
    setSearchValue(value);
  };

  const onToggle = (isOpen) => {
    if (isOpen === false) onSearch("");
    setIsDropdownOpen((prev) => !prev);
  };

  const onResetSearch = () => {
    onSearch("");
    setIsDropdownOpen(false);
  };

  const onResetAllCheckboxes = () => {
    dispatch(resetGroupFilter());
    setIsDropdownOpen(false);
  };

  if (isSuccess) {
    return (
      <SearchDropdown
        isDropdownOpen={isDropdownOpen}
        toggleText={
          groupIds?.length ? `Выбрано ${groupIds?.length}` : "Выбрать"
        }
        searchValue={searchValue}
        onToggle={onToggle}
        onSearch={onSearch}
        onResetSearch={onResetSearch}
        onResetAllCheckboxes={onResetAllCheckboxes}
        multiselect
        selectedText
        selectedCount={groupIds?.length}
      >
        {contractGroups?.map(
          (item) =>
            item?.name.toLowerCase().includes(searchValue.toLowerCase()) && (
              <SearchDropdown.Item key={item.id}>
                <Checkbox
                  checked={groupIds?.includes(item.id)}
                  onChange={() => onSelect(item)}
                />
                <Flex ml="7px">
                  <Text variant="body1">{item?.name}</Text>
                </Flex>
              </SearchDropdown.Item>
            ),
        )}
      </SearchDropdown>
    );
  }

  return null;
};

export const AnalysisDropdown = () => {
  const { showPopup } = useContext(PopupContext);
  const { projectInfo } = useSelector(
    (state) => state.rootReducer.currentProject,
  );
  const { data: analyzePeriod, isSuccess } = useListAnalyzePeriodQuery(
    {
      projectId: projectInfo.id,
    },
    { skip: !projectInfo.id },
  );

  const onClick = () => {
    showPopup(AnalysisPeriodPopup);
  };

  if (isSuccess) {
    return (
      <AnalysisDropdownUI
        toggleText={calendarFiltersToString(
          analyzePeriod?.monthFrom,
          analyzePeriod?.monthTo,
          analyzePeriod?.yearFrom,
          analyzePeriod?.yearTo,
        )}
        onClick={onClick}
      />
    );
  }

  return null;
};
