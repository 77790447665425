import { AltRoute, CalendarToday } from "app/assets/icons";
import { PopupContext } from "app/context/PopupContext";
import { resetSelectedRoutePoints, setAllPointsChecked } from "app/store/reducer/routes";
import { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TABLE_FOOTER_HEIGHT } from "shared/config";
import { pluralize } from "shared/helpers";
import { getUserRole } from "shared/libs/jwt-decode";
import { Button, Checkbox, Flex, Text } from "shared/ui";
import styled, { css } from "styled-components";

import { SetDayPopup, SetFrequencyPopup, SetRoutePopup } from "./popups";

const Footer = ({ inMap = false }) => {
  const dispatch = useDispatch();
  const role = getUserRole();
  const { showPopup } = useContext(PopupContext);
  const { selectedRoutePoints, allPointsChecked } = useSelector(
    (state) => state.rootReducer.routes,
  );

  const onSelectedReset = () => {
    dispatch(resetSelectedRoutePoints());
    dispatch(setAllPointsChecked(false));
  };

  if (selectedRoutePoints?.length || allPointsChecked)
    return (
      <Root display="flex" inMap={inMap}>
        <Flex flexDirection="row" alignItems="center">
          <Checkbox checked onChange={onSelectedReset} />
          <Flex ml="7px">
            <Text variant="body1">
              {allPointsChecked
                ? "Все точки"
                : `${selectedRoutePoints.length} ${pluralize(
                    selectedRoutePoints.length,
                    ["точка", "точки", "точек"],
                  )}`}
            </Text>
          </Flex>
        </Flex>
        <Flex flexDirection="row" alignItems="center" ml="12px">
          <Button
            text="ЗАДАТЬ МАРШРУТ"
            icon={<AltRoute />}
            onClick={() => showPopup(SetRoutePopup)}
          />
          <Flex ml="12px">
            <Button
              text="ЗАДАТЬ ДЕНЬ"
              icon={<CalendarToday />}
              onClick={() => showPopup(SetDayPopup)}
            />
          </Flex>
          {role === "ROUTE_ENG" && (
            <Flex ml="12px">
              <Button
                text="ИЗМЕНИТЬ ЧАСТОТУ И ВРЕМЯ"
                onClick={() => showPopup(SetFrequencyPopup)}
              />
            </Flex>
          )}
        </Flex>
      </Root>
    );

  return null;
};

const Root = styled.div`
  display: ${({ display }) => display};
  align-items: center;
  padding: 12px 26px;
  height: ${TABLE_FOOTER_HEIGHT};
  background: #cee3fd;

  ${({ inMap }) =>
    inMap &&
    css`
      position: fixed;
      bottom: 0;
      left: 0;
      z-index: 9999;
      background: transparent;
    `}
`;

export default Footer;
