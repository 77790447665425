import { Cross, Maximize, Minimize, Refresh } from "app/assets/icons";
import { PopupContext } from "app/context/PopupContext";
import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useGetDeliveryTermsReportQuery } from "shared/api/reports";
import { Button, Flex, Loader, Text } from "shared/ui";
import styled from "styled-components";

import { configColumns } from "./config";
import Table from "./table";

const DeliveryReport = () => {
  const { hidePopup } = useContext(PopupContext);
  const { projectInfo } = useSelector(
    (state) => state.rootReducer.currentProject,
  );

  // to show the popup only in required page without rerendering & refetching
  const notInProject = !window.location.pathname.match(
    /(routes|days|coordinates)/,
  );
  const reducerName = notInProject
    ? "routes"
    : window.location.pathname?.split("/")[1];
  useEffect(() => {
    if (notInProject) hidePopup();
  }, [notInProject]);

  // get query params from current reducer
  const filters = useSelector(
    (state) => state.rootReducer[reducerName].frontendFilters,
  );

  // wrap query args to useState to prevent refetching
  const [queryArgs, setQueryArgs] = useState({
    projectId: projectInfo.id,
    commands: filters?.commands,
    routeIds: filters?.routeIds,
  });
  const argsChanged =
    JSON.stringify(queryArgs) !==
    JSON.stringify({
      projectId: projectInfo.id,
      commands: filters?.commands,
      routeIds: filters?.routeIds,
    });

  // call the query with default values
  const {
    data: reportData,
    isFetching,
    isError,
    refetch: updateReport,
  } = useGetDeliveryTermsReportQuery(queryArgs, {
    skip: notInProject || !projectInfo.id,
  });

  const updateReportData = () => {
    // set new query args
    setQueryArgs({
      projectId: projectInfo.id,
      commands: filters?.commands,
      routeIds: filters?.routeIds,
    });
    // refetch
    updateReport();
  };

  const data = React.useMemo(() => reportData, [reportData]);
  const columns = React.useMemo(() => configColumns, []);
  const [isExpand, setIsExpand] = useState(false);

  if (isFetching) {
    return (
      <Root>
        <Header>
          <Flex mr="10px">
            <Text variant="title1">Отчет по доставке</Text>
          </Flex>
        </Header>
        <Flex height="20vh">
          <Loader isLoading />
        </Flex>
        <Flex flexDirection="row" justifyContent="end" mt="20px">
          <CustomButton className="closeButton" onClick={() => hidePopup()}>
            <Cross />
          </CustomButton>
        </Flex>
      </Root>
    );
  }

  if (isError) {
    return (
      <Root>
        <Header>
          <Flex mr="10px">
            <Text variant="title1">Отчет по доставке</Text>
          </Flex>
        </Header>
        <Flex height="20vh">
          <Text>Что-то пошло не так</Text>
        </Flex>
        <Flex flexDirection="row" justifyContent="end" mt="20px">
          <CustomButton className="closeButton" onClick={() => hidePopup()}>
            <Cross />
          </CustomButton>
        </Flex>
      </Root>
    );
  }

  return (
    <Root>
      <Header>
        <Flex mr="10px">
          <Text variant="title1">Отчет по доставке</Text>
        </Flex>
      </Header>
      <Table data={data} columns={columns} isExpand={isExpand} />
      <Flex flexDirection="row" mt="20px" alignItems="end">
        <Button text="ОБНОВИТЬ" icon={<Refresh />} onClick={updateReportData} />
        {argsChanged && (
          <Flex ml="12px">
            <Text color="color7" variant="body3">
              Фильтр изменился. Отчет не актуален
            </Text>
          </Flex>
        )}
        <CustomButton
          className="expandButton"
          onClick={() => setIsExpand(!isExpand)}
        >
          {isExpand ? <Minimize /> : <Maximize />}
        </CustomButton>
        <CustomButton className="closeButton" onClick={() => hidePopup()}>
          <Cross />
        </CustomButton>
      </Flex>
    </Root>
  );
};

const Root = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 60%;
  padding: 20px;
  background: #fff;
  z-index: 9999;
  box-shadow: 0px -8px 10px -4px rgba(0, 0, 0, 0.1);
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const CustomButton = styled.button`
  background: #ecf4fe;
  border-radius: 12px;
  width: 56px;
  height: 48px;
  border: none;
  outline: none;
  color: #2d8af6;

  &.expandButton {
    margin-left: auto;
  }

  &.closeButton {
    margin-left: 8px;
  }
`;

export default DeliveryReport;
