import { beautifyWeekDay } from "shared/helpers";
import { Text } from "shared/ui";

import { ActionsHeader, OrderCell, PointCell, PointHeader } from "../model";

export const configColumns = [
  {
    id: "POINT",
    Header: <PointHeader text='Точка'/>,
    accessor: (data) => <PointCell data={data} />,
    hiddenByDefault: false,
    style: {
      width: '100%'
    }
  },
  {
    id: "POINT_COMMAND",
    Header: "Ком-да",
    name: "Команда",
    accessor: (data) => <Text>{data.point.command}</Text>,
    hiddenByDefault: true,
  },
  {
    id: "DAY",
    Header: "День",
    name: "День",
    accessor: (data) => <Text>{beautifyWeekDay(data.dayWeek)}</Text>,
    hiddenByDefault: true,
  },
  {
    id: "CYCLE",
    Header: "Цикл",
    name: "Цикл",
    accessor: "cyclePoint",
    hiddenByDefault: false,
  },
  {
    id: "VISIT_FREQUENCY",
    Header: "Част-а",
    name: "Частота",
    accessor: "point.visitFrequency",
    hiddenByDefault: false,
    style: {
      maxHeight: '52px'
    }
  },
  {
    id: "DURATION",
    Header: "Длит.",
    name: "Длительность",
    accessor: "point.duration",
    hiddenByDefault: true,
  },
  {
    id: "ATTRIBUTE_CODE_1",
    Header: "Призн1",
    name: "Признак1",
    accessor: "point.attributeCode1",
    hiddenByDefault: false,
  },
  {
    id: "ATTRIBUTE_CODE_2",
    Header: "Призн2",
    name: "Признак2",
    accessor: "point.attributeCode2",
    hiddenByDefault: false,
  },
  {
    id: "ADDITIONALLY_TABLE_1",
    Header: "ДИ1",
    name: "Доп. инф-я1",
    accessor: (data) => <Text>{data.point.additionallyTable1}</Text>,
    hiddenByDefault: true,
  },
  {
    id: "ADDITIONALLY_TABLE_2",
    Header: "ДИ2",
    name: "Доп. инф-я2",
    accessor: (data) => <Text>{data.point.additionallyTable2}</Text>,
    hiddenByDefault: true,
  },
  {
    id: "ADDITIONALLY_TABLE_3",
    Header: "ДИ3",
    name: "Доп. инф-я3",
    accessor: (data) => <Text>{data.point.additionallyTable3}</Text>,
    hiddenByDefault: true,
  },
  {
    id: "ORDER",
    Header: ({ allColumns, toggleHideColumn }) => (
      <ActionsHeader
        allColumns={allColumns}
        toggleHideColumn={toggleHideColumn}
      />
    ),
    accessor: (data) => <OrderCell data={data} />,
    hiddenByDefault: false,
  },
  // {
  //   id: "settings",
  //   Header: ({ allColumns, toggleHideColumn }) => (
  //     <ActionsHeader
  //       allColumns={allColumns}
  //       toggleHideColumn={toggleHideColumn}
  //     />
  //   ),
  //   accessor: null,
  //   hiddenByDefault: false,
  // },
];
