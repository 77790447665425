import { switchMapPointsVisible } from "app/store/reducer/areas";
import { resetExpandedMapPopupState } from "app/store/reducer/expanded-map-popup";
import {
  expandMap,
  switchClustering,
} from "app/store/reducer/project-settings";
import { ChangeView } from "features";
import ResizeObserver from "features/map-resize-observer";
import RememberBounds from "features/return-map-to-prev-bounds";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useListAreasMapQuery } from "shared/api/areas";
import {
  useListAnalyzePeriodQuery,
  useListLogisticPointsMapQuery,
} from "shared/api/logistic";
import { Loader, Map as MapUI } from "shared/ui";

import { Cluster, Footer, Polygons } from "./model";
import { ExpandedPointPopup } from "./ui";

const Map = () => {
  const dispatch = useDispatch();
  const { projectInfo } = useSelector(
    (state) => state.rootReducer.currentProject,
  );

  // Map data
  const {
    mapCenterPoint,
    isMapPointsVisible,
    frontendFilters: { areaIds, branchIds, contractIds, groupIds },
  } = useSelector((state) => state.rootReducer.areas);
  const { data: analyzePeriod } = useListAnalyzePeriodQuery(
    {
      projectId: projectInfo.id,
    },
    { skip: !projectInfo.id },
  );
  const {
    data: areas,
    isSuccess: isAreasSuccess,
    isFetching: isAreasFetching,
  } = useListAreasMapQuery(
    {
      projectId: projectInfo.id,
      branchIds,
      groupIds,
      monthFrom: analyzePeriod?.monthFrom,
      monthTo: analyzePeriod?.monthTo,
      yearFrom: analyzePeriod?.yearFrom,
      yearTo: analyzePeriod?.yearTo,
    },
    {
      skip:
        !projectInfo.id ||
        !analyzePeriod?.monthFrom ||
        !analyzePeriod?.monthTo ||
        !analyzePeriod?.yearFrom ||
        !analyzePeriod?.yearTo,
      refetchOnMountOrArgChange: true,
    },
  );
  const {
    data: mapPoints,
    isSuccess: isPointsSuccess,
    isFetching: isPointsFetching,
  } = useListLogisticPointsMapQuery(
    {
      projectId: projectInfo.id,
      areaIds,
      branchIds,
      contractIds,
      groupIds,
      monthFrom: analyzePeriod?.monthFrom,
      monthTo: analyzePeriod?.monthTo,
      yearFrom: analyzePeriod?.yearFrom,
      yearTo: analyzePeriod?.yearTo,
    },
    {
      skip:
        !projectInfo.id ||
        !analyzePeriod?.monthFrom ||
        !analyzePeriod?.monthTo ||
        !analyzePeriod?.yearFrom ||
        !analyzePeriod?.yearTo,
      refetchOnMountOrArgChange: true,
    },
  );

  // Map settings
  const { expandMode, clustering } = useSelector(
    (state) => state.rootReducer.projectSettings,
  );

  // Fullscreen map controls
  const expanded = expandMode === "map";
  const handleSwitchMapExpand = () => {
    dispatch(expandMap());
  };
  useEffect(() => {
    dispatch(resetExpandedMapPopupState());
  }, [areas, mapPoints]);

  // Toggle clustering
  const handleSwitchClustering = () => {
    dispatch(switchClustering());
  };

  if (isAreasFetching || isPointsFetching) {
    return <Loader isLoading />;
  }

  if (isAreasSuccess && isPointsSuccess) {
    return (
      <>
        <MapUI
          switchMapExpand={handleSwitchMapExpand}
          expanded={expanded}
          isMapPointsVisible={isMapPointsVisible}
          switchMapPointsVisible={() => dispatch(switchMapPointsVisible())}
          clustering={clustering}
          switchClustering={handleSwitchClustering}
        >
          <ChangeView mapCenterPoint={mapCenterPoint} />
          <RememberBounds />
          <ResizeObserver expanded={expanded} />
          {isMapPointsVisible && (
            <Cluster data={mapPoints} mapCenterPoint={mapCenterPoint} />
          )}
          <Polygons areas={areas} mapCenterPoint={mapCenterPoint} />
        </MapUI>
        {expanded && <ExpandedPointPopup />}
        <Footer />
      </>
    );
  }

  return null;
};

export default Map;
