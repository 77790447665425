/* eslint-disable guard-for-in */
/* eslint-disable no-continue */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
import convexHull from "convex-hull";
import {
  DAYS_OF_WEEK_COLOR,
  DAYS_OF_WEEK_RU,
  MARKER_COLOR_BY, PROJECT_MODE
} from "shared/config";

export const calculateConvexHull = (points) => {
  const pointsForHull = points.map((p) => [p.latitude, p.longitude]);

  const hullIndices = convexHull(pointsForHull);

  return hullIndices.map(([index]) => [
    pointsForHull[index][0],
    pointsForHull[index][1],
  ]);
};

export const getDayColor = (day) => {
  switch (day) {
    case DAYS_OF_WEEK_RU.MONDAY: {
      return DAYS_OF_WEEK_COLOR.MONDAY;
    }
    case DAYS_OF_WEEK_RU.TUESDAY: {
      return DAYS_OF_WEEK_COLOR.TUESDAY;
    }
    case DAYS_OF_WEEK_RU.WEDNESDAY: {
      return DAYS_OF_WEEK_COLOR.WEDNESDAY;
    }
    case DAYS_OF_WEEK_RU.THURSDAY: {
      return DAYS_OF_WEEK_COLOR.THURSDAY;
    }
    case DAYS_OF_WEEK_RU.FRIDAY: {
      return DAYS_OF_WEEK_COLOR.FRIDAY;
    }
    case DAYS_OF_WEEK_RU.SATURDAY: {
      return DAYS_OF_WEEK_COLOR.SATURDAY;
    }
    case DAYS_OF_WEEK_RU.SUNDAY: {
      return DAYS_OF_WEEK_COLOR.SUNDAY;
    }
    default: {
      return "#000";
    }
  }
};

export const calculatePolygonsData = (data, projectMode, mapMarkersColoredBy) => {
  const uniqueDays = [...new Set(data.map((p) => p.dayWeek))];
  const uniqueRouteIds = [...new Set(data.map((p) => p.routeId))];

  const polygons = [];

  if (projectMode === PROJECT_MODE.ROUTES && mapMarkersColoredBy === MARKER_COLOR_BY.ROUTE ) {
    // Режим "Маршруты", кисточка на маршрут
    for (const routeId of uniqueRouteIds) {
      const filteredPoints = data.filter((p) => p.routeId === routeId);
      if (filteredPoints.length === 0) continue;

      const position = calculateConvexHull(filteredPoints);
      polygons.push({
        position,
        dayWeek: filteredPoints[0]?.dayWeek,
        routeId,
        routeName: filteredPoints[0]?.routeName,
        pointsAmount: filteredPoints.length,
        color: filteredPoints[0]?.color,
        dayColor: getDayColor(filteredPoints[0]?.dayWeek),
        routeColor: filteredPoints[0]?.color,
      });
    }

    return polygons;
  }

  if (projectMode === PROJECT_MODE.ROUTES && mapMarkersColoredBy === MARKER_COLOR_BY.DAY_OF_WEEK ) {
    // Кисточка на день или нет кисточки
    for (const routeId of uniqueRouteIds) {
      for (const dayWeek of uniqueDays) {
        const filteredPoints = data.filter((p) => p.dayWeek === dayWeek && p.routeId === routeId);
        if (filteredPoints.length === 0) continue;

        const position = calculateConvexHull(filteredPoints);
        polygons.push({
          position,
          dayWeek,
          routeId,
          routeName: filteredPoints[0]?.routeName,
          pointsAmount: filteredPoints.length,
          color: getDayColor(dayWeek),
          dayColor: getDayColor(dayWeek),
          routeColor: filteredPoints[0]?.color,
        });
      }
    }

    return polygons;
  }

  if (projectMode === PROJECT_MODE.DAYS) {
    // Кисточка на день или нет кисточки
      for (const dayWeek of uniqueDays) {
        const filteredPoints = data.filter((p) => p.dayWeek === dayWeek);
        if (filteredPoints.length === 0) continue;

        const position = calculateConvexHull(filteredPoints);
        polygons.push({
          position,
          dayWeek,
          routeId: filteredPoints[0]?.routeId,
          routeName: filteredPoints[0]?.routeName,
          pointsAmount: filteredPoints.length,
          color: getDayColor(dayWeek),
          dayColor: getDayColor(dayWeek),
          routeColor: filteredPoints[0]?.color,
        });
      }

    return polygons;
  }

  return [];
};

export const declensionPoint = (n) => {
  const remainderOfTen = n % 10;
  const remainderOfHundred = n % 100;

  if (remainderOfTen === 1 && remainderOfHundred !== 11) {
    return "точка";
  }
  if (
    remainderOfTen >= 2 &&
    remainderOfTen <= 4 &&
    (remainderOfHundred < 10 || remainderOfHundred >= 20)
  ) {
    return "точки";
  }

  return "точек";
};
