import React from "react";
import styled from "styled-components";

const Wrapper = styled.label`
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  flex-shrink: 0;
  margin: 0;
`;

const Checkbox = styled.input`
  display: none;

  & + .iconWrapper {
    border-radius: 100%;
    border: 2px solid ${({ emptyColor }) => emptyColor};
    width: 18px;
    height: 18px;

    .checkIcon {
      display: none;
      width: 10px;
    }
  }

  &:checked + .iconWrapper {
    border: 2px solid ${({ filledColor }) => filledColor};
    background: ${({ filledColor }) => filledColor};

    .checkIcon {
      display: flex;
    }
  }
`;

const CustomIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 18px;
  height: 18px;
`;

const Text = styled.p`
  margin: 0 0 0 6px;
  margin-left: 6px;
  font-size: 18px;
  line-height: 24px;
  color: ${({ color }) => color};
`;

const CheckIcon = ({ className }) => (
  <svg
    className={className}
    viewBox="0 0 18 18"
    xmlns="http://www.w3.org/2000/svg"
    fill="#fff"
  >
    <path
      stroke="null"
      id="Shape"
      d="M5.8 12.9L1.6 8.7.2 10.1l5.6 5.6 12-12-1.4-1.4L5.8 12.9z"
    />
  </svg>
);

const CustomRadio = React.forwardRef(
  (
    {
      name,
      filledColor = "#000000",
      emptyColor = "#787878",
      className,
      label,
      ...rest
    },
    ref,
  ) => (
    <Wrapper className={className}>
      <Checkbox
        ref={ref}
        type="radio"
        name={name}
        {...rest}
        filledColor={filledColor}
        emptyColor={emptyColor}
      />
      <CustomIcon className="iconWrapper">
        <CheckIcon className="checkIcon" />
      </CustomIcon>
      {!!label && <Text color={filledColor}>{label}</Text>}
    </Wrapper>
  ),
);

export default CustomRadio;
