import React, { memo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useGetDeliveryTermsPointReportQuery } from "shared/api/reports";
import { Flex, Text } from "shared/ui";
import styled from "styled-components";

const headers = ["Нед", "ПН", "ВТ", "СР", "ЧТ", "ПТ", "СБ", "ВС"];

const DeliveryTermsReport = ({ routeId, pointId }) => {
  const { projectInfo } = useSelector(
    (state) => state.rootReducer.currentProject,
  );
  const [openDeliveryReport, setOpenDeliveryReport] = useState(false);

  const { data: reportData, isSuccess } = useGetDeliveryTermsPointReportQuery(
    {
      projectId: projectInfo.id,
      routeId,
      pointId,
    },
    {
      skip: !projectInfo.id || !routeId || !pointId || !openDeliveryReport,
    },
  );

  useEffect(() => {
    setOpenDeliveryReport(false);
  }, [routeId, pointId]);

  useEffect(() => {
    if (openDeliveryReport && isSuccess) {
      setTimeout(() => {
        document.getElementById("report").scrollIntoView();
      }, 0);
    }
  }, [openDeliveryReport, isSuccess]);

  return (
    <>
      <Flex
        width="max-content"
        cursor="pointer"
        onClick={() => setOpenDeliveryReport((prevState) => !prevState)}
        mt="4px"
      >
        <Text variant="body3" color="color0">
          Отчет сроки доставки
        </Text>
      </Flex>
      {openDeliveryReport && isSuccess && (
        <Root id="report">
          <Row>
            {headers.map((header) => (
              <Cell key={header}>
                <Text variant="body3" color="color7">
                  {header}
                </Text>
              </Cell>
            ))}
          </Row>
          {reportData.map((row, rowIndex) => (
            <Row key={crypto.randomUUID()}>
              <Cell>
                <Text variant="body3" color="color7">
                  {rowIndex + 1}
                </Text>
              </Cell>
              {row.map((cell) => (
                <DataCell
                  key={cell.day + cell.weekNumber}
                  bgColor={cell.hexCode}
                >
                  {cell.visit && <VisitMarker>В</VisitMarker>}
                  {cell.periodicity && (
                    <PeriodicityValue>{cell.periodicity}</PeriodicityValue>
                  )}
                </DataCell>
              ))}
            </Row>
          ))}
        </Root>
      )}
    </>
  );
};

const Root = styled(Flex)`
  width: 256px;
  border-collapse: collapse;
  border: 0.5px solid gray;
  margin-top: 4px;
`;

const Row = styled(Flex)`
  flex-direction: row;
  border-bottom: 0.5px solid gray;

  :last-of-type {
    border: none;
  }
`;

const Cell = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 28px;
  border-right: 0.5px solid gray;

  :last-of-type {
    border: none;
  }
`;

const DataCell = styled(Cell)`
  position: relative;
  background-color: ${({ bgColor }) => bgColor};
`;

const VisitMarker = styled.div`
  position: absolute;
  top: 0;
  left: 4px;

  font-size: 0.8rem;
  font-weight: bold;
`;

const PeriodicityValue = styled.div`
  position: absolute;
  bottom: 0;
  right: 4px;

  font-size: 0.8rem;
  color: #595959;
`;

export default memo(DeliveryTermsReport);
