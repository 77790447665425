export const configColumns = [
  {
    Header: "Маршрут",
    textAlign: "left",
    rowSpan: 2,
    columns: [
      {
        Header: "hidden0",
        accessor: "ROUTE",
        displayNone: true,
        textAlign: "left",
      },
    ],
  },
  {
    Header: "Точки",
    rowSpan: 2,
    columns: [
      {
        Header: "hidden1",
        accessor: "COUNT_POINTS",
        displayNone: true,
      },
    ],
  },
  {
    Header: "Визиты",
    rowSpan: 2,
    columns: [
      {
        Header: "hidden2",
        accessor: "VISIT",
        displayNone: true,
      },
    ],
  },
  {
    Header: "Время часы/мин",
    columns: [
      {
        accessor: "TIME_IN_POINT",
        Header: "в точках",
      },
      {
        accessor: "TRAVEL_TIME",
        Header: "в пути",
      },
      {
        accessor: "TOTAL_TIME",
        Header: "общее",
      },
    ],
  },
  {
    Header: "Среднее в день, ч:м",
    columns: [
      {
        Header: "в точках",
        accessor: "AVERAGE_TIME_IN_POINT",
      },
      {
        Header: "в пути",
        accessor: "AVERAGE_TRAVEL_TIME",
      },
      {
        Header: "общее",
        accessor: "AVERAGE_TOTAL_TIME",
      },
    ],
  },
  {
    Header: "Пробег, км",
    rowSpan: 2,
    columns: [
      {
        Header: "hidden5",
        accessor: "DISTANCE",
        displayNone: true,
      },
    ],
  },
  {
    Header: "ТО",
    rowSpan: 2,
    columns: [
      {
        Header: "hidden6",
        accessor: "TO",
        displayNone: true,
      },
    ],
  },
  {
    Header: "ПР",
    rowSpan: 2,
    columns: [
      {
        Header: "hidden7",
        accessor: "PR",
        displayNone: true,
      },
    ],
  },
  {
    Header: "%",
    rowSpan: 2,
    columns: [
      {
        Header: "hidden8",
        accessor: "PERCENT",
        displayNone: true,
      },
    ],
  },
];
