import {
  setPointFoundByMarker,
  setSelectedPoints,
} from "app/store/reducer/shedule";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Flex, PointPopup as PointPopupUI, Text } from "shared/ui";

const PointPopup = React.memo(({ isCluster = false, multipleList = [] }) => {
  const dispatch = useDispatch();
  const [shownPoint, setShownPoint] = useState(multipleList[0]);
  const { selectedPoints } = useSelector((state) => state.rootReducer.shedule);

  const onSelect = (point) => {
    dispatch(setSelectedPoints(point?.id));
  };

  const onSetShownPoint = (point) => {
    setShownPoint(point);
    dispatch(
      setPointFoundByMarker({
        pointCode: point.pointCode,
        highlightedPointId: point.id,
      }),
    );
  };

  return (
    <PointPopupUI
      multipleList={multipleList}
      selectedPoints={selectedPoints}
      shownPoint={shownPoint}
      onSetShownPoint={onSetShownPoint}
      isSelectable
      onSelect={onSelect}
      withTip={!isCluster}
    >
      <Flex width="290px" mr="20px" overflowY="scroll">
        <Flex mb="4px">
          <Text variant="body3bold">{shownPoint?.pointCode}</Text>
        </Flex>
        <Flex mb="4px">
          <Text variant="body3">{shownPoint?.contractName}</Text>
        </Flex>
        <Flex mb="4px" flexDirection="row">
          <Text variant="body3" color="color7">
            {shownPoint?.areas?.length
              ? shownPoint.areas.map((item) => item?.name).join(", ")
              : "Зона не установлена"}
          </Text>
        </Flex>
        <Flex mb="4px">
          <Text variant="body3">{shownPoint?.pointName}</Text>
        </Flex>
        <Flex mb="10px">
          <Text variant="body3">{shownPoint?.address}</Text>
        </Flex>
        <Flex mb="5px">
          <Text variant="body3" color="color3">
            Продажи {shownPoint?.margin}
          </Text>
        </Flex>
        <Flex mb="5px">
          <Text variant="body3" color="color3">
            Объём {shownPoint?.volume}
          </Text>
        </Flex>
        <Flex>
          <Text variant="body3" color="color3">
            Вес {shownPoint?.weight}
          </Text>
        </Flex>
      </Flex>
    </PointPopupUI>
  );
});

export default PointPopup;
