import { PopupContext } from "app/context/PopupContext";
import { useContext } from "react";
import { useSelector } from "react-redux";
import { useDeleteAreaMutation } from "shared/api/areas";
import { Button, Flex, PinkButton, Popup, Text } from "shared/ui";

const DeleteArea = ({ area }) => {
  const { hidePopup } = useContext(PopupContext);
  const { projectInfo } = useSelector(
    (state) => state.rootReducer.currentProject,
  );
  const [deleteArea, { isLoading }] = useDeleteAreaMutation();

  const onDeleteArea = async () => {
    await deleteArea({ projectId: projectInfo.id, areaId: area.id });
    hidePopup();
  };

  return (
    <Popup isCenter title="Удаление зоны">
      <Flex mt="20px">
        <Text variant="body1">
          После удаления зоны {area.name}, всем точкам будет присвоен статус —
          Зона не установлена. Это действие невожно отменить.
        </Text>
      </Flex>
      <Flex mt="20px" flexDirection="row">
        <Flex width="120px" mr="12px">
          <Button text="УДАЛИТЬ" onClick={onDeleteArea} disabled={isLoading} />
        </Flex>
        <Flex width="120px">
          <PinkButton onClick={() => hidePopup()}>ОТМЕНА</PinkButton>
        </Flex>
      </Flex>
    </Popup>
  );
};

export default DeleteArea;
