import { PopupContext } from "app/context/PopupContext";
import { useContext, useEffect, useState } from "react";
import {
  useGetProjectDeliveryDaysOffQuery,
  useUpdateProjectDeliveryDaysOffMutation,
} from "shared/api/current-project";
import { Button, Checkbox, Flex, Popup } from "shared/ui";
import styled from "styled-components";

const DAYS_OF_WEEK = [
  {
    label: "Понедельник",
    value: "MONDAY",
  },
  {
    label: "Вторник",
    value: "TUESDAY",
  },
  {
    label: "Среда",
    value: "WEDNESDAY",
  },
  {
    label: "Четверг",
    value: "THURSDAY",
  },
  {
    label: "Пятница",
    value: "FRIDAY",
  },
  {
    label: "Суббота",
    value: "SATURDAY",
  },
  {
    label: "Воскресенье",
    value: "SUNDAY",
  },
];

export const DefineDeliveryDaysOff = ({ project }) => {
  const { hidePopup } = useContext(PopupContext);
  const [selectedDays, setSelectedDays] = useState([]);

  const { data: daysOff, isFetching } = useGetProjectDeliveryDaysOffQuery({
    projectId: project.id,
  });
  const [updateDaysOff] = useUpdateProjectDeliveryDaysOffMutation();
  useEffect(() => {
    if (daysOff) {
      setSelectedDays(daysOff);
    }
  }, [daysOff]);

  const handleSelectDay = (day) => {
    if (selectedDays.includes(day)) {
      setSelectedDays([...selectedDays.filter((item) => item !== day)]);
    } else {
      setSelectedDays([...selectedDays, day]);
    }
  };

  const handleSubmit = async () => {
    await updateDaysOff({
      projectId: project.id,
      selectedDays,
    });
    hidePopup();
  };

  if (isFetching) return null;

  return (
    <Popup isCenter title="Задать выходные дни доставки">
      <ListWrapper>
        {DAYS_OF_WEEK.map((day) => (
          <Row key={day.value}>
            <CheckboxWrapper>
              <Checkbox
                name="dayOfWeek"
                filledColor="#000000"
                emptyColor="#787878"
                checked={selectedDays.includes(day.value)}
                onChange={() => handleSelectDay(day.value)}
              />
            </CheckboxWrapper>
            <CheckboxesText>{day.label}</CheckboxesText>
          </Row>
        ))}
      </ListWrapper>
      <Flex mt="30px">
        <Button
          height="48px"
          color="#2D8AF6"
          text="ПРИМЕНИТЬ"
          onClick={handleSubmit}
        />
      </Flex>
    </Popup>
  );
};

const ListWrapper = styled.div`
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .customRadio {
    margin-bottom: 12px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const Row = styled.label`
  display: inline-flex;
  align-items: center;
  margin-bottom: 12px;
  cursor: pointer;

  &:last-child {
    margin-bottom: 0;
  }
`;

const CheckboxWrapper = styled.div`
  margin-right: 7px;
`;

const CheckboxesText = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
`;

export default DefineDeliveryDaysOff;
