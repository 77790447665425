import { VerticalDivider } from "app/assets/icons";
import React from "react";
import { Checkbox, Flex, Text } from "shared/ui";

const PointsList = ({
  multipleList = [],
  selectedPoints = [],
  shownPointCode = 0,
  onSetShownPoint = () => {},
  isSelectable = false,
  onSelect = () => {},
}) => {
  if (multipleList?.length <= 1) return null;
  return (
    <>
      <Flex width="150px" maxHeight="100%" overflowY="auto">
        {multipleList.map((item) => (
          <Flex
            key={item?.id}
            flexDirection="row"
            height="16px"
            mt="5px"
            mb="5px"
          >
            {isSelectable && (
              <Checkbox
                checked={selectedPoints.includes(item?.id)}
                onChange={() => onSelect(item)}
              />
            )}
            <Flex ml="6px" cursor="pointer">
              <Text
                variant={
                  shownPointCode === item?.pointCode ? "body3bold" : "body3"
                }
                onClick={() => onSetShownPoint(item)}
              >
                {item?.pointCode}
              </Text>
            </Flex>
          </Flex>
        ))}
      </Flex>
      <Flex m="0 16px">
        <VerticalDivider />
      </Flex>
    </>
  );
};

export default PointsList;
