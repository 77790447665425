/* eslint-disable consistent-return */
import {
  resetCommandFilter,
  resetManagerFilter,
  resetRouteFilter,
  setAddressFilter,
  setCommandFilter,
  setDistanceFilter,
  setManagerFilter,
  setRouteFilter,
  setDaysFilter,
} from "app/store/reducer/coordinates";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useListFiltersRoutesCommandsDuplicatesQuery,
  useListFiltersRoutesUsersDuplicatesQuery,
} from "shared/api/filters";
import { DISTANCE_DIFFERENCE, DAYS_OF_WEEK_COLOR, DAYS_OF_WEEK_RU } from "shared/config";
import { sortByField } from "shared/helpers";
import {
  Checkbox,
  DifferenceDropdown as DifferenceDropdownUI,
  Flex,
  Search,
  SearchDropdown,
  Text,
} from "shared/ui";

import { getUserRole } from "shared/libs/jwt-decode";
import { Days } from "pages/current-project/ui";
import { isEqual } from "lodash";

export const ManagerDropdown = () => {
  const dispatch = useDispatch();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const { projectInfo } = useSelector(
    (state) => state.rootReducer.currentProject,
  );
  const { frontendFilters } = useSelector(
    (state) => state.rootReducer.coordinates,
  );
  const { data: filters, isSuccess } = useListFiltersRoutesUsersDuplicatesQuery(
    {
      projectId: projectInfo.id,
      managers: frontendFilters.managerCodes,
    },
    { skip: !projectInfo.id, refetchOnMountOrArgChange: true },
  );

  const onManagerSelect = (manager) => {
    dispatch(setManagerFilter(manager));
  };

  const onSearch = (value) => {
    setSearchValue(value);
  };

  const onToggle = (isOpen) => {
    if (isOpen === false) onSearch("");
    setIsDropdownOpen((prev) => !prev);
  };

  const onResetSearch = () => {
    onSearch("");
    setIsDropdownOpen(false);
  };

  const onResetAllCheckboxes = () => {
    dispatch(resetManagerFilter());
    setIsDropdownOpen(false);
  };

  if (isSuccess) {
    return (
      <SearchDropdown
        isDropdownOpen={isDropdownOpen}
        toggleText={
          frontendFilters?.managerCodes?.length
            ? `Выбрано ${frontendFilters?.managerCodes?.length}`
            : "Выбрать"
        }
        searchValue={searchValue}
        onToggle={onToggle}
        onSearch={onSearch}
        onResetSearch={onResetSearch}
        onResetAllCheckboxes={onResetAllCheckboxes}
        multiselect
        selectedText
        selectedCount={frontendFilters?.managerCodes?.length}
      >
        {sortByField(filters?.users, "shortName").map(
          (item) =>
            item.shortName
              .toLowerCase()
              .includes(searchValue.toLowerCase()) && (
              <SearchDropdown.Item key={item.fullNameCode}>
                <Checkbox
                  checked={frontendFilters?.managerCodes?.includes(
                    item.fullNameCode,
                  )}
                  onChange={() => onManagerSelect(item)}
                />
                <Flex ml="7px">
                  <Text variant="body1">{item.shortName}</Text>
                </Flex>
              </SearchDropdown.Item>
            ),
        )}
      </SearchDropdown>
    );
  }

  return null;
};

export const CommandDropdown = () => {
  const dispatch = useDispatch();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const { projectInfo } = useSelector(
    (state) => state.rootReducer.currentProject,
  );
  const { frontendFilters } = useSelector(
    (state) => state.rootReducer.coordinates,
  );
  const { data: filters, isSuccess } =
    useListFiltersRoutesCommandsDuplicatesQuery(
      {
        projectId: projectInfo.id,
        commands: frontendFilters?.commands,
      },
      { skip: !projectInfo.id, refetchOnMountOrArgChange: true },
    );

  const onCommandsSelect = (manager) => {
    dispatch(setCommandFilter(manager));
  };

  const onSearch = (value) => {
    setSearchValue(value);
  };

  const onToggle = (isOpen) => {
    if (isOpen === false) onSearch("");
    setIsDropdownOpen((prev) => !prev);
  };

  const onResetSearch = () => {
    onSearch("");
    setIsDropdownOpen(false);
  };

  const onResetAllCheckboxes = () => {
    dispatch(resetCommandFilter());
    setIsDropdownOpen(false);
  };

  if (isSuccess) {
    return (
      <SearchDropdown
        isDropdownOpen={isDropdownOpen}
        toggleText={
          frontendFilters?.commands?.length
            ? `Выбрано ${frontendFilters?.commands?.length}`
            : "Выбрать"
        }
        searchValue={searchValue}
        onToggle={onToggle}
        onSearch={onSearch}
        onResetSearch={onResetSearch}
        onResetAllCheckboxes={onResetAllCheckboxes}
        multiselect
        selectedText
        selectedCount={frontendFilters?.managerCodes?.length}
      >
        {filters?.commandsResponse.map(
          (item) =>
            item.toLowerCase().includes(searchValue.toLowerCase()) && (
              <SearchDropdown.Item key={item}>
                <Checkbox
                  checked={frontendFilters?.commands?.includes(item)}
                  onChange={() => onCommandsSelect(item)}
                />
                <Flex ml="7px">
                  <Text variant="body1">{item}</Text>
                </Flex>
              </SearchDropdown.Item>
            ),
        )}
      </SearchDropdown>
    );
  }

  return null;
};

export const RouteDropdown = () => {
  const dispatch = useDispatch();
  const role = getUserRole();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const { projectInfo } = useSelector(
    (state) => state.rootReducer.currentProject,
  );
  const { frontendFilters } = useSelector(
    (state) => state.rootReducer.coordinates,
  );
  const { data: userFilters, isSuccess: userFiltersIsSuccess } =
    useListFiltersRoutesUsersDuplicatesQuery(
      {
        projectId: projectInfo.id,
        managers: frontendFilters.managerCodes,
      },
      {
        skip: !projectInfo.id || role === "ROUTE_ENG",
        refetchOnMountOrArgChange: true,
      },
    );
  const { data: routeEngFilters, isSuccess: routeEngFiltersIsSuccess } =
    useListFiltersRoutesCommandsDuplicatesQuery(
      {
        projectId: projectInfo.id,
        commands: frontendFilters?.commands,
      },
      {
        skip: !projectInfo.id || role !== "ROUTE_ENG",
        refetchOnMountOrArgChange: true,
      },
    );
  const filters = role === "ROUTE_ENG" ? routeEngFilters : userFilters;

  const onRouteSelect = (route) => {
    dispatch(setRouteFilter(route));
  };

  const onSearch = (value) => {
    setSearchValue(value);
  };

  const onToggle = (isOpen) => {
    if (isOpen === false) onSearch("");
    setIsDropdownOpen((prev) => !prev);
  };

  const onResetSearch = () => {
    onSearch("");
    setIsDropdownOpen(false);
  };

  const onResetAllCheckboxes = () => {
    dispatch(resetRouteFilter());
    setIsDropdownOpen(false);
  };

  if (userFiltersIsSuccess || routeEngFiltersIsSuccess) {
    return (
      <SearchDropdown
        isDropdownOpen={isDropdownOpen}
        toggleText={
          frontendFilters?.routeCodes?.length
            ? `Выбрано ${frontendFilters?.routeCodes?.length}`
            : "Выбрать"
        }
        searchValue={searchValue}
        onToggle={onToggle}
        onSearch={onSearch}
        onResetSearch={onResetSearch}
        onResetAllCheckboxes={onResetAllCheckboxes}
        multiselect
        selectedText
        selectedCount={frontendFilters?.routeCodes?.length}
      >
        {sortByField(filters?.routes, "routeName").map(
          (item) =>
            item.routeName
              .toLowerCase()
              .includes(searchValue.toLowerCase()) && (
              <SearchDropdown.Item key={item.routeId}>
                <Checkbox
                  filledColor={item.color}
                  emptyColor={item.color}
                  checked={frontendFilters?.routeCodes?.includes(
                    item.routeCode,
                  )}
                  onChange={() => onRouteSelect(item)}
                />
                <Flex ml="7px">
                  <Text variant="body1">{item.routeName}</Text>
                </Flex>
              </SearchDropdown.Item>
            ),
        )}
      </SearchDropdown>
    );
  }

  return null;
};

export const AddressSearch = () => {
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [isMounted, setIsMounted] = useState(false);
  const { frontendFilters } = useSelector(
    (state) => state.rootReducer.coordinates,
  );

  useEffect(() => {
    if (isMounted && search !== frontendFilters.address) {
      const setFilterWithDelay = setTimeout(() => {
        dispatch(setAddressFilter(search));
      }, 300);

      return () => {
        clearTimeout(setFilterWithDelay);
      };
    }
  }, [search]);

  useEffect(() => {
    setSearch(frontendFilters.address);
  }, [frontendFilters.address]);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  return (
    <Search
      value={search}
      onChange={(e) => setSearch(e.target.value)}
      onReset={() => setSearch("")}
    />
  );
};

export const DifferenceDropdown = () => {
  const dispatch = useDispatch();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { frontendFilters } = useSelector(
    (state) => state.rootReducer.coordinates,
  );

  const onSelect = (value) => {
    dispatch(setDistanceFilter(value));
    setIsDropdownOpen(false);
  };

  const onToggle = () => {
    setIsDropdownOpen((prev) => !prev);
  };

  const onReset = () => {
    dispatch(setDistanceFilter(DISTANCE_DIFFERENCE.EMPTY));
  };

  const items = [
    {
      id: 0,
      text: ">",
      distance: DISTANCE_DIFFERENCE.EMPTY,
    },
    {
      id: 1,
      text: "Не определено",
      distance: DISTANCE_DIFFERENCE.UNDEFINED,
    },
    {
      id: 2,
      text: ">50",
      distance: DISTANCE_DIFFERENCE.MORE_OR_EQUALS_50,
    },
    {
      id: 3,
      text: ">100",
      distance: DISTANCE_DIFFERENCE.MORE_OR_EQUALS_100,
    },
    {
      id: 4,
      text: ">150",
      distance: DISTANCE_DIFFERENCE.MORE_OR_EQUALS_150,
    },
    {
      id: 5,
      text: ">300",
      distance: DISTANCE_DIFFERENCE.MORE_OR_EQUALS_300,
    },
    {
      id: 6,
      text: ">1000",
      distance: DISTANCE_DIFFERENCE.MORE_OR_EQUALS_1000,
    },
  ];

  return (
    <DifferenceDropdownUI
      isDropdownOpen={isDropdownOpen}
      items={items}
      distance={frontendFilters.distance}
      onToggle={onToggle}
      onSelect={onSelect}
      onReset={onReset}
    />
  );
};

export const VisitDays = () => {
  const dispatch = useDispatch();
  const [selectedDays, setSelectedDays] = useState([]);
  const [isMounted, setIsMounted] = useState(false);

  const { daysOfWeek } = useSelector(
    (state) => state.rootReducer.coordinates.frontendFilters,
  );
  const onSelect = (day) => {
    if (selectedDays.includes(day)) {
      setSelectedDays((prev) => prev.filter((v) => v !== day));
    } else {
      setSelectedDays((prev) => [...prev, day]);
    }
  };
  useEffect(() => {
    if (isMounted && !isEqual(selectedDays, daysOfWeek)) {
      if (selectedDays.length === 8) setSelectedDays([]);

      const setFilterWithDelay = setTimeout(() => {
        dispatch(setDaysFilter(selectedDays));
      }, 300);

      return () => {
        clearTimeout(setFilterWithDelay);
      };
    }
  }, [selectedDays]);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    setSelectedDays(daysOfWeek);
  }, [daysOfWeek]);

  const days = [
    {
      name: DAYS_OF_WEEK_RU.MONDAY,
      color: DAYS_OF_WEEK_COLOR.MONDAY,
    },
    {
      name: DAYS_OF_WEEK_RU.TUESDAY,
      color: DAYS_OF_WEEK_COLOR.TUESDAY,
    },
    {
      name: DAYS_OF_WEEK_RU.WEDNESDAY,
      color: DAYS_OF_WEEK_COLOR.WEDNESDAY,
    },
    {
      name: DAYS_OF_WEEK_RU.THURSDAY,
      color: DAYS_OF_WEEK_COLOR.THURSDAY,
    },
    {
      name: DAYS_OF_WEEK_RU.FRIDAY,
      color: DAYS_OF_WEEK_COLOR.FRIDAY,
    },
    {
      name: DAYS_OF_WEEK_RU.SATURDAY,
      color: DAYS_OF_WEEK_COLOR.SATURDAY,
    },
    {
      name: DAYS_OF_WEEK_RU.SUNDAY,
      color: DAYS_OF_WEEK_COLOR.SUNDAY,
    }
  ];

  return <Days days={days} selectedDays={selectedDays} onSelect={onSelect} />;
};
