import { Cross } from "app/assets/icons";
import {
  resetExpandedMapPopupState,
  setShownPoint,
} from "app/store/reducer/expanded-map-popup";
import {
  setPointFoundByMarker,
  setSelectedPoints,
} from "app/store/reducer/shedule";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { FULLSCREEN_POPUP_HEIGHT, MARKER_COLOR_BY } from "shared/config";
import { getMarkerColorByWeekDay } from "shared/helpers";
import styled from "styled-components";

import AreaInfo from "./area-info";
import PointInfo from "./point-info";
import PointsList from "./points-list";

const ExpandedPointPopup = () => {
  const dispatch = useDispatch();
  const {
    point = {},
    multipleList = [],
    area = {},
  } = useSelector((state) => state.rootReducer.expandedMapPopup);

  const { selectedPoints, mapMarkersColoredBy } = useSelector(
    (state) => state.rootReducer.shedule,
  );

  const onSelect = (targetPoint) => {
    dispatch(setSelectedPoints(targetPoint?.id));
  };

  const onSetShownPoint = (targetPoint) => {
    dispatch(setShownPoint(targetPoint));
    dispatch(
      setPointFoundByMarker({
        pointCode: targetPoint.pointCode,
        highlightedPointId: targetPoint.id,
      }),
    );
  };

  if (area?.id)
    return (
      <FixedPopup>
        <Root color={area?.color?.hexCode}>
          <AreaInfo area={area} />
          <CloseButton onClick={() => dispatch(resetExpandedMapPopupState())}>
            <Cross />
          </CloseButton>
        </Root>
      </FixedPopup>
    );

  if (multipleList.length === 0) return null;
  return (
    <FixedPopup>
      <Root
        color={
          mapMarkersColoredBy === MARKER_COLOR_BY.ROUTE
            ? point.color
            : getMarkerColorByWeekDay(point.dayWeek)
        }
      >
        <PointsList
          isSelectable
          multipleList={multipleList}
          selectedPoints={selectedPoints}
          shownPointCode={point.pointCode}
          onSetShownPoint={onSetShownPoint}
          onSelect={onSelect}
        />
        <PointInfo shownPoint={point} />
        <CloseButton onClick={() => dispatch(resetExpandedMapPopupState())}>
          <Cross />
        </CloseButton>
      </Root>
    </FixedPopup>
  );
};

const FixedPopup = styled.div`
  position: fixed;
  left: 60px;
  top: 165px;
  z-index: 800;
  background: white;
  height: ${FULLSCREEN_POPUP_HEIGHT};
  border-radius: 20px;
`;

const Root = styled.div`
  display: flex;
  height: ${FULLSCREEN_POPUP_HEIGHT};
  position: relative;
  border: 2px solid ${(p) => p.color};
  border-radius: 20px;
  padding: 16px;
`;

const CloseButton = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
`;

export default ExpandedPointPopup;
