import { Cross, Maximize, Minimize, Refresh } from "app/assets/icons";
import { PopupContext } from "app/context/PopupContext";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useGetRouteEngDaysReportQuery } from "shared/api/reports";
import { translateWeekDay } from "shared/helpers";
import { Button, Checkbox, Flex, Loader, Text } from "shared/ui";
import styled from "styled-components";

import { configColumns } from "./config";
import Table from "./table";

export const FILTERS = {
  WEEKS: "WEEKS",
  DAYS: "DAYS",
};

const DaysReport = () => {
  const { hidePopup } = useContext(PopupContext);
  const [isExpand, setIsExpand] = useState(false);
  const [filter, setFilter] = useState(FILTERS.DAYS);
  const { projectInfo } = useSelector(
    (state) => state.rootReducer.currentProject,
  );
  const [withGoogleMaps, setWithGoogleMaps] = useState(false)


  // to show the popup only in required page without rerendering & refetching
  const notInProject = !window.location.pathname.match(
    /(routes|days|coordinates)/,
  );
  const reducerName = notInProject
    ? "routes"
    : window.location.pathname?.split("/")[1];
  useEffect(() => {
    if (notInProject) hidePopup();
  }, [notInProject]);

  // get query params from current reducer
  const filters = useSelector(
    (state) => state.rootReducer[reducerName].frontendFilters,
  );

  // wrap query args to useState to prevent refetching
  const [queryArgs, setQueryArgs] = useState({
    projectId: projectInfo.id,
    commands: filters?.commands,
    routeIds: filters?.routeIds,
    days: filters?.daysOfWeek?.map((day) => translateWeekDay(day)),
    cycles: filters?.cyclePoint === "EVERY_WEEK" ? "" : filters?.cyclePoint,
    getGoogleMapData: withGoogleMaps
  });
  const argsChanged =
    JSON.stringify(queryArgs) !==
    JSON.stringify({
      projectId: projectInfo.id,
      commands: filters?.commands,
      routeIds: filters?.routeIds,
      days: filters?.daysOfWeek?.map((day) => translateWeekDay(day)),
      cycles: filters?.cyclePoint === "EVERY_WEEK" ? "" : filters?.cyclePoint,
      getGoogleMapData: withGoogleMaps
    });

  // call the query with default values
  const {
    data: reportData,
    isFetching,
    isError,
    refetch: updateReport,
  } = useGetRouteEngDaysReportQuery(queryArgs, {
    skip: notInProject || !projectInfo.id,
  });

  const updateReportData = () => {
    // set new query args
    setQueryArgs({
      projectId: projectInfo.id,
      commands: filters?.commands,
      routeIds: filters?.routeIds,
      days: filters.daysOfWeek?.map((day) => translateWeekDay(day)),
      cycles: filters?.cyclePoint === "EVERY_WEEK" ? "" : filters?.cyclePoint,
      getGoogleMapData: withGoogleMaps
    });

    // refetch
    updateReport();
  };

  const data = useMemo(() => {
    if (isFetching || isError) return [];
    if (reportData.length === 0) return [];

    if (filter === FILTERS.DAYS) {
      return reportData
        .map((item) => {
          // Итого
          if (item.WEEK_NUMBER === "0" && item.DAY === "") return item;

          // Итого на каждый день + вложенные недели
          if (item.WEEK_NUMBER === "0") {
            return {
              ...item,
              NAME: item.DAY,
              subRows: reportData
                .filter(
                  (subItem) =>
                    subItem.DAY === item.DAY && subItem.WEEK_NUMBER !== "0",
                )
                .map((subItem) => ({
                  ...subItem,
                  NAME: `Неделя ${subItem.WEEK_NUMBER}`,
                })),
            };
          }
          return item;
        })
        .filter((item) => item.WEEK_NUMBER === "0" || item.NAME === "Итого");
    }

    if (filter === FILTERS.WEEKS) {
      return reportData
        .map((item) => {
          // Итого
          if (item.WEEK_NUMBER === "0" && item.DAY === "") return item;

          // Итого на каждую неделю + вложенные дни
          if (item.DAY === "") {
            return {
              ...item,
              NAME: `Неделя ${item.WEEK_NUMBER}`,
              subRows: reportData
                .filter(
                  (subItem) =>
                    subItem.WEEK_NUMBER === item.WEEK_NUMBER &&
                    subItem.DAY !== "",
                )
                .map((subItem) => ({
                  ...subItem,
                  NAME: subItem.DAY,
                })),
            };
          }
          return item;
        })
        .filter((item) => item.DAY === "" || item.NAME === "Итого");
    }

    return [];
  }, [filter, reportData, isFetching]);
  const columns = React.useMemo(() => configColumns, []);

  if (isFetching) {
    return (
      <Root>
        <Header>
          <Flex mr="10px">
            <Text variant="title1">Визиты по дням</Text>
          </Flex>
        </Header>
        <Flex height="20vh">
          <Loader isLoading />
        </Flex>
        <Flex flexDirection="row" justifyContent="end" mt="20px">
          <CustomButton className="closeButton" onClick={() => hidePopup()}>
            <Cross />
          </CustomButton>
        </Flex>
      </Root>
    );
  }

  if (isError) {
    return (
      <Root>
        <Header>
          <Flex mr="10px">
            <Text variant="title1">Визиты по дням</Text>
          </Flex>
        </Header>
        <Flex height="20vh">
          <Text>Что-то пошло не так</Text>
        </Flex>
        <Flex flexDirection="row" justifyContent="end" mt="20px">
          <CustomButton className="closeButton" onClick={() => hidePopup()}>
            <Cross />
          </CustomButton>
        </Flex>
      </Root>
    );
  }

  return (
    <Root>
      <Header>
        <Flex mr="10px" flex={1}>
          <Text variant="title1">Визиты по дням</Text>
        </Flex>
        <CheckboxWrapper>
          <Checkbox
            name="google"
            filledColor="#000000"
            emptyColor="#787878"
            checked={withGoogleMaps}
            onChange={() => setWithGoogleMaps((prev) => !prev)}
          />
          <Text variant='body2'>Расчитывать данные о пробеге и времени в пути</Text>
        </CheckboxWrapper>
        <Filters>
          <FilterItem
            isActive={filter === FILTERS.DAYS}
            onClick={() => setFilter(FILTERS.DAYS)}
          >
            <Text variant="body3">Дни</Text>
          </FilterItem>
          <FilterItem
            isActive={filter === FILTERS.WEEKS}
            onClick={() => setFilter(FILTERS.WEEKS)}
          >
            <Text variant="body3">Недели</Text>
          </FilterItem>
        </Filters>
      </Header>
      <Table data={data} columns={columns} isExpand={isExpand} />
      <Flex flexDirection="row" mt="20px" alignItems="end">
        <Button text="ОБНОВИТЬ" icon={<Refresh />} onClick={updateReportData} />
        {argsChanged && (
          <Flex ml="12px">
            <Text color="color7" variant="body3">
              Фильтр изменился. Отчет не актуален
            </Text>
          </Flex>
        )}
        <CustomButton
          className="expandButton"
          onClick={() => setIsExpand(!isExpand)}
        >
          {isExpand ? <Minimize /> : <Maximize />}
        </CustomButton>
        <CustomButton className="closeButton" onClick={() => hidePopup()}>
          <Cross />
        </CustomButton>
      </Flex>
    </Root>
  );
};

const Root = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 60%;
  padding: 20px;
  background: #fff;
  z-index: 9999;
  box-shadow: 0px -8px 10px -4px rgba(0, 0, 0, 0.1);
`;

const Header = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: 20px;
`;

const CustomButton = styled.button`
  background: #ecf4fe;
  border-radius: 12px;
  width: 56px;
  height: 48px;
  border: none;
  outline: none;
  color: #2d8af6;

  &.expandButton {
    margin-left: auto;
  }

  &.closeButton {
    margin-left: 8px;
  }
`;

const Filters = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
`;

const FilterItem = styled.button`
  background: #fff;
  border: ${({ isActive }) =>
    isActive ? "2px solid #2D8AF6" : "1px solid #CCCCCC"};
  padding: ${({ isActive }) => (isActive ? "0 12px" : "0 13px")};
  height: 32px;
  margin-right: 8px;

  &:last-child {
    margin-right: 0;
  }
`;

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 12px;

  > p {
    margin-left: 7px;
  }
`;


export default DaysReport;
