import { ArrowDownward } from "app/assets/icons";
import {
  resetSelectedPoints,
  setAllPointsChecked,
  setSelectedPoints,
  setSortFilter,
} from "app/store/reducer/logistic-coordinates";
import Highlighter from "react-highlight-words";
import { useDispatch, useSelector } from "react-redux";
import { SORT } from "shared/config";
import { beautifyPointAddress } from "shared/helpers";
import { Checkbox, Flex, IconButton, Text } from "shared/ui";

export const HeaderCellSort = ({
  text = "",
  sortBy = SORT.POINT,
  withAllPointsCheckbox = false,
}) => {
  const dispatch = useDispatch();
  const { frontendFilters, allPointsChecked } = useSelector(
    (state) => state.rootReducer.logisticCoordinates,
  );

  return (
    <Flex flexDirection="row" alignItems="center">
      {withAllPointsCheckbox && (
        <Flex mr="7px">
          <Checkbox
            checked={allPointsChecked}
            onChange={() => {
              dispatch(setAllPointsChecked(!allPointsChecked));
              dispatch(resetSelectedPoints());
            }}
          />
        </Flex>
      )}

      <Text>{text}</Text>
      <IconButton onClick={() => dispatch(setSortFilter(sortBy))}>
        <ArrowDownward
          fill={frontendFilters.sort === sortBy ? "#000000" : "#CCCCCC"}
        />
      </IconButton>
    </Flex>
  );
};

export const PointCell = ({ data = {} }) => {
  const dispatch = useDispatch();
  const { frontendFilters, selectedPoints } = useSelector(
    (state) => state.rootReducer.logisticCoordinates,
  );

  const onSelect = () => {
    dispatch(setSelectedPoints(data.id));
    dispatch(setAllPointsChecked(false));
  };

  return (
    <Flex flexDirection="row" alignItems="center">
      <Flex mr="7px">
        <Checkbox
          className="checkbox"
          checked={selectedPoints.includes(data.id)}
          onChange={() => onSelect()}
        />
      </Flex>
      <Highlighter
        highlightClassName="YourHighlightClass"
        searchWords={[frontendFilters.address]}
        autoEscape
        textToHighlight={beautifyPointAddress(data)}
        highlightStyle={{ background: "#CEE3FD", padding: 0 }}
      />
    </Flex>
  );
};
