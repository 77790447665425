import { api } from "shared/api";

const reportsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getRouteEngRoutesReport: builder.query({
      query: ({
        projectId,
        routeIds = [],
        days = [],
        commands = [],
        getGoogleMapData = false,
      }) =>
        `projects/${projectId}/routes/reports/engineer?routeIds=${routeIds}&days=${days}&commands=${commands}&getGoogleMapData=${getGoogleMapData}`,
    }),
    getRouteEngDaysReport: builder.query({
      query: ({
        projectId,
        routeIds = [],
        days = [],
        cycles = [],
        commands = [],
        getGoogleMapData = false,
      }) =>
        `projects/${projectId}/routes/reports/visits?routeIds=${routeIds}&days=${days}&cycles=${cycles}&commands=${commands}&getGoogleMapData=${getGoogleMapData}`,
    }),
    getDeliveryTermsReport: builder.query({
      query: ({ projectId, commands, routeIds }) =>
        `projects/${projectId}/routes/reports/terms?commands=${commands}&routeIds=${routeIds}`,
    }),
    getDeliveryTermsPointReport: builder.query({
      query: ({ projectId, routeId, pointId }) =>
        `projects/${projectId}/routes/${routeId}/points/${pointId}/reports`,
    }),
  }),
});

export const {
  useGetRouteEngRoutesReportQuery,
  useGetRouteEngDaysReportQuery,
  useGetDeliveryTermsReportQuery,
  useGetDeliveryTermsPointReportQuery,
} = reportsApi;
