import { NavArrow, NavLogo } from "app/assets/icons";
import { useNavigate } from "react-router-dom";
import { APP_ROUTES } from "shared/config";
import { Flex, IconButton } from "shared/ui";

import {
  ModeDropdown,
  ProjectDropdown,
  RedoDropdown,
  UndoDropdown,
  UserDropdown,
} from "./model";

const ProjectNavigation = () => {
  const navigate = useNavigate();

  return (
    <Flex
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      w="100%"
      h="24px"
    >
      <Flex flexDirection="row" alignItems="center">
        <IconButton onClick={() => navigate(APP_ROUTES.PROJECTS)}>
          <NavLogo />
        </IconButton>
        <Flex m="0px 8px">
          <NavArrow />
        </Flex>
        <UserDropdown />
        <Flex m="0px 8px">
          <NavArrow />
        </Flex>
        <ProjectDropdown />
        <Flex m="0px 8px">
          <NavArrow />
        </Flex>
        <ModeDropdown />
      </Flex>
      <Flex flexDirection="row" alignItems="center">
        <Flex mr="15px">
          <UndoDropdown />
        </Flex>
        <RedoDropdown />
      </Flex>
    </Flex>
  );
};

export default ProjectNavigation;
