import { ArrowIos } from "app/assets/icons";

export const configColumns = [
  {
    Header: "День",
    textAlign: "left",
    rowSpan: 2,
    columns: [
      {
        Header: "hidden0",
        accessor: "NAME",
        displayNone: true,
        textAlign: "left",
        Cell: ({ row, cell }) =>
          row.canExpand ? (
            <span {...row.getToggleRowExpandedProps()}>
              {row.isExpanded ? (
                <ArrowIos direction="down" />
              ) : (
                <ArrowIos direction="right" />
              )}
              {"\t"}
              {cell.value}
            </span>
          ) : (
            <span style={{ paddingLeft: cell.value === "Итого" ? 2 : 32 }}>
              {cell.value}
            </span>
          ),
      },
    ],
  },
  {
    Header: "Визиты",
    rowSpan: 2,
    columns: [
      {
        Header: "hidden1",
        accessor: "VISIT",
        displayNone: true,
      },
    ],
  },
  {
    Header: "Время часы/мин",
    columns: [
      {
        accessor: "TIME_IN_POINT",
        Header: "в точках",
      },
      {
        accessor: "TRAVEL_TIME",
        Header: "в пути",
      },
      {
        accessor: "TOTAL_TIME",
        Header: "общее",
      },
    ],
  },
  {
    Header: "Пробег, км",
    rowSpan: 2,
    columns: [
      {
        Header: "hidden5",
        accessor: "DISTANCE",
        displayNone: true,
      },
    ],
  },
  {
    Header: "ТО",
    rowSpan: 2,
    columns: [
      {
        Header: "hidden6",
        accessor: "TO",
        displayNone: true,
      },
    ],
  },
  {
    Header: "ПР",
    rowSpan: 2,
    columns: [
      {
        Header: "hidden7",
        accessor: "PR",
        displayNone: true,
      },
    ],
  },
  {
    Header: "%",
    rowSpan: 2,
    columns: [
      {
        Header: "hidden8",
        accessor: "PERCENT",
        displayNone: true,
      },
    ],
  },
];
