import { VisitDayIcon } from "icons/icons";
import { beautifyWeekDay } from "shared/helpers";
import { Flex, Text } from "shared/ui";
/* eslint-disable no-unused-expressions */
/* eslint-disable consistent-return */
/* eslint-disable no-shadow */
import styled from "styled-components";

const Days = ({ days = [], selectedDays = [], onSelect = () => {} }) => (
  <Flex flexDirection="row">
    {days.map((day) => (
      <Day
        key={day.name}
        isDaySelected={selectedDays.includes(day.name)}
        onClick={() => onSelect(day.name)}
      >
        <Text variant="body1" color="color7">
          {beautifyWeekDay(day.name)}
        </Text>
        <VisitDayIcon color={day.color} />
      </Day>
    ))}
  </Flex>
);

const Day = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 48px;
  margin-right: 5px;
  border: ${(p) =>
    p.isDaySelected ? "2px solid #2D8AF6" : "1px solid #CCCCCC"};
  box-sizing: border-box;
  background: #ffffff;
  cursor: pointer;

  &:last-child {
    margin-right: 0;
  }
`;

export default Days;
