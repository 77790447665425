import { PopupContext } from "app/context/PopupContext";
import { useContext } from "react";
import { useSelector } from "react-redux";
import { useDeletePointFromIndividualAreaMutation } from "shared/api/shedule";
import { Button, Flex, PinkButton, Popup, Text } from "shared/ui";

const DeletePoint = ({ point, individualAreaId }) => {
  const { hidePopup } = useContext(PopupContext);
  const { projectInfo } = useSelector(
    (state) => state.rootReducer.currentProject,
  );

  const [deletePointFromIndividualArea, { isLoading }] =
    useDeletePointFromIndividualAreaMutation();

  const onDelete = async () => {
    await deletePointFromIndividualArea({
      projectId: projectInfo.id,
      deliveryAreaId: individualAreaId,
      pointId: point?.id,
    });
    hidePopup();
  };

  return (
    <Popup isCenter title="Исключение точки из индивидуального графика">
      <Flex mt="20px">
        <Text variant="body1">
          После исключения точки из индивидуального графика, ей будет присвоен
          статус — Зона не установлена, если она не попадает в текущие зоны.
          Если точка попадает в одну из зон, она будет включена в неё.
        </Text>
      </Flex>
      <Flex mt="20px" flexDirection="row">
        <Flex mr="12px">
          <Button text="ИСКЛЮЧИТЬ" onClick={onDelete} disabled={isLoading} />
        </Flex>
        <Flex width="120px">
          <PinkButton onClick={() => hidePopup()}>ОТМЕНА</PinkButton>
        </Flex>
      </Flex>
    </Popup>
  );
};

export default DeletePoint;
