/* eslint-disable consistent-return */
import { PopupContext } from "app/context/PopupContext";
import {
  resetAreaFilter,
  resetContractFilter,
  resetGroupFilter,
  setAddressFilter,
  setAreaFilter,
  setContractFilter,
  setGroupFilter,
} from "app/store/reducer/shedule";
import { AnalysisPeriodPopup } from "pages/current-project/areas/filters/popups";
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useListAnalyzePeriodQuery } from "shared/api/logistic";
import { useFiltersListQuery } from "shared/api/shedule";
import { calendarFiltersToString } from "shared/helpers";
import {
  AnalysisDropdown as AnalysisDropdownUI,
  Checkbox,
  Flex,
  Search,
  SearchDropdown,
  Text,
} from "shared/ui";

export const AddressSearch = () => {
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [isMounted, setIsMounted] = useState(false);
  const { frontendFilters } = useSelector((state) => state.rootReducer.shedule);

  useEffect(() => {
    if (isMounted && search !== frontendFilters.address) {
      const setFilterWithDelay = setTimeout(() => {
        dispatch(setAddressFilter(search));
      }, 300);

      return () => {
        clearTimeout(setFilterWithDelay);
      };
    }
  }, [search]);

  useEffect(() => {
    setSearch(frontendFilters.address);
  }, [frontendFilters.address]);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  return (
    <Search
      value={search}
      onChange={(e) => setSearch(e.target.value)}
      onReset={() => setSearch("")}
    />
  );
};

export const GroupDropdown = () => {
  const dispatch = useDispatch();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const { projectInfo } = useSelector(
    (state) => state.rootReducer.currentProject,
  );
  const {
    frontendFilters: { branchIds, contractIds, groupIds },
  } = useSelector((state) => state.rootReducer.shedule);
  const { data: filters, isSuccess } = useFiltersListQuery(
    {
      projectId: projectInfo.id,
      branchIds,
      contractIds,
      groupIds,
    },
    { skip: !projectInfo.id, refetchOnMountOrArgChange: true },
  );

  const onSelect = (group) => {
    dispatch(setGroupFilter(group));
  };

  const onSearch = (value) => {
    setSearchValue(value);
  };

  const onToggle = (isOpen) => {
    if (isOpen === false) onSearch("");
    setIsDropdownOpen((prev) => !prev);
  };

  const onResetSearch = () => {
    onSearch("");
    setIsDropdownOpen(false);
  };

  const onResetAllCheckboxes = () => {
    dispatch(resetGroupFilter());
    setIsDropdownOpen(false);
  };

  if (isSuccess) {
    return (
      <SearchDropdown
        isDropdownOpen={isDropdownOpen}
        toggleText={
          groupIds?.length ? `Выбрано ${groupIds?.length}` : "Выбрать"
        }
        searchValue={searchValue}
        onToggle={onToggle}
        onSearch={onSearch}
        onResetSearch={onResetSearch}
        onResetAllCheckboxes={onResetAllCheckboxes}
        multiselect
        selectedText
        selectedCount={groupIds?.length}
      >
        {filters?.groups?.map(
          (item) =>
            item?.groupName
              .toLowerCase()
              .includes(searchValue.toLowerCase()) && (
              <SearchDropdown.Item key={item.id}>
                <Checkbox
                  checked={groupIds?.includes(item.id)}
                  onChange={() => onSelect(item)}
                />
                <Flex ml="7px">
                  <Text variant="body1">{item.groupName}</Text>
                </Flex>
              </SearchDropdown.Item>
            ),
        )}
      </SearchDropdown>
    );
  }

  return null;
};

export const AreaDropdown = () => {
  const dispatch = useDispatch();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const { projectInfo } = useSelector(
    (state) => state.rootReducer.currentProject,
  );
  const {
    frontendFilters: { areaIds, branchIds, contractIds, groupIds },
  } = useSelector((state) => state.rootReducer.shedule);
  const { data: filters, isSuccess } = useFiltersListQuery(
    {
      projectId: projectInfo.id,
      branchIds,
      contractIds,
      groupIds,
    },
    { skip: !projectInfo.id, refetchOnMountOrArgChange: true },
  );

  const onSelect = (area) => {
    dispatch(setAreaFilter(area));
  };

  const onSearch = (value) => {
    setSearchValue(value);
  };

  const onToggle = (isOpen) => {
    if (isOpen === false) onSearch("");
    setIsDropdownOpen((prev) => !prev);
  };

  const onResetSearch = () => {
    onSearch("");
    setIsDropdownOpen(false);
  };

  const onResetAllCheckboxes = () => {
    dispatch(resetAreaFilter());
    setIsDropdownOpen(false);
  };

  useEffect(() => {
    // reset area frontend filter if area was deleted
    const areasFiltersIds = filters?.areas.map((item) => item.id);

    for (let i = 0; i < areaIds.length; i += 1) {
      if (!areasFiltersIds.includes(areaIds[i])) {
        dispatch(setAreaFilter({ id: areaIds[i] }));
      }
    }
  }, [areaIds, filters]);

  if (isSuccess) {
    return (
      <SearchDropdown
        isDropdownOpen={isDropdownOpen}
        toggleText={areaIds?.length ? `Выбрано ${areaIds?.length}` : "Выбрать"}
        searchValue={searchValue}
        onToggle={onToggle}
        onSearch={onSearch}
        onResetSearch={onResetSearch}
        onResetAllCheckboxes={onResetAllCheckboxes}
        multiselect
        selectedText
        selectedCount={areaIds?.length}
      >
        {filters?.areas?.map(
          (item) =>
            item?.areaName
              .toLowerCase()
              .includes(searchValue.toLowerCase()) && (
              <SearchDropdown.Item key={item.id}>
                <Checkbox
                  checked={areaIds?.includes(item.id)}
                  onChange={() => onSelect(item)}
                />
                <Flex ml="7px">
                  <Text variant="body1">{item.areaName}</Text>
                </Flex>
              </SearchDropdown.Item>
            ),
        )}
      </SearchDropdown>
    );
  }

  return null;
};

export const ContractDropdown = () => {
  const dispatch = useDispatch();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const { projectInfo } = useSelector(
    (state) => state.rootReducer.currentProject,
  );
  const {
    frontendFilters: { branchIds, contractIds, groupIds },
  } = useSelector((state) => state.rootReducer.shedule);
  const { data: filters, isSuccess } = useFiltersListQuery(
    {
      projectId: projectInfo.id,
      branchIds,
      contractIds,
      groupIds,
    },
    { skip: !projectInfo.id, refetchOnMountOrArgChange: true },
  );

  const onSelect = (contract) => {
    dispatch(setContractFilter(contract));
  };

  const onSearch = (value) => {
    setSearchValue(value);
  };

  const onToggle = (isOpen) => {
    if (isOpen === false) onSearch("");
    setIsDropdownOpen((prev) => !prev);
  };

  const onResetSearch = () => {
    onSearch("");
    setIsDropdownOpen(false);
  };

  const onResetAllCheckboxes = () => {
    dispatch(resetContractFilter());
    setIsDropdownOpen(false);
  };

  if (isSuccess) {
    return (
      <SearchDropdown
        isDropdownOpen={isDropdownOpen}
        toggleText={
          contractIds?.length ? `Выбрано ${contractIds?.length}` : "Выбрать"
        }
        searchValue={searchValue}
        onToggle={onToggle}
        onSearch={onSearch}
        onResetSearch={onResetSearch}
        onResetAllCheckboxes={onResetAllCheckboxes}
        multiselect
        selectedText
        selectedCount={contractIds?.length}
      >
        {filters?.contracts?.map(
          (item) =>
            item?.contractName
              .toLowerCase()
              .includes(searchValue.toLowerCase()) && (
              <SearchDropdown.Item key={item.id}>
                <Checkbox
                  checked={contractIds?.includes(item.id)}
                  onChange={() => onSelect(item)}
                />
                <Flex ml="7px">
                  <Text variant="body1">{item.contractName}</Text>
                </Flex>
              </SearchDropdown.Item>
            ),
        )}
      </SearchDropdown>
    );
  }

  return null;
};

export const AnalysisDropdown = () => {
  const { showPopup } = useContext(PopupContext);
  const { projectInfo } = useSelector(
    (state) => state.rootReducer.currentProject,
  );
  const { data: analyzePeriod, isSuccess } = useListAnalyzePeriodQuery(
    {
      projectId: projectInfo.id,
    },
    { skip: !projectInfo.id },
  );

  const onClick = () => {
    showPopup(AnalysisPeriodPopup);
  };

  if (isSuccess) {
    return (
      <AnalysisDropdownUI
        toggleText={calendarFiltersToString(
          analyzePeriod?.monthFrom,
          analyzePeriod?.monthTo,
          analyzePeriod?.yearFrom,
          analyzePeriod?.yearTo,
        )}
        onClick={onClick}
      />
    );
  }

  return null;
};
