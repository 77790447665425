import { api } from "shared/api";

const colorsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    listColors: builder.query({
      query: () => `colors`,
    }),
  }),
});

export const { useListColorsQuery } = colorsApi;
