import { ContractHeaderCell } from "../model";

export const configColumns = [
  {
    id: "0",
    Header: () => <ContractHeaderCell />,
    accessor: "CONTRACT_NAME",
    w: "25%",
  },
  {
    id: "1",
    Header: "Заказы",
    accessor: "ORDER_COUNT",
    w: "15%",
    textAlign: "center",
  },
  {
    id: "2",
    Header: "Точки",
    accessor: "POINT_SUM",
    w: "15%",
    textAlign: "center",
  },
  {
    id: "3",
    Header: "Продажи (наценка), т.р.",
    accessor: "MARGIN",
    w: "15%",
    textAlign: "center",
  },
  {
    id: "4",
    Header: "Объём, м3",
    accessor: "VOLUME",
    w: "15%",
    textAlign: "center",
  },
  {
    id: "5",
    Header: "Вес, кг",
    accessor: "WEIGHT",
    w: "15%",
    textAlign: "center",
  },
];
