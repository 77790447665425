import {
  resetState as resetCurrentProjectState,
  setInfo,
} from "app/store/reducer/current-project";
import { resetState } from "app/store/reducer/days";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useProjectInfoQuery } from "shared/api/current-project";
import styled, { css } from "styled-components";
import { ProjectNavigation } from "widgets";

import Filters from "./filters";
import Map from "./map";
import Table from "./table";

const Days = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const { data: projectInfo, isFetching } = useProjectInfoQuery(
    { projectId: params.id },
    { skip: !params.id },
  );

  const { expandMode } = useSelector(
    (state) => state.rootReducer.projectSettings,
  );

  useEffect(() => {
    if (projectInfo) {
      dispatch(
        setInfo({
          id: projectInfo.id,
          name: projectInfo.name,
          status: projectInfo.status,
        }),
      );
    }
  }, [projectInfo]);

  useEffect(
    () => () => {
      dispatch(resetCurrentProjectState());
      dispatch(resetState());
    },
    [],
  );

  if (isFetching) {
    return null;
  }
  const   getCurrentQuarterWeek = () =>  {
    const now = new Date();
    const quarterStartMonth = Math.floor(now.getMonth() / 3) * 3; // Начало квартала в месяцах (0 - январь, 3 - апрель и т.д.)
    const quarterStartDate = new Date(now.getFullYear(), quarterStartMonth, 1); // Первый день текущего квартала
    const daysSinceQuarterStart = Math.floor((now - quarterStartDate) / (1000 * 60 * 60 * 24)); // Количество дней с начала квартала
    const currentWeek = Math.ceil((daysSinceQuarterStart + 1) / 7); // Текущая неделя квартала
    return currentWeek;
}

  return (
    <Root>
      <LeftPageContainer expanded={expandMode === "table"}>
        <Header>
          <ProjectNavigation />
          <Filters />
          <CurrentWeekContainer>
              {`${getCurrentQuarterWeek()} неделя квартала`}
            </CurrentWeekContainer>

        </Header>
        {expandMode !== "map" && <Table />}
      </LeftPageContainer>
      <RightPageContainer expanded={expandMode === "map"}>
        {expandMode !== "table" && <Map />}
      </RightPageContainer>
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  height: 100%;
`;

const LeftPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 60%;

  ${(props) =>
    props.expanded &&
    css`
      position: fixed;
      top: 0;
      right: 0;
      z-index: 10;
      height: 100vh;
      width: 100vw;
    `}
`;

const CurrentWeekContainer = styled.div`
  margin-top: 10px;
  border: 1px solid #CCCCCC;
  max-width: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 8px;
  background: #fff;
`


const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px 16px;
  background: #ecf4fe;
  z-index: 20;
`;

const RightPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 40%;

  ${(props) =>
    props.expanded &&
    css`
      position: fixed;
      top: 0;
      right: 0;
      z-index: 10;
      height: 100vh;
      width: 100vw;
    `}
`;

export default Days;
