export const APP_ROUTES = {
  DEFAULT: "/",
  SIGN_IN: "/sign-in",
  PROJECTS: "/projects",
  ROUTES: "/routes/:id",
  DAYS: "/days/:id",
  COORDINATES: "/coordinates/:id",
  LOGISTIC_COORDINATES: "/logistic-coordinates/:id",
  AREAS: "/areas/:id",
  SHEDULE: "/shedule/:id",
  IMPORT_EXCEL_DATA: "/import-excel-data/:id",
  ALL: "*",
};

export const SERVER_ERROR_TEXT = "Ошибка в запросе...";

export const PROJECT_MODE = {
  ROUTES: "Маршруты",
  DAYS: "Дни",
  COORDINATES: "Координаты",
  LOGISTIC_COORDINATES: "Координаты",
  AREAS: "Общие зоны",
  SHEDULE: "Индивидуальный график",
};

export const MARKER_COLOR_BY = {
  ROUTE: "ROUTE",
  DAY_OF_WEEK: "DAY_OF_WEEK",
};

export const USER_TYPE = {
  USER: "USER",
  KEY_USER: "KEY_USER",
  LOGISTIC_USER: "LOGISTIC_USER",
  ROUTE_ENG: "ROUTE_ENG",
};

export const EXPORT_LIST = {
  ROUTES: "ROUTES",
  COORDINATES: "COORDINATES",
  LOGISTIC_CALENDAR: "LOGISTIC_CALENDAR",
};

export const CYCLES = {
  EVERY_WEEK: "EVERY_WEEK",
  EVEN_WEEK: "EVEN_WEEK",
  ODD_WEEK: "ODD_WEEK",
  FIRST_WEEK: "FIRST_WEEK",
  SECOND_WEEK: "SECOND_WEEK",
  THIRD_WEEK: "THIRD_WEEK",
  FOURTH_WEEK: "FOURTH_WEEK",
};

export const CYCLES_RU = {
  EVERY_WEEK: "Каждая неделя",
  FIRST_WEEK: "Первая неделя",
  SECOND_WEEK: "Вторая неделя",
  THIRD_WEEK: "Третья неделя",
  FOURTH_WEEK: "Четвёртая неделя",
  EVEN_WEEK: "Чётная неделя",
  ODD_WEEK: "Нечётная неделя",
};

export const SORT = {
  ROUTE: "ROUTE_NAME",
  DAY: "DAY_WEEK",
  POINT: "POINT_NAME",
  DISTANCE: "DISTANCE",
  MARGIN: "MARGIN",
  VOLUME: "VOLUME",
  WEIGHT: "WEIGHT",
};

export const DAYS_OF_WEEK = {
  MONDAY: "MONDAY",
  TUESDAY: "TUESDAY",
  WEDNESDAY: "WEDNESDAY",
  THURSDAY: "THURSDAY",
  FRIDAY: "FRIDAY",
  SATURDAY: "SATURDAY",
  SUNDAY: "SUNDAY",
};

export const DAYS_OF_WEEK_SHORT_RU = {
  MONDAY: "ПН",
  TUESDAY: "ВТ",
  WEDNESDAY: "СР",
  THURSDAY: "ЧТ",
  FRIDAY: "ПТ",
  SATURDAY: "СБ",
  SUNDAY: "ВС",
};

export const DAYS_OF_WEEK_RU = {
  MONDAY: "Понедельник",
  TUESDAY: "Вторник",
  WEDNESDAY: "Среда",
  THURSDAY: "Четверг",
  FRIDAY: "Пятница",
  SATURDAY: "Суббота",
  SUNDAY: "Воскресенье",
};

export const DAYS_OF_WEEK_COLOR = {
  MONDAY: "#F50008",
  TUESDAY: "#9B00F5",
  WEDNESDAY: "#1274CE",
  THURSDAY: "#0ABDA8",
  FRIDAY: "#00C23A",
  SATURDAY: "#787878",
  SUNDAY: "#787878",
  NA: "#000",
};

export const DAYS_OF_WEEK_LOGISTIC_CALENDAR_COLOR = {
  MONDAY: "#00C23A",
  TUESDAY: "#F50008",
  WEDNESDAY: "#9B00F5",
  THURSDAY: "#0088C2",
  FRIDAY: "#00B6C2",
  SATURDAY: "#787878",
  SUNDAY: "#787878",
};

export const DISTANCE_DIFFERENCE = {
  EMPTY: "EMPTY",
  UNDEFINED: "UNDEFINED",
  MORE_OR_EQUALS_50: "MORE_OR_EQUALS_50",
  MORE_OR_EQUALS_100: "MORE_OR_EQUALS_100",
  MORE_OR_EQUALS_150: "MORE_OR_EQUALS_150",
  MORE_OR_EQUALS_300: "MORE_OR_EQUALS_300",
  MORE_OR_EQUALS_1000: "MORE_OR_EQUALS_1000",
};

export const PERIODICITY = {
  _24_HOURS: "24 ч.",
  _48_HOURS: "48 ч.",
};

export const PERIODICITY_NEW = [
  {
    value: 'DELIVERY_24',
    label: '24',
  },
  {
    value: 'DELIVERY_48',
    label: '48',
  },
  {
    value: 'DELIVERY_MORE_THAN_48',
    label: '> 48',
  },
];

export const DEFAULT_BOUNDS = [
  [54.7065, 20.511],
  [54.9924, 73.3686],
];

export const MAX_LIMIT_FILTER = 9999;

export const PAGE_HEADER_HEIGHT = "156px";
export const TABLE_HEAD_HEIGHT = "45px";
export const TABLE_FOOTER_HEIGHT = "72px";
export const TABLE_HEIGHT = "calc(100vh - 156px)";
export const TABLE_HEIGHT_WITH_FOOTER = "calc(100vh - 228px)";
export const FULLSCREEN_POPUP_HEIGHT = "calc(100vh - 180px - 56px)";
export const TABLE_BODY_HEIGHT = "calc(100vh - 201px)";
export const TABLE_BODY_HEIGHT_WITH_FOOTER = "calc(100vh - 273px)";
