import { BodyCell, ContractHeaderCell } from "../model";

export const configColumns = [
  {
    id: "0",
    Header: () => <ContractHeaderCell />,
    accessor: "contractName",
    w: "30%",
    textAlign: "left",
  },
  {
    id: "1",
    Header: "ПН",
    accessor: (data) => <BodyCell value={data.report.MONDAY} />,
    w: "10%",
    textAlign: "center",
  },
  {
    id: "2",
    Header: "ВТ",
    accessor: (data) => <BodyCell value={data.report.TUESDAY} />,
    w: "10%",
    textAlign: "center",
  },
  {
    id: "3",
    Header: "СР",
    accessor: (data) => <BodyCell value={data.report.WEDNESDAY} />,
    w: "10%",
    textAlign: "center",
  },
  {
    id: "4",
    Header: "ЧТ",
    accessor: (data) => <BodyCell value={data.report.THURSDAY} />,
    w: "10%",
    textAlign: "center",
  },
  {
    id: "5",
    Header: "ПТ",
    accessor: (data) => <BodyCell value={data.report.FRIDAY} />,
    w: "10%",
    textAlign: "center",
  },
  {
    id: "6",
    Header: "СБ",
    accessor: (data) => <BodyCell value={data.report.SATURDAY} />,
    w: "10%",
    textAlign: "center",
  },
  {
    id: "7",
    Header: "ВС",
    accessor: (data) => <BodyCell value={data.report.SUNDAY} />,
    w: "10%",
    textAlign: "center",
  },
];
