import styled from "styled-components";

const Divider = ({ ...props }) => <Root {...props} />;

const Root = styled.div`
  margin: 20px 0;
  height: 1px;
  width: auto;
  background: #cccccc;
  ${(props) => ({ ...props })}
`;

export default Divider;
