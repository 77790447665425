import { isPointInPolygon } from "geolib";
import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";

const useLasso = (mapRoutePoints, selectPointsAction) => {
  const dispatch = useDispatch();

  const [isLassoActive, setIsLassoActive] = useState(false);
  const handleSwitchLasso = useCallback(() => {
    setIsLassoActive((prevState) => !prevState);
  }, []);
  const handleFinishLasso = useCallback(
    (polygonCoords) => {
      const markersIdsInsidePolygon = mapRoutePoints
        .filter((markerData) =>
          isPointInPolygon(
            {
              lat: markerData.latitude,
              lng: markerData.longitude,
            },
            polygonCoords,
          ),
        )
        .map((markerData) => markerData.id);

      dispatch(selectPointsAction(markersIdsInsidePolygon));
      setIsLassoActive(false);
    },
    [mapRoutePoints],
  );

  return { isLassoActive, handleSwitchLasso, handleFinishLasso };
};

export default useLasso;
