import * as React from "react";

const SvgIndividualMarker = ({ width = 40, height = 40, ...props }) => (
  <svg
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={width / 2} cy={width / 2} r={width / 2} fill="#fff" />
    <circle cx={width / 2} cy={width / 2} r={width / 2.5} fill="#787878" />
    <circle cx={width / 2} cy={width / 2} r={width / 3.6} fill="#fff" />
    <circle cx={width / 2} cy={width / 2} r={width / 6.6} fill="#787878" />
  </svg>
);

export default SvgIndividualMarker;
