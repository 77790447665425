import React, { memo } from "react";
import { useGetRoutePointInfoQuery } from "shared/api/points";
import { beautifyWeekDay } from "shared/helpers";
import { Divider, Flex, Loader, Text } from "shared/ui";
import styled from "styled-components";
import { DeliveryTermsReport } from "widgets";

const PointInfo = ({ projectId, shownPoint }) => {
  const {
    data: pointData,
    isSuccess,
    isFetching,
  } = useGetRoutePointInfoQuery({
    projectId,
    pointId: shownPoint.pointId,
  });

  if (isFetching)
    return (
      <Flex width="270px" alignItems="center" justifyContent="center">
        <Loader isLoading />
      </Flex>
    );

  if (isSuccess)
    return (
      <Root width="270px" overflowY="auto">
        <Flex>
          <Text variant="body1bold">{pointData.pointCode}</Text>
        </Flex>
        <Flex mt="8px">
          <Text variant="body2">{pointData.pointName}</Text>
        </Flex>
        <Flex mt="8px">
          <Text variant="body3" color="color7">
            {pointData.address}
          </Text>
        </Flex>
        <DeliveryTermsReport
          routeId={shownPoint?.routeId}
          pointId={shownPoint?.pointId}
        />
        {pointData.routePoints.map((rp) => (
          <div>
            <Divider margin="8px 0" width="100%" />
            <PointParam name="Команда" value={rp.command} />
            <PointParam name="Маршрут" value={rp.routeName} />
            <PointParam name="День визита" value={rp.days} uppercase />
            <PointParam name="Время" value={rp.duration} />
            <PointParam name="Частота" value={rp.frequency} />
            <PointParam name="Признак 1" value={rp.attributeCode1} />
            <PointParam name="Признак 2" value={rp.attributeCode2} />
            <PointParam name="Доп. мера 1" value={rp.additionallyTable1} />
            <PointParam name="Доп. мера 2" value={rp.additionallyTable2} />
            <PointParam name="Доп. мера 3" value={rp.additionallyTable3} />
          </div>
        ))}
      </Root>
    );

  // if we can't get data from server
  return (
    <Flex width="270px" mr="20px" overflowY="auto">
      <Flex mb="4px">
        <Text variant="body3bold">{shownPoint.pointCode}</Text>
      </Flex>
      <Flex mb="4px">
        <Text variant="body3">{shownPoint.pointName}</Text>
      </Flex>
      <Flex mb="10px">
        <Text variant="body3">{shownPoint.address}</Text>
      </Flex>
      <Flex flexDirection="row" mb="10px">
        <Flex>
          <Text variant="body3">
            {beautifyWeekDay(shownPoint.dayWeek) || "-"}
          </Text>
        </Flex>
        <Flex ml="8px">
          <Text variant="body3" color={shownPoint.color}>
            {shownPoint.routeName}
          </Text>
        </Flex>
      </Flex>
      <Flex mb="10px">
        <Text variant="body3" color="color3">
          {shownPoint.attributeCode1}, {shownPoint.attributeCode2}
        </Text>
      </Flex>
      <Flex mb="10px">
        <Text variant="body3" color="color3">
          {`ТО: ${shownPoint.additionallyTable1}, ПР: ${shownPoint.additionallyTable2}, %: ${shownPoint.additionallyTable3}`}
        </Text>
      </Flex>
      <Flex mb="4px">
        <Text variant="body3">Разница {shownPoint.distance}</Text>
      </Flex>
      <DeliveryTermsReport
        routeId={shownPoint?.routeId}
        pointId={shownPoint?.pointId}
      />
    </Flex>
  );
};

const Root = styled(Flex)`
  padding-right: 8px;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) 5%,
      rgba(204, 204, 204, 1) 5%,
      rgba(204, 204, 204, 1) 100%
    );
    padding-left: 8px;
    border-left: 4px solid white;
  }

  &::-webkit-scrollbar-track {
    width: 0;
  }
`;

const PointParam = ({ name, value, uppercase = false }) => (
  <Flex flexDirection="row" mt="4px" justifyContent="space-between">
    <Text variant="body3" color="color3">
      {name}
    </Text>
    <Text variant="body3" textTransform={uppercase && "uppercase"}>
      {value}
    </Text>
  </Flex>
);

export default memo(PointInfo);
