/* eslint-disable no-param-reassign */
import { yupResolver } from "@hookform/resolvers/yup";
import { PopupContext } from "app/context/PopupContext";
import { NamesDropdown } from "entities/area/model";
import { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import {
  useCreateAreaMutation,
  useListAreasNamesQuery,
} from "shared/api/areas";
import { useListColorsQuery } from "shared/api/colors";
import { useListContractGroupsByBranchQuery } from "shared/api/contract-group";
import { useBranchesListQuery } from "shared/api/current-project";
import {
  CYCLES,
  CYCLES_RU,
  DAYS_OF_WEEK,
  DAYS_OF_WEEK_SHORT_RU,
  PERIODICITY,
  SERVER_ERROR_TEXT,
} from "shared/config";
import {
  Button,
  Checkbox,
  ColorPicker,
  CustomRadio,
  ErrorText,
  Flex,
  Loader,
  PageShadow,
  Popup,
  Select,
  Text,
} from "shared/ui";

import { CreateAreaSchema } from "./config";

const CreateArea = ({ coordinates }) => {
  const { hidePopup } = useContext(PopupContext);
  const [serverError, setServerError] = useState("");
  const { projectInfo } = useSelector(
    (state) => state.rootReducer.currentProject,
  );
  const { data: areasNames, isFetching: isAreasNamesFetching } =
    useListAreasNamesQuery(
      { projectId: projectInfo.id },
      { skip: !projectInfo.id, refetchOnMountOrArgChange: true },
    );
  const { data: branches, isFetching: isBranchesFetching } =
    useBranchesListQuery(
      {
        projectId: projectInfo.id,
      },
      { skip: !projectInfo.id, refetchOnMountOrArgChange: true },
    );
  const { data: colors, isFetching: isColorsFetching } = useListColorsQuery();
  const [createArea, { isLoading: isCreateAreaLoading }] =
    useCreateAreaMutation();
  const {
    register,
    setValue,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(CreateAreaSchema),
    defaultValues: {
      name: "",
      colorId: 47, // default grey color
      branchId: null,
      groupId: null,
      cyclePoint: "",
      deliveryDays: [],
      periodicity: "_24_HOURS",
    },
  });
  const { data: contractGroups, isFetching: isContractGroupsFetching } =
    useListContractGroupsByBranchQuery(
      {
        projectId: projectInfo.id,
        branchId: watch("branchId"),
      },
      {
        skip: !projectInfo.id || !watch("branchId"),
        refetchOnMountOrArgChange: true,
      },
    );

  const cycleOptions = Object.keys(CYCLES).map((cycle) => ({
    id: cycle,
    name: CYCLES_RU[cycle],
    value: cycle,
  }));

  const onNameSelect = (value) => {
    setValue("name", value);
  };

  const onColorSelect = (color) => {
    setValue("colorId", color.id);
  };

  const onSubmit = async (values) => {
    values.coordinates = coordinates;
    if (values.cyclePoint === "") {
      delete values.cyclePoint;
    }

    const response = await createArea({ projectId: projectInfo.id, ...values });

    if (response.error) {
      setServerError(response.error?.data?.message || SERVER_ERROR_TEXT);
      return;
    }

    hidePopup();
  };

  if (
    isColorsFetching ||
    isBranchesFetching ||
    isContractGroupsFetching ||
    isAreasNamesFetching
  ) {
    return (
      <PageShadow>
        <Loader isLoading />
      </PageShadow>
    );
  }

  return (
    <Popup title="Заполните данные" width="600px">
      <Flex flexDirection="row" mt="20px">
        <NamesDropdown
          title="Список зон филиалов"
          areasNames={areasNames}
          name={watch("name")}
          onSelect={onNameSelect}
          errorText={errors?.name?.message}
        />
      </Flex>
      <Flex mt="20px">
        <ColorPicker
          title="Цвет"
          disabled={isCreateAreaLoading}
          selectedId={watch("colorId")}
          onSelect={onColorSelect}
          colors={colors}
          errorText={errors?.colorId?.message}
        />
      </Flex>
      <Flex flexDirection="row" mt="20px">
        <Flex width="100%" mr="12px">
          <Select
            {...register("branchId")}
            title="Филиал"
            disabled={isCreateAreaLoading}
            options={branches.map((item) => ({
              id: item.id,
              name: item.branchName,
              value: item.id,
            }))}
            errorText={errors?.branchId?.message}
          />
        </Flex>
        <Flex width="100%">
          <Select
            {...register("groupId")}
            title="Группа"
            disabled={isCreateAreaLoading}
            options={contractGroups?.map((item) => ({
              id: item.id,
              name: item.groupName,
              value: item.id,
            }))}
            errorText={errors?.groupId?.message}
          />
        </Flex>
      </Flex>
      <Flex mt="20px">
        <Select
          {...register("cyclePoint")}
          title="Цикличность"
          disabled={isCreateAreaLoading}
          options={[
            ...cycleOptions,
            {
              id: "empty",
              name: "Не выбрано",
              value: "",
            },
          ]}
          errorText={errors?.cyclePoint?.message}
        />
      </Flex>
      <Flex mt="20px">
        <Flex mb="4px">
          <Text
            variant="body3"
            color={errors?.deliveryDays ? "color5" : "color3"}
          >
            Выберите дни недели
          </Text>
        </Flex>
        <Flex flexDirection="row">
          {Object.values(DAYS_OF_WEEK).map((day) => (
            <Flex key={day} flexDirection="row" alignItems="center" mr="15px">
              <Flex mr="7px">
                <Checkbox value={day} {...register("deliveryDays")} />
              </Flex>
              <Text variant="body1">{DAYS_OF_WEEK_SHORT_RU[day]}</Text>
            </Flex>
          ))}
        </Flex>
      </Flex>
      <Flex mt="20px">
        <Flex mb="4px">
          <Text
            variant="body3"
            color={errors?.periodicity ? "color5" : "color3"}
          >
            Выберите периодичность
          </Text>
        </Flex>
        <Flex flexDirection="row">
          {Object.keys(PERIODICITY).map((item) => (
            <Flex key={item} flexDirection="row" alignItems="center" mr="35px">
              <Flex mr="7px">
                <CustomRadio value={item} {...register("periodicity")} />
              </Flex>
              <Text variant="body1">{PERIODICITY[item]}</Text>
            </Flex>
          ))}
        </Flex>
      </Flex>
      <Flex mt="20px">
        <Button
          width="144px"
          text="СОХРАНИТЬ"
          onClick={handleSubmit(onSubmit)}
          disabled={isCreateAreaLoading}
        />
      </Flex>
      <ErrorText errorText={serverError} />
    </Popup>
  );
};

export default CreateArea;
