import { useTable } from "react-table";
import { hexToRgba, isOSX } from "shared/helpers";
import styled from "styled-components";

const Table = ({ data = [], columns = [], isExpand = false }) => {
  const tableInstance = useTable({
    columns,
    data,
  });
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  return (
    <TableWrapper isExpand={isExpand}>
      <TableContent {...getTableProps()}>
        <TableHead>
          {headerGroups.map((headerGroup) => (
            <TableHeaderRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <TableHeaderCell
                  width={column.w}
                  textAlign={column.textAlign}
                  {...column.getHeaderProps()}
                >
                  {column.render("Header")}
                </TableHeaderCell>
              ))}
            </TableHeaderRow>
          ))}
        </TableHead>
        <TableBody isExpand={isExpand} {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <TableDataRow
                id={row.original.routeCode}
                color={row.original.color}
                {...row.getRowProps()}
              >
                {row.cells.map((cell) => (
                  <TableDataCell
                    width={cell.column.w}
                    textAlign={cell.column.textAlign}
                    {...cell.getCellProps()}
                  >
                    {cell.render("Cell")}
                  </TableDataCell>
                ))}
              </TableDataRow>
            );
          })}
        </TableBody>
      </TableContent>
    </TableWrapper>
  );
};

const TableWrapper = styled.div`
  height: 100%;
  overflow: hidden;
`;

const TableContent = styled.table`
  border-collapse: collapse;
  height: auto;
  width: 100%;
`;

const TableHead = styled.thead`
  width: 100%;
`;

const TableHeaderRow = styled.tr`
  display: flex;
  border: 1px solid #cccccc;
  background: rgba(0, 0, 0, 0.04);
  overflow-y: scroll;

  ${!isOSX && "::-webkit-scrollbar {opacity: 0;}"}
`;

const TableHeaderCell = styled.th`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${(p) => p.width};
  padding: 4px;
  border-right: 1px solid #cccccc;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  text-align: ${(p) => p.textAlign};
  color: #787878;

  &:last-child {
    border: none;
  }
`;

const TableDataRow = styled.tr`
  background: ${({ color }) => (color ? hexToRgba(color, 0.04) : "#fff")};
  border-bottom: 1px solid #cccccc;
  border-left: 1px solid #cccccc;
  border-right: 1px solid #cccccc;
  display: flex;

  &:hover {
    background: #ecf4fe;
  }
`;

const TableDataCell = styled.td`
  width: ${(p) => p.width};
  padding: 4px;
  border-right: 1px solid #cccccc;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  text-align: ${(p) => p.textAlign};
  word-break: break-all;

  &:last-child {
    border: none;
  }
`;

const TableBody = styled.tbody`
  display: block;
  height: 100%;
  height: ${({ isExpand }) => (isExpand ? "300px" : "115px")};
  transition: 0.3s;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
`;

export default Table;
