import { useSelector } from "react-redux";
import { useListStatesQuery, useUndoStatesMutation } from "shared/api/states";

import { StateDropdown } from "../../ui";

const UndoDropdown = () => {
  const { projectInfo } = useSelector(
    (state) => state.rootReducer.currentProject,
  );
  const { data, isFetching } = useListStatesQuery(
    { projectId: projectInfo.id },
    { skip: !projectInfo.id },
  );
  const [undoStates] = useUndoStatesMutation();

  const onLastStateClick = async () => {
    if (data?.undoEvents?.length) {
      await undoStates({
        projectId: projectInfo.id,
        step: data?.undoEvents[0]?.step,
      });
    }
  };

  const onStateClick = async (step) => {
    await undoStates({ projectId: projectInfo.id, step });
  };

  if (isFetching) {
    return null;
  }

  return (
    <StateDropdown
      toggleText="Отмена"
      actionsText="Действий для отмены:"
      noActionsText="Нет действий для отмены"
      state={data?.undoEvents}
      onLastStateClick={onLastStateClick}
      onStateClick={onStateClick}
    />
  );
};

export default UndoDropdown;
