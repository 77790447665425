import { crossIcon } from "icons/icons";
import React from "react";
import { DISTANCE_DIFFERENCE } from "shared/config";
import { beautifyDistanceDifference } from "shared/helpers";
import { Flex, Text } from "shared/ui";
import styled from "styled-components";

const Toggle = React.forwardRef(({ onClick, distance, onReset }, ref) => (
  <Root
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
    borderColor={
      distance === DISTANCE_DIFFERENCE.EMPTY
        ? "1px solid #CCCCCC"
        : "2px solid #FF3131"
    }
  >
    <Flex>
      <Text color="color3">{beautifyDistanceDifference(distance)}</Text>
    </Flex>
    {distance !== DISTANCE_DIFFERENCE.EMPTY && (
      <Flex
        onClick={(e) => {
          e.stopPropagation();
          onReset();
        }}
      >
        {crossIcon}
      </Flex>
    )}
  </Root>
));

const Root = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 48px;
  border: ${({ borderColor }) => borderColor};
  box-sizing: border-box;
  padding: 12px;
  background: #ffffff;
  cursor: pointer;
`;

export default Toggle;
