import { Dropdown } from "react-bootstrap";
import styled, { css } from "styled-components";

import Divider from "../Divider";
import Text from "../Text";
import { Toggle as CustomToggle } from "./ui";

const { Menu, Toggle, Item } = Dropdown;

const ActionsDropdown = ({ list = [], onToggle = () => {} }) => (
  <Wrapper>
    <Dropdown onToggle={(show) => onToggle(show)}>
      <Toggle as={CustomToggle} />
      <Menu>
        {list.map((item) => (
          <StyledItem
            key={item?.id}
            eventKey={item?.id}
            onClick={item?.onClick}
            disabled={item.disabled}
          >
            <Text variant="body1">{item?.text}</Text>
            <Divider className="divider" />
          </StyledItem>
        ))}
      </Menu>
    </Dropdown>
  </Wrapper>
);

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  .dropdown-menu {
    position: fixed !important;
    border-radius: 0;
    padding: 20px;
    pointer-events: none;
  }

  .dropdown-item {
    padding: 0;
    margin: 0;
    pointer-events: auto;

    p {
      cursor: pointer;
      &:hover {
        color: #2d8af6;
      }
    }

    &:last-child {
      .divider {
        display: none;
      }
    }
  }

  .dropdown-item:focus,
  .dropdown-item:hover {
    background: none;
  }
`;

const StyledItem = styled(Item)`
  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none !important;
      p {
        color: #ccc;
      }
    `};
`;

export default ActionsDropdown;
