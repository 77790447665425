/* eslint-disable no-underscore-dangle */
import {
  setMapCenterPoint,
  setSelectedByLassoRoutePoints,
  setSelectedRoutePoints,
  switchMapRoutePointsVisible,
} from "app/store/reducer/days";
import {
  resetExpandedMapPopupState,
  setExpandedMapPopupData,
} from "app/store/reducer/expanded-map-popup";
import { expandMap } from "app/store/reducer/project-settings";
import { ChangeView } from "features";
import ResizeObserver from "features/map-resize-observer";
import RememberBounds from "features/return-map-to-prev-bounds";
import useLasso from "features/use-lasso";
import L from "leaflet";
import { CustomMarker } from "pages/current-project/ui";
import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactDOMServer from "react-dom/server";
import { Marker } from "react-leaflet";
import { useDispatch, useSelector } from "react-redux";
import { useListAreasMapQuery } from "shared/api/areas";
import { useListMapRoutePointsDaysQuery } from "shared/api/days";
import { PROJECT_MODE } from "shared/config";
import { calcZIndex, scrollInTable } from "shared/helpers";
import { Loader, Map as MapUI } from "shared/ui";
import Lasso from "shared/ui/lasso";

import { DayBasedPolygons } from "../../../../widgets";
import TableFooter from "../table/footer";
import { Footer, Polygons } from "./model";
import { ExpandedMapPopup, MarkerPopup, RouteLines } from "./ui";

const Map = () => {
  const dispatch = useDispatch();
  const popupRef = useRef();
  const { projectInfo } = useSelector(
    (state) => state.rootReducer.currentProject,
  );

  // Map data
  const {
    frontendFilters,
    mapCenterPoint,
    selectedRoutePoints,
    isMapRoutePointsVisible,
    isAreasVisible,
  } = useSelector((state) => state.rootReducer.days);
  const {
    data: mapRoutePoints,
    isLoading,
    isFetching,
    isSuccess,
  } = useListMapRoutePointsDaysQuery(
    {
      projectId: projectInfo.id,
      managerCodes: frontendFilters?.managerCodes,
      commands: frontendFilters?.commands,
      routeCodes: frontendFilters?.routeCodes,
      daysOfWeek: frontendFilters?.daysOfWeek,
      cyclePoint: frontendFilters?.cyclePoint,
    },
    { skip: !projectInfo.id, refetchOnMountOrArgChange: true },
  );
  const { data: areas } = useListAreasMapQuery(
    {
      projectId: projectInfo.id,
    },
    { skip: !projectInfo.id, refetchOnMountOrArgChange: true },
  );

  // Map settings
  const { expandMode } = useSelector(
    (state) => state.rootReducer.projectSettings,
  );

  // Fullscreen map controls
  const expanded = expandMode === "map";
  const handleSwitchMapExpand = () => {
    dispatch(expandMap());
  };
  useEffect(() => {
    dispatch(resetExpandedMapPopupState());
  }, [mapRoutePoints]);

  // Lasso
  const { isLassoActive, handleSwitchLasso, handleFinishLasso } = useLasso(
    mapRoutePoints,
    setSelectedByLassoRoutePoints,
  );

  // Day based polygons
  const [isDayBasedPolygonsVisible, setIsDayBasedPolygonsVisible] =
    useState(false);
  const handleSwitchDayBasedPolygonsVisibility = useCallback(() => {
    setIsDayBasedPolygonsVisible((prevState) => !prevState);
  }, []);

  const onMarkerClicked = async (e, mapRoutePoint) => {
    if (e.originalEvent.ctrlKey || e.originalEvent.metaKey) {
      popupRef.current._closeButton.click();
      dispatch(setMapCenterPoint({}));
      dispatch(setSelectedRoutePoints(mapRoutePoint.id));
    } else {
      if (selectedRoutePoints.includes(mapRoutePoint.id)) return;

      dispatch(setExpandedMapPopupData({ mapRoutePoint }));
      dispatch(
        setMapCenterPoint({
          id: mapRoutePoint.id,
          latitude: mapRoutePoint.latitude,
          longitude: mapRoutePoint.longitude,
          clickedIn: "map",
        }),
      );
      if (
        mapRoutePoint.typePoint !== "START" &&
        mapRoutePoint.typePoint !== "FINISH"
      ) {
        scrollInTable(mapRoutePoint.id);
      }
    }
  };

  if (isLoading || isFetching) {
    return <Loader isLoading />;
  }

  if (isSuccess) {
    return (
      <>
        <MapUI
          enableLasso
          enableDayBasedPolygons
          enableClusteringDisableButton={false}
          isLassoActive={isLassoActive}
          switchIsLassoActive={handleSwitchLasso}
          expanded={expanded}
          switchMapExpand={handleSwitchMapExpand}
          isMapPointsVisible={isMapRoutePointsVisible}
          switchMapPointsVisible={() => dispatch(switchMapRoutePointsVisible())}
          dayBasedPolygonsVisible={isDayBasedPolygonsVisible}
          switchDayBasedPolygonsVisibility={
            handleSwitchDayBasedPolygonsVisibility
          }
        >
          <ChangeView mapCenterPoint={mapCenterPoint} />
          <RememberBounds />
          <ResizeObserver expanded={expanded} />
          <RouteLines mapRoutePoints={mapRoutePoints} />
          {isMapRoutePointsVisible &&
            mapRoutePoints?.map((item) => (
              <Marker
                key={item.id}
                zIndexOffset={calcZIndex(
                  item,
                  mapCenterPoint,
                  selectedRoutePoints,
                )}
                position={
                  item.latitude !== "NULL" && item.longitude !== "NULL"
                    ? [item.latitude, item.longitude]
                    : [0, 0]
                }
                icon={L.divIcon({
                  className: "custom-icon",
                  html: ReactDOMServer.renderToString(
                    <CustomMarker
                      mapRoutePoint={item}
                      mapCenterPoint={mapCenterPoint}
                      selectedRoutePoints={selectedRoutePoints}
                      projectMode={PROJECT_MODE.DAYS}
                    />,
                  ),
                })}
                eventHandlers={{
                  click: (e) => onMarkerClicked(e, item),
                }}
              >
                <MarkerPopup
                  popupRef={popupRef}
                  mapRoutePoints={mapRoutePoints}
                  mapRoutePoint={item}
                  projectMode={PROJECT_MODE.DAYS}
                />
              </Marker>
            ))}
          {isAreasVisible && (
            <Polygons areas={areas} mapCenterPoint={mapCenterPoint} />
          )}
          <Lasso
            isLassoActive={isLassoActive}
            onLassoFinished={handleFinishLasso}
          />
          {isDayBasedPolygonsVisible && (
            <DayBasedPolygons
              data={mapRoutePoints}
              projectMode={PROJECT_MODE.DAYS}
            />
          )}
        </MapUI>
        {expanded && <ExpandedMapPopup projectMode={PROJECT_MODE.DAYS} />}
        {expanded && <TableFooter inMap />}
        <Footer expanded={expanded} />
      </>
    );
  }

  return null;
};

export default Map;
