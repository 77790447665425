import React, { useCallback } from "react";
import { Polyline } from "react-leaflet";
import { useSelector } from "react-redux";

import { convertMapPointsToPolyline } from "shared/helpers";
import { DAYS_OF_WEEK_COLOR, DAYS_OF_WEEK_RU } from "shared/config";


const RouteLines = ({ mapRoutePoints = [] }) => {
  const { frontendFilters: { daysOfWeek, routeCodes }, isMapRoutePointsVisible } = useSelector(
    (state) => state.rootReducer.days
  );
  const days = daysOfWeek.length !== 0 ? daysOfWeek : [...Object.values(DAYS_OF_WEEK_RU), "N/A"];

  const getDayColor = useCallback((day) => {
    switch (day) {
      case DAYS_OF_WEEK_RU.MONDAY: return DAYS_OF_WEEK_COLOR.MONDAY
      case DAYS_OF_WEEK_RU.TUESDAY: return DAYS_OF_WEEK_COLOR.TUESDAY
      case DAYS_OF_WEEK_RU.WEDNESDAY: return DAYS_OF_WEEK_COLOR.WEDNESDAY
      case DAYS_OF_WEEK_RU.THURSDAY: return DAYS_OF_WEEK_COLOR.THURSDAY
      case DAYS_OF_WEEK_RU.FRIDAY: return DAYS_OF_WEEK_COLOR.FRIDAY
      case DAYS_OF_WEEK_RU.SATURDAY: return DAYS_OF_WEEK_COLOR.SATURDAY
      case DAYS_OF_WEEK_RU.SUNDAY: return DAYS_OF_WEEK_COLOR.SUNDAY
      default: return '#000'
    }
  }, [])

  const getPolylinePositions = useCallback(
    (day, route) => {
      if (mapRoutePoints.length === 0) return [];
      const filteredMapRoutePoints = mapRoutePoints?.filter(
        (point) => point.dayWeek === day && point.routeName === route
      );
      if (filteredMapRoutePoints.length === 0) return [];
      return convertMapPointsToPolyline(filteredMapRoutePoints);
    },
    [daysOfWeek, routeCodes]
  );

  if (!isMapRoutePointsVisible || mapRoutePoints.length === 0) return null;

  return days.map((day) =>
    routeCodes.map((route) => (
      <Polyline
        key={`polyline-${day}-${route}`}
        positions={getPolylinePositions(day, route)}
        pathOptions={{
          color: getDayColor(day)
        }}
      />
    ))
  );
};

export default RouteLines;
