import {
  AreasPage,
  CoordinatesPage,
  DaysPage,
  ImportExcelDataPage,
  LogisticCoordinatesPage,
  ProjectsPage,
  RoutesPage,
  ShedulePage,
} from "pages";
import { APP_ROUTES } from "shared/config";

export const AUTH_PAGES = [
  {
    id: 0,
    path: APP_ROUTES.PROJECTS,
    element: <ProjectsPage />,
  },
  {
    id: 1,
    path: APP_ROUTES.ROUTES,
    element: <RoutesPage />,
  },
  {
    id: 2,
    path: APP_ROUTES.DAYS,
    element: <DaysPage />,
  },
  {
    id: 3,
    path: APP_ROUTES.COORDINATES,
    element: <CoordinatesPage />,
  },
  {
    id: 4,
    path: APP_ROUTES.LOGISTIC_COORDINATES,
    element: <LogisticCoordinatesPage />,
  },
  {
    id: 5,
    path: APP_ROUTES.AREAS,
    element: <AreasPage />,
  },
  {
    id: 6,
    path: APP_ROUTES.SHEDULE,
    element: <ShedulePage />,
  },
  {
    id: 6,
    path: APP_ROUTES.IMPORT_EXCEL_DATA,
    element: <ImportExcelDataPage />,
  },
];
