import Lottie from "react-lottie";
import styled from "styled-components";

import { defaultOptions } from "./config";

const Loader = ({ isLoading }) => (
  <Wrapper isLoading={isLoading}>
    <Lottie options={defaultOptions} height={200} width={200} />
  </Wrapper>
);

const Wrapper = styled.div`
  display: ${({ isLoading }) => (isLoading ? "block" : "none")};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10000;
  pointer-events: none;
`;

export default Loader;
