import { Actions } from "app/assets/icons";
import React from "react";
import styled from "styled-components";

const Toggle = React.forwardRef(({ onClick }, ref) => (
  <Root
    ref={ref}
    onClick={(e) => {
      e.stopPropagation();
      onClick(e);
    }}
  >
    <Actions />
  </Root>
));

const Root = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

export default Toggle;
