import { IndividualMarker } from "app/assets/icons";
import { CYCLES_RU } from "shared/config";
import { beautifyDeliveryDays } from "shared/helpers";
import { Flex, Text } from "shared/ui";

import { EditArea, Report } from "../model";

export const configColumns = [
  {
    id: "0",
    Header: "Зона, филиал",
    accessor: ({ branch, name, coordinates }) => (
      <Flex>
        <Flex flexDirection="row" alignItems="center">
          <Flex mr="4px">
            <Text>{name}</Text>
          </Flex>
          {!coordinates?.length && (
            <Flex>
              <IndividualMarker width={24} height={24} />
            </Flex>
          )}
        </Flex>
        <Text color="color7">{branch.branchName}</Text>
      </Flex>
    ),
  },
  {
    id: "1",
    Header: "Заказы",
    accessor: "orderCount",
    textAlign: "center",
  },
  {
    id: "2",
    Header: "Точки",
    accessor: "activePointCount",
    textAlign: "center",
  },
  {
    id: "3",
    Header: "Продажи (наценка), т.р.",
    accessor: "margin",
    textAlign: "center",
  },
  {
    id: "4",
    Header: "Объём, м3",
    accessor: "volume",
    textAlign: "center",
  },
  {
    id: "5",
    Header: "Вес, кг",
    accessor: "weight",
    textAlign: "center",
  },
  {
    id: "6",
    Header: "День недели",
    accessor: (data) => (
      <Text variant="body1">{beautifyDeliveryDays(data.deliveryDays)}</Text>
    ),
    textAlign: "center",
  },
  {
    id: "7",
    Header: "Цикличность",
    accessor: ({ cyclePoint }) => <Text>{CYCLES_RU[cyclePoint]}</Text>,
    textAlign: "center",
  },
  {
    id: "8",
    Header: "Действия",
    accessor: (data) => (
      <Flex
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Report area={data} />
        <EditArea area={data} />
      </Flex>
    ),
    textAlign: "center",
  },
];
