/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { DISTANCE_DIFFERENCE, SORT } from "shared/config";

const resetStateByFilters = (state) => {
  state.selectedPoints = [];
  state.frontendFilters.limit = 25;
  state.mapCenterPoint = {};
  state.highlightedPointId = null;
  state.changeCoordsFormData = {
    isVisible: false,
    point: {},
    newPoint: {},
  };
  state.allPointsChecked = false;
};

const initialState = {
  frontendFilters: {
    page: 0,
    limit: 25,
    managerCodes: [],
    commands: [],
    routeCodes: [],
    routeIds: [],
    address: "",
    distance: DISTANCE_DIFFERENCE.EMPTY,
    sort: SORT.ROUTE,
    daysOfWeek: [],
  },
  selectedPoints: [],
  mapCenterPoint: {},
  highlightedPointId: null,
  isMapPointsVisible: true,
  isAreasVisible: true,
  changeCoordsFormData: {
    isVisible: false,
    point: {},
    newPoint: {},
  },
  allPointsChecked: false,
};

const coordinates = createSlice({
  name: "coordinates",
  initialState,
  reducers: {
    resetState: () => initialState,
    increaseLimitFilter: (state) => {
      state.frontendFilters.limit += 10;
    },
    setManagerFilter: (state, action) => {
      state.frontendFilters.routeCodes = [];
      state.frontendFilters.routeIds = [];
      resetStateByFilters(state);
      if (
        state.frontendFilters.managerCodes.includes(action.payload.fullNameCode)
      ) {
        state.frontendFilters.managerCodes =
          state.frontendFilters.managerCodes.filter(
            (item) => item !== action.payload.fullNameCode,
          );
      } else {
        state.frontendFilters.managerCodes = [
          ...state.frontendFilters.managerCodes,
          action.payload.fullNameCode,
        ];
      }
    },
    resetManagerFilter: (state) => {
      state.frontendFilters.managerCodes = [];
      resetStateByFilters(state);
    },
    setCommandFilter: (state, action) => {
      state.frontendFilters.routeCodes = [];
      state.frontendFilters.routeIds = [];
      resetStateByFilters(state);
      if (state.frontendFilters.commands.includes(action.payload)) {
        state.frontendFilters.commands = state.frontendFilters.commands.filter(
          (item) => item !== action.payload,
        );
      } else {
        state.frontendFilters.commands = [
          ...state.frontendFilters.commands,
          action.payload,
        ];
      }
    },
    resetCommandFilter: (state) => {
      state.frontendFilters.commands = [];
      resetStateByFilters(state);
    },
    setRouteFilter: (state, action) => {
      resetStateByFilters(state);
      if (state.frontendFilters.routeCodes.includes(action.payload.routeCode)) {
        state.frontendFilters.routeCodes =
          state.frontendFilters.routeCodes.filter(
            (item) => item !== action.payload.routeCode,
          );
        state.frontendFilters.routeIds = state.frontendFilters.routeIds.filter(
          (item) => item !== action.payload.routeId,
        );
      } else {
        state.frontendFilters.routeCodes = [
          ...state.frontendFilters.routeCodes,
          action.payload.routeCode,
        ];
        state.frontendFilters.routeIds = [
          ...state.frontendFilters.routeIds,
          action.payload.routeId,
        ];
      }
    },
    resetRouteFilter: (state) => {
      resetStateByFilters(state);
      state.frontendFilters.routeCodes = [];
      state.frontendFilters.routeIds = [];
    },
    setAddressFilter: (state, action) => {
      state.frontendFilters.address = action.payload;
    },
    setDaysFilter: (state, action) => {
      resetStateByFilters(state);
      state.frontendFilters.daysOfWeek = action.payload;
    },
    setDistanceFilter: (state, action) => {
      resetStateByFilters(state);
      state.frontendFilters.distance = action.payload;
    },
    setSortFilter: (state, action) => {
      if (action.payload !== state.frontendFilters.sort) {
        resetStateByFilters(state);
        state.frontendFilters.sort = action.payload;
      }
    },
    setSelectedPoints: (state, action) => {
      if (state.selectedPoints.includes(action.payload)) {
        state.selectedPoints = state.selectedPoints.filter(
          (item) => item !== action.payload,
        );
      } else {
        state.selectedPoints = [...state.selectedPoints, action.payload];
      }
    },
    setSelectedByLassoRoutePoints: (state, action) => {
      if (action.payload instanceof Array) {
        state.selectedPoints = [
          ...new Set([...state.selectedPoints, ...action.payload]),
        ];
      } else if (!state.selectedPoints.includes(action.payload)) {
        state.selectedPoints = [...state.selectedPoints, action.payload];
      }
    },
    resetSelectedPoints: (state) => {
      state.selectedPoints = [];
    },
    setMapCenterPoint: (state, action) => {
      state.mapCenterPoint = action.payload;
    },
    setPointFoundByTable: (state, action) => {
      state.mapCenterPoint = {
        latitude: action.payload.latitude,
        longitude: action.payload.longitude,
      };
      state.highlightedPointId = action.payload.pointId;
    },
    setPointFoundByMarker: (state, action) => {
      state.frontendFilters.address = action.payload.pointCode;
      state.highlightedPointId = action.payload.highlightedPointId;
    },
    switchMapPointsVisible: (state) => {
      state.isMapPointsVisible = !state.isMapPointsVisible;
    },
    switchAreasVisible: (state) => {
      state.isAreasVisible = !state.isAreasVisible;
    },
    setChangeCoordsFormData: (state, action) => {
      state.changeCoordsFormData = action.payload;
    },
    setChangeCoordsFormDataNewPoint: (state, action) => {
      state.changeCoordsFormData.newPoint = action.payload;
    },
    setAllPointsChecked: (state, action) => {
      state.allPointsChecked = action.payload;
    },
  },
});

export const {
  resetState,
  increaseLimitFilter,
  setManagerFilter,
  resetManagerFilter,
  setCommandFilter,
  resetCommandFilter,
  setRouteFilter,
  resetRouteFilter,
  setAddressFilter,
  setDistanceFilter,
  setDaysFilter,
  setSortFilter,
  setSelectedPoints,
  setSelectedByLassoRoutePoints,
  resetSelectedPoints,
  setMapCenterPoint,
  setPointFoundByTable,
  setPointFoundByMarker,
  switchMapPointsVisible,
  switchAreasVisible,
  setChangeCoordsFormData,
  setChangeCoordsFormDataNewPoint,
  setAllPointsChecked,
} = coordinates.actions;

export default coordinates.reducer;
