import React from "react";
import styled from "styled-components";

const PinkButton = ({
  disabled = false,
  loading = false,
  onClick = () => {},
  children,
  ...props
}) => (
  <StyledButton
    type="button"
    disabled={disabled || loading}
    onClick={onClick}
    {...props}
  >
    <span>{children || "Удалить"}</span>
  </StyledButton>
);

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  background-color: #ffe5e5;
  border-radius: 12px;
  border: 0;
  padding: 12px 16px;
  box-sizing: border-box;
  transition: 0.3s;
  cursor: pointer;

  span {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #ff9494;
  }

  &:disabled {
    opacity: 0.3;
  }
`;

export default PinkButton;
