import { resetSelectedRoutePoints } from "app/store/reducer/days";
import { PopupMarker, PopupMarkerChecked } from "icons/icons";
import React from "react";
import { useDispatch } from "react-redux";
import { beautifyWeekDay } from "shared/helpers";
import { Flex, Text } from "shared/ui";
import styled from "styled-components";
import { DeliveryTermsReport } from "widgets";

const Single = ({
  mapRoutePoint,
  markerColor,
  duplicatesSelectedRoutePoints,
}) => {
  const dispatch = useDispatch();

  return (
    <Flex width="290px" mr="20px" overflowY="scroll">
      <Flex mb="4px">
        <Text variant="body3bold">{mapRoutePoint.pointCode}</Text>
      </Flex>
      <Flex mb="4px">
        <Text variant="body3">{mapRoutePoint.pointName}</Text>
      </Flex>
      <Flex mb="10px">
        <Text variant="body3">{mapRoutePoint.address}</Text>
      </Flex>
      <>
        <Flex flexDirection="row" mb="10px">
          <Flex>
            <Text variant="body3">
              {beautifyWeekDay(mapRoutePoint.dayWeek) || "-"}
            </Text>
          </Flex>
          <Flex ml="8px">
            <Text variant="body3" color={markerColor}>
              {mapRoutePoint.routeName}
            </Text>
          </Flex>
        </Flex>
        <Flex mb="10px">
          <Text variant="body3" color="color3">
            {mapRoutePoint.attributeCode1}, {mapRoutePoint.attributeCode2}
          </Text>
        </Flex>
        <Flex mb="10px">
          <Text variant="body3" color="color3">
            {`ТО: ${mapRoutePoint.additionallyTable1}, ПР: ${mapRoutePoint.additionallyTable2}, %: ${mapRoutePoint.additionallyTable3}`}
          </Text>
        </Flex>
      </>
      <Flex mb="4px">
        <Text variant="body3">Разница {mapRoutePoint.distance}</Text>
      </Flex>
      <DeliveryTermsReport
        routeId={mapRoutePoint.routeId}
        pointId={mapRoutePoint.pointId}
      />
      <TipWrapper color={markerColor}>
        <Flex
          width="44px"
          height="44px"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
        >
          {duplicatesSelectedRoutePoints.length > 0 ? (
            <PopupMarkerChecked
              color={markerColor || "#000"}
              onClick={() => {
                dispatch(resetSelectedRoutePoints());
              }}
            />
          ) : (
            <PopupMarker color={markerColor || "#000"} />
          )}
        </Flex>
      </TipWrapper>
    </Flex>
  );
};

const TipWrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: -68px;
  left: calc(50% - 34px);
  width: 68px;
  height: 68px;
  background-color: #ffffff;
  border: 2px solid ${(p) => p.color};
  border-top: none;
  border-radius: 0 0 20px 20px;
`;

export default Single;
