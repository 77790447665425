export const configColumns = [
  {
    id: "0",
    Header: "Неделя",
    accessor: "WEEK_NUMBER",
    w: "10%",
    textAlign: "center",
  },
  {
    id: "1",
    Header: "Визитов",
    accessor: "VISIT",
    w: "10%",
    textAlign: "center",
  },
  {
    id: "2",
    Header: "Время в ТТ, мин",
    accessor: "TIME_IN_POINT",
    w: "20%",
    textAlign: "center",
  },
  {
    id: "3",
    Header: "Время в пути, мин",
    accessor: "TRAVEL_TIME",
    w: "20%",
    textAlign: "center",
  },
  {
    id: "4",
    Header: "Пробег, км",
    accessor: "DISTANCE",
    w: "15%",
    textAlign: "center",
  },
  {
    id: "5",
    Header: "ТО",
    accessor: "TO",
    w: "10%",
    textAlign: "center",
  },
  {
    id: "6",
    Header: "ПР",
    accessor: "PR",
    w: "15%",
    textAlign: "center",
  },
];
