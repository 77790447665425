import { useContext } from "react";
import { Checkbox, Flex, Text, TextButton } from "shared/ui";

import GroupsContext from "../../context";
import { ChooseGroups, SelectGroups } from "./ui";

const GroupsCell = ({ data }) => {
  const {
    selectedObject,
    selectedBranches,
    editedBranches,
    onAllContractsCheckboxClick,
    setSelectedBranches,
    setEditedBranches,
  } = useContext(GroupsContext);
  const isBranchSelected = selectedBranches.includes(data?.branchId);
  const isBranchEdited = editedBranches.includes(data?.branchId);
  const isBranchHasContractsWithoutGroup =
    data?.contractCodesWithoutGroup.length;
  const isBranchHasContracts = data?.contractGroups.length;

  const selectBranch = () => {
    setSelectedBranches([...selectedBranches, data?.branchId]);
  };

  const editBranch = () => {
    setSelectedBranches(
      selectedBranches.filter((item) => item !== data?.branchId),
    );
    setEditedBranches([...editedBranches, data?.branchId]);
  };

  if (isBranchEdited) {
    return (
      <ChooseGroups
        branchId={data?.branchId}
        defaultGroups={data?.contractGroups}
        defaultContracts={data?.contractCodesWithoutGroup}
      />
    );
  }

  if (isBranchSelected) {
    return (
      <SelectGroups
        branchCode={data.branchCode}
        groups={data.contractGroups}
        editBranch={editBranch}
      />
    );
  }

  if (isBranchHasContractsWithoutGroup) {
    return (
      <TextButton onClick={() => editBranch()}>
        <Text variant="body1" color="color0">
          Распределить группы контрактов
        </Text>
      </TextButton>
    );
  }

  if (isBranchHasContracts) {
    return (
      <Flex flexDirection="row" alignItems="center">
        <Flex flexDirection="row" alignItems="center" mr="40px">
          <Flex mr="7px">
            <Checkbox
              checked={
                selectedObject[data.branchCode]?.length ===
                data.contractGroups?.length
              }
              onChange={(e) =>
                onAllContractsCheckboxClick(e.target.checked, data)
              }
            />
          </Flex>
          <Text>Выбрать все группы контрактов</Text>
        </Flex>
        <Flex>
          <TextButton onClick={() => selectBranch()}>
            {selectedObject[data.branchCode]?.length > 0 ? (
              <Text variant="body1" color="color0">
                Выбрано {selectedObject[data.branchCode]?.length} из{" "}
                {data.contractGroups.length}
              </Text>
            ) : (
              <Text variant="body1" color="color0">
                Выбрать группы
              </Text>
            )}
          </TextButton>
        </Flex>
      </Flex>
    );
  }

  return null;
};

export default GroupsCell;
