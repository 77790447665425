import { useEffect } from "react";
import { useMap } from "react-leaflet";

const RememberBounds = () => {
  const map = useMap();

  useEffect(() => {
    const position = {
      lat: sessionStorage.getItem("mapLat"),
      lng: sessionStorage.getItem("mapLng"),
    };
    const zoom = sessionStorage.getItem("mapZoom");
    if (zoom) {
      map.setView(position, zoom);
    }
  }, []);

  useEffect(
    () => () => {
      sessionStorage.setItem("mapLat", map.getCenter().lat);
      sessionStorage.setItem("mapLng", map.getCenter().lng);
      sessionStorage.setItem("mapZoom", map.getZoom());
    },
    [map],
  );

  return null;
};

export default RememberBounds;
