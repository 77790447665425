import { Eye, EyeCrossed } from "app/assets/icons";
import Clustering from "app/assets/icons/Clustering";
import Lasso from "app/assets/icons/Lasso";
import L from "leaflet";
import { MapContainer, TileLayer } from "react-leaflet";
import styled, { css } from "styled-components";

import {
  CollapseIcon,
  DayBasedPolygonIcon,
  ExpandIcon,
} from "../../../icons/icons";
import { DEFAULT_BOUNDS } from "../../config";

const Map = ({
  isMapPointsVisible = true,
  switchMapPointsVisible = () => {},
  enableLasso = false,
  isLassoActive = false,
  switchIsLassoActive = () => {},
  expanded = false,
  switchMapExpand = () => {},
  enableClusteringDisableButton = true,
  clustering = true,
  switchClustering = () => {},
  enableDayBasedPolygons = false,
  dayBasedPolygonsVisible = false,
  switchDayBasedPolygonsVisibility = () => {},
  children,
}) => (
  <Root expanded={expanded}>
    <MapContainer
      crs={L.CRS.EPSG3395}
      preferCanvas
      scrollWheelZoom
      style={{ height: "100%", width: "100%" }}
      zoomControl={false}
      bounds={DEFAULT_BOUNDS}
    >
      <TileLayer
        url="https://core-renderer-tiles.maps.yandex.net/tiles?l=map&v=21.06.08-0-b210520094930&x={x}&y={y}&z={z}&scale=1&lang=ru_RU&apikey=6e1f65dd-d560-42e8-be80-3237dad79e41"
        attribution='<a http="yandex.ru" target="_blank">Яндекс</a>'
        reuseTiles
        updateWhenIdle={false}
      />
      {children}
      <Controls expanded={expanded}>
        <ControlButton
          bgColor="#2d8af6"
          onClick={() => switchMapPointsVisible()}
        >
          {isMapPointsVisible ? <EyeCrossed /> : <Eye />}
          <ControlButtonHint bgColor="#2d8af6">
            {isMapPointsVisible ? "Скрыть точки" : "Показать точки"}
          </ControlButtonHint>
        </ControlButton>
        {enableClusteringDisableButton && (
          <ControlButton
            bgColor={!clustering ? "#1562b9" : "#2d8af6"}
            onClick={() => switchClustering()}
          >
            <Clustering />
            <ControlButtonHint bgColor={!clustering ? "#1562b9" : "#2d8af6"}>
              {clustering
                ? "Отключить кластеризацию"
                : "Включить кластеризацию"}
            </ControlButtonHint>
          </ControlButton>
        )}
        {enableLasso && (
          <ControlButton
            onClick={switchIsLassoActive}
            bgColor={isLassoActive ? "#1562b9" : "#2d8af6"}
          >
            <Lasso />
            <ControlButtonHint bgColor={isLassoActive ? "#1562b9" : "#2d8af6"}>
              Лассо для выделения точек
            </ControlButtonHint>
          </ControlButton>
        )}
        {enableDayBasedPolygons && (
          <ControlButton
            bgColor={dayBasedPolygonsVisible ? "#1562b9" : "#2d8af6"}
            onClick={switchDayBasedPolygonsVisibility}
          >
            <DayBasedPolygonIcon />
            <ControlButtonHint
              bgColor={dayBasedPolygonsVisible ? "#1562b9" : "#2d8af6"}
            >
              Обводка границ дней / маршрутов
            </ControlButtonHint>
          </ControlButton>
        )}
        <ControlButton bgColor="#595959" onClick={switchMapExpand}>
          {expanded ? (
            <CollapseIcon color="white" />
          ) : (
            <ExpandIcon color="white" />
          )}

          <ControlButtonHint bgColor="#595959">
            {expanded ? "Свернуть карту" : "Развернуть карту"}
          </ControlButtonHint>
        </ControlButton>
      </Controls>
    </MapContainer>
  </Root>
);

const Root = styled.div`
  width: 100%;
  height: 100%;

  .leaflet-popup.leaflet-zoom-animated {
    bottom: 18px !important;
  }

  .leaflet-popup-content {
    margin: -2px;
    width: auto !important;
    max-height: 200px;
  }

  .leaflet-popup-content p {
    margin: 0;
  }

  .leaflet-popup-content-wrapper {
    border-radius: 20px;
  }

  .leaflet-popup-tip-container {
    display: none;
  }

  ${({ expanded }) =>
    expanded &&
    css`
      .leaflet-popup-pane {
        display: none;
      }
    `}
  .leaflet-container a.leaflet-popup-close-button {
    top: 16px;
    right: 16px;
  }

  .leaflet-map-pane svg {
    z-index: 0;
  }

  .custom-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 44px !important; //TODO: make wider
    height: 56px !important;
    margin-top: -34px !important;
    margin-left: -28px !important;
  }

  .leaflet-marker-icon.leaflet-div-icon.leaflet-editing-icon.leaflet-touch-icon.leaflet-zoom-animated.leaflet-interactive {
    background: #fff;
    border: 1px solid #666;
  }

  .leaflet-marker-icon.leaflet-div-icon.leaflet-zoom-animated.leaflet-interactive {
    border: 0;
    background: none;
  }
`;

const Controls = styled.div`
  position: relative;
  margin-top: ${({ expanded }) => (expanded ? "166px" : "10px")};
  margin-left: 10px;
  display: flex;
  width: 30px;
  flex-direction: column;
  grid-row-gap: 8px;
  z-index: 1000;
`;

const ControlButton = styled.div`
  background-color: ${({ bgColor }) => bgColor};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 4px;
  cursor: pointer;
  position: relative;

  :hover span {
    clip: initial;
    opacity: 1;
    visibility: visible;
    transition: opacity 0.2s 0.5s
  }
`;

const ControlButtonHint = styled.span`
  width: auto;
  height: auto;
  position: absolute;
  font-size: 12px;
  white-space: nowrap;
  border: 1px solid ${({ bgColor }) => bgColor};
  background: white;
  color: black;
  line-height: normal;
  top: 50%;
  left: 100%;
  transform: translate(8px, -50%);
  padding: 3px 8px;
  border-radius: 4px;
  display: inline-block;
  opacity: 0;
  visibility: hidden;
  z-index: 99999;

  ::before {
    content: "";
    left: -10px;
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
    z-index: 1;
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 5px solid transparent;
    border-right: 5px solid ${({ bgColor }) => bgColor};
  }
`;

export default Map;
