import { SORT } from "shared/config";
import { beautifyWeekDay } from "shared/helpers";
import { Text } from "shared/ui";

import { HeaderCellSort, PointCell, RouteCell } from "../model";

export const configColumns = [
  {
    id: "0",
    Header: (
      <HeaderCellSort
        text="Маршрут"
        sortBy={SORT.ROUTE}
        withAllPointsCheckbox
      />
    ),
    accessor: (data) => <RouteCell data={data} />,
  },
  {
    id: "1",
    Header: <HeaderCellSort text="Точка" sortBy={SORT.POINT} />,
    accessor: (data) => <PointCell data={data} />,
    style: {
      width: '100%'
    }
  },
  {
    id: "2",
    Header: "Дни недели",
    accessor: (data) => <Text>{data?.daysOfWeek?.map((day) => beautifyWeekDay(day)).join()}</Text>
  },
  {
    id: "3",
    Header: "Текущие координаты",
    accessor: "position",
  },
  {
    id: "4",
    Header: "Расчётные координаты",
    accessor: "sprPosition",
  },
  {
    id: "5",
    Header: <HeaderCellSort text="Разница" sortBy={SORT.DISTANCE} />,
    accessor: "distance",
  },
];
