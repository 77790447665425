import { useTable } from "react-table";
import styled from "styled-components";

const Table = ({ data, columns }) => {
  const tableInstance = useTable({
    data,
    columns,
  });

  const { getTableProps, getTableBodyProps, rows, prepareRow } = tableInstance;

  return (
    <TableWrapper>
      <TableContent {...getTableProps()}>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <TableDataRow {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <TableDataCell {...cell.getCellProps()}>
                    {cell.render("Cell")}
                  </TableDataCell>
                ))}
              </TableDataRow>
            );
          })}
        </tbody>
      </TableContent>
    </TableWrapper>
  );
};

const TableWrapper = styled.div`
  display: block;
  max-height: 60vh;
  overflow-y: scroll;
  overflow-x: hidden;
  width: 100%;
  margin-bottom: 24px;
`;

const TableContent = styled.table`
  border-collapse: collapse;
  width: 100%;
`;

const TableDataRow = styled.tr`
  border-bottom: 1px solid #cccccc;
`;

const TableDataCell = styled.td`
  padding: 20px 24px 20px 8px;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
  vertical-align: top;
`;

export default Table;
