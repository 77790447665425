export const handleDragStart = (e, dragFrom, contract, groupIdFrom) => {
  if (dragFrom === "group") {
    e.dataTransfer.setData("groupIdFrom", groupIdFrom);
  }
  e.dataTransfer.setData("dragFrom", dragFrom);
  e.dataTransfer.setData("contract", contract);
};

export const handleDragOver = (e) => {
  e.preventDefault();
  e.stopPropagation();
};
