export const mergeRouteDuplicates = (arr) => {
  const temp = arr.reduce((acc, obj) => {
    if (!acc[obj.routeCode]) {
      acc[obj.routeCode] = obj;
    } else {
      acc[obj.routeCode] = Object.assign(acc[obj.routeCode], obj);
    }
    return acc;
  }, {});

  return Object.values(temp).map((obj, index) => ({
    ...obj,
    rowNumber: index + 1,
  }));
};

export const mergePointDuplicates = (arr) => {
  const temp = arr.reduce((acc, obj) => {
    if (!acc[obj.pointCode]) {
      acc[obj.pointCode] = obj;
    } else {
      acc[obj.pointCode] = Object.assign(acc[obj.pointCode], obj);
    }
    return acc;
  }, {});

  return Object.values(temp).map((obj, index) => ({
    ...obj,
    rowNumber: index + 1,
  }));
};
