import { Cross } from "app/assets/icons";
import { resetExpandedMapPopupState } from "app/store/reducer/expanded-map-popup";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { FULLSCREEN_POPUP_HEIGHT } from "shared/config";
import { checkMarkerColor } from "shared/helpers";
import styled from "styled-components";

import AreaInfo from "./area-info";
import PointInfo from "./point-info";

const ExpandedMapMarkerPopup = ({ projectMode }) => {
  const dispatch = useDispatch();
  const { projectInfo } = useSelector(
    (state) => state.rootReducer.currentProject,
  );
  const { mapRoutePoint, area = {} } = useSelector(
    (state) => state.rootReducer.expandedMapPopup,
  );

  if (area?.id)
    return (
      <FixedPopup>
        <Root color={area?.color?.hexCode}>
          <AreaInfo area={area} />
          <CloseButton onClick={() => dispatch(resetExpandedMapPopupState())}>
            <Cross />
          </CloseButton>
        </Root>
      </FixedPopup>
    );

  if (!mapRoutePoint) return null;
  const markerColor = checkMarkerColor(undefined, mapRoutePoint, projectMode);

  return (
    <FixedPopup>
      <Root color={markerColor}>
        <PointInfo
          projectId={projectInfo.id}
          mapRoutePoint={mapRoutePoint}
          markerColor={markerColor}
        />
        <CloseButton onClick={() => dispatch(resetExpandedMapPopupState())}>
          <Cross />
        </CloseButton>
      </Root>
    </FixedPopup>
  );
};

const FixedPopup = styled.div`
  position: fixed;
  left: 60px;
  top: 165px;
  z-index: 800;
  background: white;
  height: ${FULLSCREEN_POPUP_HEIGHT};
  border-radius: 20px;
`;

const Root = styled.div`
  display: flex;
  height: ${FULLSCREEN_POPUP_HEIGHT};
  position: relative;
  border: 2px solid ${(p) => p.color};
  border-radius: 20px;
  padding: 16px;
`;

const CloseButton = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
`;

export default ExpandedMapMarkerPopup;
