import {
  setAttributeFilter,
  resetAttributeFilter,
} from "app/store/reducer/routes";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PERIODICITY_NEW } from "shared/config";
import { Checkbox, Flex, SearchDropdown, Text } from "shared/ui";

const PeriodicityDropdown = () => {
  const dispatch = useDispatch();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const { frontendFilters: { periodicityList } } = useSelector((state) => state.rootReducer.routes);

  const filters = PERIODICITY_NEW;

  function onPeriodSelect(period) {
    dispatch(setAttributeFilter({ attribute: 'periodicityList',newItem: period }));
  }

  const onSearch = (value) => {
    setSearchValue(value);
  };

  const onToggle = (isOpen) => {
    if (isOpen === false) onSearch("");
    setIsDropdownOpen((prev) => !prev);
  };

  const onResetSearch = () => {
    onSearch("");
    setIsDropdownOpen(false);
  };

  const onResetAllCheckboxes = () => {
    dispatch(resetAttributeFilter('periodicityList'));
    setIsDropdownOpen(false);
  };

  return (
    <SearchDropdown
      isDropdownOpen={isDropdownOpen}
      toggleText={
        periodicityList?.length
          ? `Выбрано ${periodicityList?.length}`
          : "Выбрать"
      }
      searchValue={searchValue}
      onToggle={onToggle}
      onSearch={onSearch}
      onResetSearch={onResetSearch}
      onResetAllCheckboxes={onResetAllCheckboxes}
      multiselect
      selectedText
      selectedCount={periodicityList?.length}
    >
      {filters.map(
        (period) =>
          period.label.toLowerCase().includes(searchValue.toLowerCase()) && (
            <SearchDropdown.Item key={period.value}>
              <Checkbox
                filledColor="#000000"
                emptyColor="#787878"
                checked={periodicityList?.includes(
                  period.value,
                )}
                onChange={() => onPeriodSelect(period.value)}
              />
              <Flex ml="7px">
                <Text variant="body1">{period.label}</Text>
              </Flex>
            </SearchDropdown.Item>
          ),
      )}
    </SearchDropdown>
  );
};

export default PeriodicityDropdown
