import { crossIcon, searchIcon } from "icons/icons";
import styled from "styled-components";

const Search = ({ value = "", onChange = () => {}, onReset = () => {} }) => (
  <InputWrapper>
    <SearchIconWrapper>{searchIcon}</SearchIconWrapper>
    <Input value={value} onChange={onChange} />
    <CrossIconWrapper onClick={onReset && onReset}>
      {crossIcon}
    </CrossIconWrapper>
  </InputWrapper>
);

const InputWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 48px;
`;

const SearchIconWrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
  top: 17px;
  left: 10px;
  cursor: pointer;
`;

const Input = styled.input`
  width: 100%;
  height: 48px;
  font-size: 18px;
  color: #000000;
  border: 1px solid #cccccc;
  padding: 0px 30px 0px 30px;
  box-sizing: border-box;
  &:focus {
    outline: none;
    border: 2px solid #2d8af6;
  }
`;

const CrossIconWrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 14px;
  height: 14px;
  top: 17px;
  right: 10px;
  cursor: pointer;
`;

export default Search;
