import { ReportProblem as ProblemIcon } from "app/assets/icons";
import { setRouteCellValue } from "app/store/reducer/excel-data";
import React, { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useBlockLayout, useTable } from "react-table";
import { FixedSizeList } from "react-window";
import { Text } from "shared/ui";
import styled, { css } from "styled-components";

import { pointColumns, routeColumns, routePointColumns } from "../../config";
import EditableCell from "../editable-cell";

const getDefaultCellValue = (key, value) => {
  if (value !== undefined) return "";
  if (key === "routeCode" || key === "dayWeek") return "N/A";
  if (key === "order") return "1";
  return "";
};

const Table = ({
  data = [],
  errors = [],
  table,
  checked = false,
  editable = false,
  showJustLinesWithErrors = false,
}) => {
  if (data.length === 0)
    return (
      <TableWrapper>
        <Text>Данных нет</Text>
      </TableWrapper>
    );

  const dispatch = useDispatch();
  const tableData = useMemo(() => {
    if (!showJustLinesWithErrors) return data.map(({ id, ...rest }) => rest);

    const rowNumbersWithErrors = [
      ...new Set(errors.map((item) => item.rowNumber)),
    ];
    return data
      .filter((line) => rowNumbersWithErrors.indexOf(line.rowNumber) !== -1)
      .map(({ id, ...rest }) => rest);
  }, [data, table, errors, showJustLinesWithErrors]);

  const columns = useMemo(() => {
    if (table === "routeList") return routeColumns;
    if (table === "pointList") return pointColumns;
    if (table === "routePointList") return routePointColumns;
    return [];
  }, [data, table]);

  const updateCellValue = useCallback((row, column, value) => {
    dispatch(setRouteCellValue({ row, column, value }));
  }, []);

  const tableInstance = useTable(
    {
      columns,
      data: tableData,
      defaultColumn: editable ? { Cell: EditableCell } : {},
      updateCellValue,
    },
    useBlockLayout,
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    totalColumnsWidth,
  } = tableInstance;

  const RenderRow = React.useCallback(
    ({ index, style }) => {
      const row = rows[index];
      prepareRow(row);
      // eslint-disable-next-line no-param-reassign
      row.errors = errors.filter(
        (err) => err.rowNumber === row.original.rowNumber,
      );
      return (
        <TableRow {...row.getRowProps({ style })}>
          {row.cells.map((cell) => {
            const error = row.errors.find(
              (err) => err.columnName === cell.column.key,
            );

            if (cell.column.key !== "status") {
              return (
                <TableCell {...cell.getCellProps()} error={error}>
                  <TableCellContent>
                    {cell.render("Cell")}
                    {cell.column.table === "route_point" &&
                      getDefaultCellValue(cell.column.key, cell.value)}
                  </TableCellContent>
                  {error && <TableCellHint>{error.text}</TableCellHint>}
                </TableCell>
              );
            }

            return (
              <TableCell {...cell.getCellProps()} className="status">
                {checked && (
                  <ProblemIcon
                    fill={row.errors?.length > 0 ? "#FFB342" : "#4AAB1D"}
                  />
                )}
                {row.errors.length > 0 && (
                  <TableCellHint>
                    {row.errors.map((e) => e.text).join("\n\n")}
                  </TableCellHint>
                )}
              </TableCell>
            );
          })}
        </TableRow>
      );
    },
    [prepareRow, rows],
  );

  return (
    <TableWrapper>
      <TableContainer {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <TableColumnsNamesRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <TableHeadCell {...column.getHeaderProps()}>
                  {column.render("Header")}
                </TableHeadCell>
              ))}
            </TableColumnsNamesRow>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          <FixedSizeList
            height={500}
            itemCount={rows.length}
            itemSize={35}
            width={totalColumnsWidth + 20}
          >
            {RenderRow}
          </FixedSizeList>
        </tbody>
      </TableContainer>
    </TableWrapper>
  );
};

const TableWrapper = styled.div`
  overflow-x: auto;
  overflow-y: hidden;
  width: 100%;
  height: 60vh;

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar {
    width: 4px;
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: #cee3fd;
    width: 4px;
    border-radius: 8px;
  }
`;

const TableContainer = styled.table`
  min-width: 100%;
  white-space: nowrap;
`;

const TableColumnsNamesRow = styled.tr`
  border-bottom: 1px solid #cbcbcb;
`;

const TableHeadCell = styled.th`
  border-right: 1px solid #cbcbcb;
  padding: 4px 8px;

  &:last-child {
    border-right: none;
  }
`;

const TableRow = styled.tr`
  max-height: 80px;
`;

const TableCell = styled.td`
  border-right: 1px solid #cbcbcb;
  padding: 6px 8px;
  max-width: 250px;

  ${({ error }) =>
    error &&
    css`
      :before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        outline: 1px solid #a80000;
      }
    `}
  &.status {
    text-align: center;
  }

  &:last-child {
    border-right: none;
  }

  input {
    font-size: 1rem;
    padding: 0;
    margin: 0;
    border: 0;
    width: 100%;
  }

  position: relative;

  :hover span {
    transition-delay: 0.7s;
    width: auto;
    height: auto;
    clip: initial;
    opacity: 1;
    visibility: visible;
  }
`;

const TableCellContent = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
`;

const TableCellHint = styled.span`
  position: absolute;
  font-size: 12px;
  width: 1px;
  height: 1px;
  clip: rect(0, 0, 0, 0);
  white-space: break-spaces;
  border: 1px solid #a80000;
  background: white;
  color: black;
  line-height: normal;
  top: 100%;
  transform: translateY(-10%);
  left: 10%;
  padding: 3px 8px;
  border-radius: 4px;
  display: inline-block;
  opacity: 0;
  visibility: hidden;
  transition: 0.2s;
  z-index: 99999;

  ::before {
    content: "";
    left: 25%;
    top: 0;
    transform: translateY(-100%);
    position: absolute;
    z-index: 1;
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-bottom: 5px solid #a80000;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
  }
`;

export default Table;
