import { PopupContext } from "app/context/PopupContext";
import { resetSelectedRoutePoints, setAllPointsChecked } from "app/store/reducer/routes";
import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  useChangePointsFrequenciesMutation,
  useListPointsFrequenciesQuery,
} from "shared/api/points";
import { useListMapRoutePointsQuery } from "shared/api/routes";
import { Flex, Popup } from "shared/ui";

import { PointsInfo, SetFrequencyInputs } from "./ui";

function checkEquality(arr, property) {
  if (arr.length === 0) {
    return undefined;
  }
  const initialValue = arr[0][property];
  for (let i = 1; i < arr.length; i += 1) {
    if (arr[i][property] !== initialValue) {
      return undefined;
    }
  }
  return initialValue;
}

const SetFrequency = () => {
  const dispatch = useDispatch();
  const { hidePopup } = useContext(PopupContext);
  const { projectInfo } = useSelector(
    (state) => state.rootReducer.currentProject,
  );
  // get selected point ids
  const { frontendFilters, selectedRoutePoints, allPointsChecked } =
    useSelector((state) => state.rootReducer.routes);
  // get already downloaded points info
  const { data: mapRoutePoints } = useListMapRoutePointsQuery(
    {
      projectId: projectInfo.id,
      managerCodes: frontendFilters?.managerCodes,
      commands: frontendFilters?.commands,
      periodicityList: frontendFilters?.periodicityList,
      routeCodes: frontendFilters?.routeCodes,
      daysOfWeek: frontendFilters?.daysOfWeek,
      attributeCodes1: frontendFilters?.attributeCodes1,
      attributeCodes2: frontendFilters?.attributeCodes2,
    },
    { skip: !projectInfo.id },
  );
  // get options for frequencies select
  const { data: frequenciesOptions, isSuccess } = useListPointsFrequenciesQuery(
    { projectId: projectInfo.id },
  );
  const [setFrequencyAndDuration] = useChangePointsFrequenciesMutation();

  // form
  const {
    register,
    setValue,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    // resolver: yupResolver(SetFrequencySchema),
    defaultValues: {
      visitFrequency: undefined,
      duration: undefined,
    },
  });

  // filter points and save only that were selected (because we only know the ids)
  const [pointsInfo, setPointsInfo] = useState({});
  useEffect(() => {
    if (!mapRoutePoints || !frequenciesOptions) return;
    const points = mapRoutePoints
      .map((p) => ({
        id: p.id,
        duration: p.duration,
        visitFrequency: p.visitFrequency,
        pointId: p.pointId,
      }))
      .filter((p) => allPointsChecked || selectedRoutePoints.includes(p.id));

    const currentFrequency = checkEquality(points, "visitFrequency");
    const currentDuration = checkEquality(points, "duration");

    if (currentFrequency && currentFrequency !== "-") {
      setValue(
        "visitFrequency",
        frequenciesOptions.find((opt) => opt.name === currentFrequency).value,
      );
    } else {
      // разные значения
      setValue("visitFrequency", undefined);
    }
    setValue("duration", currentDuration);

    setPointsInfo({
      currentFrequency,
      currentDuration,
      pointIds: points.map((p) => p.pointId),
    });
  }, [mapRoutePoints, frequenciesOptions]);

  const onSubmit = async (values) => {
    const { visitFrequency, duration } = values;
    if (!visitFrequency && !duration) return;

    const { pointIds } = pointsInfo;
    const data = {
      projectId: projectInfo.id,
      pointIds,
    };
    if (visitFrequency) data.visitFrequency = visitFrequency;
    if (duration) data.duration = duration;

    await setFrequencyAndDuration(data);
    dispatch(
      allPointsChecked
        ? setAllPointsChecked(false)
        : resetSelectedRoutePoints(),
    );
    hidePopup();
  };

  if (isSuccess) {
    return (
      <Popup
        title="Изменить частоту и время"
        isCenter={false}
        positionLeft="500px"
      >
        <Flex flexDirection="column" mt="12px">
          <PointsInfo
            frequency={pointsInfo.currentFrequency}
            duration={pointsInfo.currentDuration}
          />
        </Flex>
        <Flex>
          <SetFrequencyInputs
            frequenciesOptions={frequenciesOptions}
            register={register}
            errors={errors}
            onSubmit={handleSubmit(onSubmit)}
            watch={watch}
          />
        </Flex>
      </Popup>
    );
  }

  return null;
};

export default SetFrequency;
