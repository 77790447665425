import styled from "styled-components";

const Item = ({ isSelected = false, onClick = () => {}, children }) => (
  <Root onClick={onClick} isSelected={isSelected}>
    {children}
  </Root>
);

const Root = styled.label`
  display: flex;
  align-items: center;
  padding: 10px;
  margin: 0;
  cursor: pointer;
  word-break: break-all;
  ${({ isSelected }) => isSelected && "background: #ECF4FE;"}

  &:hover {
    background: #ecf4fe;
  }
`;

export default Item;
