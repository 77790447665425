export const routePointColumns = [
  // {
  //   id: 100,
  //   table: "route_point",
  //   value: "routeCode",
  //   label: "Маршрут.Код"
  // },
  // {
  //   id: 101,
  //   table: "route_point",
  //   value: "pointCode",
  //   label: "Точка.Код"
  // },
  {
    id: 102,
    table: "route_point",
    value: "dayWeek",
    label: "Визиты.День",
  },
  {
    id: 103,
    table: "route_point",
    value: "cycle",
    label: "Визиты.Цикл",
  },
  {
    id: 104,
    table: "route_point",
    value: "order",
    label: "Визиты.Порядок",
  },
  // {
  //   id: 105,
  //   table: "route_point",
  //   value: "command",
  //   label: "Точка.Команда"
  // },
];

export const pointColumns = [
  {
    id: 200,
    table: "point",
    value: "pointCode",
    label: "Точка.Код",
  },
  {
    id: 201,
    table: "point",
    value: "pointName",
    label: "Точка.Наименование",
  },
  {
    id: 202,
    table: "point",
    value: "address",
    label: "Точка.Адрес",
  },
  {
    id: 203,
    table: "point",
    value: "longitude",
    label: "Точка.Долгота",
  },
  {
    id: 204,
    table: "point",
    value: "latitude",
    label: "Точка.Широта",
  },
  {
    id: 205,
    table: "point",
    value: "duration",
    label: "Точка.ВремяВизита",
  },
  {
    id: 206,
    table: "point",
    value: "visitFrequency",
    label: "Точка.ЧастотаВизита",
  },
  {
    id: 207,
    table: "point",
    value: "attributeCode1",
    label: "Точка.Признак1",
  },
  {
    id: 208,
    table: "point",
    value: "attributeCode2",
    label: "Точка.Признак2",
  },
  {
    id: 209,
    table: "point",
    value: "additionallyTable1",
    label: "Точка.ДопМера1",
  },
  {
    id: 210,
    table: "point",
    value: "additionallyTable2",
    label: "Точка.ДопМера2",
  },
  {
    id: 211,
    table: "point",
    value: "additionallyTable3",
    label: "Точка.ДопМера3",
  },
  {
    id: 212,
    table: "point",
    value: "command",
    label: "Точка.Команда",
  },
];

export const routeColumns = [
  {
    id: 300,
    table: "route",
    value: "routeCode",
    label: "Маршрут.Код",
  },
  {
    id: 301,
    table: "route",
    value: "command",
    label: "Маршрут.Команда",
  },
  {
    id: 302,
    table: "route",
    value: "routeName",
    label: "Маршрут.Наименование",
  },
  {
    id: 303,
    table: "route",
    value: "pointStartCode",
    label: "Маршрут.КодТочкиСтарта",
  },
  {
    id: 304,
    table: "route",
    value: "pointFinishCode",
    label: "Маршрут.КодТочкиФиниша",
  },
];
