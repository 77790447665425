/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  headers: [],
  data: [],
  sheetNames: [],
};

const excelFileMapping = createSlice({
  name: "excelFileMapping",
  initialState,
  reducers: {
    deleteImportedFile: () => initialState,
    saveRawData: (state, action) => {
      const sheetsNumber = action.payload.data.length;
      const emptyHeaders = Array(sheetsNumber).fill([]);
      for (let i = 0; i < sheetsNumber; i += 1) {
        emptyHeaders[i] = Array(action.payload.data[i][0].length).fill({
          id: "",
          table: "",
          value: "",
          label: "...",
        });
      }

      state.sheetNames = action.payload.sheetNames;
      state.data = action.payload.data;
      state.headers = emptyHeaders;
    },
    updateHeader: (state, action) => {
      state.headers[action.payload.sheetNumber][action.payload.index] =
        action.payload.selectedOption;
    },
  },
});

export const { deleteImportedFile, saveRawData, updateHeader } =
  excelFileMapping.actions;

export default excelFileMapping.reducer;
