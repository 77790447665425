import React, { useRef } from "react";

const EditableCell = ({
  value: initialValue,
  row: { index },
  column: { key: columnKey },
  updateCellValue,
}) => {
  const ref = useRef();

  const onBlur = () => {
    updateCellValue(index, columnKey, ref.current.value);
  };

  if (columnKey === "routeCode") return <div>{initialValue}</div>;

  return <input onBlur={onBlur} defaultValue={initialValue} ref={ref} />;
};

export default EditableCell;
