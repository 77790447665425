import React, { useState } from "react";
import { Flex, PointPopup as PointPopupUI, Text } from "shared/ui";

const PointPopup = React.memo(({ isCluster = false, multipleList = [] }) => {
  const [shownPoint, setShownPoint] = useState(multipleList[0]);

  const onSetShownPoint = (point) => {
    setShownPoint(point);
  };

  return (
    <PointPopupUI
      multipleList={multipleList}
      shownPoint={shownPoint}
      onSetShownPoint={onSetShownPoint}
      withTip={!isCluster}
    >
      <Flex width="290px" mr="20px" overflowY="scroll">
        <Flex mb="4px">
          <Text variant="body3bold">{shownPoint?.pointCode}</Text>
        </Flex>
        <Flex mb="4px">
          <Text variant="body3">{shownPoint?.contractName}</Text>
        </Flex>
        <Flex mb="4px">
          <Text variant="body3">{shownPoint?.pointName}</Text>
        </Flex>
        <Flex mb="10px">
          <Text variant="body3">{shownPoint?.address}</Text>
        </Flex>
        <Flex mb="5px">
          <Text variant="body3" color="color3">
            Продажи {shownPoint?.margin}
          </Text>
        </Flex>
        <Flex mb="5px">
          <Text variant="body3" color="color3">
            Объём {shownPoint?.volume}
          </Text>
        </Flex>
        <Flex>
          <Text variant="body3" color="color3">
            Вес {shownPoint?.weight}
          </Text>
        </Flex>
      </Flex>
    </PointPopupUI>
  );
});

export default PointPopup;
