import {
  CenteredMarker,
  CheckedMarker,
  DefaultMarker,
  IndividualMarker,
} from "app/assets/icons";
import L from "leaflet";
import ReactDOMServer from "react-dom/server";
import { Marker } from "react-leaflet";

const MarkerIcon = ({
  point = {},
  selectedPoints = [],
  highlightedPointId = null,
  onMarkerClicked = () => {},
  onMarkerCtrlClick = () => {},
  onPopupClosed = () => {},
  children,
}) => {
  const eventHandlers = {
    preclick: (e) => {
      if (e.originalEvent.ctrlKey || e.originalEvent.metaKey) {
        onMarkerCtrlClick();
        e.originalEvent.preventDefault();
      }
    },
    click: (e) => onMarkerClicked(e),
    popupclose: () => onPopupClosed(),
  };

  if (selectedPoints.includes(point.id)) {
    return (
      <Marker
        position={[point.latitude, point.longitude]}
        icon={L.divIcon({
          className: "custom-icon",
          html: ReactDOMServer.renderToString(<CheckedMarker />),
        })}
        zIndexOffset={5000}
        eventHandlers={eventHandlers}
      >
        {children}
      </Marker>
    );
  }

  if (point.id === highlightedPointId) {
    return (
      <Marker
        position={[point.latitude, point.longitude]}
        icon={L.divIcon({
          className: "custom-icon",
          html: ReactDOMServer.renderToString(<CenteredMarker />),
        })}
        zIndexOffset={10000}
        eventHandlers={eventHandlers}
      >
        {children}
      </Marker>
    );
  }

  if (point?.individualArea) {
    return (
      <Marker
        position={[point.latitude, point.longitude]}
        icon={L.divIcon({
          className: "custom-icon",
          html: ReactDOMServer.renderToString(
            <IndividualMarker width={32} height={32} />,
          ),
        })}
        zIndexOffset={1000}
        eventHandlers={eventHandlers}
      >
        {children}
      </Marker>
    );
  }

  return (
    <Marker
      position={[point.latitude, point.longitude]}
      icon={L.divIcon({
        className: "custom-icon",
        html: ReactDOMServer.renderToString(<DefaultMarker />),
      })}
      zIndexOffset={1000}
      eventHandlers={eventHandlers}
    >
      {children}
    </Marker>
  );
};

export default MarkerIcon;
