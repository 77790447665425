import InfiniteScroll from "react-infinite-scroll-component";
import { useTable } from "react-table";
import {
  TABLE_BODY_HEIGHT,
  TABLE_BODY_HEIGHT_WITH_FOOTER,
  TABLE_HEAD_HEIGHT,
  TABLE_HEIGHT,
} from "shared/config";
import { hexToRgba } from "shared/helpers";
import { Flex, Loader, Text } from "shared/ui";
import styled, { css } from "styled-components";

const Table = ({
  data = [],
  columns = [],
  totalElements = 0,
  isFooterVisible = false,
  onRowClick = () => {},
  onInfinityScroll = () => {},
  highlightedPointId = null,
  tableActionsId = null,
  isFetching = false,
}) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ data, columns, defaultColumn: { width: "100%" } });

  if (!data.length) {
    return (
      <Flex
        width="70%"
        height="70%"
        alignItems="center"
        justifyContent="center"
        margin="auto"
      >
        <Text variant="title1" color="color3">
          Нет данных
        </Text>
      </Flex>
    );
  }

  return (
    <Root>
      <InfiniteScroll
        scrollableTarget="scrollableDiv"
        dataLength={rows.length}
        next={() => onInfinityScroll()}
        hasMore={rows.length < totalElements}
        scrollThreshold={1}
        loader={<Loader isLoading={isFetching} />}
      >
        <StyledTable {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <TableHeaderRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <HeaderCell
                    width={column.w}
                    textAlign={column.textAlign}
                    {...column.getHeaderProps()}
                  >
                    {column.render("Header")}
                  </HeaderCell>
                ))}
              </TableHeaderRow>
            ))}
          </thead>
          <TableBody
            id="scrollableDiv"
            {...getTableBodyProps()}
            isFooterVisible={isFooterVisible}
            isFetching={isFetching}
          >
            {rows.map((row) => {
              prepareRow(row);
              return (
                <Row
                  {...row.getRowProps()}
                  data={row.original}
                  isRowHighlighted={highlightedPointId === row.original.id}
                  tableActionsId={tableActionsId}
                  onClick={() => onRowClick(row)}
                >
                  {row.cells.map((cell) => (
                    <BodyCell
                      width={cell.column.w}
                      textAlign={cell.column.textAlign}
                      {...cell.getCellProps()}
                    >
                      {cell.render("Cell")}
                    </BodyCell>
                  ))}
                </Row>
              );
            })}
          </TableBody>
        </StyledTable>
      </InfiniteScroll>
    </Root>
  );
};

const Root = styled.div`
  height: ${TABLE_HEIGHT});
`;

const StyledTable = styled.table`
  width: 100%;
  height: 100%;
  border-collapse: collapse;
`;

const TableHeaderRow = styled.tr`
  display: flex;
  align-items: center;
  width: 100%;
  height: ${TABLE_HEAD_HEIGHT};
  border-bottom: 1px solid #cccccc;
`;

const HeaderCell = styled.th`
  padding: 12px 24px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-align: ${(p) => p.textAlign};
  color: #595959;
  background-color: #ffffff;
  white-space: nowrap;
`;

const Row = styled.tr`
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 96px;
  border-bottom: 1px solid #cccccc;
  cursor: pointer;

  background-color: ${({ isRowHighlighted }) =>
    isRowHighlighted ? hexToRgba("#000000", 0.1) : "#FFFFFF"};

  &:hover {
    background: #ecf4fe;
  }

  ${({ data, tableActionsId }) => {
    if (data.id === tableActionsId) {
      return css`
        position: relative;
        z-index: 20000;
        pointer-events: none;
        background: #ffffff;

        &:hover {
          background: #ffffff;
        }
      `;
    }

    return "";
  }}
`;

const TableBody = styled.tbody`
  display: block;
  height: ${({ isFooterVisible }) =>
    isFooterVisible ? TABLE_BODY_HEIGHT_WITH_FOOTER : TABLE_BODY_HEIGHT};
  overflow: auto;
  pointer-events: ${({ isFetching }) => isFetching && "none"};
`;

const BodyCell = styled.td`
  padding: 12px 24px;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
  text-align: ${(p) => p.textAlign};
  word-break: break-all;
`;

export default Table;
