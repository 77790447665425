import { Dropdown } from "react-bootstrap";
import { Flex, Text } from "shared/ui";
import styled from "styled-components";

import { Search, Toggle as ToggleUI } from "./ui";

const { Toggle, Menu, ItemText } = Dropdown;

const BranchDropdown = ({
  isDropdownOpen = false,
  toggleText = "Выбрать",
  searchValue = "",
  branches = [],
  onToggle = () => {},
  onSearch = () => {},
  onResetSearch = () => {},
  onBranchSelect = () => {},
}) => (
  <Root>
    <Dropdown onToggle={(isOpen) => onToggle(isOpen)} show={isDropdownOpen}>
      <Toggle
        id="dropdown-custom-components"
        as={ToggleUI}
        toggleText={toggleText}
      />
      <Menu>
        <Flex p="10px">
          <Search
            value={searchValue}
            onChange={(e) => onSearch(e.target.value)}
            onReset={() => onResetSearch()}
          />
        </Flex>
        <Flex maxHeight="200px" overflowY="scroll" overflowX="hidden">
          {branches.map(
            (item) =>
              item?.branchName
                .toLowerCase()
                .includes(searchValue.toLowerCase()) && (
                <ItemText key={item.id} onClick={() => onBranchSelect(item)}>
                  <Label>
                    <Text>{item.branchName}</Text>
                  </Label>
                </ItemText>
              ),
          )}
        </Flex>
      </Menu>
    </Dropdown>
  </Root>
);

const Root = styled.div`
  .dropdown-menu {
    width: 100%;
    min-width: 0;
    padding: 0;
    margin: 0;
    border: none;
    box-sizing: border-box;
  }
  .dropdown-menu.show {
    width: 100%;
    box-sizing: border-box;
    border-radius: 0;
    border: 2px solid #000000;
    transform: translate(0px, 0px) !important;
  }
  .dropdown-item-text {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0;
    word-break: break-all;
  }
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 10px;
  width: 100%;
  margin: 0;

  &:hover {
    background: #ecf4fe;
  }
`;

export default BranchDropdown;
