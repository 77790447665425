import { Flex, Text } from "shared/ui";

import { Actions, EditProject, ProjectReport } from "./model";

function beautifyDate(date) {
  const day = date[2];
  let month = date[1];
  const year = date[0];
  if (month < 10) {
    month = `0${month}`;
  }
  return `${day}.${month}.${year}`;
}

export const configColumns = [
  {
    id: "0",
    Header: "№",
    accessor: (data) => (
      <Flex>
        <Text color="color7">{data.id}</Text>
      </Flex>
    ),
  },
  {
    id: "1",
    Header: "Название",
    accessor: (data) => <EditProject project={data} />,
  },
  {
    id: "2",
    Header: "Дата",
    accessor: (data) => (
      <Flex>
        <Text color="color7">{beautifyDate(data.creationTime)}</Text>
      </Flex>
    ),
  },
  {
    id: "3",
    Header: "Актуальность",
    accessor: (data) => (data.actual === true ? "Актуально" : "Не актуально"),
  },
  {
    id: "4",
    Header: "Статус",
    accessor: "status",
  },
  {
    id: "5",
    Header: "Отчёт",
    accessor: (data) => <ProjectReport project={data} />,
  },
  {
    id: "6",
    Header: "Действия",
    accessor: (data) => <Actions project={data} />,
  },
];
