import { api } from "shared/api";

const coordinatesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    listPointsCoordinates: builder.query({
      query: ({ projectId, filters }) =>
        `projects/${projectId}/route-points/mode-coordinates-filters?limit=${filters.limit}&page=${filters.page}&managerCodes=${filters.managerCodes}&commands=${filters.commands}&routeCodes=${filters.routeCodes}&address=${filters.address}&distance=${filters.distance}&sortBy=${filters.sort}&daysOfWeek=${filters.daysOfWeek}`,
      providesTags: ["Coordinates"],
    }),
    listMapPointsCoordinates: builder.query({
      query: ({ projectId, managerCodes, commands, routeCodes, distance, daysOfWeek }) =>
        `projects/${projectId}/route-points/maps/mode-coordinates-filters?managerCodes=${managerCodes}&commands=${commands}&routeCodes=${routeCodes}&distance=${distance}&daysOfWeek=${daysOfWeek}`,
      providesTags: ["Coordinates"],
    }),
    changeCurrentCoordsToCalculatedCoordinates: builder.mutation({
      query: ({ projectId, ...body }) => ({
        url: `projects/${projectId}/points/coordinates`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["Coordinates", "States"],
    }),
    changeCurrentCoordsToCalculatedForAllPointsCoordinates: builder.mutation({
      query: ({ projectId, ...body }) => ({
        url: `projects/${projectId}/points/filters/coordinates`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["Coordinates", "States"],
    }),
    changeCurrentCoordsToNewCoordinates: builder.mutation({
      query: ({ projectId, pointId, ...body }) => ({
        url: `projects/${projectId}/points/${pointId}/coordinates`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["Coordinates", "States"],
    }),
  }),
});

export const {
  useListPointsCoordinatesQuery,
  useListMapPointsCoordinatesQuery,
  useChangeCurrentCoordsToCalculatedCoordinatesMutation,
  useChangeCurrentCoordsToCalculatedForAllPointsCoordinatesMutation,
  useChangeCurrentCoordsToNewCoordinatesMutation,
} = coordinatesApi;
