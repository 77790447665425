import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getAuthToken } from "shared/libs/local-storage";

import { API_URL } from "./config";

export const api = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_URL}/api`,
    prepareHeaders: (headers, request) => {
      if (request.endpoint === "getCoordsByMapClick") {
        headers.set(
          "authorization",
          "Token 6f65fdf0666c7d8947c171a1c5ecb2ed3575962f",
        );
      } else {
        const token = getAuthToken();
        if (token) {
          headers.set("authorization", `Bearer ${token}`);
        }
      }
      return headers;
    },
  }),
  tagTypes: [
    "Projects",
    "Routes",
    "Days",
    "Coordinates",
    "Logistic-Coordinates",
    "Logistic",
    "Areas",
    "Shedule",
    "States",
    "Contract-Group",
    "Filters",
    "Project-Settings",
  ],
  endpoints: () => ({}),
});
