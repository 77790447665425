import React from "react";
import { useMap } from "react-leaflet";

const ChangeView = React.memo(({ mapCenterPoint = {} }) => {
  const map = useMap();
  const { clickedIn, latitude, longitude } = mapCenterPoint;

  if (clickedIn === "table") {
    const newView =
      latitude === "NULL" || longitude === "NULL"
        ? [0, 0]
        : [latitude, longitude];
    map.setView(newView, map.getZoom() || 15);
  }

  return null;
});

export default ChangeView;
