import * as React from "react";

const SvgMinimize = (props) => (
  <svg
    width={10}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M.41 14.59 1.83 16 5 12.83 8.17 16l1.41-1.41L5 10 .41 14.59zM9.59 1.41 8.17 0 5 3.17 1.83 0 .41 1.41 5 6l4.59-4.59z"
      fill="currentColor"
    />
  </svg>
);

export default SvgMinimize;
