import styled from "styled-components";

const Button = ({ icon, text, color = "#2D8AF6", ...props }) => (
  <Root color={color} {...props}>
    {icon && <IconWrapper>{icon}</IconWrapper>}
    {text}
  </Root>
);

const Root = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${(props) => props.width};
  height: ${(props) => props.height || "48px"};
  padding: 12px 18px;
  background-color: ${(props) => props.color};
  border: none;
  outline: none;
  border-radius: 12px;
  font-size: 18px;
  color: ${(props) => (props.textColor ? props.textColor : "#ffffff")};
  cursor: pointer;
  opacity: ${(p) => (p.isRequestLoading || p.disabled ? "0.5" : "1")};
  white-space: nowrap;
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  margin-right: 6px;
`;

export default Button;
