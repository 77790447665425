import { CalculatedMarker, CurrentMarker, NewMarker } from "app/assets/icons";
import { setChangeCoordsFormDataNewPoint } from "app/store/reducer/coordinates";
import L from "leaflet";
import React from "react";
import ReactDOMServer from "react-dom/server";
import { Marker, Polyline, useMapEvent } from "react-leaflet";
import { useDispatch } from "react-redux";
import { useGetCoordsByMapClickMutation } from "shared/api/dadata";

const ChangeCoordsMarkers = React.memo(({ changeCoordsFormData }) => {
  const dispatch = useDispatch();
  let calculatedCoords;
  const currentCoords = changeCoordsFormData.point.position.split(", ");

  if (parseInt(changeCoordsFormData.point.distance, 10) > 0) {
    calculatedCoords = changeCoordsFormData.point.sprPosition.split(", ");
  }

  const [getCoordsByMapClick] = useGetCoordsByMapClickMutation();

  const onMapClick = async (body) => {
    const response = await getCoordsByMapClick(body);

    const newPoint = {
      latitude: body.lat,
      longitude: body.lon,
      address: "",
    };

    if (response.data.suggestions.length > 0) {
      newPoint.address = response.data.suggestions[0].value;
    }

    dispatch(setChangeCoordsFormDataNewPoint(newPoint));
  };

  useMapEvent({
    click(e) {
      const { lat, lng } = e.latlng;
      onMapClick({ lat, lon: lng });
    },
  });

  return (
    <>
      <Marker
        position={currentCoords}
        icon={L.divIcon({
          className: "custom-icon",
          html: ReactDOMServer.renderToString(<CurrentMarker />),
        })}
      />
      {calculatedCoords && (
        <>
          <Marker
            position={calculatedCoords}
            icon={L.divIcon({
              className: "custom-icon",
              html: ReactDOMServer.renderToString(<CalculatedMarker />),
            })}
          />
          <Polyline positions={[currentCoords, calculatedCoords]} />
        </>
      )}
      {changeCoordsFormData?.newPoint?.latitude &&
        changeCoordsFormData?.newPoint?.longitude && (
          <>
            <Marker
              position={[
                changeCoordsFormData?.newPoint?.latitude,
                changeCoordsFormData?.newPoint?.longitude,
              ]}
              icon={L.divIcon({
                className: "custom-icon",
                html: ReactDOMServer.renderToString(<NewMarker />),
              })}
            />
            <Polyline
              positions={[
                [
                  changeCoordsFormData?.newPoint?.latitude,
                  changeCoordsFormData?.newPoint?.longitude,
                ],
                currentCoords,
              ]}
            />
          </>
        )}
    </>
  );
});

export default ChangeCoordsMarkers;
