/* eslint-disable no-console */
import React from "react";
import { useTable } from "react-table";
import { USER_TYPE } from "shared/config";
import { ErrorText } from "shared/ui";
import styled, { css } from "styled-components";

const Table = ({
  data,
  columns,
  role,
  actionsDropdownId = null,
  onProjectOpen = () => {},
}) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      data,
      columns,
      initialState: {
        hiddenColumns: role === USER_TYPE.LOGISTIC_USER ? ["5"] : [],
      },
    });

  if (!data.length) {
    return <ErrorText errorText="Нет данных" />;
  }

  return (
    <TableWrapper>
      <TableContent {...getTableProps()}>
        <TableHead>
          {headerGroups.map((headerGroup) => (
            <TableHeaderRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <TableHeaderCell {...column.getHeaderProps()}>
                  {column.render("Header")}
                </TableHeaderCell>
              ))}
            </TableHeaderRow>
          ))}
        </TableHead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <TableDataRow
                data={row.original}
                actionsDropdownId={actionsDropdownId}
                onClick={() => onProjectOpen(row.original)}
                isUrgentPoints={row.original.name === "Срочные точки"}
                {...row.getRowProps()}
              >
                {row.cells.map((cell) => (
                  <TableDataCell {...cell.getCellProps()}>
                    {cell.render("Cell")}
                  </TableDataCell>
                ))}
              </TableDataRow>
            );
          })}
        </tbody>
      </TableContent>
    </TableWrapper>
  );
};

const TableWrapper = styled.div`
  display: block;
  max-height: 300px;
  overflow-y: scroll;
  overflow-x: hidden;
  width: 100%;
  margin-bottom: 24px;
`;

const TableContent = styled.table`
  border-collapse: collapse;
  width: 100%;
`;

const TableHead = styled.thead`
  border-bottom: 1px solid #cccccc;
  border-top: 1px solid #cccccc;
`;

const TableHeaderCell = styled.th`
  padding: 12px 0 12px 8px;
  text-align: left;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #787878;
`;

const TableHeaderRow = styled.tr`
  border-bottom: 1px solid #cccccc;
`;

const TableDataRow = styled.tr`
  background: ${({ data, actionsDropdownId }) =>
    data.id === actionsDropdownId ? "#FFFFFF" : null};
  border-bottom: 1px solid #cccccc;
  cursor: pointer;

  &:hover {
    background-color: ${({ data, actionsDropdownId }) =>
      data.id === actionsDropdownId ? "none" : "#ECF4FE"};
  }

  ${({ isUrgentPoints }) =>
    isUrgentPoints &&
    css`
      background-color: #ddd;
    `}
`;

const TableDataCell = styled.td`
  padding: 20px 24px 20px 8px;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
`;

export default Table;
