import { crossIcon } from "icons/icons";
import React from "react";
import styled from "styled-components";

const InputText = React.forwardRef(({ onClear, ...props }, ref) => (
  <Root>
    <TextField>
      <input ref={ref} type="text" {...props} />
    </TextField>
    {onClear && <IconWrapper onClick={onClear}>{crossIcon}</IconWrapper>}
  </Root>
));

const Root = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  padding: 12px;
  margin-right: 12px;
  border: 1px solid #cccccc;
  background: #ffffff;
`;

const TextField = styled.div`
  width: 100%;
  margin-right: 12px;

  input {
    width: 100%;
    height: 100%;
    outline: none;
    border: none;
  }
`;

const IconWrapper = styled.div`
  cursor: pointer;
`;

export default InputText;
