import { PopupContext } from "app/context/PopupContext";
import { saveRawData } from "app/store/reducer/import-excel-file";
import { plusIcon } from "icons/icons";
/* eslint-disable no-unused-vars */
import { useContext, useRef } from "react";
import { useDispatch } from "react-redux";
import { Button, Flex, Popup, Text } from "shared/ui";
import { read, utils } from "xlsx";

const ImportExcelFile = ({ navigateToExcelImport = () => {} }) => {
  const { hidePopup } = useContext(PopupContext);
  const fileInputRef = useRef(null);
  const dispatch = useDispatch();

  const onChooseFileButtonClick = async (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = (event) => {
      const data = new Uint8Array(event.target.result);
      const workbook = read(data, { type: "array" });

      const sheetsData = workbook.SheetNames.map((sheetName) => {
        const worksheet = workbook.Sheets[sheetName];
        const rawData = utils.sheet_to_json(worksheet, {
          header: 1,
          defval: "",
        });
        return rawData.filter((row) => row.some((cell) => cell !== ""));
      });

      dispatch(
        saveRawData({ data: sheetsData, sheetNames: workbook.SheetNames }),
      );
    };
    await reader.readAsArrayBuffer(file);

    hidePopup();
    navigateToExcelImport();
  };

  return (
    <Popup isCenter title="Загрузить данные из Excel">
      <Flex mt="20px">
        <input
          ref={fileInputRef}
          type="file"
          accept=".xls,.xlsx"
          onChange={onChooseFileButtonClick}
          style={{ display: "none" }}
        />
        <Button
          width="180px"
          height="48px"
          color="#2D8AF6"
          icon={plusIcon}
          text="Выбрать файл"
          onClick={() => {
            fileInputRef.current.click();
          }}
        />
      </Flex>
      <Flex flexDirection="column" mt="20px">
        <Text variant="body1">Рекомендации к данным файла:</Text>
      </Flex>

      <Flex flexDirection="column" mt="10px">
        <Text variant="body1">Точка.ЧастотаВизита</Text>
        <Text variant="body2" color="color7">
          Частота визитов задается колиеством требуемых посещений точки в 4
          недели. Т.е., если точка должна посещаться каждую неделю один раз, то
          значение должно быть 4.
        </Text>
      </Flex>

      <Flex flexDirection="column" mt="10px">
        <Text variant="body1">Визиты.Цикл</Text>
        <Text variant="body2" color="color7">
          Фактическая цикличность посещения задается на русском языке следующими
          вариантами:
        </Text>
        <ul>
          <li>
            <Text variant="body2" color="color7">
              Каждую неделю
            </Text>
          </li>
          <li>
            <Text variant="body2" color="color7">
              Четные недели
            </Text>
          </li>
          <li>
            <Text variant="body2" color="color7">
              Нечетные недели
            </Text>
          </li>
          <li>
            <Text variant="body2" color="color7">
              Каждую первую неделю
            </Text>
          </li>
          <li>
            <Text variant="body2" color="color7">
              Каждую вторую неделю
            </Text>
          </li>
          <li>
            <Text variant="body2" color="color7">
              Каждую третью неделю
            </Text>
          </li>
          <li>
            <Text variant="body2" color="color7">
              Каждую четвертую неделю
            </Text>
          </li>
        </ul>
      </Flex>

      <Flex flexDirection="column" mt="10px" pb="20px">
        <Text variant="body1">Визиты.День</Text>
        <Text variant="body2" color="color7">
          День недели посещения точки задается полным названием дня недели на
          русском языке. Т.е. Понедельник, Вторник, Среда и т.д.
        </Text>
      </Flex>
    </Popup>
  );
};

export default ImportExcelFile;
