import { Cross, Move } from "app/assets/icons";
import {
  resetSelectedRoutePoints,
  setAllPointsChecked,
  setSelectedRoutePoints,
} from "app/store/reducer/days";
import { expandTable } from "app/store/reducer/project-settings";
import { CollapseIcon, ExpandIcon, GearIcon } from "icons/icons";
import React, { useEffect, useState } from "react";
import Highlighter from "react-highlight-words";
import { useDispatch, useSelector } from "react-redux";
import {
  useGetDaysModeColumnsQuery,
  useUpdateDaysModeColumnsMutation,
} from "shared/api/current-project";
import { CYCLES, TABLE_HEAD_HEIGHT } from "shared/config";
import { beautifyPointAddress } from "shared/helpers";
import { Checkbox, Flex, IconButton, Text } from "shared/ui";
import styled from "styled-components";

export const PointHeader = ({ text }) => {
  const dispatch = useDispatch();
  const { allPointsChecked } = useSelector((state) => state.rootReducer.days);

  return (
    <Flex flexDirection="row" alignItems="center">
      <Flex mr="7px">
        <Checkbox
          checked={allPointsChecked}
          onChange={() => {
            dispatch(setAllPointsChecked(!allPointsChecked));
            dispatch(resetSelectedRoutePoints());
          }}
        />
      </Flex>
      <Text>{text}</Text>
    </Flex>
  );
};

export const PointCell = ({ data }) => {
  const dispatch = useDispatch();
  const { frontendFilters, selectedRoutePoints } = useSelector(
    (state) => state.rootReducer.days,
  );

  return (
    <Flex flexDirection="row" alignItems="center">
      <Flex mr="7px">
        <Checkbox
          className="checkbox"
          filledColor={data.color}
          emptyColor={data.color}
          checked={selectedRoutePoints.includes(data.id)}
          onChange={() => {
            dispatch(setSelectedRoutePoints(data.id));
            dispatch(setAllPointsChecked(false));
          }}
        />
      </Flex>
      <Highlighter
        highlightClassName="YourHighlightClass"
        searchWords={[frontendFilters.address]}
        autoEscape
        textToHighlight={beautifyPointAddress(data.point)}
        highlightStyle={{ background: "#CEE3FD", padding: 0 }}
      />
    </Flex>
  );
};

export const OrderCell = ({ data }) => {
  const {
    frontendFilters: { cyclePoint, daysOfWeek },
  } = useSelector((state) => state.rootReducer.days);

  return (
    <Flex flexDirection="row" alignItems="center" justifyContent="center">
      <Text variant="body1">{data.orderPoint}</Text>
      {daysOfWeek.length === 1 && cyclePoint === CYCLES.EVERY_WEEK && (
        <Flex ml="5px">
          <IconButton>
            <Move />
          </IconButton>
        </Flex>
      )}
    </Flex>
  );
};

export const ActionsHeader = ({ allColumns, toggleHideColumn }) => {
  const dispatch = useDispatch();
  const [showSettings, setShowSettings] = useState(false);

  const {
    projectInfo: { id: projectId },
  } = useSelector((state) => state.rootReducer.currentProject);
  const { expandMode } = useSelector(
    (state) => state.rootReducer.projectSettings,
  );

  const { data: columnsToShow, isSuccess } = useGetDaysModeColumnsQuery(
    { projectId },
    { refetchOnMountOrArgChange: true },
  );
  const [saveColumns] = useUpdateDaysModeColumnsMutation();

  // hide additional columns before settings were fetched
  useEffect(() => {
    allColumns.map((column) => {
      if (column.name) toggleHideColumn(column.id, column.hiddenByDefault);
      return null;
    });
  }, []);

  // if data exists on server – show only those columns
  useEffect(() => {
    if (isSuccess && columnsToShow.length > 0) {
      allColumns.map((column) => {
        if (column.name)
          toggleHideColumn(column.id, columnsToShow.indexOf(column.id) === -1);
        return null;
      });
    }
  }, [isSuccess, projectId]);

  const handleSaveHiddenColumns = async () => {
    setTimeout(async () => {
      const columnsToShowIds = allColumns
        .map((column) => (column.isVisible ? column.id : null))
        .filter((id) => id !== "order")
        .filter(Boolean);

      await saveColumns({ projectId, columns: columnsToShowIds });
    }, 0);
  };

  const handleSwitchTableExpand = () => {
    dispatch(expandTable());
    setShowSettings(false);
  };

  const resetColumnsVisibility = () => {
    allColumns.map((column) => {
      toggleHideColumn(column.id, column.hiddenByDefault);
      return null;
    });
    setShowSettings(false);
    handleSaveHiddenColumns();
  };

  return (
    <>
      <Flex flexDirection="row" alignItems="center" justifyContent="center">
        <ControlButton
          onClick={() => setShowSettings((prevState) => !prevState)}
        >
          <GearIcon />
        </ControlButton>
        <ControlButton onClick={handleSwitchTableExpand}>
          {expandMode === "table" ? (
            <CollapseIcon color="#595959" />
          ) : (
            <ExpandIcon color="#595959" />
          )}
        </ControlButton>
      </Flex>
      {showSettings && (
        <SettingsMenu>
          {allColumns.map((column) => {
            if (column.name)
              return (
                <Flex
                  key={column.id}
                  flexDirection="row"
                  alignItems="center"
                  p="4px 2px"
                >
                  <input
                    type="checkbox"
                    {...column.getToggleHiddenProps()}
                    onChange={(e) => {
                      column.getToggleHiddenProps().onChange(e);
                      handleSaveHiddenColumns();
                    }}
                  />
                  <span>{column.name}</span>
                </Flex>
              );
            return null;
          })}
          <ResetButton onClick={resetColumnsVisibility}>Сбросить</ResetButton>
          <CloseButton onClick={() => setShowSettings(false)}>
            <Cross />
          </CloseButton>
        </SettingsMenu>
      )}
    </>
  );
};

const ControlButton = styled.div`
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  border-radius: 4px;
  cursor: pointer;

  :last-of-type {
    margin-left: 16px;
  }
`;

const SettingsMenu = styled.div`
  position: absolute;
  top: ${TABLE_HEAD_HEIGHT};
  right: 0;
  z-index: 9999;
  padding: 12px;
  background: white;
  box-shadow: 0px -8px 10px -4px rgba(0, 0, 0, 0.1);
  border: 1px solid #ccc;

  span {
    margin-left: 4px;
  }
`;

const ResetButton = styled.p`
  margin-top: 4px;
  cursor: pointer;
  text-decoration: underline;
`;

const CloseButton = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
  cursor: pointer;
  transform: scale(0.75);
`;
