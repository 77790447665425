import {
  CenterMarkerIcon,
  CyclesEvenWeeksMarkerIcon,
  CyclesEveryWeekMarkerIcon,
  CyclesFirstWeekMarkerIcon,
  CyclesFourthWeekMarkerIcon,
  CyclesNAWeekMarkerIcon,
  CyclesOddWeeksMarkerIcon,
  CyclesSecondWeekMarkerIcon,
  CyclesThirdWeekMarkerIcon,
  DefaultMarkerIcon,
  SelectedMarkerIcon,
  finishIcon,
  startIcon
} from "icons/icons";
import { PROJECT_MODE } from "shared/config";
import { checkMarkerColor } from "shared/helpers";

const CustomMarker = ({
  mapRoutePoint,
  mapCenterPoint,
  mapMarkersColoredBy,
  selectedRoutePoints,
  projectMode,
}) => {
  const markerColor = checkMarkerColor(
    mapMarkersColoredBy,
    mapRoutePoint,
    projectMode,
  );
  if (mapRoutePoint.typePoint === "START") return startIcon;
  if (mapRoutePoint.typePoint === "FINISH") return finishIcon;

  if (projectMode === PROJECT_MODE.ROUTES) {
    if (mapRoutePoint.id === mapCenterPoint.id) {
      return (
        <CenterMarkerIcon
          mapCenterPoint={mapCenterPoint}
          markerColor={markerColor}
        />
      );
    }
    if (selectedRoutePoints.includes(mapRoutePoint.id)) {
      return <SelectedMarkerIcon markerColor={markerColor} />;
    }
    return <DefaultMarkerIcon markerColor={markerColor} />;
  }

  switch (mapRoutePoint.cyclePoint) {
    case "Каждая неделя":
      return (
        <CyclesEveryWeekMarkerIcon
          mapRoutePoint={mapRoutePoint}
          mapCenterPoint={mapCenterPoint}
          selectedRoutePoints={selectedRoutePoints}
          markerColor={markerColor}
        />
      );
    case "Первая неделя":
      return (
        <CyclesFirstWeekMarkerIcon
          mapRoutePoint={mapRoutePoint}
          mapCenterPoint={mapCenterPoint}
          selectedRoutePoints={selectedRoutePoints}
          markerColor={markerColor}
        />
      );
    case "Вторая неделя":
      return (
        <CyclesSecondWeekMarkerIcon
          mapRoutePoint={mapRoutePoint}
          mapCenterPoint={mapCenterPoint}
          selectedRoutePoints={selectedRoutePoints}
          markerColor={markerColor}
        />
      );
    case "Третья неделя":
      return (
        <CyclesThirdWeekMarkerIcon
          mapRoutePoint={mapRoutePoint}
          mapCenterPoint={mapCenterPoint}
          selectedRoutePoints={selectedRoutePoints}
          markerColor={markerColor}
        />
      );
    case "Четвертая неделя":
      return (
        <CyclesFourthWeekMarkerIcon
          mapRoutePoint={mapRoutePoint}
          mapCenterPoint={mapCenterPoint}
          selectedRoutePoints={selectedRoutePoints}
          markerColor={markerColor}
        />
      );
    case "Четная неделя":
      return (
        <CyclesEvenWeeksMarkerIcon
          mapRoutePoint={mapRoutePoint}
          mapCenterPoint={mapCenterPoint}
          selectedRoutePoints={selectedRoutePoints}
          markerColor={markerColor}
        />
      );
    case "Нечетная неделя":
      return (
        <CyclesOddWeeksMarkerIcon
          mapRoutePoint={mapRoutePoint}
          mapCenterPoint={mapCenterPoint}
          selectedRoutePoints={selectedRoutePoints}
          markerColor={markerColor}
        />
      );
    default:
      return (
        <CyclesNAWeekMarkerIcon
          mapRoutePoint={mapRoutePoint}
          mapCenterPoint={mapCenterPoint}
          selectedRoutePoints={selectedRoutePoints}
          markerColor={markerColor}
        />
      );
  }
};

export default CustomMarker;
