import * as React from "react";

const SvgMove = (props) => (
  <svg
    width={14}
    height={22}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.333 3.667h2.811l-.012 3.52h1.736l-.012-3.52h2.81L7 0 3.333 3.667zm7.334 14.666H7.856l.012-3.52H6.132l.012 3.52h-2.81L7 22l3.667-3.667zm2.666-8.189-12.52-.012v1.736l12.52-.012v-1.712z"
      fill="#CCC"
    />
  </svg>
);

export default SvgMove;
