/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  routeList: [],
  pointList: [],
  routePointList: [],
};

const excelData = createSlice({
  name: "excelFileMapping",
  initialState,
  reducers: {
    resetState: () => initialState,
    updateExcelData: (state, action) => action.payload,
    setRouteCellValue: (state, action) => {
      state.routeList[action.payload.row][action.payload.column] =
        action.payload.value;
    },
    excludeUploadedData: (state, action) => {
      const routeErrors = action.payload.filter((e) => e.type === "ROUTE");
      const pointErrors = action.payload.filter((e) => e.type === "POINT");
      const routePointErrors = action.payload.filter(
        (e) => e.type === "ROUTE_POINT",
      );

      // leave rows that contained errors and reset rowNumbers
      state.routeList = state.routeList
        .filter((r) => routeErrors.some((e) => e.rowNumber === r.rowNumber))
        .map((r, index) => ({ ...r, rowNumber: index + 1 }));

      state.pointList = state.pointList
        .filter((p) => pointErrors.some((e) => e.rowNumber === p.rowNumber))
        .map((p, index) => ({ ...p, rowNumber: index + 1 }));

      state.routePointList = state.routePointList
        .filter((rp) =>
          routePointErrors.some((e) => e.rowNumber === rp.rowNumber),
        )
        .map((rp, index) => ({ ...rp, rowNumber: index + 1 }));
    },
  },
});

export const {
  resetState,
  updateExcelData,
  setRouteCellValue,
  excludeUploadedData,
} = excelData.actions;

export default excelData.reducer;
