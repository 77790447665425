import { AccordionCell, GroupsCell } from "../model";

export const configColumns = [
  {
    id: "0",
    accessor: "branchName",
  },
  {
    id: "1",
    accessor: (data) => <GroupsCell data={data} />,
  },
  {
    id: "2",
    accessor: (data) => <AccordionCell data={data} />,
  },
];
