import { yupResolver } from "@hookform/resolvers/yup";
import { DragIndicator } from "app/assets/icons";
import GroupsContext from "pages/projects/popups/load-contract-groups/context";
import { useContext, useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useSaveContractGroupsMutation } from "shared/api/contract-group";
import { SERVER_ERROR_TEXT } from "shared/config";
import { Button, ErrorText, Flex, Text } from "shared/ui";
import styled from "styled-components";

import { GroupsSchema } from "./config";
import { handleDragOver, handleDragStart } from "./helpers";
import { Group } from "./ui";

const ChooseGroups = ({
  branchId,
  defaultGroups = [],
  defaultContracts = [],
}) => {
  const {
    selectedBranches,
    setSelectedBranches,
    editedBranches,
    setEditedBranches,
    resetContractsSelected,
  } = useContext(GroupsContext);
  const [serverError, setServerError] = useState("");
  const [contracts, setContracts] = useState([]);
  const [saveContractGroups] = useSaveContractGroupsMutation();
  const {
    control,
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(GroupsSchema),
    defaultValues: {
      groups: defaultGroups?.length
        ? defaultGroups
        : [
            {
              id: 0,
              name: "",
              contractCodes: [],
            },
          ],
    },
  });
  const { append, replace, remove } = useFieldArray({
    control,
    name: "groups",
  });
  const groups = watch("groups");

  const addGroup = () => {
    append({
      id: groups[groups.length - 1].id + 1,
      name: "",
      contractCodes: [],
    });
  };

  const deleteGroup = (index, group) => {
    if (groups?.length > 1) {
      remove(index);
      setContracts([...contracts, ...group.contractCodes]);
    }
  };

  const handleDropGroup = (e, groupIdTo) => {
    const dragFrom = e.dataTransfer.getData("dragFrom");
    const contract = e.dataTransfer.getData("contract");

    if (dragFrom === "list") {
      const newGroupsState = groups.map((item) => {
        if (item.id === groupIdTo) {
          item.contractCodes.push(contract);
        }
        return item;
      });
      const newContractsState = contracts.filter((item) => item !== contract);

      replace(newGroupsState);
      setContracts(newContractsState);
    } else {
      const groupIdFrom = Number(e.dataTransfer.getData("groupIdFrom"));

      if (groupIdFrom === groupIdTo) return;

      const newGroupsState = groups.map((group) => {
        if (group.id === groupIdFrom) {
          return {
            ...group,
            contractCodes: group.contractCodes.filter(
              (item) => item !== contract,
            ),
          };
        }
        if (group.id === groupIdTo) {
          return {
            ...group,
            contractCodes: [...group.contractCodes, contract],
          };
        }
        return group;
      });

      replace(newGroupsState);
    }
  };

  const handleDropList = (e) => {
    const dragFrom = e.dataTransfer.getData("dragFrom");
    const contract = e.dataTransfer.getData("contract");

    if (dragFrom === "group") {
      const groupIdFrom = Number(e.dataTransfer.getData("groupIdFrom"));

      const newGroupsState = groups.map((group) => {
        if (group.id === groupIdFrom) {
          return {
            ...group,
            contractCodes: group.contractCodes.filter(
              (item) => item !== contract,
            ),
          };
        }
        return group;
      });
      const newContractsState = [...contracts, contract];

      replace(newGroupsState);
      setContracts(newContractsState);
    }
  };

  const onSaveGroups = async (values) => {
    const body = values.groups.filter(
      (group) => group.contractCodes.length > 0,
    );

    const response = await saveContractGroups({ branchId, body });

    if (response.error) {
      setServerError(response.error?.data?.message || SERVER_ERROR_TEXT);
      return;
    }

    setSelectedBranches([...selectedBranches, branchId]);
    setEditedBranches(editedBranches.filter((item) => item !== branchId));
    resetContractsSelected();
  };

  useEffect(() => {
    if (defaultContracts?.length) {
      setContracts(defaultContracts);
    }
  }, [defaultContracts]);

  return (
    <Flex>
      <StyledGroupsList>
        {groups.map((group, index) => (
          <Group
            {...register(`groups.${index}.name`)}
            key={group?.id}
            index={index}
            group={group}
            handleDragStart={handleDragStart}
            handleDragOver={handleDragOver}
            handleDropGroup={handleDropGroup}
            deleteGroup={deleteGroup}
            errors={errors}
          />
        ))}
      </StyledGroupsList>
      <Flex flexDirection="row" mt="10px">
        <Flex mr="15px">
          <Button
            text="ДОБАВИТЬ ГРУППУ"
            width="270px"
            color="#ECF4FE"
            textColor="#2D8AF6"
            disabled={groups.length > 5}
            onClick={() => addGroup()}
          />
        </Flex>
        {contracts?.length ? (
          <StyledContractsList
            onDragOver={(e) => handleDragOver(e)}
            onDrop={(e) => handleDropList(e)}
          >
            {contracts?.map((contract) => (
              <StyledContract
                key={contract}
                draggable
                onDragStart={(e) => handleDragStart(e, "list", contract)}
              >
                <Text variant="body3">{contract}</Text>
                <DragIndicator />
              </StyledContract>
            ))}
          </StyledContractsList>
        ) : (
          <Flex mt="10px">
            <Text variant="body1" color="color8">
              Все контракты распределены.
            </Text>
          </Flex>
        )}
      </Flex>
      {!contracts?.length && (
        <Flex mt="15px">
          <Button
            text="СОХРАНИТЬ"
            width="144px"
            color="#ECF4FE"
            textColor="#2D8AF6"
            onClick={handleSubmit(onSaveGroups)}
          />
        </Flex>
      )}
      <ErrorText errorText={serverError} />
    </Flex>
  );
};

const StyledGroupsList = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: auto;
  gap: 15px;
`;

const StyledContractsList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5px;
`;

const StyledContract = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 60px;
  height: 24px;
  padding: 4px;
  background: rgba(0, 0, 0, 0.04);
  cursor: pointer;
`;

export default ChooseGroups;
