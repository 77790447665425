import { PopupContext } from "app/context/PopupContext";
import { excludeUploadedData } from "app/store/reducer/excel-data";
import { deleteImportedFile } from "app/store/reducer/import-excel-file";
import { checkDataIcon } from "icons/icons";
/* eslint-disable no-console */
import React, { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useCheckExcelDataMutation,
  useUploadExcelDataMutation,
} from "shared/api/excel-data";
import { Button, Checkbox, Flex, Loader, Popup, Text } from "shared/ui";
import styled from "styled-components";

import { Table, Tabs } from "./ui";

// const calculateLength = (data) => {
//   let length = 0;
//   Object.values(data).forEach((arr) => {
//     length += arr.length;
//   });
//   return length;
// };

const EditExcelData = ({ project, backToFile }) => {
  const { hidePopup } = useContext(PopupContext);
  const dispatch = useDispatch();
  const data = useSelector((state) => state.rootReducer.excelData);
  const [checkData] = useCheckExcelDataMutation();
  const [uploadData, { isSuccess: uploaded, isLoading: isUploading }] =
    useUploadExcelDataMutation();
  const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showJustLinesWithErrors, setShowJustLinesWithErrors] = useState(false);

  const [currentTable, setCurrentTable] = useState("routeList");
  const [errors, setErrors] = useState({
    routeList: [],
    pointList: [],
    routePointList: [],
  });
  const [linesWithErrors, setLinesWithErrors] = useState([]);

  const resetStates = () => {
    setChecked(false);
    setLoading(false);
    setLinesWithErrors([]);
    setErrors({
      routeList: [],
      pointList: [],
      routePointList: [],
    });
  };

  const handleBackToFile = () => {
    resetStates();
    hidePopup();
    backToFile();
  };

  const handleCheckData = async () => {
    setLoading(true);
    const { data: errorsList } = await checkData({
      projectId: project.id,
      ...data,
    });

    const routeErrors = [];
    const pointErrors = [];
    const routePointErrors = [];

    errorsList.forEach((err) => {
      switch (err.type) {
        case "POINT": {
          pointErrors.push(err);
          break;
        }
        case "ROUTE": {
          routeErrors.push(err);
          break;
        }
        case "ROUTE_POINT": {
          routePointErrors.push(err);
          break;
        }
        default: {
          break;
        }
      }
    });

    setErrors({
      routeList: routeErrors,
      pointList: pointErrors,
      routePointList: routePointErrors,
    });

    // get lines (from cells) with errors to exclude them after upload
    const uniqueLinesWithErrors = [
      ...new Map(
        routePointErrors.map((item) => [item.rowNumber, item]),
      ).values(),
    ]
      .concat([
        ...new Map(pointErrors.map((item) => [item.rowNumber, item])).values(),
      ])
      .concat([
        ...new Map(routeErrors.map((item) => [item.rowNumber, item])).values(),
      ]);
    setLinesWithErrors(uniqueLinesWithErrors);

    setChecked(true);
    setLoading(false);
  };

  const handleUploadData = async () => {
    setLoading(true);
    dispatch(deleteImportedFile());
    await uploadData({
      projectId: project.id,
      ...data,
    });

    // leave just lines that weren't upload and clear errors
    dispatch(excludeUploadedData(linesWithErrors));
    resetStates();

    setLoading(false);
  };

  const toggleHideValidLines = (e) => {
    setShowJustLinesWithErrors(e.target.checked);
  };

  const handleChangeTable = (event, newValue) => {
    setCurrentTable(newValue);
  };

  const handleToProject = () => {
    hidePopup();
  };

  return (
    <StyledCommonPopup
      isCenter
      title="Загрузить данные из Excel"
      className="edit-excel-data-popup__common-popup"
    >
      <Flex mt="20px" flexDirection="row">
        <Button
          height="48px"
          color="transparent"
          textColor="#2D8AF6"
          text="НАЗАД К ФАЙЛУ"
          disabled={isUploading || uploaded}
          onClick={handleBackToFile}
        />
        <Button
          height="48px"
          color="#CEE3FD"
          textColor="#2D8AF6"
          text=" ПРОВЕРКА ДАННЫХ"
          icon={checkDataIcon}
          disabled={isUploading || loading}
          onClick={handleCheckData}
          style={{ marginLeft: "16px" }}
        />
        <Button
          height="48px"
          color="#2D8AF6"
          text="ЗАГРУЗИТЬ В ПРОЕКТ"
          disabled={!checked || loading || isUploading}
          onClick={handleUploadData}
          style={{ marginLeft: "16px" }}
        />
        {linesWithErrors.length > 0 && !uploaded && (
          <Flex
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
            ml="16px"
          >
            <Checkbox
              checked={showJustLinesWithErrors}
              onChange={toggleHideValidLines}
            />
            <Text style={{ marginLeft: 8 }}>
              Отобразить только строки с ошибками
            </Text>
          </Flex>
        )}
      </Flex>
      <Flex mt="16px" flexDirection="row">
        <Tabs
          currentTable={currentTable}
          handleChangeTable={handleChangeTable}
        />
      </Flex>
      <Flex mt="20px" flexDirection="row">
        {isUploading || loading ? (
          <Flex height='30vh'>
            <Loader isLoading />
          </Flex>
        ) : (
          <Table
            table={currentTable}
            data={data[currentTable]}
            errors={errors[currentTable]}
            checked={checked}
            editable={currentTable === "routeList"}
            showJustLinesWithErrors={showJustLinesWithErrors}
          />
        )}
      </Flex>
      <Flex mt="20px" flexDirection="column">
        {linesWithErrors.length > 0 && !uploaded && !isUploading && (
          <Text variant="body1" color="color6">
            Исправьте строки с ошибками (
            <strong>{linesWithErrors.length}</strong>
            ), иначе они не будут загружены в проект
          </Text>
        )}
        {
          isUploading && <Text variant="body1" color="color6">
            Пожалуйста, не закрывайте данное окно. Загрузка более 700 точек доставки влияет на скорость работы АРТЕМИДЫ.
          </Text>
        }
        {uploaded && (
          <>
            <Text variant="body1" color="color8">
              Данные успешно загружены!
            </Text>
            <Button
              height="48px"
              color="#2D8AF6"
              text="ПЕРЕЙТИ В ПРОЕКТ"
              onClick={handleToProject}
            />
          </>
        )}
      </Flex>
    </StyledCommonPopup>
  );
};

const StyledCommonPopup = styled(Popup)`
  &.edit-excel-data-popup__common-popup {
    min-width: 80vw;
    max-width: 90vw;
  }
`;

export default EditExcelData;
