import { api } from "shared/api";

const statesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    listStates: builder.query({
      query: ({ projectId }) => `projects/${projectId}/states`,
      providesTags: ["States"],
    }),
    undoStates: builder.mutation({
      query: ({ projectId, step }) => ({
        url: `projects/${projectId}/states/undo?step=${step}`,
        method: "HEAD",
      }),
      invalidatesTags: [
        "Routes",
        "Days",
        "Coordinates",
        "Logistic-Coordinates",
        "Areas",
        "Shedule",
        "States",
      ],
    }),
    redoStates: builder.mutation({
      query: ({ projectId, step }) => ({
        url: `projects/${projectId}/states/redo?step=${step}`,
        method: "HEAD",
      }),
      invalidatesTags: [
        "Routes",
        "Days",
        "Coordinates",
        "Logistic-Coordinates",
        "Areas",
        "Shedule",
        "States",
      ],
    }),
  }),
});

export const {
  useListStatesQuery,
  useUndoStatesMutation,
  useRedoStatesMutation,
} = statesApi;
