/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  actionsDropdownId: null,
};

const projects = createSlice({
  name: "projects",
  initialState,
  reducers: {
    resetState: () => initialState,
    setActionsDropdownId: (state, action) => {
      state.actionsDropdownId = action.payload;
    },
  },
});

export const { resetState, setActionsDropdownId } = projects.actions;

export default projects.reducer;
