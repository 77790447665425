import React, { useCallback, useEffect, useState } from "react";
import { useMap } from "react-leaflet";
import { useSelector } from "react-redux";
import useSupercluster from "use-supercluster";

import { ClusterMarker, PointMarker } from "../../ui";

const Cluster = ({ data = [], highlightedPointId = null }) => {
  const [bounds, setBounds] = useState(null);
  const [zoom, setZoom] = useState(12);
  const map = useMap();
  const { clustering } = useSelector(
    (state) => state.rootReducer.projectSettings,
  );

  function updateMap() {
    const b = map.getBounds();
    setBounds([
      b.getSouthWest().lng,
      b.getSouthWest().lat,
      b.getNorthEast().lng,
      b.getNorthEast().lat,
    ]);
    setZoom(map.getZoom());
  }

  const onMove = useCallback(() => {
    updateMap();
  }, [map]);

  React.useEffect(() => {
    updateMap();
  }, [map]);

  useEffect(() => {
    map.on("move", onMove);
    return () => {
      map.off("move", onMove);
    };
  }, [map, onMove]);

  const points = data.map((item) => ({
    type: "Feature",
    properties: { cluster: false, id: item.id, point: item },
    geometry: {
      type: "Point",
      coordinates: [item.longitude, item.latitude],
    },
  }));

  const { clusters, supercluster } = useSupercluster({
    points,
    bounds,
    zoom,
    options: { radius: clustering ? 75 : 1, maxZoom: 17 },
  });

  return (
    <>
      {clusters.map((cluster) => {
        const {
          cluster: isCluster,
          point_count: pointCount,
          point,
        } = cluster.properties;

        if (isCluster) {
          return (
            <ClusterMarker
              key={`cluster-${cluster.id}`}
              cluster={cluster}
              pointCount={pointCount}
              points={points}
              supercluster={supercluster}
              highlightedPointId={highlightedPointId}
            />
          );
        }

        return (
          <PointMarker
            key={`point-${cluster.properties.id}`}
            data={data}
            point={point}
            highlightedPointId={highlightedPointId}
          />
        );
      })}
    </>
  );
};

export default Cluster;
