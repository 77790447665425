import { PopupContext } from "app/context/PopupContext";
import {
  resetState as resetCurrentProjectState,
  setInfo,
} from "app/store/reducer/current-project";
import { resetState } from "app/store/reducer/routes";
import { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useProjectInfoQuery } from "shared/api/current-project";
import { PAGE_HEADER_HEIGHT } from "shared/config";
import styled, { css } from "styled-components";
import { ProjectNavigation } from "widgets";
import { EditExcelData } from "widgets/popups";

import Filters from "./filters";
import Map from "./map";
import Table from "./table";

const Routes = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const { pathname, state: queryParams } = useLocation();
  const { showPopup } = useContext(PopupContext);

  const { data: projectInfo, isFetching } = useProjectInfoQuery(
    { projectId: params.id },
    { skip: !params.id },
  );

  const { expandMode } = useSelector(
    (state) => state.rootReducer.projectSettings,
  );

  // if user comes from Excel file upload – show him popup
  useEffect(() => {
    if (queryParams?.openEditPopup) {
      showPopup(EditExcelData, {
        project: projectInfo,
        backToFile: () => navigate(-1),
      });
      navigate(pathname, { replace: true });
    }
  }, [queryParams, navigate]);

  useEffect(() => {
    if (projectInfo) {
      dispatch(
        setInfo({
          id: projectInfo.id,
          name: projectInfo.name,
          status: projectInfo.status,
        }),
      );
    }
  }, [projectInfo]);

  useEffect(
    () => () => {
      dispatch(resetCurrentProjectState());
      dispatch(resetState());
    },
    [],
  );

  if (isFetching) {
    return null;
  }

  return (
    <Root>
      <LeftPageContainer expanded={expandMode === "table"}>
        <Header>
          <ProjectNavigation />
          <Filters />
        </Header>
        {expandMode !== "map" && <Table />}
      </LeftPageContainer>
      <RightPageContainer expanded={expandMode === "map"}>
        {expandMode !== "table" && <Map />}
      </RightPageContainer>
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  height: 100%;
`;

const LeftPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 60%;

  ${({ expanded }) =>
    expanded &&
    css`
      position: fixed;
      top: 0;
      right: 0;
      z-index: 10;
      height: 100vh;
      width: 100vw;
    `}
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: ${PAGE_HEADER_HEIGHT};
  padding: 20px 16px;
  background: #ecf4fe;
  z-index: 20;
`;

const RightPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 40%;

  ${({ expanded }) =>
    expanded &&
    css`
      position: fixed;
      top: 0;
      right: 0;
      z-index: 10;
      height: 100vh;
      width: 100vw;
    `}
`;

export default Routes;
