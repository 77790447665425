import styled, { css } from "styled-components";

const ClusterMarker = ({
  size = 20,
  count = 0,
  gradient = "#2d8af6",
  highlighted = false,
}) => (
  <Root size={size} gradient={gradient} highlighted={highlighted}>
    <Inner highlighted={highlighted}>{count}</Inner>
  </Root>
);

const Root = styled.div`
  color: #fff;
  border-radius: 50%;
  padding: 10px;
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  // if cluster without pie chart
  ${({ gradient, highlighted }) =>
    gradient === "#2d8af6" &&
    css`
      background: ${() => (highlighted ? "red" : gradient)};
    `}

  // if cluster with pie chart
  ${({ gradient }) =>
    gradient !== "#2d8af6" &&
    css`
      background: ${() => gradient};
      @keyframes pulse {
        0% {
          transform: scale(1.35);
        }
        50% {
          transform: scale(1.5);
        }
        100% {
          transform: scale(1.35);
        }
      }
      animation: ${({ highlighted }) =>
        highlighted ? "pulse 1.75s infinite" : "none"};
      box-shadow: ${({ highlighted }) =>
        highlighted ? "0 0 10px 3px rgba(0, 0, 0, 0.2)" : "none"};
    `}
`;

const Inner = styled.div`
  color: #000;
  background: #fff;
  border-radius: 50%;
  padding: 10px;
  width: 66%;
  height: 66%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
`;

export default ClusterMarker;
