import React from "react";
import { Flex, Text } from "shared/ui";

const PointInfo = ({ shownPoint }) => (
  <Flex width="290px" overflowY="auto">
    <Flex mb="4px">
      <Text variant="body3bold">{shownPoint?.pointCode}</Text>
    </Flex>
    <Flex mb="4px">
      <Text variant="body3">{shownPoint?.contractName}</Text>
    </Flex>
    <Flex mb="4px" flexDirection="row">
      <Text variant="body3" color="color7">
        {shownPoint?.areas?.length
          ? shownPoint.areas.map((item) => item?.name).join(", ")
          : "Зона не установлена"}
      </Text>
    </Flex>
    <Flex mb="4px">
      <Text variant="body3">{shownPoint?.pointName}</Text>
    </Flex>
    <Flex mb="10px">
      <Text variant="body3">{shownPoint?.address}</Text>
    </Flex>
    <Flex mb="5px">
      <Text variant="body3" color="color3">
        Продажи {shownPoint?.margin}
      </Text>
    </Flex>
    <Flex mb="5px">
      <Text variant="body3" color="color3">
        Объём {shownPoint?.volume}
      </Text>
    </Flex>
    <Flex>
      <Text variant="body3" color="color3">
        Вес {shownPoint?.weight}
      </Text>
    </Flex>
  </Flex>
);

export default PointInfo;
