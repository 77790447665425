import {
  setChangeCoordsFormData,
  setPointFoundByMarker,
  setSelectedPoints,
} from "app/store/reducer/coordinates";
import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Flex, PointPopup as PointPopupUI, Text } from "shared/ui";
import { DeliveryTermsReport } from "widgets";

const PointPopup = React.memo(({ isCluster = false, multipleList = [] }) => {
  const dispatch = useDispatch();
  const [shownPoint, setShownPoint] = useState(multipleList[0]);
  const { selectedPoints } = useSelector(
    (state) => state.rootReducer.coordinates,
  );

  const onChangeCoordsButtonClick = useCallback(() => {
    dispatch(
      setChangeCoordsFormData({
        isVisible: true,
        point: shownPoint,
      }),
    );
  }, [dispatch, shownPoint]);

  const onSelect = (point) => {
    dispatch(setSelectedPoints(point?.id));
  };

  const onSetShownPoint = (point) => {
    setShownPoint(point);
    dispatch(
      setPointFoundByMarker({
        pointCode: point.pointCode,
        highlightedPointId: point.id,
      }),
    );
  };

  return (
    <PointPopupUI
      multipleList={multipleList}
      selectedPoints={selectedPoints}
      shownPoint={shownPoint}
      onSetShownPoint={onSetShownPoint}
      isSelectable
      onSelect={onSelect}
      withTip={!isCluster}
    >
      <Flex width="290px" mr="20px" overflowY="scroll">
        <Flex mb="4px">
          <Text variant="body3bold">{shownPoint?.pointCode}</Text>
        </Flex>
        <Flex mb="4px">
          <Text variant="body3">{shownPoint?.pointName}</Text>
        </Flex>
        <Flex mb="10px">
          <Text variant="body3">{shownPoint?.address}</Text>
        </Flex>
        <Flex mb="10px">
          <Text variant="body3">Разница {shownPoint?.distance}</Text>
        </Flex>
        <Flex
          width="max-content"
          cursor="pointer"
          onClick={() => onChangeCoordsButtonClick()}
        >
          <Text variant="body3" color="color0">
            Изменить координаты
          </Text>
        </Flex>
        <DeliveryTermsReport
          routeId={shownPoint?.routeId}
          pointId={shownPoint?.id}
        />
      </Flex>
    </PointPopupUI>
  );
});

export default PointPopup;
