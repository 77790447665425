/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { CYCLES, MAX_LIMIT_FILTER } from "shared/config";

const resetStateByFilters = (state) => {
  state.selectedRoutePoints = [];
  state.mapCenterPoint = {};
  state.allPointsChecked = false;
};

const initialState = {
  frontendFilters: {
    page: 0,
    limit: MAX_LIMIT_FILTER,
    managerCodes: [],
    commands: [],
    routeCodes: [],
    routeIds: [],
    address: "",
    daysOfWeek: ["Понедельник"],
    cyclePoint: CYCLES.EVERY_WEEK,
  },
  selectedRoutePoints: [],
  mapCenterPoint: {},
  isMapRoutePointsVisible: true,
  isAreasVisible: true,
  allPointsChecked: false,
};

const days = createSlice({
  name: "days",
  initialState,
  reducers: {
    resetState: () => initialState,
    setManagerFilter: (state, action) => {
      state.frontendFilters.routeCodes = [];
      state.frontendFilters.routeIds = [];
      resetStateByFilters(state);
      state.frontendFilters.managerCodes = action.payload;
    },
    resetManagerFilter: (state) => {
      state.frontendFilters.managerCodes = [];
      resetStateByFilters(state);
    },
    setCommandFilter: (state, action) => {
      state.frontendFilters.routeCodes = [];
      state.frontendFilters.routeIds = [];
      resetStateByFilters(state);
      state.frontendFilters.commands = action.payload;
    },
    resetCommandFilter: (state) => {
      state.frontendFilters.commands = [];
      resetStateByFilters(state);
    },
    setRouteFilter: (state, action) => {
      resetStateByFilters(state);
      state.frontendFilters.routeCodes = action.payload.routeCode;
      state.frontendFilters.routeIds = action.payload.routeId;
    },
    setMultipleRouteFilter: (state, action) => {
      if (state.frontendFilters.routeCodes.includes(action.payload.routeCode)) {
        if (state.frontendFilters.routeCodes.length === 1) return;
        state.frontendFilters.routeCodes =
          state.frontendFilters.routeCodes.filter(
            (item) => item !== action.payload.routeCode,
          );
        state.frontendFilters.routeIds = state.frontendFilters.routeIds.filter(
          (item) => item !== action.payload.routeId,
        );
      } else {
        state.frontendFilters.routeCodes = [
          ...state.frontendFilters.routeCodes,
          action.payload.routeCode,
        ];
        state.frontendFilters.routeIds = [
          ...state.frontendFilters.routeIds,
          action.payload.routeId,
        ];
      }
    },
    resetMultipleRouteFilter: (state) => {
      state.frontendFilters.routeCodes = [state.frontendFilters.routeCodes[0]];
      state.frontendFilters.routeIds = [state.frontendFilters.routeIds[0]];
    },
    setAddressFilter: (state, action) => {
      resetStateByFilters(state);
      state.frontendFilters.address = action.payload;
    },
    setDaysFilter: (state, action) => {
      resetStateByFilters(state);
      state.frontendFilters.daysOfWeek = action.payload;
    },
    setCycleFilter: (state, action) => {
      resetStateByFilters(state);
      state.frontendFilters.cyclePoint = action.payload;
    },
    setSelectedRoutePoints: (state, action) => {
      if (state.selectedRoutePoints.includes(action.payload)) {
        state.selectedRoutePoints = state.selectedRoutePoints.filter(
          (item) => item !== action.payload,
        );
      } else {
        state.selectedRoutePoints = [
          ...state.selectedRoutePoints,
          action.payload,
        ];
      }
    },
    setSelectedByLassoRoutePoints: (state, action) => {
      if (action.payload instanceof Array) {
        state.selectedRoutePoints = [
          ...new Set([...state.selectedRoutePoints, ...action.payload]),
        ];
      } else if (!state.selectedRoutePoints.includes(action.payload)) {
        state.selectedRoutePoints = [
          ...state.selectedRoutePoints,
          action.payload,
        ];
      }
    },
    resetSelectedRoutePoints: (state) => {
      state.selectedRoutePoints = [];
    },
    setAllPointsChecked: (state, action) => {
      state.allPointsChecked = action.payload;
    },
    setMapCenterPoint: (state, action) => {
      state.mapCenterPoint = action.payload;
    },
    switchMapRoutePointsVisible: (state) => {
      state.isMapRoutePointsVisible = !state.isMapRoutePointsVisible;
    },
    switchAreasVisible: (state) => {
      state.isAreasVisible = !state.isAreasVisible;
    },
  },
});

export const {
  resetState,
  setManagerFilter,
  resetManagerFilter,
  setCommandFilter,
  resetCommandFilter,
  setRouteFilter,
  setMultipleRouteFilter,
  resetMultipleRouteFilter,
  setAddressFilter,
  setDaysFilter,
  setCycleFilter,
  setSelectedRoutePoints,
  setSelectedByLassoRoutePoints,
  resetSelectedRoutePoints,
  setAllPointsChecked,
  setMapCenterPoint,
  switchMapRoutePointsVisible,
  switchAreasVisible,
} = days.actions;

export default days.reducer;
