import {
  resetExpandedMapPopupState,
  setExpandedMapPopupData,
} from "app/store/reducer/expanded-map-popup";
import { useDispatch } from "react-redux";

import PointPopup from "../point-popup";
import { MarkerIcon } from "./ui";

const PointMarker = ({ data = [], point = {} }) => {
  const dispatch = useDispatch();

  const onMarkerClicked = async () => {
    dispatch(
      setExpandedMapPopupData({
        point,
        multipleList: data
          .filter(
            (item) =>
              Math.abs(item.latitude - point.latitude) < 0.00001 &&
              Math.abs(item.longitude - point.longitude) < 0.00001,
          )
          .sort((a, b) => a.id - b.id),
      }),
    );
  };

  const onPopupClosed = () => {
    dispatch(resetExpandedMapPopupState());
  };

  return (
    <MarkerIcon
      point={point}
      onMarkerClicked={onMarkerClicked}
      onPopupClosed={onPopupClosed}
    >
      <PointPopup
        multipleList={data
          .filter(
            (item) =>
              Math.abs(item.latitude - point.latitude) < 0.00001 &&
              Math.abs(item.longitude - point.longitude) < 0.00001,
          )
          .reverse()}
      />
    </MarkerIcon>
  );
};

export default PointMarker;
