import { dropdownArrowBottomIcon } from "icons/icons";
import React from "react";
import { Flex, Text } from "shared/ui";
import styled from "styled-components";

const Toggle = React.forwardRef(({ onClick, toggleText }, ref) => (
  <Root
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    <Flex>
      <Text variant="body1" color="color2">
        {toggleText}
      </Text>
    </Flex>
    <Flex cursor="pointer">{dropdownArrowBottomIcon}</Flex>
  </Root>
));

const Root = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 48px;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  padding: 12px;
  background: #ffffff;
  cursor: pointer;
`;

export default Toggle;
