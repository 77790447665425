import styled from "styled-components";
import { color, compose, flexbox, space, typography } from "styled-system";

const Text = ({ variant, children, ...props }) => (
  <Root $variant={variant} {...props}>
    {children}
  </Root>
);

const Root = styled.p`
  ${({ $variant, theme: { typography: themeTypography } }) =>
    themeTypography[$variant]}
  ${compose(color, space, typography, flexbox)};
  padding: 0;
  margin: 0;
  ${({ textTransform }) => ({ textTransform })}
`;

export default Text;
