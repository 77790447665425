import React from "react";

const Clustering = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 32 32"
    fill='white'
    {...props}
  >
    <path
      d="M21,5c-1.802,0-3.499,0.442-5,1.21C14.499,5.442,12.802,5,11,5C4.925,5,0,9.925,0,16s4.925,11,11,11
	c1.802,0,3.499-0.442,5-1.21c1.501,0.768,3.198,1.21,5,1.21c6.075,0,11-4.925,11-11S27.075,5,21,5z M15.426,8.941
	c0.342-0.269,0.84-0.267,1.18,0.004c0.735,0.587,1.36,1.285,1.873,2.055h-4.95C14.047,10.228,14.679,9.528,15.426,8.941z M12.514,13
	h6.974c0.226,0.642,0.379,1.312,0.455,2h-7.886C12.133,14.312,12.287,13.642,12.514,13z M12.057,17h7.886
	c-0.076,0.688-0.23,1.358-0.457,2h-6.973C12.287,18.358,12.133,17.688,12.057,17z M16.589,23.047c-0.341,0.269-0.838,0.269-1.178,0
	c-0.74-0.585-1.367-1.28-1.881-2.047h4.941C17.956,21.766,17.329,22.462,16.589,23.047z M21,25c-1.05,0-2.054-0.191-2.991-0.523
	C20.446,22.46,22,19.412,22,16c0-3.413-1.555-6.462-3.994-8.48C18.944,7.188,19.95,7,21,7c4.963,0,9,4.037,9,9
	C30,20.963,25.963,25,21,25z"
    />
  </svg>
);

export default Clustering;
