import * as yup from "yup";

export const GroupsSchema = yup.object().shape({
  groups: yup.array().of(
    yup.object().shape({
      id: yup
        .number()
        .required("Обязательное поле")
        .typeError("Обязательное поле"),
      name: yup.string().required("Обязательное поле"),
      contractCodes: yup.array(),
    }),
  ),
});
