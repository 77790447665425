import { PopupContext } from "app/context/PopupContext";
import moment from "moment-timezone";
import { useContext, useState } from "react";
import { API_URL } from "shared/api/config";
import { SERVER_ERROR_TEXT } from "shared/config";
import { getHeadersWithToken } from "shared/helpers";
import { Button, ErrorText, Flex, Loader, Popup, Text } from "shared/ui";

const UploadReport = ({ project }) => {
  const { hidePopup } = useContext(PopupContext);
  const [isLoading, setIsLoading] = useState(false);
  const [serverError, setServerError] = useState("");

  const onUploadButtonClick = async (withGoogleData) => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `${API_URL}/api/projects/${project.id}/routes/reports/filling-routes/downloads?getGoogleMapData=${withGoogleData}`,
        {
          mode: "cors",
          credentials: "include",
          withCredentials: true,
          headers: getHeadersWithToken(),
        },
      );
      setIsLoading(false);
      if (response.status === 200) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `routes_${project.id}_${moment()
          .tz("Europe/Moscow")
          .format("DD-MM-YYYY_HH-mm")}(МСК).xlsx`;
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove();
        hidePopup();
      } else setServerError(SERVER_ERROR_TEXT);
    } catch (error) {
      setServerError(SERVER_ERROR_TEXT);
    }
  };

  return (
    <Popup
      isCenter
      title="Выгрузить отчётность по проекту?"
      isLoading={isLoading}
    >
      <Flex mt="6px">
        <Text variant="body2" color="color3">
          Для расчета пробега и времени в пути используются платные запросы в Google Maps.
        </Text>
      </Flex>
      <Flex
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        mt="12px"
      >
        <Button
          height="48px"
          color="#2D8AF6"
          text="С пробегом и временем в пути"
          onClick={() => onUploadButtonClick(true)}
          disabled={isLoading}
        />
        <Button
          height="48px"
          color="#2D8AF6"
          text="Без пробега и времени в пути"
          onClick={() => onUploadButtonClick(false)}
          disabled={isLoading}
        />
      </Flex>
      <ErrorText errorText={serverError} />
      <Loader isLoading={isLoading} />
    </Popup>
  );
};

export default UploadReport;
