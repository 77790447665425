import * as React from "react";

const SvgCalendarToday = (props) => (
  <svg
    width={20}
    height={22}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M20 2h-3V0h-2v2H5V0H3v2H0v20h20V2Zm-2 18H2V7h16v13Z" fill="#fff" />
  </svg>
);

export default SvgCalendarToday;
