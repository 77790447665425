import { api } from "shared/api";

const yandexApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getCoordsByAddressSearch: builder.query({
      query: (address) => `projects/points/coordinates?address=${address}`,
    }),
  }),
});

export const { useLazyGetCoordsByAddressSearchQuery } = yandexApi;
