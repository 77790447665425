import { useSelector } from "react-redux";
import { useListStatesQuery, useRedoStatesMutation } from "shared/api/states";

import { StateDropdown } from "../../ui";

const RedoDropdown = () => {
  const { projectInfo } = useSelector(
    (state) => state.rootReducer.currentProject,
  );
  const { data, isFetching } = useListStatesQuery(
    { projectId: projectInfo.id },
    { skip: !projectInfo.id },
  );
  const [redoStates] = useRedoStatesMutation();

  const onLastStateClick = async () => {
    if (data?.redoEvents?.length) {
      await redoStates({
        projectId: projectInfo.id,
        step: data?.redoEvents[0]?.step,
      });
    }
  };

  const onStateClick = async (step) => {
    await redoStates({ projectId: projectInfo.id, step });
  };

  if (isFetching) {
    return null;
  }

  return (
    <StateDropdown
      toggleText="Повторить"
      actionsText="Действий для повтора:"
      noActionsText="Нет действий для повтора"
      state={data?.redoEvents}
      onLastStateClick={onLastStateClick}
      onStateClick={onStateClick}
    />
  );
};

export default RedoDropdown;
