import { UndoIcon, dropdownArrowBottomIcon } from "icons/icons";
import { useState } from "react";
import { Dropdown as BootstrapDropdown } from "react-bootstrap";
import { Flex, Text } from "shared/ui";
import styled from "styled-components";

const { Toggle, Menu, ItemText, Divider, Item } = BootstrapDropdown;

const StateDropdown = ({
  toggleText = "Выбрать",
  actionsText = "Действий",
  noActionsText = "Нет действий",
  state = [],
  onLastStateClick = () => {},
  onStateClick = () => {},
}) => {
  const [hoverCount, setHoverCount] = useState(0);

  const onToggle = (show) => {
    if (!show) {
      setHoverCount(0);
    }
  };

  return (
    <Root>
      <BootstrapDropdown onToggle={(show) => onToggle(show)}>
        <Flex flexDirection="row" alignItems="center" cursor="pointer">
          <Flex
            flexDirection="row"
            alignItems="center"
            onClick={() => onLastStateClick()}
          >
            <Flex mr="6px">
              <UndoIcon color={state.length ? "#000000" : "#CCCCCC"} />
            </Flex>
            <Text variant="body3" color={state.length ? "color2" : "color4"}>
              {toggleText}
            </Text>
          </Flex>
          <Flex ml="10px">
            <Toggle>{dropdownArrowBottomIcon}</Toggle>
          </Flex>
        </Flex>

        <Menu>
          {state.length ? (
            <ItemText>
              {actionsText} {hoverCount + 1}
            </ItemText>
          ) : (
            <ItemText>{noActionsText}</ItemText>
          )}
          <Divider />
          <Flex maxHeight="200px" overflowY="auto">
            {state.map((item, index) => (
              <ItemWrapper
                key={item.step}
                hoveredIndex={hoverCount}
                currentIndex={index}
                onMouseMove={() => setHoverCount(index)}
              >
                <Item onClick={() => onStateClick(item.step)}>{item.name}</Item>
              </ItemWrapper>
            ))}
          </Flex>
        </Menu>
      </BootstrapDropdown>
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  margin-right: 5px;

  .btn {
    border-radius: 0;
  }

  .btn-primary {
    width: 100%;
    padding: 0;
    background: #ecf4fe;
    color: #000000;
    border: none;
    box-shadow: none;
  }

  .show > .btn-primary.dropdown-toggle {
    width: 100%;
    padding: 0;
    background-color: #fff;
    color: #000000;
    border: none;
  }

  .show > .btn-primary.dropdown-toggle:focus {
    box-shadow: none;
    background: #ecf4fe;
    color: #000000;
  }

  .btn-primary:not(:disabled):not(.disabled).active,
  .btn-primary:not(:disabled):not(.disabled):active,
  .show > .btn-primary.dropdown-toggle {
    background: #ecf4fe;
    color: #000000;
  }

  .dropdown-toggle::after {
    content: none;
  }

  .dropdown-menu {
    width: auto;
    padding: 10px 20px;
    border: 0;
    z-index: 10000;
  }

  .dropdown-menu.show {
    border-radius: 0;
    transform: translate(-20px, -18px) !important;
  }

  .dropdown-item {
    font-style: normal;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    padding: 10px 0px;
  }

  .dropdown-item-text {
    display: flex;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    padding: 10px 0px;
    white-space: nowrap;
  }

  .dropdown-divider {
    border-top: 1px solid #cccccc;
  }
`;

const ItemWrapper = styled.div`
  .dropdown-item {
    background-color: ${({ hoveredIndex, currentIndex }) =>
      hoveredIndex >= currentIndex ? "#2D8AF6" : "none"};
  }
`;

export default StateDropdown;
