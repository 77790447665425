import React from "react";
import { useMap } from "react-leaflet";

const ChangeViewNew = React.memo(({ mapCenterPoint = {} }) => {
  const map = useMap();
  const { latitude, longitude } = mapCenterPoint;

  if (latitude && longitude) {
    map.setView([latitude, longitude], map.getZoom());
  }

  return null;
});

export default ChangeViewNew;
