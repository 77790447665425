import { PopupContext } from "app/context/PopupContext";
import moment from "moment-timezone";
import { useContext, useState } from "react";
import { Calendar } from "react-calendar";
import { useExportDataMutation } from "shared/api/projects";
import { EXPORT_LIST, SERVER_ERROR_TEXT } from "shared/config";
import {
  Button,
  Checkbox,
  Divider,
  ErrorText,
  Flex,
  Loader,
  PageShadow,
  Popup,
  Text,
} from "shared/ui";
import styled from "styled-components";

const ExportLogistic = ({ project }) => {
  const { hidePopup } = useContext(PopupContext);
  const isUrgentPoints = project.name === "Срочные точки";
  const [exportList, setExportList] = useState([]);
  const [dateFrom, setDate] = useState(new Date(moment().add("day", 1)));
  const [serverError, setServerError] = useState("");
  const [exportData, { isLoading }] = useExportDataMutation();

  const onExportListChange = (checked, exportItem) => {
    if (checked) setExportList([...exportList, exportItem]);
    else {
      const newArr = exportList.filter((item) => item !== exportItem);
      setExportList(newArr);
    }
  };

  const onSetRoutesButtonClick = async () => {
    let dateString;
    let response;

    if (isUrgentPoints) {
      dateString = moment(moment.now()).format("YYYY-MM-DD");
      response = await exportData({
        id: project.id,
        exportList: [EXPORT_LIST.COORDINATES, EXPORT_LIST.LOGISTIC_CALENDAR],
        dateFrom: dateString,
      });
    } else {
      dateString = moment(dateFrom).format("YYYY-MM-DD");
      response = await exportData({
        id: project.id,
        exportList,
        dateFrom: dateString,
      });
    }

    if (response.error) {
      setServerError(response.error?.data?.message || SERVER_ERROR_TEXT);
      return;
    }

    hidePopup();
  };

  if (isLoading) {
    return (
      <PageShadow>
        <Loader isLoading />
      </PageShadow>
    );
  }

  return (
    <Popup isCenter title="Выгрузка в УС">
      <Flex flexDirection="row" mt="20px">
        <Flex flexDirection="row" alignItems="center" mr="35px">
          <Flex mr="7px">
            <Checkbox
              checked={
                isUrgentPoints || exportList.includes(EXPORT_LIST.COORDINATES)
              }
              onChange={(e) =>
                onExportListChange(e.target.checked, EXPORT_LIST.COORDINATES)
              }
              filledColor={isUrgentPoints ? "#ccc" : "black"}
            />
          </Flex>
          <Text variant="body1" color={isUrgentPoints ? "color4" : "color2"}>
            Координаты
          </Text>
        </Flex>
        <Flex flexDirection="row" alignItems="center" mr="35px">
          <Flex mr="7px">
            <Checkbox
              checked={
                isUrgentPoints ||
                exportList.includes(EXPORT_LIST.LOGISTIC_CALENDAR)
              }
              onChange={(e) =>
                onExportListChange(
                  e.target.checked,
                  EXPORT_LIST.LOGISTIC_CALENDAR,
                )
              }
              filledColor={isUrgentPoints ? "#ccc" : "black"}
            />
          </Flex>
          <Text variant="body1" color={isUrgentPoints ? "color4" : "color2"}>
            Лог календарь
          </Text>
        </Flex>
      </Flex>
      <Divider />
      {!isUrgentPoints && (
        <Flex alignItems="center">
          <Flex mb="20px">
            <Text variant="body3">
              Выберите дату начала действия изменений логкалендаря
            </Text>
          </Flex>
          <CalendarWrapper>
            <Calendar
              value={dateFrom}
              minDate={new Date(moment().add("day", 1))}
              onChange={(value) => setDate(value)}
            />
          </CalendarWrapper>
        </Flex>
      )}
      <Flex mt="20px">
        <Button
          width="100%"
          height="48px"
          color="#2D8AF6"
          text="ВЫГРУЗИТЬ В УС"
          onClick={() => onSetRoutesButtonClick()}
          disabled={!isUrgentPoints && exportList.length < 1}
        />
      </Flex>
      <ErrorText errorText={serverError} />
    </Popup>
  );
};

const CalendarWrapper = styled.div`
  .react-calendar {
    border: none;
  }
`;

export default ExportLogistic;
