import { PopupContext } from "app/context/PopupContext";
/* eslint-disable no-console */
/* eslint-disable import/no-cycle */
import React, { useContext, useEffect, useState } from "react";
import { API_URL } from "shared/api/config";
import { getHeadersWithToken } from "shared/helpers";
import { Button, CustomRadio, Loader, Popup } from "shared/ui";
import styled from "styled-components";

import DriveModeDetails from "./drive-mode-details";

const MOVEMENT_MODES = {
  DRIVE: "DRIVE",
  WALKING: "WALKING",
};

export const DRIVE_MODE_PARAMETERS = {
  HIGHWAYS: "HIGHWAYS",
  TOLLS: "TOLLS",
  FERRIES: "FERRIES",
};

const RoutesCalculation = ({ project }) => {
  const { hidePopup } = useContext(PopupContext);
  const [settings, setSettings] = useState({
    id: null,
    driveMode: {
      mode: "DRIVE",
      departureTime: null,
      parameters: [],
    },
    walkingMode: {
      mode: "WALKING",
      departureTime: null,
      parameters: [],
    },
  });

  const [movementMode, setMovementMode] = useState(null);

  const [isSettingsLoading, setIsSettingsLoading] = useState(false);
  const [isUpdateLoading, setIsUpdateLoading] = useState(false);

  const handleMovementMode = (mode) => {
    setMovementMode(mode);
  };

  const handleDriveModeParameters = (value) => {
    if (settings.driveMode.parameters.includes(value)) {
      setSettings({
        ...settings,
        driveMode: {
          ...settings.driveMode,
          parameters: settings.driveMode.parameters.filter(
            (item) => item !== value,
          ),
        },
      });
    } else {
      setSettings({
        ...settings,
        driveMode: {
          ...settings.driveMode,
          parameters: [...settings.driveMode.parameters, value],
        },
      });
    }
  };

  const handleDriveModeTime = (e) => {
    setSettings({
      ...settings,
      driveMode: {
        ...settings.driveMode,
        departureTime: e.target.value ? e.target.value : null,
      },
    });
  };

  const updateSettings = async () => {
    const headers = getHeadersWithToken();

    const body =
      movementMode === MOVEMENT_MODES.DRIVE
        ? {
            id: settings.id,
            ...settings.driveMode,
          }
        : {
            id: settings.id,
            ...settings.walkingMode,
          };

    setIsUpdateLoading(true);
    try {
      const response = await fetch(
        `${API_URL}/api/projects/${project.id}/settings`,
        {
          method: body.id ? "PATCH" : "POST",
          mode: "cors",
          credentials: "include",
          withCredentials: true,
          headers,
          body: JSON.stringify(body),
        },
      );
      setIsUpdateLoading(false);
      if (response.status === 200) {
        hidePopup();
      }
    } catch (error) {
      console.error(error);
      setIsUpdateLoading(false);
    }
  };

  useEffect(() => {
    const getSettings = async () => {
      const headers = getHeadersWithToken();

      setIsSettingsLoading(true);
      try {
        const response = await fetch(
          `${API_URL}/api/projects/${project.id}/settings`,
          {
            mode: "cors",
            credentials: "include",
            withCredentials: true,
            headers,
          },
        );
        setIsSettingsLoading(false);
        if (response.status === 200) {
          const data = await response.json();
          setSettings((prev) =>
            data.mode === MOVEMENT_MODES.DRIVE
              ? {
                  ...prev,
                  id: data.id,
                  driveMode: {
                    mode: data.mode,
                    departureTime: data.departureTime,
                    parameters: data.parameters,
                  },
                }
              : {
                  ...prev,
                  id: data.id,
                  walkingMode: {
                    mode: data.mode,
                    departureTime: data.departureTime,
                    parameters: data.parameters,
                  },
                },
          );
          setMovementMode(data.mode);
        }
      } catch (error) {
        console.error(error);
        setIsSettingsLoading(false);
      }
    };

    getSettings();
  }, [project]);

  return (
    <StyledCommonPopup
      isCenter
      title="Параметры расчета маршрутов"
      className="calculation-parameters-popup__common-popup"
    >
      <Container>
        {isSettingsLoading && (
          <LoaderWrapper>
            <Loader isLoading />
          </LoaderWrapper>
        )}
        {!isSettingsLoading && (
          <>
            <Options>
              <CustomRadio
                name="movementMode"
                className="custom-radio"
                label="На машине"
                checked={movementMode === MOVEMENT_MODES.DRIVE}
                onChange={() => handleMovementMode(MOVEMENT_MODES.DRIVE)}
              />
              <CustomRadio
                name="movementMode"
                className="custom-radio"
                label="Пешком"
                checked={movementMode === MOVEMENT_MODES.WALKING}
                onChange={() => handleMovementMode(MOVEMENT_MODES.WALKING)}
              />
            </Options>
            {movementMode === MOVEMENT_MODES.DRIVE && (
              <OptionDetails>
                <DriveModeDetails
                  handleTime={handleDriveModeTime}
                  time={settings.driveMode.departureTime || "00:00"}
                  parameters={settings.driveMode.parameters}
                  handleParameters={handleDriveModeParameters}
                />
              </OptionDetails>
            )}

            {movementMode && (
              <ButtonsWrapper>
                <Button
                  width="200px"
                  height="56px"
                  color="#2D8AF6"
                  text="СОХРАНИТЬ"
                  onClick={updateSettings}
                  disabled={isUpdateLoading}
                />
                <Button
                  width="200px"
                  height="56px"
                  color="#CEE3FD"
                  text="ОТМЕНА"
                  onClick={() => hidePopup()}
                  className="cancel-button"
                />
              </ButtonsWrapper>
            )}
          </>
        )}
      </Container>
    </StyledCommonPopup>
  );
};

const StyledCommonPopup = styled(Popup)`
  &.calculation-parameters-popup__common-popup {
    max-width: 898px;
    width: 100%;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  height: 390px;
`;

const Options = styled.div`
  display: flex;
  justify-content: flex-start;
  padding: 22px 0;
  border-top: 1px solid #cccccc;
  font-size: 18px;

  .custom-radio {
    margin-right: 50px;

    &:last-of-type {
      margin-right: 0;
    }
  }
`;

const OptionDetails = styled.div`
  padding: 20px 0 27px 0;
  border-top: 1px solid #cccccc;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 20px;
  border-top: 1px solid #cccccc;
  margin-top: auto;

  .cancel-button {
    color: #2d8af6;
    margin-left: 12px;
  }
`;

const LoaderWrapper = styled.div`
  position: relative;
  height: 100%;
`;

export default RoutesCalculation;
