import { useMemo } from "react";
import { Polygon } from "react-leaflet";
import { useSelector } from "react-redux";

import { calculatePolygonsData } from "./model";
import DayBasedPolygonPopup from "./popup";
import { PROJECT_MODE } from "../../shared/config";

const DayBasedPolygons = ({ data = [], projectMode = "" }) => {
  const { mapMarkersColoredBy } = useSelector(
    (state) => state.rootReducer.routes,
  );
  const polygonsData = useMemo(
    () => calculatePolygonsData(data, projectMode, mapMarkersColoredBy),
    [data, mapMarkersColoredBy, projectMode],
  );

  if (polygonsData.length === 0) return null;

  return (
    <>
      {polygonsData.map((polygon) => (
        <Polygon
          key={`${polygon.routeId}-${polygon.dayWeek}`}
          positions={polygon.position}
          pathOptions={{
            color: polygon.color,
            fillColor: polygon.color,
            fillOpacity: projectMode === PROJECT_MODE.ROUTES ? 0.1 : 0.15,
            stroke: projectMode === PROJECT_MODE.ROUTES
          }}
        >
          <DayBasedPolygonPopup polygon={polygon} />
        </Polygon>
      ))}
    </>
  );
};

export default DayBasedPolygons;
