import {
  resetExpandedMapPopupState,
  setExpandedMapPopupData,
} from "app/store/reducer/expanded-map-popup";
import {
  expandMap,
  switchClustering,
} from "app/store/reducer/project-settings";
import {
  setSelectedByLassoRoutePoints,
  switchMapPointsVisible,
} from "app/store/reducer/shedule";
import { ChangeViewNew } from "features";
import ResizeObserver from "features/map-resize-observer";
import RememberBounds from "features/return-map-to-prev-bounds";
import useLasso from "features/use-lasso";
import React, { useEffect } from "react";
import { Polygon } from "react-leaflet";
import { useDispatch, useSelector } from "react-redux";
import { useListAreasMapQuery } from "shared/api/areas";
import {
  useListAnalyzePeriodQuery,
  useListLogisticPointsMapQuery,
} from "shared/api/logistic";
import { Loader, Map as MapUI } from "shared/ui";
import Lasso from "shared/ui/lasso";

import TableFooter from "../table/footer";
import { Cluster, Footer } from "./model";
import { AreaPopup, ExpandedPointPopup } from "./ui";

const Map = () => {
  const dispatch = useDispatch();
  const { projectInfo } = useSelector(
    (state) => state.rootReducer.currentProject,
  );

  // Map data
  const {
    mapCenterPoint,
    isMapPointsVisible,
    highlightedPointId,
    frontendFilters: { areaIds, branchIds, contractIds, groupIds },
  } = useSelector((state) => state.rootReducer.shedule);
  const { data: analyzePeriod } = useListAnalyzePeriodQuery(
    {
      projectId: projectInfo.id,
    },
    { skip: !projectInfo.id },
  );
  const {
    data: areas,
    isSuccess: isAreasSuccess,
    isFetching: isAreasFetching,
  } = useListAreasMapQuery(
    {
      projectId: projectInfo.id,
      branchIds,
      groupIds,
    },
    {
      skip: !projectInfo.id,
      refetchOnMountOrArgChange: true,
    },
  );
  const {
    data: mapPoints,
    isSuccess: isPointsSuccess,
    isFetching: isPointsFetching,
  } = useListLogisticPointsMapQuery(
    {
      projectId: projectInfo.id,
      areaIds,
      branchIds,
      contractIds,
      groupIds,
      monthFrom: analyzePeriod?.monthFrom,
      monthTo: analyzePeriod?.monthTo,
      yearFrom: analyzePeriod?.yearFrom,
      yearTo: analyzePeriod?.yearTo,
    },
    {
      skip:
        !projectInfo.id ||
        !analyzePeriod?.monthFrom ||
        !analyzePeriod?.monthTo ||
        !analyzePeriod?.yearFrom ||
        !analyzePeriod?.yearTo,
      refetchOnMountOrArgChange: true,
    },
  );

  // Map settings
  const { expandMode, clustering } = useSelector(
    (state) => state.rootReducer.projectSettings,
  );

  // Fullscreen map controls
  const expanded = expandMode === "map";
  const handleSwitchMapExpand = () => {
    dispatch(expandMap());
  };
  useEffect(() => {
    dispatch(resetExpandedMapPopupState());
  }, [areas, mapPoints]);

  // Toggle clustering
  const handleSwitchClustering = () => {
    dispatch(switchClustering());
  };

  const { isLassoActive, handleSwitchLasso, handleFinishLasso } = useLasso(
    mapPoints,
    setSelectedByLassoRoutePoints,
  );

  if (isAreasFetching || isPointsFetching) {
    return <Loader isLoading />;
  }

  if (isAreasSuccess && isPointsSuccess) {
    return (
      <>
        <MapUI
          enableLasso
          switchMapExpand={handleSwitchMapExpand}
          expanded={expanded}
          isMapPointsVisible={isMapPointsVisible}
          switchMapPointsVisible={() => dispatch(switchMapPointsVisible())}
          isLassoActive={isLassoActive}
          switchIsLassoActive={handleSwitchLasso}
          clustering={clustering}
          switchClustering={handleSwitchClustering}
        >
          <ChangeViewNew mapCenterPoint={mapCenterPoint} />
          <RememberBounds />
          <ResizeObserver expanded={expanded} />
          {isMapPointsVisible && (
            <Cluster data={mapPoints} highlightedPointId={highlightedPointId} />
          )}
          {areas?.map((item) => (
            <Polygon
              key={item.id}
              positions={item.coordinates}
              color={item.color.hexCode}
              polygon={item}
              eventHandlers={{
                popupopen: () =>
                  dispatch(setExpandedMapPopupData({ area: item })),
                popupclose: () => dispatch(resetExpandedMapPopupState()),
              }}
            >
              <AreaPopup area={item} />
            </Polygon>
          ))}
          <Lasso
            isLassoActive={isLassoActive}
            onLassoFinished={handleFinishLasso}
          />
        </MapUI>
        {expanded && <ExpandedPointPopup />}
        {expanded && <TableFooter inMap />}
        <Footer />
      </>
    );
  }

  return null;
};

export default Map;
