import { crossIcon, searchIcon } from "icons/icons";
import styled from "styled-components";

const Search = ({ value = "", onChange = () => {}, onReset = () => {} }) => (
  <InputWrapper>
    <SearchIconWrapper>{searchIcon}</SearchIconWrapper>
    <Input placeholder="Поиск" value={value} onChange={onChange} />
    <CrossIconWrapper onClick={onReset}>{crossIcon}</CrossIconWrapper>
  </InputWrapper>
);

const InputWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 24px;
`;

const SearchIconWrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
  top: 5px;
  left: 0px;
  cursor: pointer;
`;

const Input = styled.input`
  width: 100%;
  height: 24px;
  font-size: 18px;
  color: #787878;
  padding: 0px 30px 0px 30px;
  box-sizing: border-box;
  border: none;
  outline: none;
`;

const CrossIconWrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 14px;
  height: 14px;
  top: 5px;
  right: 0px;
  cursor: pointer;
`;

export default Search;
