import * as React from "react";

const SvgArrowForward = (props) => (
  <svg
    width={100}
    height={100}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#arrow_forward_svg__a)">
      <path
        d="m50 16.667-5.875 5.875 23.25 23.291H16.666v8.334h50.709l-23.25 23.291L50 83.333 83.333 50 50 16.667Z"
        fill="#787878"
      />
    </g>
    <defs>
      <clipPath id="arrow_forward_svg__a">
        <path fill="#fff" d="M0 0h100v100H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgArrowForward;
