/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  expandMode: null, // null | 'map' | 'table'
  clustering: true,
};

const projectSettings = createSlice({
  name: "projectSettings",
  initialState,
  reducers: {
    resetState: () => initialState,
    expandMap: (state) => {
      if (state.expandMode === "map") {
        state.expandMode = null;
      } else {
        state.expandMode = "map";
      }
    },
    expandTable: (state) => {
      if (state.expandMode === "table") {
        state.expandMode = null;
      } else {
        state.expandMode = "table";
      }
    },
    switchClustering: (state) => {
      state.clustering = !state.clustering;
    },
  },
});

export const { resetState, expandMap, expandTable, switchClustering } =
  projectSettings.actions;

export default projectSettings.reducer;
