import { Edit } from "app/assets/icons";
import { PopupContext } from "app/context/PopupContext";
import { setOpenedReport, setTableReportAreaId } from "app/store/reducer/areas";
import {
  EditAreaPopup,
  EditIndividualAreaPopup,
} from "pages/current-project/areas/table/popups";
import { useContext } from "react";
import { useDispatch } from "react-redux";
import { ActionsDropdown, IconButton } from "shared/ui";

export const Report = ({ area }) => {
  const dispatch = useDispatch();

  const onToggle = (show) => {
    if (show) {
      dispatch(setTableReportAreaId(area.id));
    } else {
      dispatch(setTableReportAreaId(null));
    }
  };

  const onSelect = (e, reportName) => {
    e.stopPropagation();
    dispatch(
      setOpenedReport({
        name: reportName,
        areaId: area.id,
        areaName: area.name,
      }),
    );
  };

  const actionsList = [
    {
      id: 0,
      onClick: (e) => onSelect(e, "consolidated"),
      text: "Сводный отчёт",
    },
    {
      id: 1,
      onClick: (e) => onSelect(e, "days"),
      text: "Отчёт по дням недели",
    },
  ];

  return <ActionsDropdown list={actionsList} onToggle={onToggle} />;
};

export const EditArea = ({ area }) => {
  const { showPopup } = useContext(PopupContext);

  return (
    <IconButton
      onClick={(e) => {
        e.stopPropagation();
        showPopup(
          area?.coordinates?.length ? EditAreaPopup : EditIndividualAreaPopup,
          {
            areaId: area?.id,
          },
        );
      }}
    >
      <Edit />
    </IconButton>
  );
};
