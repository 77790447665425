import { Cross, Maximize, Minimize, Refresh } from "app/assets/icons";
import { resetOpenedReport } from "app/store/reducer/shedule";
import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useListDaysReportQuery } from "shared/api/areas";
import { useListAnalyzePeriodQuery } from "shared/api/logistic";
import { Button, Flex, Text } from "shared/ui";
import styled from "styled-components";

import { configColumns } from "./config";
import ReportContext from "./context";
import Table from "./ui/table";

export const FILTERS = {
  ORDER_COUNT: "ORDER_COUNT",
  MARGIN: "MARGIN",
  VOLUME: "VOLUME",
  WEIGHT: "WEIGHT",
};

const DaysReport = () => {
  const dispatch = useDispatch();
  const [isExpand, setIsExpand] = useState(false);
  const [filter, setFilter] = useState(FILTERS.ORDER_COUNT);
  const { projectInfo } = useSelector(
    (state) => state.rootReducer.currentProject,
  );
  const { openedReport } = useSelector((state) => state.rootReducer.shedule);
  const { data: analyzePeriod } = useListAnalyzePeriodQuery(
    {
      projectId: projectInfo.id,
    },
    { skip: !projectInfo.id },
  );
  const {
    data: report,
    isFetching,
    refetch,
  } = useListDaysReportQuery(
    {
      projectId: projectInfo.id,
      areaId: openedReport.areaId,
      monthFrom: analyzePeriod?.monthFrom,
      monthTo: analyzePeriod?.monthTo,
      yearFrom: analyzePeriod?.yearFrom,
      yearTo: analyzePeriod?.yearTo,
    },
    {
      skip:
        !projectInfo.id ||
        !openedReport.areaId ||
        !analyzePeriod?.monthFrom ||
        !analyzePeriod?.monthTo ||
        !analyzePeriod?.yearFrom ||
        !analyzePeriod?.yearTo,
      refetchOnMountOrArgChange: true,
    },
  );

  const data = React.useMemo(() => report, [report]);
  const columns = React.useMemo(() => configColumns, []);

  const contextValue = useMemo(() => ({ filter }), [filter]);

  if (isFetching) {
    return null;
  }

  return (
    <Root>
      <Header>
        <Flex mr="10px">
          <Text variant="title1">
            Маршруты по дням недели {openedReport.areaName}
          </Text>
        </Flex>
        <Filters>
          <FilterItem
            isActive={filter === FILTERS.ORDER_COUNT}
            onClick={() => setFilter(FILTERS.ORDER_COUNT)}
          >
            <Text variant="body3">Кол-во</Text>
          </FilterItem>
          <FilterItem
            isActive={filter === FILTERS.MARGIN}
            onClick={() => setFilter(FILTERS.MARGIN)}
          >
            <Text variant="body3">₽</Text>
          </FilterItem>
          <FilterItem
            isActive={filter === FILTERS.VOLUME}
            onClick={() => setFilter(FILTERS.VOLUME)}
          >
            <Text variant="body3">Объём</Text>
          </FilterItem>
          <FilterItem
            isActive={filter === FILTERS.WEIGHT}
            onClick={() => setFilter(FILTERS.WEIGHT)}
          >
            <Text variant="body3">Вес</Text>
          </FilterItem>
        </Filters>
      </Header>
      <ReportContext.Provider value={contextValue}>
        <Table data={data} columns={columns} isExpand={isExpand} />
      </ReportContext.Provider>
      <Flex flexDirection="row" mt="20px">
        <Button text="ОБНОВИТЬ" icon={<Refresh />} onClick={() => refetch()} />
        <CustomButton
          className="expandButton"
          onClick={() => setIsExpand(!isExpand)}
        >
          {isExpand ? <Minimize /> : <Maximize />}
        </CustomButton>
        <CustomButton
          className="closeButton"
          onClick={() => dispatch(resetOpenedReport())}
        >
          <Cross />
        </CustomButton>
      </Flex>
    </Root>
  );
};

const Root = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 20px;
  background: #fff;
  z-index: 9999;
  box-shadow: 0px -8px 10px -4px rgba(0, 0, 0, 0.1);
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const Filters = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
`;

const FilterItem = styled.button`
  background: #fff;
  border: ${({ isActive }) =>
    isActive ? "2px solid #2D8AF6" : "1px solid #CCCCCC"};
  height: 32px;
  margin-right: 8px;
  box-sizing: border-box;
  white-space: nowrap;

  &:last-child {
    margin-right: 0;
  }
`;

const CustomButton = styled.button`
  background: #ecf4fe;
  border-radius: 12px;
  width: 56px;
  height: 48px;
  border: none;
  outline: none;
  color: #2d8af6;

  &.expandButton {
    margin-left: auto;
  }

  &.closeButton {
    margin-left: 8px;
  }
`;

export default DaysReport;
