import { resetCommandFilter, setCommandFilter } from "app/store/reducer/routes";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useListFiltersRoutesCommandsDuplicatesQuery } from "shared/api/filters";
import { Checkbox, Flex, SearchDropdown, Text } from "shared/ui";

const CommandDropdown = () => {
  const dispatch = useDispatch();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const { projectInfo } = useSelector(
    (state) => state.rootReducer.currentProject,
  );
  const { frontendFilters: { commands } } = useSelector((state) => state.rootReducer.routes);
  const { data: filters, isSuccess } =
    useListFiltersRoutesCommandsDuplicatesQuery(
      {
        projectId: projectInfo.id,
        commands,
      },
      { skip: !projectInfo.id, refetchOnMountOrArgChange: true },
    );

  function onCommandsSelect(command) {
    dispatch(setCommandFilter(command));
  }

  const onSearch = (value) => {
    setSearchValue(value);
  };

  const onToggle = (isOpen) => {
    if (isOpen === false) onSearch("");
    setIsDropdownOpen((prev) => !prev);
  };

  const onResetSearch = () => {
    onSearch("");
    setIsDropdownOpen(false);
  };

  const onResetAllCheckboxes = () => {
    dispatch(resetCommandFilter());
    setIsDropdownOpen(false);
  };

  if (isSuccess) {
    return (
      <SearchDropdown
        isDropdownOpen={isDropdownOpen}
        toggleText={
          commands?.length
            ? `Выбрано ${commands?.length}`
            : "Выбрать"
        }
        searchValue={searchValue}
        onToggle={onToggle}
        onSearch={onSearch}
        onResetSearch={onResetSearch}
        onResetAllCheckboxes={onResetAllCheckboxes}
        multiselect
        selectedText
        selectedCount={commands?.length}
      >
        {filters?.commandsResponse.map(
          (item) =>
            item.toLowerCase().includes(searchValue.toLowerCase()) && (
              <SearchDropdown.Item key={item}>
                <Checkbox
                  checked={commands?.includes(item)}
                  onChange={() => onCommandsSelect(item)}
                />
                <Flex ml="7px">
                  <Text variant="body1">{item}</Text>
                </Flex>
              </SearchDropdown.Item>
            ),
        )}
      </SearchDropdown>
    );
  }

  return null;
};

export default CommandDropdown;
