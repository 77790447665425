import { api } from "shared/api";

const sheduleApi = api.injectEndpoints({
  endpoints: (builder) => ({
    filtersList: builder.query({
      query: ({ projectId, branchIds, contractIds, groupIds }) =>
        `projects/${projectId}/filters/branches/areas/contracts?branchIds=${branchIds}&contractIds=${contractIds}&groupIds=${groupIds}`,
      providesTags: ["Filters"],
    }),
    individualAreasList: builder.query({
      query: ({ projectId }) =>
        `projects/${projectId}/delivery-areas/individual-areas`,
    }),
    createIndividualArea: builder.mutation({
      query: ({ projectId, ...body }) => ({
        url: `projects/${projectId}/delivery-areas/individual-areas`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Logistic"],
    }),
    editIndividualArea: builder.mutation({
      query: ({ projectId, deliveryAreaId, ...body }) => ({
        url: `projects/${projectId}/delivery-areas/individual-areas/${deliveryAreaId}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Areas", "Filters", "Logistic"],
    }),
    addPointsToIndividualArea: builder.mutation({
      query: ({ projectId, deliveryAreaId, body }) => ({
        url: `projects/${projectId}/delivery-areas/individual-areas/${deliveryAreaId}/points`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Logistic"],
    }),
    deletePointFromIndividualArea: builder.mutation({
      query: ({ projectId, deliveryAreaId, pointId }) => ({
        url: `projects/${projectId}/delivery-areas/individual-areas/${deliveryAreaId}/points/${pointId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Logistic"],
    }),
  }),
});

export const {
  useFiltersListQuery,
  useIndividualAreasListQuery,
  useCreateIndividualAreaMutation,
  useEditIndividualAreaMutation,
  useAddPointsToIndividualAreaMutation,
  useDeletePointFromIndividualAreaMutation,
} = sheduleApi;
