import jwtDecode from "jwt-decode";
import moment from "moment-timezone";

import { getAuthToken } from "./local-storage";

export function getUserFullName() {
  const token = getAuthToken();

  return jwtDecode(token).fullName;
}

export function getUserRole() {
  const token = getAuthToken();

  return jwtDecode(token).role;
}

export function isAuthorized() {
  const token = getAuthToken();
  const unixTimeNow = moment().unix();

  if (!token) return false;

  if (token) {
    if (unixTimeNow > jwtDecode(token).exp) return false;

    return true;
  }

  return true;
}
