import { PopupContext } from "app/context/PopupContext";
import React, { useContext, useEffect, useState } from "react";
import {
  useGetProjectContractAreasQuery,
  useUpdateProjectContractAreasMutation,
} from "shared/api/current-project";
import { Button, Checkbox, Flex, Popup, Text, TextButton } from "shared/ui";
import { Search } from "shared/ui/search-dropdown/ui";
import styled from "styled-components";

const createColumns = (contracts, numColumns) => {
  const columns = Array.from({ length: numColumns }, () => []);
  contracts.forEach((contract, index) => {
    const columnIndex = Math.floor(index / (contracts.length / numColumns));
    columns[columnIndex].push(contract);
  });
  return columns;
};

export const DefineContractAreas = ({ project }) => {
  const { hidePopup } = useContext(PopupContext);
  const [selectedContracts, setSelectedContracts] = useState({});
  const [searchValue, setSearchValue] = useState("");

  const { data: contracts, isFetching } = useGetProjectContractAreasQuery({
    projectId: project.id,
  });
  const [updateContracts] = useUpdateProjectContractAreasMutation();

  useEffect(() => {
    if (contracts) {
      setSelectedContracts(contracts);
    }
  }, [contracts]);

  const handleSelectContract = (contractKey) => {
    setSelectedContracts((prevValue) => ({
      ...prevValue,
      [contractKey]: !prevValue[contractKey],
    }));
  };

  const handleSubmit = async () => {
    const selectedContractsNames = Object.entries(selectedContracts)
      // eslint-disable-next-line no-unused-vars
      .filter(([_, value]) => value)
      .map(([key]) => key);

    await updateContracts({
      projectId: project.id,
      selectedContracts: selectedContractsNames,
    });
    hidePopup();
  };

  const handleSelectAll = () => {
    setSelectedContracts(
      Object.keys(contracts).reduce((newObj, key) => {
        // eslint-disable-next-line no-param-reassign
        newObj[key] = true;
        return newObj;
      }, {}),
    );
  };

  const handleClearAll = () => {
    setSelectedContracts(
      Object.keys(contracts).reduce((newObj, key) => {
        // eslint-disable-next-line no-param-reassign
        newObj[key] = false;
        return newObj;
      }, {}),
    );
  };

  const onSearch = (e) => {
    setSearchValue(e.target.value);
  };

  const onResetSearch = () => {
    setSearchValue("");
  };

  const sortedKeys = Object.keys(selectedContracts).filter((contract) =>
    contract.toLowerCase().includes(searchValue.toLowerCase())
  );
  const columns = createColumns(sortedKeys, 5);

  if (isFetching) return null;

  return (
    <StyledCommonPopup
      isCenter
      title="Задать контракты зон доставки"
      className="define-contract-areas-popup__common-popup"
    >
      <Root>
        <Flex flexDirection="row" mt="8px" alignItems="center">
          <SearchWrapper>
            <Search
              value={searchValue}
              onChange={onSearch}
              onReset={onResetSearch}
            />
          </SearchWrapper>
          <Flex mr="16px">
            <TextButton onClick={handleSelectAll}>
              <Text variant="body2" color="color0">
                Выбрать все
              </Text>
            </TextButton>
          </Flex>
          <TextButton onClick={handleClearAll}>
            <Text variant="body2" color="color0">
              Сбросить все
            </Text>
          </TextButton>
        </Flex>
        <ListWrapper>
          {columns.map((column) => (
            <Column key={crypto.randomUUID()}>
              {column.map((contract) => (
                <Row key={contract}>
                  <CheckboxWrapper>
                    <Checkbox
                      name="contract"
                      filledColor="#000000"
                      emptyColor="#787878"
                      checked={selectedContracts[contract]}
                      onChange={() => handleSelectContract(contract)}
                    />
                  </CheckboxWrapper>
                  <CheckboxesText>{contract}</CheckboxesText>
                </Row>
              ))}
            </Column>
          ))}
        </ListWrapper>
        <Flex my="12px">
          <Button
            height="48px"
            color="#2D8AF6"
            text="ПРИМЕНИТЬ"
            onClick={handleSubmit}
          />
        </Flex>
      </Root>
    </StyledCommonPopup>
  );
};

const StyledCommonPopup = styled(Popup)`
  &.define-contract-areas-popup__common-popup {
    width: 60vw;
    height: 80vh;
  }
`;

const Root = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const SearchWrapper = styled(Flex)`
  width: 200px;
  padding: 8px 0;
  margin-right: 16px;
`;


const ListWrapper = styled.div`
  margin-top: 20px;
  overflow-y: auto;
  height: 100%;
  max-height: 66vh;
  display: flex;
  justify-content: space-between;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1
`;

const Row = styled.label`
  display: inline-flex;
  align-items: center;
  margin-bottom: 12px;
  cursor: pointer;

  &:last-child {
    margin-bottom: 0;
  }
`;

const CheckboxWrapper = styled.div`
  margin-right: 7px;
`;

const CheckboxesText = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
`;

export default DefineContractAreas;
