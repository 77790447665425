import { api } from "shared/api";

const currentProjectApi = api.injectEndpoints({
  endpoints: (builder) => ({
    projectInfo: builder.query({
      query: ({ projectId }) => `projects/${projectId}`,
    }),
    branchesList: builder.query({
      query: ({ projectId }) => `projects/${projectId}/filters/branches`,
    }),
    contractGroupsFilter: builder.query({
      query: ({ projectId }) => `projects/${projectId}/contract-groups`,
    }),
    updateProjectPoints: builder.mutation({
      query: ({ projectId }) => ({
        url: `projects/${projectId}/points`,
        method: "PUT",
      }),
      invalidatesTags: [
        "Routes",
        "Days",
        "Coordinates",
        "Logistic-Coordinates",
        "Areas",
        "Shedule",
      ],
    }),
    getProjectDeliveryDaysOff: builder.query({
      query: ({ projectId }) => `projects/${projectId}/settings/weekends`,
      providesTags: ["States"],
    }),
    updateProjectDeliveryDaysOff: builder.mutation({
      query: ({ projectId, selectedDays }) => ({
        url: `projects/${projectId}/settings/weekends`,
        method: "PUT",
        body: selectedDays,
      }),
      invalidatesTags: ["States", "Routes", "Days", "Coordinates"],
    }),
    getProjectContractAreas: builder.query({
      query: ({ projectId }) => `projects/${projectId}/settings/contracts`,
      providesTags: ["States"],
    }),
    updateProjectContractAreas: builder.mutation({
      query: ({ projectId, selectedContracts }) => ({
        url: `projects/${projectId}/settings/contracts`,
        method: "PUT",
        body: selectedContracts,
      }),
      invalidatesTags: ["States", "Areas"],
    }),
    getRoutesModeColumns: builder.query({
      query: ({ projectId }) =>
        `projects/${projectId}/settings/column-names/route-mode`,
      providesTags: ["Project-Settings"],
    }),
    updateRoutesModeColumns: builder.mutation({
      query: ({ projectId, columns }) => ({
        url: `projects/${projectId}/settings/column-names/route-mode`,
        method: "PUT",
        body: columns,
      }),
      invalidatesTags: ["Project-Settings"],
    }),
    getDaysModeColumns: builder.query({
      query: ({ projectId }) =>
        `projects/${projectId}/settings/column-names/day-mode`,
      providesTags: ["Project-Settings"],
    }),
    updateDaysModeColumns: builder.mutation({
      query: ({ projectId, columns }) => ({
        url: `projects/${projectId}/settings/column-names/day-mode`,
        method: "PUT",
        body: columns,
      }),
      invalidatesTags: ["Project-Settings"],
    }),
  }),
});

export const {
  useProjectInfoQuery,
  useBranchesListQuery,
  useContractGroupsFilterQuery,
  useUpdateProjectPointsMutation,
  useGetProjectDeliveryDaysOffQuery,
  useUpdateProjectDeliveryDaysOffMutation,
  useGetProjectContractAreasQuery,
  useUpdateProjectContractAreasMutation,
  useGetRoutesModeColumnsQuery,
  useUpdateRoutesModeColumnsMutation,
  useGetDaysModeColumnsQuery,
  useUpdateDaysModeColumnsMutation,
} = currentProjectApi;
