import { DuplicatesWarningIcon } from "icons/icons";
import styled from "styled-components";

const Duplicates = ({ count = 0, isSelected = false, onSelect = () => {} }) => (
  <Root onClick={onSelect} isSelected={isSelected}>
    <IconWrapper>
      <DuplicatesWarningIcon color={isSelected ? "#000000" : "#CCCCCC"} />
    </IconWrapper>
    <WarningCountWrapper isSelected={isSelected}>{count}</WarningCountWrapper>
  </Root>
);

const Root = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  border: ${({ isSelected }) =>
    isSelected ? "2px solid #2D8AF6" : "1px solid #CCCCCC"};
  box-sizing: border-box;
  background: #ffffff;
  cursor: pointer;
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 22px;
  height: 19px;
`;

const WarningCountWrapper = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: ${({ isSelected }) => (isSelected ? "#000000" : "#CCCCCC")};
  margin-left: 5px;
`;

export default Duplicates;
