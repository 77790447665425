import { SORT } from "shared/config";

import { HeaderCellSort, PointCell } from "../model";

export const configColumns = [
  {
    id: "0",
    Header: (
      <HeaderCellSort text="Точка" sortBy={SORT.POINT} withAllPointsCheckbox />
    ),
    accessor: (data) => <PointCell data={data} />,
    w: "40%",
  },
  {
    id: "1",
    Header: "Текущие координаты",
    accessor: "position",
    w: "20%",
  },
  {
    id: "2",
    Header: "Расчётные координаты",
    accessor: "sprPosition",
    w: "20%",
  },
  {
    id: "3",
    Header: <HeaderCellSort text="Разница" sortBy={SORT.DISTANCE} />,
    accessor: "distance",
    w: "20%",
  },
];
