import * as React from "react";

const SvgCurrentMarker = ({ color = "#000000", ...props }) => (
  <svg
    width={46}
    height={68}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M23 2C12.785 2 4.5 10.285 4.5 20.5c0 5.924 3.267 12.304 6.503 17.236 3.29 5.014 6.809 8.916 7.777 9.965.051.055.107.117.166.184C19.615 48.634 20.836 50 23 50c2.165 0 3.385-1.366 4.054-2.115.06-.067.115-.129.166-.184.968-1.048 4.487-4.95 7.777-9.965C38.233 32.804 41.5 26.424 41.5 20.5 41.5 10.285 33.215 2 23 2Zm0 27.5a9 9 0 1 1 0-18 9 9 0 0 1 0 18ZM23 13c-4.14 0-7.5 3.36-7.5 7.5 0 4.14 3.36 7.5 7.5 7.5 4.14 0 7.5-3.36 7.5-7.5 0-4.14-3.36-7.5-7.5-7.5Z"
      fill={color}
      stroke="#fff"
      strokeWidth={4}
    />
    <g clipPath="url(#current_marker_svg__a)">
      <path
        d="M18.25 58.78 11.648 56l-2.249.94 8.851 3.727 19-8-2.233-.94L18.25 58.78Z"
        fill={color}
      />
    </g>
    <rect
      x={2}
      y={46}
      width={42}
      height={20}
      rx={6}
      stroke="#fff"
      strokeWidth={4}
    />
    <rect x={4} y={48} width={38} height={16} rx={4} fill={color} />
    <path
      d="M7.001 55.735v-1.19h4.943v1.19h-1.757V60H8.745v-4.265H7Zm8.151 4.371c-.561 0-1.044-.113-1.449-.34a2.335 2.335 0 0 1-.93-.973c-.218-.422-.327-.92-.327-1.495 0-.562.109-1.054.327-1.478.218-.423.524-.754.92-.99.397-.237.864-.356 1.399-.356.36 0 .694.058 1.005.174.312.114.584.286.816.515.235.23.417.519.547.867.13.346.195.75.195 1.214v.416H13.05v-.938h3.181c0-.218-.047-.41-.142-.578a1.017 1.017 0 0 0-.394-.395 1.124 1.124 0 0 0-.579-.145c-.23 0-.433.053-.61.16a1.12 1.12 0 0 0-.412.422c-.1.175-.15.37-.153.586v.891c0 .27.05.503.15.7.101.196.244.348.429.455.184.106.404.16.657.16.168 0 .322-.024.462-.072a.927.927 0 0 0 .586-.561l1.399.093a1.91 1.91 0 0 1-.437.88c-.218.249-.5.443-.845.583-.344.137-.74.205-1.19.205Zm3.49-.106v-5.455h1.514v2.117h.426l1.484-2.117h1.776l-1.929 2.706L23.863 60h-1.797l-1.346-1.935h-.564V60h-1.513Zm6.882 2.045c-.192 0-.372-.015-.54-.046a1.964 1.964 0 0 1-.412-.11l.341-1.13c.178.055.337.085.48.09a.608.608 0 0 0 .372-.1c.107-.07.193-.192.26-.362l.088-.23-1.956-5.611h1.59l1.13 4.005h.057l1.14-4.006h1.601l-2.12 6.044c-.102.294-.24.55-.415.767-.173.22-.392.39-.657.508-.265.121-.585.181-.959.181Zm13.494-3.235-.106 2.752h-1.389V60h-.607v-1.19h2.102Zm-8.569-4.265h1.446V58.8h1.537v-4.255h1.446V58.8h1.537v-4.255h1.446V60h-7.412v-5.455Z"
      fill="#fff"
    />
    <defs>
      <clipPath id="current_marker_svg__a">
        <rect x={4} y={48} width={38} height={16} rx={4} fill="#fff" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgCurrentMarker;
