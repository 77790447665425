import { useSelector } from "react-redux";

import ConsolidatedReport from "./consolidated-report";
import DaysReport from "./days-report";

const Footer = () => {
  const { openedReport } = useSelector((state) => state.rootReducer.areas);

  if (openedReport.name === "consolidated") {
    return <ConsolidatedReport />;
  }

  if (openedReport.name === "days") {
    return <DaysReport />;
  }

  return null;
};

export default Footer;
