import { useState } from "react";
import { Flex, SearchDropdown, Text } from "shared/ui";

const NamesDropdown = ({
  title = "",
  areasNames = [],
  name = "",
  onSelect = () => {},
  errorText = "",
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const onSearch = (value) => {
    setSearchValue(value);
  };

  const onToggle = (isOpen) => {
    if (isOpen === false) onSearch("");
    setIsDropdownOpen((prev) => !prev);
  };

  const onResetSearch = () => {
    onSearch("");
    setIsDropdownOpen(false);
  };

  return (
    <SearchDropdown
      title={title}
      isDropdownOpen={isDropdownOpen}
      toggleText={name}
      searchValue={searchValue}
      onToggle={onToggle}
      onSearch={onSearch}
      onResetSearch={onResetSearch}
      errorText={errorText}
    >
      {areasNames.map(
        (item) =>
          item?.toLowerCase().includes(searchValue.toLowerCase()) && (
            <SearchDropdown.Item
              key={item}
              isSelected={item === name}
              onClick={() => {
                onSelect(item);
                setIsDropdownOpen(false);
              }}
            >
              <Flex ml="7px">
                <Text variant="body1">{item}</Text>
              </Flex>
            </SearchDropdown.Item>
          ),
      )}
    </SearchDropdown>
  );
};

export default NamesDropdown;
