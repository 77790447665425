/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/state-in-constructor */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/sort-comp */
import React, { Component, createContext } from "react";

export const PopupContext = createContext({
  component: null,
  props: {},
  showPopup: () => {},
  hidePopup: () => {},
});

export class PopupProvider extends Component {
  showPopup = (component, props = {}) => {
    this.setState({
      component,
      props,
    });
  };

  hidePopup = () =>
    this.setState({
      component: null,
      props: {},
    });

  state = {
    component: null,
    props: {},
    showPopup: this.showPopup,
    hidePopup: this.hidePopup,
  };

  render() {
    return (
      <PopupContext.Provider value={this.state}>
        {this.props.children}
      </PopupContext.Provider>
    );
  }
}

export const PopupConsumer = PopupContext.Consumer;
