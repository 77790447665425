import { GearIcon } from "icons/icons";
import { useState } from "react";
import { useExpanded, useTable } from "react-table";
import { hexToRgba, isOSX } from "shared/helpers";
import styled from "styled-components";

const Table = ({ data = [], columns = [], isExpand = false }) => {
  const [showSettings, setShowSettings] = useState(false);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useExpanded,
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    allColumns,
  } = tableInstance;

  return (
    <>
      <TableWrapper isExpand={isExpand}>
        <TableContent {...getTableProps()}>
          <TableHead>
            {headerGroups.map((headerGroup) => (
              <TableHeaderRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <TableHeaderCell
                    width={column.w}
                    textAlign={column.textAlign}
                    rowSpan={column.rowSpan}
                    displayNone={column.displayNone}
                    {...column.getHeaderProps()}
                  >
                    {column.render("Header")}
                  </TableHeaderCell>
                ))}
              </TableHeaderRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <TableDataRow
                  id={row.original.routeCode}
                  color={row.original.color}
                  {...row.getRowProps()}
                >
                  {row.cells.map((cell) => (
                    <TableDataCell
                      width={cell.column.w}
                      textAlign={cell.column.textAlign}
                      {...cell.getCellProps()}
                    >
                      {cell.render("Cell")}
                    </TableDataCell>
                  ))}
                </TableDataRow>
              );
            })}
          </TableBody>
        </TableContent>
      </TableWrapper>
      <SettingsButton
        onClick={() => setShowSettings((prevState) => !prevState)}
      >
        <GearIcon />
      </SettingsButton>
      {showSettings && (
        <SettingsMenu>
          {allColumns.map((column) => (
            <div key={column.id}>
              <input type="checkbox" {...column.getToggleHiddenProps()} />{" "}
              {column.displayNone
                ? column.parent.Header
                : `${column.parent.Header}, ${column.Header}`}
            </div>
          ))}
        </SettingsMenu>
      )}
    </>
  );
};

const TableWrapper = styled.div`
  max-height: ${({ isExpand }) => (isExpand ? "40vh" : "20vh")};
  width: 98%;
  transition: 0.3s;
  overflow-x: hidden;
  overflow-y: auto;
`;

const TableContent = styled.table`
  border-collapse: collapse;
  width: 100%;
`;

const TableHead = styled.thead`
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 2;
`;

const TableHeaderRow = styled.tr`
  width: 100%;
  ${!isOSX && "::-webkit-scrollbar {opacity: 0;}"}
`;

const TableHeaderCell = styled.th`
  display: ${({ displayNone }) => (displayNone ? "none" : "")};
  background: white;
  padding: 4px 8px;

  border-spacing: 0;
  outline: 0.5px solid #ccc;
  outline-offset: -0.5px;
  border-top: 1px solid #ccc;

  font-size: 14px;
  line-height: 18px;
  font-style: normal;
  font-weight: normal;
  text-align: ${({ textAlign }) => textAlign ?? "center"};
  vertical-align: top;
  color: #787878;
`;

const TableDataRow = styled.tr`
  background: ${({ color }) => (color ? hexToRgba(color, 0.04) : "#fff")};
  border-bottom: 1px solid #cccccc;
  border-left: 1px solid #cccccc;
  border-right: 1px solid #cccccc;

  &:hover {
    background: #ecf4fe;
  }
`;

const TableDataCell = styled.td`
  padding: 6px 4px;
  border-right: 1px solid #cccccc;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  text-align: ${({ textAlign }) => textAlign ?? "center"};
  word-break: break-all;

  :first-of-type {
    padding: 6px 8px;
  }
`;

const TableBody = styled.tbody`
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
`;

const SettingsButton = styled.div`
  position: absolute;
  top: 68px;
  right: 1%;
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

const SettingsMenu = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding: 12px;
  transform: translate(100%);
  background: white;
  box-shadow: 0px -8px 10px -4px rgba(0, 0, 0, 0.1);
`;

export default Table;
