import { Flex, Text } from "shared/ui";
import styled from "styled-components";

import {
  AddressSearch,
  BranchDropdown,
  ContractDropdown,
  DifferenceDropdown,
} from "./model";

const Filters = () => (
  <Root>
    <Flex flex={1} mr="12px" mt={2}>
      <Flex flexDirection="row" mb="4px">
        <Text variant="body3" color="color3">
          Филиал
        </Text>
      </Flex>
      <BranchDropdown />
    </Flex>
    <Flex flex={1} mr="12px">
      <Flex flexDirection="row" mb="4px">
        <Text variant="body3" color="color3">
          Контракт
        </Text>
      </Flex>
      <ContractDropdown />
    </Flex>
    <Flex flex={1} mr="12px">
      <Flex flexDirection="row" mb="4px">
        <Text variant="body3" color="color3">
          Поиск
        </Text>
      </Flex>
      <AddressSearch />
    </Flex>
    <Flex flex={1}>
      <Flex mb="4px">
        <Text variant="body3" color="color3">
          Разница координат, м
        </Text>
      </Flex>
      <DifferenceDropdown />
    </Flex>
  </Root>
);

const Root = styled.div`
  display: flex;
  align-items: center;
`;

export default Filters;
