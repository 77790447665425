import {
  setPointFoundByMarker,
  setSelectedRoutePoints,
} from "app/store/reducer/routes";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MARKER_COLOR_BY } from "shared/config";
import { beautifyWeekDay, getMarkerColorByWeekDay } from "shared/helpers";
import { Flex, PointPopup as PointPopupUI, Text } from "shared/ui";
import { DeliveryTermsReport } from "widgets";

const PointPopup = React.memo(({ isCluster = false, multipleList = [] }) => {
  const dispatch = useDispatch();
  const [shownPoint, setShownPoint] = useState(multipleList[0]);
  const { selectedRoutePoints, mapMarkersColoredBy } = useSelector(
    (state) => state.rootReducer.routes,
  );

  const onSelect = (routePoint) => {
    dispatch(setSelectedRoutePoints(routePoint?.id));
  };

  const onSetShownPoint = (point) => {
    setShownPoint(point);
    dispatch(
      setPointFoundByMarker({
        pointCode: point.pointCode,
        highlightedPointId: point.id,
      }),
    );
  };

  return (
    <PointPopupUI
      multipleList={multipleList}
      selectedPoints={selectedRoutePoints}
      shownPoint={shownPoint}
      onSetShownPoint={onSetShownPoint}
      isSelectable
      onSelect={onSelect}
      withTip={!isCluster}
      color={
        mapMarkersColoredBy === MARKER_COLOR_BY.ROUTE
          ? shownPoint.color
          : getMarkerColorByWeekDay(shownPoint.dayWeek)
      }
    >
      <Flex width="290px" mr="20px" overflowY="scroll">
        <Flex mb="4px">
          <Text variant="body3bold">{shownPoint.pointCode}</Text>
        </Flex>
        <Flex mb="4px">
          <Text variant="body3">{shownPoint.pointName}</Text>
        </Flex>
        <Flex mb="10px">
          <Text variant="body3">{shownPoint.address}</Text>
        </Flex>
        <>
          <Flex flexDirection="row" mb="10px">
            <Flex>
              <Text variant="body3">
                {beautifyWeekDay(shownPoint.dayWeek) || "-"}
              </Text>
            </Flex>
            <Flex ml="8px">
              <Text variant="body3" color={shownPoint.color}>
                {shownPoint.routeName}
              </Text>
            </Flex>
          </Flex>
          <Flex mb="10px">
            <Text variant="body3" color="color3">
              {shownPoint.attributeCode1}, {shownPoint.attributeCode2}
            </Text>
          </Flex>
          <Flex mb="10px">
            <Text variant="body3" color="color3">
              {`ТО: ${shownPoint.additionallyTable1}, ПР: ${shownPoint.additionallyTable2}, %: ${shownPoint.additionallyTable3}`}
            </Text>
          </Flex>
        </>
        <Flex mb="4px">
          <Text variant="body3">Разница {shownPoint.distance}</Text>
        </Flex>
        <DeliveryTermsReport
          routeId={shownPoint?.routeId}
          pointId={shownPoint?.pointId}
        />
      </Flex>
    </PointPopupUI>
  );
});

export default PointPopup;
