import {
  resetState,
  setChangeCoordsFormDataNewPoint,
} from "app/store/reducer/logistic-coordinates";
import { crossIcon } from "icons/icons";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useChangeCurrentCoordsToNewLogisticCoordinatesMutation } from "shared/api/logistic-coordinates";
import { useLazyGetCoordsByAddressSearchQuery } from "shared/api/yandex";
import { Button, Flex, InputText, Text } from "shared/ui";
import styled from "styled-components";

const ChangeCoordsForm = ({ expanded }) => {
  const dispatch = useDispatch();
  const [addressInput, setAddressInput] = useState("");
  const { projectInfo } = useSelector(
    (state) => state.rootReducer.currentProject,
  );
  const { changeCoordsFormData } = useSelector(
    (state) => state.rootReducer.logisticCoordinates,
  );
  const [getCoordsBySearch] = useLazyGetCoordsByAddressSearchQuery();
  const [changeCurrentCoordsToNew] =
    useChangeCurrentCoordsToNewLogisticCoordinatesMutation();

  const onAddressSearchClick = async () => {
    const response = await getCoordsBySearch(addressInput);

    if (response.error) return;

    const newPoint = {
      latitude: response.data?.latitude,
      longitude: response.data?.longitude,
      address: addressInput,
    };

    dispatch(setChangeCoordsFormDataNewPoint(newPoint));
  };

  const onSaveButtonClick = async () => {
    const response = await changeCurrentCoordsToNew({
      projectId: projectInfo.id,
      pointId: changeCoordsFormData.point.id,
      latitude: changeCoordsFormData.newPoint.latitude,
      longitude: changeCoordsFormData.newPoint.longitude,
    });

    if (response.error) return;

    dispatch(resetState());
  };

  useEffect(() => {
    setAddressInput(changeCoordsFormData?.newPoint?.address);
  }, [changeCoordsFormData?.newPoint?.address]);

  useEffect(() => {
    if (changeCoordsFormData?.point?.address) {
      setAddressInput(changeCoordsFormData?.point?.address);
    }
  }, []);

  return (
    <Root isVisible={changeCoordsFormData.isVisible} expanded={expanded}>
      <Flex flexDirection="row" justifyContent="space-between">
        <Flex>
          <Text variant="title1">
            Изменить координаты точки {changeCoordsFormData.point.pointCode}
          </Text>
        </Flex>
        <Flex cursor="pointer" onClick={() => dispatch(resetState())}>
          {crossIcon}
        </Flex>
      </Flex>
      <Flex mt="12px">
        <Flex>
          <Text variant="body3" color="color3">
            Введите адрес или поставьте точку на карте
          </Text>
        </Flex>
        <Flex flexDirection="row" mt="4px">
          <InputText
            value={addressInput || ""}
            onChange={(e) => setAddressInput(e.target.value)}
            onClear={() => setAddressInput("")}
          />
          <Flex width="144px">
            <Button
              height="48px"
              width="144px"
              color="#2D8AF6"
              text="НАЙТИ"
              onClick={onAddressSearchClick}
            />
          </Flex>
        </Flex>
        <Flex flexDirection="row" justifyContent="space-between" mt="12px">
          <Flex
            width="100%"
            justifyContent="center"
            mr="12px"
            padding="12px"
            border="1px solid #CCCCCC"
            bg="#e7e6e6"
          >
            {changeCoordsFormData?.newPoint?.latitude &&
            changeCoordsFormData?.newPoint?.longitude ? (
              <Text variant="body1">
                {changeCoordsFormData?.newPoint?.latitude},{" "}
                {changeCoordsFormData?.newPoint?.longitude} - новые координаты
              </Text>
            ) : (
              <Text variant="body1">Нет данных о новых координатах</Text>
            )}
          </Flex>
          {changeCoordsFormData?.newPoint?.latitude &&
            changeCoordsFormData?.newPoint?.longitude && (
              <Flex width="144px">
                <Button
                  height="48px"
                  width="144px"
                  color="#2D8AF6"
                  text="СОХРАНИТЬ"
                  onClick={onSaveButtonClick}
                />
              </Flex>
            )}
        </Flex>
      </Flex>
    </Root>
  );
};

const Root = styled.div`
  display: ${({ isVisible }) => (isVisible ? "block" : "none")};
  position: absolute;
  width: ${({ expanded }) => (expanded ? "40%" : "100%")};
  left: ${({ expanded }) => (expanded ? "calc(100% - 40%)" : 0)};
  height: auto;
  padding: 24px;
  background: #cee3fd;
  z-index: 2000;
`;

export default ChangeCoordsForm;
