export const routeColumns = [
  {
    id: 300,
    table: "route",
    accessor: "routeCode",
    key: "routeCode",
    Header: "Маршрут.Код",
  },
  {
    id: 302,
    table: "route",
    accessor: "routeName",
    key: "routeName",
    Header: "Маршрут.Наименование",
  },
  {
    id: 301,
    table: "route",
    accessor: "command",
    key: "command",
    Header: "Маршрут.Команда",
  },
  {
    id: 303,
    table: "route",
    accessor: "pointStartCode",
    key: "pointStartCode",
    Header: "Маршрут.КодТочкиСтарта",
  },
  {
    id: 304,
    table: "route",
    accessor: "pointFinishCode",
    key: "pointFinishCode",
    Header: "Маршрут.КодТочкиФиниша",
  },
];

export const getStatusData = (status) => {
  // null, success, warning, error
  if (status === "success")
    return { text: "Маршрут успешно обновлен", color: "color8" };
  if (status === "warning")
    return {
      text: "Исправьте ошибку, иначе маршрут не будет обновлен:",
      color: "color6",
    };
  if (status === "error")
    return { text: "Что-то пошло не так", color: "color5" };
  if (status === "loading")
    return { text: "Маршрут обновляется...", color: "color2" };
  return {};
};
