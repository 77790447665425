import { ArrowDownward, Cross, Delete, ReportProblem } from "app/assets/icons";
import { expandTable } from "app/store/reducer/project-settings";
import {
  resetSelectedRoutePoints,
  setAllPointsChecked,
  setSelectedRoutePoints,
  setSortFilter
} from "app/store/reducer/routes";
import { CollapseIcon, ExpandIcon, GearIcon } from "icons/icons";
import React, { useEffect, useState } from "react";
import Highlighter from "react-highlight-words";
import { useDispatch, useSelector } from "react-redux";
import {
  useGetRoutesModeColumnsQuery,
  useUpdateRoutesModeColumnsMutation,
} from "shared/api/current-project";
import { useDeletePointMutation } from "shared/api/routes";
import { TABLE_HEAD_HEIGHT } from "shared/config";
import { Checkbox, Flex, IconButton, Text } from "shared/ui";
import styled from "styled-components";

export const HeaderCellSort = ({ text = "", sortBy,
                                 withAllPointsCheckbox = false, }) => {
  const dispatch = useDispatch();
  const { frontendFilters, allPointsChecked } = useSelector((state) => state.rootReducer.routes);

  return (
    <Flex flexDirection="row" alignItems="center">
      {withAllPointsCheckbox && (
        <Flex mr="7px">
          <Checkbox
            checked={allPointsChecked}
            onChange={() => {
              dispatch(setAllPointsChecked(!allPointsChecked));
              dispatch(resetSelectedRoutePoints());
            }}
          />
        </Flex>
      )}
      <Text>{text}</Text>
      <IconButton onClick={() => dispatch(setSortFilter(sortBy))}>
        <ArrowDownward
          fill={frontendFilters.sort === sortBy ? "#000000" : "#CCCCCC"}
        />
      </IconButton>
    </Flex>
  );
};

export const RouteCell = ({ data = {} }) => {
  const dispatch = useDispatch();
  const { selectedRoutePoints } = useSelector(
    (state) => state.rootReducer.routes,
  );

  const onRouteSelect = () => {
    // dispatch(unsetMapCenterPoint());
    dispatch(setSelectedRoutePoints(data.id));
    dispatch(setAllPointsChecked(false));
  };

  return (
    <Flex flexDirection="row" alignItems="center">
      <Checkbox
        filledColor={data.color}
        emptyColor={data.color}
        checked={selectedRoutePoints.includes(data.id)}
        onChange={() => onRouteSelect()}
      />
      <Flex ml="7px">
        <Text>{data.routeName}</Text>
      </Flex>
    </Flex>
  );
};

export const PointCell = ({ text }) => {
  const { frontendFilters } = useSelector((state) => state.rootReducer.routes);

  return (
    <Highlighter
      highlightClassName="YourHighlightClass"
      searchWords={[frontendFilters.address]}
      autoEscape
      textToHighlight={text}
      highlightStyle={{ background: "#CEE3FD", padding: 0 }}
    />
  );
};

export const ActionsCell = ({ data = {} }) => {
  const { projectInfo } = useSelector(
    (state) => state.rootReducer.currentProject,
  );
  const { frontendFilters } = useSelector((state) => state.rootReducer.routes);
  const [deletePoint] = useDeletePointMutation();

  const onDelete = async (e) => {
    e.stopPropagation();
    await deletePoint({
      projectId: projectInfo.id,
      routeId: data.routeId,
      id: data.id,
    });
  };

  if (data.duplicate) {
    return (
      <DuplicateButton>
        <IconButton onClick={(e) => onDelete(e)}>
          <Delete className="delete_icon" />
          <ReportProblem
            className="report_icon"
            fill={frontendFilters.duplicates ? data.color : "#CCCCCC"}
          />
        </IconButton>
      </DuplicateButton>
    );
  }

  if (data.additionalRoute) {
    return (
      <IconButton onClick={(e) => onDelete(e)}>
        <Delete />
      </IconButton>
    );
  }

  return null;
};

export const ActionsHeader = ({ allColumns, toggleHideColumn }) => {
  const dispatch = useDispatch();
  const [showSettings, setShowSettings] = useState(false);

  const {
    projectInfo: { id: projectId },
  } = useSelector((state) => state.rootReducer.currentProject);
  const { expandMode } = useSelector(
    (state) => state.rootReducer.projectSettings,
  );

  const { data: columnsToShow, isSuccess } = useGetRoutesModeColumnsQuery(
    { projectId },
    { refetchOnMountOrArgChange: true },
  );
  const [saveColumns] = useUpdateRoutesModeColumnsMutation();

  // hide additional columns before settings were fetched
  useEffect(() => {
    allColumns.map((column) => {
      if (column.name) toggleHideColumn(column.id, column.hiddenByDefault);
      return null;
    });
  }, []);

  // if data exists on server – hide those columns
  useEffect(() => {
    if (isSuccess && columnsToShow.length > 0) {
      allColumns.map((column) => {
        if (column.name)
          toggleHideColumn(column.id, columnsToShow.indexOf(column.id) === -1);
        return null;
      });
    }
  }, [isSuccess, projectId]);

  const handleSaveHiddenColumns = async () => {
    setTimeout(async () => {
      const columnsToShowIds = allColumns
        .map((column) => (column.isVisible ? column.id : null))
        .filter((id) => id !== "actions")
        .filter(Boolean);

      await saveColumns({ projectId, columns: columnsToShowIds });
    }, 0);
  };

  const handleSwitchTableExpand = () => {
    dispatch(expandTable());
    setShowSettings(false);
  };

  const resetColumnsVisibility = () => {
    allColumns.map((column) => {
      toggleHideColumn(column.id, column.hiddenByDefault);
      return null;
    });
    setShowSettings(false);
    handleSaveHiddenColumns();
  };

  return (
    <>
      <Flex flexDirection="row" alignItems='center' justifyContent='center'>
        <ControlButton
          onClick={() => setShowSettings((prevState) => !prevState)}
        >
          <GearIcon />
        </ControlButton>
        <ControlButton onClick={handleSwitchTableExpand}>
          {expandMode === "table" ? (
            <CollapseIcon color="#595959" />
          ) : (
            <ExpandIcon color="#595959" />
          )}
        </ControlButton>
      </Flex>
      {showSettings && (
        <SettingsMenu>
          {allColumns.map((column) => {
            if (column.name)
              return (
                <Flex
                  key={column.id}
                  flexDirection="row"
                  alignItems="center"
                  p="4px 2px"
                >
                  <input
                    type="checkbox"
                    {...column.getToggleHiddenProps()}
                    onChange={(e) => {
                      column.getToggleHiddenProps().onChange(e);
                      handleSaveHiddenColumns();
                    }}
                  />
                  <span>{column.name}</span>
                </Flex>
              );
            return null;
          })}
          <ResetButton onClick={resetColumnsVisibility}>Сбросить</ResetButton>
          <CloseButton onClick={() => setShowSettings(false)}>
            <Cross/>
          </CloseButton>
        </SettingsMenu>
      )}
    </>
  );
};

const DuplicateButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  .delete_icon {
    display: none;
  }

  .report_icon {
    display: block;
  }

  &:hover {
    .delete_icon {
      display: block;
    }

    .report_icon {
      display: none;
    }
  }
`;

const ControlButton = styled.div`
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  border-radius: 4px;
  cursor: pointer;

  :last-of-type {
    margin-left: 16px;
  }
`;

const SettingsMenu = styled.div`
  position: absolute;
  top: ${TABLE_HEAD_HEIGHT};
  right: 0;
  z-index: 9999;
  padding: 12px;
  background: white;
  box-shadow: 0px -8px 10px -4px rgba(0, 0, 0, 0.1);
  border: 1px solid #ccc;

  span {
    margin-left: 4px;
  }
`;

const ResetButton = styled.p`
  margin-top: 4px;
  cursor: pointer;
  text-decoration: underline;
`;

const CloseButton = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
  cursor: pointer;
  transform: scale(.75);
`
