import * as React from "react";

const SvgArrowDownward = ({ fill = '#ccc', ...props }) => (
  <svg
    width={16}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#arrow_downward_svg__a)">
      <path
        d="m13.333 8-.94-.94-3.726 3.72V2.667H7.333v8.113l-3.72-3.727L2.667 8 8 13.333 13.333 8Z"
        fill={fill}
      />
    </g>
    <defs>
      <clipPath id="arrow_downward_svg__a">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgArrowDownward;
