import Switch from "@material-ui/core/Switch";
import { PopupContext } from "app/context/PopupContext";
import {
  resetSelectedRoutePoints,
  setAllPointsChecked,
} from "app/store/reducer/routes";
import { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useListMapRoutePointsQuery,
  useSetDayMutation,
} from "shared/api/routes";
import {
  Button,
  Checkbox,
  CustomRadio,
  MultilineErrorText,
  Popup,
} from "shared/ui";
import styled from "styled-components";

const DAYS_OF_WEEK = [
  "Понедельник",
  "Вторник",
  "Среда",
  "Четверг",
  "Пятница",
  "Суббота",
  "Воскресенье",
];

const SetDay = () => {
  const dispatch = useDispatch();
  const { hidePopup } = useContext(PopupContext);
  const [serverErrors, setServerErrors] = useState([]);
  const [isMultiple, setIsMultiple] = useState(false);
  const [selectedDays, setSelectedDays] = useState([]);
  const { projectInfo } = useSelector(
    (state) => state.rootReducer.currentProject,
  );
  const { frontendFilters, selectedRoutePoints, allPointsChecked } =
    useSelector((state) => state.rootReducer.routes);
  const { data: allRoutePoints } = useListMapRoutePointsQuery(
    {
      projectId: projectInfo.id,
      managerCodes: frontendFilters?.managerCodes,
      commands: frontendFilters?.commands,
      periodicityList: frontendFilters?.periodicityList,
      routeCodes: frontendFilters?.routeCodes,
      daysOfWeek: frontendFilters?.daysOfWeek,
      attributeCodes1: frontendFilters?.attributeCodes1,
      attributeCodes2: frontendFilters?.attributeCodes2,
    },
    { skip: !projectInfo.id, refetchOnMountOrArgChange: true },
  );

  const [setDay, { isLoading }] = useSetDayMutation();

  const handleSwitch = () => {
    setIsMultiple((prev) => !prev);
    setSelectedDays([]);
  };

  const handleSelectedDays = (day) => {
    if (isMultiple) {
      if (selectedDays.includes(day)) {
        setSelectedDays([...selectedDays.filter((item) => item !== day)]);
      } else {
        setSelectedDays([...selectedDays, day]);
      }
    } else {
      setSelectedDays([day]);
    }
  };

  const onSubmit = async () => {
    let response;
    if (allPointsChecked) {
      const allRoutePointsIdList = allRoutePoints.map((point) => point.id);
      response = await setDay({
        projectId: projectInfo.id,
        routePointIdList: allRoutePointsIdList,
        daysOfWeek: selectedDays,
      });
    } else {
      response = await setDay({
        projectId: projectInfo.id,
        routePointIdList: selectedRoutePoints,
        daysOfWeek: selectedDays,
      });
    }

    if (response?.data?.errorMessages.length) {
      setServerErrors(response?.data?.errorMessages);
      return;
    }

    dispatch(
      allPointsChecked
        ? setAllPointsChecked(false)
        : resetSelectedRoutePoints(),
    );
    hidePopup();
  };

  return (
    <Popup title="Задать день" isCenter={false} positionLeft="370px">
      <SwitchRow isMultiple={isMultiple}>
        <SwitchText>Выбрать несколько</SwitchText>
        <div>
          <Switch
            checked={isMultiple}
            onChange={handleSwitch}
            size="small"
            color="default"
          />
        </div>
      </SwitchRow>
      {!isMultiple && (
        <ListWrapper>
          {DAYS_OF_WEEK.map((day) => (
            <CustomRadio
              key={day}
              className="customRadio"
              label={day}
              name="dayOfWeek"
              checked={selectedDays.includes(day)}
              onChange={() => handleSelectedDays(day)}
            />
          ))}
        </ListWrapper>
      )}
      {isMultiple && (
        <ListWrapper>
          {DAYS_OF_WEEK.map((day) => (
            <Row key={day}>
              <CheckboxWrapper>
                <Checkbox
                  name="dayOfWeek"
                  filledColor="#000000"
                  emptyColor="#787878"
                  checked={selectedDays.includes(day)}
                  onChange={() => handleSelectedDays(day)}
                />
              </CheckboxWrapper>
              <CheckboxesText>{day}</CheckboxesText>
            </Row>
          ))}
        </ListWrapper>
      )}
      <Button
        width="100%"
        text="Применить"
        onClick={onSubmit}
        disabled={!selectedDays.length || isLoading}
      />
      <MultilineErrorText errorsArr={serverErrors} />
    </Popup>
  );
};

const SwitchRow = styled.label`
  display: flex;
  align-items: center;
  margin: 20px 0;
  cursor: pointer;

  .MuiSwitch-thumb {
    color: ${({ isMultiple }) => (isMultiple ? "#000" : "#8c8c8c")};
  }

  .MuiSwitch-track {
    opacity: ${({ isMultiple }) => (isMultiple ? "0.5" : "0.15")};
  }
`;

const SwitchText = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  color: #787878;
  margin-right: 7px;
`;

const ListWrapper = styled.div`
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .customRadio {
    margin-bottom: 12px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const Row = styled.label`
  display: inline-flex;
  align-items: center;
  margin-bottom: 12px;
  cursor: pointer;

  &:last-child {
    margin-bottom: 0;
  }
`;

const CheckboxWrapper = styled.div`
  margin-right: 7px;
`;

const CheckboxesText = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
`;

export default SetDay;
