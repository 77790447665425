import { setMapMarkersColoredBy } from "app/store/reducer/routes";
import { BrashIcon } from "icons/icons";
import { useDispatch, useSelector } from "react-redux";
import { MARKER_COLOR_BY, USER_TYPE } from "shared/config";
import { getUserRole } from "shared/libs/jwt-decode";
import { Flex, Text } from "shared/ui";
import styled from "styled-components";

import {
  AddressSearch, AttributeDropdown,
  CommandDropdown,
  Duplicates,
  ManagerDropdown,
  PeriodicityDropdown,
  RouteDropdown,
  VisitDays,
  VisitDaysRouteEng
} from "./model";

const Filters = () => {
  const dispatch = useDispatch();
  const role = getUserRole();
  const isRouteEng = role === USER_TYPE.ROUTE_ENG;

  const { mapMarkersColoredBy } = useSelector(
    (state) => state.rootReducer.routes,
  );

  const onRouteBrashIconClick = () => {
    if (mapMarkersColoredBy === MARKER_COLOR_BY.DAY_OF_WEEK) {
      dispatch(setMapMarkersColoredBy(MARKER_COLOR_BY.ROUTE));
    }
  };

  const onVisitDaysBrashIconClick = () => {
    if (mapMarkersColoredBy === MARKER_COLOR_BY.ROUTE) {
      dispatch(setMapMarkersColoredBy(MARKER_COLOR_BY.DAY_OF_WEEK));
    }
  };

  return (
    <Root>
      <Flex flex={1} minWidth="120px" mr="8px">
        <Flex flexDirection="row" mb="4px">
          <Text variant="body3" color="color3">
            {isRouteEng ? "Команда" : "Менеджер"}
          </Text>
        </Flex>
        {isRouteEng ? <CommandDropdown /> : <ManagerDropdown />}
      </Flex>
      <Flex flex={2} minWidth="120px" mr="8px">
        <Flex flexDirection="row" mb="4px">
          <Text variant="body3" color="color3">
            Маршрут
          </Text>
          <Flex
            justifyContent="center"
            alignItems="center"
            ml="6px"
            cursor="pointer"
            onClick={onRouteBrashIconClick}
          >
            <BrashIcon
              color={
                mapMarkersColoredBy === MARKER_COLOR_BY.ROUTE
                  ? "#000000"
                  : "#CCCCCC"
              }
            />
          </Flex>
        </Flex>
        <RouteDropdown />
      </Flex>
      <Flex flex={2} minWidth="100px" mr="8px">
        <Flex flexDirection="row" mb="4px">
          <Text variant="body3" color="color3">
            Поиск
          </Text>
        </Flex>
        <AddressSearch />
      </Flex>
      {isRouteEng && (
        <Flex flex={1} mr="8px">
          <Flex flexDirection="row" mb="4px">
            <Text variant="body3" color="color3">
              Срок доставки
            </Text>
          </Flex>
          <PeriodicityDropdown />
        </Flex>
      )}
      <Flex flex={1} mr="8px">
        <Flex flexDirection="row" mb="4px">
          <Text variant="body3" color="color3">
            День визита
          </Text>
          <Flex
            justifyContent="center"
            alignItems="center"
            ml="6px"
            cursor="pointer"
            onClick={onVisitDaysBrashIconClick}
          >
            <BrashIcon
              color={
                mapMarkersColoredBy === MARKER_COLOR_BY.DAY_OF_WEEK
                  ? "#000000"
                  : "#CCCCCC"
              }
            />
          </Flex>
        </Flex>
        {isRouteEng ? <VisitDaysRouteEng /> : <VisitDays />}
      </Flex>
      {isRouteEng && (
        <Flex flex={1} mr="8px">
          <Flex flexDirection="row" mb="4px">
            <Text variant="body3" color="color3">
              Признак1
            </Text>
          </Flex>
          <AttributeDropdown attribute='attributeCodes1' />
        </Flex>
      )}
      {isRouteEng && (
        <Flex flex={1} mr="8px">
          <Flex flexDirection="row" mb="4px">
            <Text variant="body3" color="color3">
              Признак2
            </Text>
          </Flex>
          <AttributeDropdown attribute='attributeCodes2' />
        </Flex>
      )}
      <Flex>
        <Flex flexDirection="row" mb="4px">
          <Text variant="body3" color="color3">
            Дубли
          </Text>
        </Flex>
        <Duplicates />
      </Flex>
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  align-items: center;
`;

export default Filters;
