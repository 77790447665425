import { setDuplicateFilter } from "app/store/reducer/routes";
import { useDispatch, useSelector } from "react-redux";
import {
  useListFiltersRoutesCommandsDuplicatesQuery,
  useListFiltersRoutesUsersDuplicatesQuery,
} from "shared/api/filters";
import { getUserRole } from "shared/libs/jwt-decode";

import { Duplicates as DuplicatesUI } from "../../../../ui";

const Duplicates = () => {
  const role = getUserRole();
  const dispatch = useDispatch();
  const { projectInfo } = useSelector(
    (state) => state.rootReducer.currentProject,
  );
  const { frontendFilters: { managerCodes, commands, duplicates } } = useSelector((state) => state.rootReducer.routes);
  const { data: userFilters, isSuccess: userFiltersIsSuccess } =
    useListFiltersRoutesUsersDuplicatesQuery(
      {
        projectId: projectInfo.id,
        managers: managerCodes,
      },
      {
        skip: !projectInfo.id || role === "ROUTE_ENG",
        refetchOnMountOrArgChange: true,
      },
    );
  const { data: routeEngFilters, isSuccess: routeEngFiltersIsSuccess } =
    useListFiltersRoutesCommandsDuplicatesQuery(
      {
        projectId: projectInfo.id,
        commands,
      },
      {
        skip: !projectInfo.id || role !== "ROUTE_ENG",
        refetchOnMountOrArgChange: true,
      },
    );
  const filters = role === "ROUTE_ENG" ? routeEngFilters : userFilters;

  const onSelect = () => {
    dispatch(setDuplicateFilter());
  };

  if (userFiltersIsSuccess || routeEngFiltersIsSuccess) {
    return (
      <DuplicatesUI
        count={filters?.countDuplicate}
        isSelected={duplicates}
        onSelect={onSelect}
      />
    );
  }

  return null;
};

export default Duplicates