import { crossIcon } from "icons/icons";
import React from "react";
import styled from "styled-components";

import Flex from "./Flex";
import Text from "./Text";

const Input = React.forwardRef(
  (
    {
      title = "",
      placeholder = "",
      errorText = "",
      withClear = false,
      onChange = () => {},
      onClear = () => {},
      ...props
    },
    ref,
  ) => (
    <Wrapper>
      <Title>{title}</Title>
      <InputWrapper>
        <StyledInput
          onChange={onChange}
          placeholder={placeholder}
          ref={ref}
          {...props}
        />
        {withClear && <ClearIcon onClick={onClear}>{crossIcon}</ClearIcon>}
      </InputWrapper>
      {errorText && (
        <Flex mt="4px">
          <Text variant="body3" color="color5">
            {errorText}
          </Text>
        </Flex>
      )}
    </Wrapper>
  ),
);

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Title = styled.div`
  font-size: 14px;
  line-height: 16px;
  color: #787878;
`;

const InputWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 48px;
  margin-top: 4px;
`;

const StyledInput = styled.input`
  width: 100%;
  height: 48px;
  font-size: 18px;
  border: 1px solid #cccccc;
  padding: 12px 44px 12px 12px;
  box-sizing: border-box;
`;

const ClearIcon = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 14px;
  height: 14px;
  top: 17px;
  right: 17px;
  cursor: pointer;
`;

export default Input;
