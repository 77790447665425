import { useTable } from "react-table";
import { hexToRgba, isOSX } from "shared/helpers";
import styled from "styled-components";

const Table = ({ data = [], columns = [], isExpand = false }) => {
  const tableInstance = useTable({
    columns,
    data,
  });
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  return (
    <TableWrapper isExpand={isExpand}>
      <TableContent {...getTableProps()}>
        <TableHead>
          {headerGroups.map((headerGroup) => (
            <TableHeaderRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <TableHeaderCell
                  width={column.w}
                  textAlign={column.textAlign}
                  {...column.getHeaderProps()}
                >
                  {column.render("Header")}
                </TableHeaderCell>
              ))}
            </TableHeaderRow>
          ))}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <TableDataRow
                id={row.original.routeCode}
                color={row.original.color}
                {...row.getRowProps()}
              >
                {row.cells.map((cell) => (
                  <TableDataCell
                    width={cell.column.w}
                    textAlign={cell.column.textAlign}
                    {...cell.getCellProps()}
                  >
                    {cell.render("Cell")}
                  </TableDataCell>
                ))}
              </TableDataRow>
            );
          })}
        </TableBody>
      </TableContent>
    </TableWrapper>
  );
};

const TableWrapper = styled.div`
  max-height: ${({ isExpand }) => (isExpand ? "40vh" : "25vh")};
  width: 98%;
  transition: 0.3s;
  overflow-x: hidden;
  overflow-y: auto;
`;

const TableContent = styled.table`
  border-collapse: collapse;
  width: 100%;
`;

const TableHead = styled.thead`
  width: 100%;
  position: sticky;
  top: 0;
`;

const TableHeaderRow = styled.tr`
  ${!isOSX && "::-webkit-scrollbar {opacity: 0;}"}
`;

const TableHeaderCell = styled.th`
  background: white;
  padding: 4px 8px;

  border-spacing: 0;
  outline: 0.5px solid #ccc;
  outline-offset: -0.5px;
  border-top: 1px solid #ccc;

  font-size: 14px;
  line-height: 14px;
  font-style: normal;
  font-weight: normal;
  text-align: ${({ textAlign }) => textAlign ?? "center"};
  vertical-align: top;
  color: #787878;
`;

const TableDataRow = styled.tr`
  background: ${({ color }) => (color ? hexToRgba(color, 0.04) : "#fff")};
  border-bottom: 1px solid #cccccc;
  border-left: 1px solid #cccccc;
  border-right: 1px solid #cccccc;

  &:hover {
    background: #ecf4fe;
  }
`;

const TableDataCell = styled.td`
  padding: 4px;
  border-right: 1px solid #cccccc;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  text-align: ${({ textAlign }) => textAlign ?? "center"};
  word-break: break-all;

  :first-of-type {
    padding: 4px 8px;
  }
`;

const TableBody = styled.tbody`
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
`;

export default Table;
