import { DragIndicator } from "app/assets/icons";
import React from "react";
import { Flex, Input, Text, TextButton } from "shared/ui";
import styled from "styled-components";

import { handleDragOver, handleDragStart } from "../../helpers";

const Group = React.forwardRef(
  (
    {
      index,
      group = {},
      handleDropGroup = () => {},
      deleteGroup = () => {},
      errors = {},
      ...props
    },
    ref,
  ) => (
    <Flex width="270px">
      <Flex mb="8px">
        <Flex flexDirection="row" justifyContent="space-between">
          <Text
            variant="body3"
            color={errors?.groups?.[index]?.name?.message ? "color5" : "color3"}
          >
            Группа {index + 1}
          </Text>
          <TextButton onClick={() => deleteGroup(index, group)}>
            <Text variant="body3" color="color0">
              Удалить
            </Text>
          </TextButton>
        </Flex>
        <Input ref={ref} {...props} />
        {}
      </Flex>
      <StyledContractsList
        onDragOver={handleDragOver}
        onDrop={(e) => handleDropGroup(e, group.id)}
      >
        {group?.contractCodes?.map((contract) => (
          <StyledContract
            key={contract}
            draggable
            onDragStart={(e) => handleDragStart(e, "group", contract, group.id)}
          >
            <Text variant="body3">{contract}</Text>
            <DragIndicator />
          </StyledContract>
        ))}
      </StyledContractsList>
    </Flex>
  ),
);

const StyledContractsList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5px;
  padding: 5px;
  min-height: 100px;
  border: 2px dashed #cee3fd;
`;

const StyledContract = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 60px;
  height: 24px;
  padding: 4px;
  background: #cee3fd;
  cursor: pointer;
`;

export default Group;
