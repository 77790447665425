/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-shadow */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable consistent-return */
import { PopupContext } from "app/context/PopupContext";
import { includes, pull } from "lodash";
import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  useCreateContractsProjectJobMutation,
  useLazyGetProjectJobQuery,
  useListContractsQuery,
} from "shared/api/projects";
import {
  Button,
  Checkbox,
  Flex,
  Loader,
  PageShadow,
  Popup,
  Text,
} from "shared/ui";
import styled from "styled-components";

import { Table } from "./ui";

const LoadContracts = ({ project, navigateToProject = () => {} }) => {
  const { hidePopup } = useContext(PopupContext);
  const [selectedObject, setSelectedObject] = useState({});
  const [checkboxToggleArr, setCheckboxToggleArr] = useState([]);
  const [isErrorText, setErrorText] = useState(false);
  const { data: contracts, isFetching } = useListContractsQuery({
    id: project.id,
  });
  const [createProjectJob, { isLoading: isCreateProjectJobLoading }] =
    useCreateContractsProjectJobMutation();
  const [getProjectJob, projectState] = useLazyGetProjectJobQuery({
    pollingInterval: 3000,
  });

  const onAllCheckboxClick = useCallback(
    (checked, data) => {
      const { branchCode, contractNameCodeMap } = data;
      const newSelectedObject = { ...selectedObject };
      if (checked) {
        newSelectedObject[branchCode] = Object.values(contractNameCodeMap);
      } else {
        delete newSelectedObject[branchCode];
      }
      setSelectedObject(newSelectedObject);
    },
    [selectedObject],
  );

  const onContractCheckboxClick = useCallback(
    (checked, branchName, branchCode) => {
      const newSelectedObject = { ...selectedObject };
      if (checked) {
        if (newSelectedObject[branchName] === undefined) {
          newSelectedObject[branchName] = [];
        }
        newSelectedObject[branchName].push(branchCode);
      } else if (newSelectedObject[branchName].length === 1) {
        delete newSelectedObject[branchName];
      } else pull(newSelectedObject[branchName], branchCode);
      setSelectedObject(newSelectedObject);
    },
    [selectedObject],
  );

  const onChooseContractButtonClick = useCallback(
    (branchName) => {
      setCheckboxToggleArr([...checkboxToggleArr, branchName]);
    },
    [checkboxToggleArr],
  );

  const onGetContractsButtonClick = async () => {
    const branchContractItems = Object.keys(selectedObject).map((item) => ({
      branchCode: item,
      contractCodes: selectedObject[item],
    }));

    if (branchContractItems.length < 1) {
      return setErrorText(true);
    }

    const response = await createProjectJob({
      projectId: project.id,
      branchContractItems,
    });
    if (response.error) return;

    await getProjectJob({
      projectId: project.id,
      jobId: response.data?.id,
    });
  };

  useEffect(() => {
    if (Object.keys(selectedObject).length > 0 && isErrorText) {
      setErrorText(false);
    }
  }, [selectedObject, isErrorText]);

  useEffect(() => {
    if (projectState?.data?.status === "DONE") {
      hidePopup();
      navigateToProject();
    }
  }, [projectState?.data?.status, navigateToProject]);

  const data = React.useMemo(() => contracts, [contracts]);
  const columns = React.useMemo(
    () => [
      {
        id: "0",
        accessor: (data) => (
          <Flex minWidth="200px">
            <Text variant="body1">{data.branchName}</Text>
          </Flex>
        ),
      },
      {
        id: "1",
        accessor: (data) =>
          Object.keys(data.contractNameCodeMap).length > 0 ? (
            <Flex flexDirection="row" alignItems="center" minWidth="200px">
              <Flex mr="7px">
                <Checkbox
                  checked={
                    Object.keys(data.contractNameCodeMap).length ===
                    selectedObject[data.branchCode]?.length
                  }
                  onChange={(e) => onAllCheckboxClick(e.target.checked, data)}
                />
              </Flex>
              <Text variant="body1">Все контракты</Text>
            </Flex>
          ) : (
            <Flex>
              <Text variant="body1">Нет маршрутов</Text>
            </Flex>
          ),
      },
      {
        id: "2",
        accessor: (data) =>
          includes(checkboxToggleArr, data.branchCode) ? (
            <Flex flexDirection="row" flexWrap="wrap" minWidth="700px">
              {Object.values(data.contractNameCodeMap).map((branchCode) => (
                <Flex
                  key={branchCode}
                  flexDirection="row"
                  alignItems="center"
                  m="5px"
                  mr="24px"
                >
                  <Flex mr="7px">
                    <Checkbox
                      checked={includes(
                        selectedObject[data.branchCode],
                        branchCode,
                      )}
                      onChange={(e) =>
                        onContractCheckboxClick(
                          e.target.checked,
                          data.branchCode,
                          branchCode,
                        )
                      }
                    />
                  </Flex>
                  <Text variant="body1">{branchCode}</Text>
                </Flex>
              ))}
            </Flex>
          ) : (
            <Flex
              minWidth="200px"
              cursor="pointer"
              onClick={() => onChooseContractButtonClick(data.branchCode)}
            >
              {selectedObject[data.branchCode]?.length > 0 ? (
                <Text variant="body1" color="color0">
                  Выбрано {selectedObject[data.branchCode]?.length} из{" "}
                  {Object.keys(data.contractNameCodeMap).length}
                </Text>
              ) : (
                <>
                  {Object.keys(data.contractNameCodeMap).length > 0 && (
                    <Text variant="body1" color="color0">
                      Выбрать контракты
                    </Text>
                  )}
                </>
              )}
            </Flex>
          ),
      },
    ],
    [
      onAllCheckboxClick,
      onContractCheckboxClick,
      onChooseContractButtonClick,
      selectedObject,
      checkboxToggleArr,
    ],
  );

  if (
    isFetching ||
    isCreateProjectJobLoading ||
    projectState?.endpointName === "getProjectJob"
  ) {
    return (
      <PageShadow>
        <Loader isLoading />
      </PageShadow>
    );
  }

  if (!contracts) {
    return (
      <Popup isCenter title="Выберите филиалы и контракты для загрузки из УС">
        <Flex height="1px" bg="color4" mt="20px" />
        <Flex mt="20px">
          <Text variant="body1" color="color0" textAlign="center">
            Филиалы и контракты временно недоступны
          </Text>
        </Flex>
      </Popup>
    );
  }

  return (
    <Popup isCenter title="Выберите филиалы и контракты для загрузки из УС">
      <PopupWrapper>
        <Flex height="1px" bg="color4" mt="20px" />
        <Table data={data} columns={columns} />
        <Flex flexDirection="row" justifyContent="center">
          <Flex>
            <Button
              width="253px"
              height="48px"
              color="#2D8AF6"
              text="ЗАГРУЗИТЬ ДАННЫЕ"
              onClick={onGetContractsButtonClick}
            />
          </Flex>
        </Flex>
        {isErrorText && (
          <Flex mt="24px">
            <Text variant="body3" color="color5" textAlign="center">
              Выберите контракты для загрузки
            </Text>
          </Flex>
        )}
      </PopupWrapper>
    </Popup>
  );
};

const PopupWrapper = styled.div`
  max-height: 800px;
`;

export default LoadContracts;
