import { resetRouteFilter, setRouteFilter } from "app/store/reducer/routes";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useListFiltersRoutesCommandsDuplicatesQuery,
  useListFiltersRoutesUsersDuplicatesQuery,
} from "shared/api/filters";
import { sortByField } from "shared/helpers";
import { getUserRole } from "shared/libs/jwt-decode";
import { Checkbox, Flex, SearchDropdown, Text } from "shared/ui";

const RouteDropdown = () => {
  const dispatch = useDispatch();
  const role = getUserRole();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const { projectInfo } = useSelector(
    (state) => state.rootReducer.currentProject,
  );
  const {
    frontendFilters: { managerCodes, commands, routeCodes },
  } = useSelector((state) => state.rootReducer.routes);
  const { data: userFilters, isSuccess: userFiltersIsSuccess } =
    useListFiltersRoutesUsersDuplicatesQuery(
      {
        projectId: projectInfo.id,
        managers: managerCodes,
      },
      {
        skip: !projectInfo.id || role === "ROUTE_ENG",
        refetchOnMountOrArgChange: true,
      },
    );
  const { data: routeEngFilters, isSuccess: routeEngFiltersIsSuccess } =
    useListFiltersRoutesCommandsDuplicatesQuery(
      {
        projectId: projectInfo.id,
        commands,
      },
      {
        skip: !projectInfo.id || role !== "ROUTE_ENG",
        refetchOnMountOrArgChange: true,
      },
    );
  const filters = role === "ROUTE_ENG" ? routeEngFilters : userFilters;

  function onRouteSelect(route) {
    dispatch(setRouteFilter(route));
  }

  const onSearch = (value) => {
    setSearchValue(value);
  };

  const onToggle = (isOpen) => {
    if (isOpen === false) onSearch("");
    setIsDropdownOpen((prev) => !prev);
  };

  const onResetSearch = () => {
    onSearch("");
    setIsDropdownOpen(false);
  };

  const onResetAllCheckboxes = () => {
    dispatch(resetRouteFilter());
    setIsDropdownOpen(false);
  };

  if (userFiltersIsSuccess || routeEngFiltersIsSuccess) {
    return (
      <SearchDropdown
        isDropdownOpen={isDropdownOpen}
        toggleText={
          routeCodes?.length ? `Выбрано ${routeCodes?.length}` : "Выбрать"
        }
        searchValue={searchValue}
        onToggle={onToggle}
        onSearch={onSearch}
        onResetSearch={onResetSearch}
        onResetAllCheckboxes={onResetAllCheckboxes}
        multiselect
        selectedText
        selectedCount={routeCodes?.length}
      >
        {sortByField(filters?.routes, "routeName").map(
          (item) =>
            item.routeName
              .toLowerCase()
              .includes(searchValue.toLowerCase()) && (
              <SearchDropdown.Item key={item.routeId}>
                <Checkbox
                  filledColor={item.color}
                  emptyColor={item.color}
                  checked={routeCodes?.includes(item.routeCode)}
                  onChange={() => onRouteSelect(item)}
                />
                <Flex ml="7px">
                  <Text variant="body1">{item.routeName}</Text>
                </Flex>
              </SearchDropdown.Item>
            ),
        )}
      </SearchDropdown>
    );
  }

  return null;
};

export default RouteDropdown;
