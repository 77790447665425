import { Eye, EyeCrossed, File } from "app/assets/icons";
import { switchAreasVisible } from "app/store/reducer/days";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserRole } from "shared/libs/jwt-decode";
import { Button, Flex } from "shared/ui";
import styled from "styled-components";

import Report from "./points-report";

const Footer = ({ expanded }) => {
  const role = getUserRole();
  const dispatch = useDispatch();
  const [isReportOpen, setIsReportOpen] = useState(false);
  const { isAreasVisible } = useSelector((state) => state.rootReducer.days);

  const openReport = () => {
    setIsReportOpen(true);
  };

  if (isReportOpen) {
    return <Report setIsReportOpen={setIsReportOpen} />;
  }

  return (
    <Buttons>
      <Flex
        flexDirection="row"
        justifyContent={expanded ? "end" : "space-between"}
        gridColumnGap={expanded ? "20px" : "0"}
      >
        {role !== "ROUTE_ENG" && (
          <Button text="ОТЧЕТ ПО ТОЧКАМ" icon={<File />} onClick={openReport} />
        )}
        <Button
          text="ЗОНЫ ДОСТАВКИ"
          icon={isAreasVisible ? <EyeCrossed /> : <Eye />}
          onClick={() => dispatch(switchAreasVisible())}
        />
      </Flex>
    </Buttons>
  );
};

const Buttons = styled.div`
  width: 90%;
  position: absolute;
  z-index: 999;
  bottom: 12px;
  left: 50%;
  transform: translateX(-50%);
`;

export default Footer;
