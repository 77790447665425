import React from "react";
import styled from "styled-components";
import {
  border,
  color,
  compose,
  flexbox,
  grid,
  gridColumnGap,
  layout,
  position,
  space,
  zIndex,
} from "styled-system";

const Flex = ({ children, ...props }) => <Root {...props}>{children}</Root>;

const Root = styled.div`
  display: flex;
  flex-direction: column;
  cursor: ${(props) => props.cursor};
  white-space: ${(props) => props.whiteSpace};
  ${compose(
    flexbox,
    grid,
    space,
    layout,
    color,
    position,
    border,
    zIndex,
    gridColumnGap,
  )};
`;

export default Flex;
