/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { SORT } from "shared/config";

const resetStateByFilters = (state) => {
  state.selectedPoints = [];
  state.frontendFilters.limit = 10;
  state.mapCenterPoint = {};
  state.highlightedPointId = null;
  state.openedReport = {
    name: "",
    areaId: null,
    areaName: "",
  };
};

const initialState = {
  frontendFilters: {
    page: 0,
    limit: 10,
    address: "",
    areaIds: [],
    branchIds: [],
    contractIds: [],
    groupIds: [],
    sort: SORT.POINT,
  },
  selectedPoints: [],
  mapCenterPoint: {},
  highlightedPointId: null,
  isMapPointsVisible: true,
  tableActionsId: null,
  openedReport: {
    name: "",
    areaId: null,
    areaName: "",
  },
};

const shedule = createSlice({
  name: "shedule",
  initialState,
  reducers: {
    resetState: () => initialState,
    increaseLimitFilter: (state) => {
      state.frontendFilters.limit += 10;
    },
    setAddressFilter: (state, action) => {
      state.frontendFilters.address = action.payload;
    },
    setGroupFilter: (state, action) => {
      resetStateByFilters(state);
      if (state.frontendFilters.groupIds.includes(action.payload.id)) {
        state.frontendFilters.groupIds = state.frontendFilters.groupIds.filter(
          (item) => item !== action.payload.id,
        );
      } else {
        state.frontendFilters.groupIds = [
          ...state.frontendFilters.groupIds,
          action.payload.id,
        ];
      }
    },
    resetGroupFilter: (state) => {
      state.frontendFilters.groupIds = [];
      resetStateByFilters(state);
    },
    setAreaFilter: (state, action) => {
      resetStateByFilters(state);
      if (state.frontendFilters.areaIds.includes(action.payload.id)) {
        state.frontendFilters.areaIds = state.frontendFilters.areaIds.filter(
          (item) => item !== action.payload.id,
        );
      } else {
        state.frontendFilters.areaIds = [
          ...state.frontendFilters.areaIds,
          action.payload.id,
        ];
      }
    },
    resetAreaFilter: (state) => {
      state.frontendFilters.areaIds = [];
      resetStateByFilters(state);
    },
    setContractFilter: (state, action) => {
      resetStateByFilters(state);
      if (state.frontendFilters.contractIds.includes(action.payload.id)) {
        state.frontendFilters.contractIds =
          state.frontendFilters.contractIds.filter(
            (item) => item !== action.payload.id,
          );
      } else {
        state.frontendFilters.contractIds = [
          ...state.frontendFilters.contractIds,
          action.payload.id,
        ];
      }
    },
    resetContractFilter: (state) => {
      state.frontendFilters.contractIds = [];
      resetStateByFilters(state);
    },
    setSortFilter: (state, action) => {
      if (action.payload !== state.frontendFilters.sort) {
        resetStateByFilters(state);
        state.frontendFilters.sort = action.payload;
      }
    },
    setSelectedPoints: (state, action) => {
      if (state.selectedPoints.includes(action.payload)) {
        state.selectedPoints = state.selectedPoints.filter(
          (item) => item !== action.payload,
        );
      } else {
        state.selectedPoints = [...state.selectedPoints, action.payload];
      }
    },
    setSelectedByLassoRoutePoints: (state, action) => {
      if (action.payload instanceof Array) {
        state.selectedPoints = [
          ...new Set([...state.selectedPoints, ...action.payload]),
        ];
      } else if (!state.selectedPoints.includes(action.payload)) {
        state.selectedPoints = [...state.selectedPoints, action.payload];
      }
    },
    resetSelectedPoints: (state) => {
      state.selectedPoints = [];
    },
    setMapCenterPoint: (state, action) => {
      state.mapCenterPoint = action.payload;
    },
    setPointFoundByTable: (state, action) => {
      state.mapCenterPoint = {
        latitude: action.payload.latitude,
        longitude: action.payload.longitude,
      };
      state.highlightedPointId = action.payload.pointId;
    },
    setPointFoundByMarker: (state, action) => {
      state.frontendFilters.address = action.payload.pointCode;
      state.highlightedPointId = action.payload.highlightedPointId;
    },

    switchMapPointsVisible: (state) => {
      state.isMapPointsVisible = !state.isMapPointsVisible;
    },
    setTableActionsId: (state, action) => {
      state.tableActionsId = action.payload;
    },
    setOpenedReport: (state, action) => {
      state.openedReport = action.payload;
    },
    resetOpenedReport: (state) => {
      state.openedReport = {
        name: "",
        areaId: null,
        areaName: "",
      };
    },
  },
});

export const {
  resetState,
  increaseLimitFilter,
  setAddressFilter,
  setGroupFilter,
  resetGroupFilter,
  setAreaFilter,
  resetAreaFilter,
  setContractFilter,
  resetContractFilter,
  setSortFilter,
  setSelectedPoints,
  setSelectedByLassoRoutePoints,
  resetSelectedPoints,
  setMapCenterPoint,
  setPointFoundByTable,
  setPointFoundByMarker,
  switchMapPointsVisible,
  setTableActionsId,
  setOpenedReport,
  resetOpenedReport,
} = shedule.actions;

export default shedule.reducer;
