import { SERVER_ERROR_TEXT } from "shared/config";

import Flex from "../Flex";
import Text from "../Text";

const ErrorText = ({ errorText = SERVER_ERROR_TEXT }) => {
  if (errorText) {
    return (
      <Flex m="10px 0px">
        <Text variant="body3" textAlign="center" color="color5">
          {errorText}
        </Text>
      </Flex>
    );
  }
  return null;
};

export default ErrorText;
