/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

const resetStateByFilters = (state) => {
  state.mapCenterPoint = {};
  state.openedReport = {
    name: "",
    areaId: null,
    areaName: "",
  };
};

const initialState = {
  frontendFilters: {
    areaIds: [],
    branchIds: [],
    contractIds: [],
    groupIds: [],
  },
  mapCenterPoint: {},
  isMapPointsVisible: true,
  tableReportAreaId: null,
  openedReport: {
    name: "",
    areaId: null,
    areaName: "",
  },
};

const areas = createSlice({
  name: "areas",
  initialState,
  reducers: {
    resetState: () => initialState,
    setGroupFilter: (state, action) => {
      resetStateByFilters(state);
      if (state.frontendFilters.groupIds.includes(action.payload.id)) {
        state.frontendFilters.groupIds = state.frontendFilters.groupIds.filter(
          (item) => item !== action.payload.id,
        );
      } else {
        state.frontendFilters.groupIds = [
          ...state.frontendFilters.groupIds,
          action.payload.id,
        ];
      }
    },
    resetGroupFilter: (state) => {
      state.frontendFilters.groupIds = [];
      resetStateByFilters(state);
    },
    setMapCenterPoint: (state, action) => {
      state.mapCenterPoint = action.payload;
    },
    switchMapPointsVisible: (state) => {
      state.isMapPointsVisible = !state.isMapPointsVisible;
    },
    setTableReportAreaId: (state, action) => {
      state.tableReportAreaId = action.payload;
    },
    setOpenedReport: (state, action) => {
      state.openedReport = action.payload;
    },
    resetOpenedReport: (state) => {
      state.openedReport = {
        name: "",
        areaId: null,
        areaName: "",
      };
    },
  },
});

export const {
  resetState,
  setGroupFilter,
  resetGroupFilter,
  setMapCenterPoint,
  switchMapPointsVisible,
  setTableReportAreaId,
  setOpenedReport,
  resetOpenedReport,
} = areas.actions;

export default areas.reducer;
