/* eslint-disable no-unsafe-optional-chaining */
import { setMapCenterPoint } from "app/store/reducer/areas";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useListAreasQuery } from "shared/api/areas";
import { useListAnalyzePeriodQuery } from "shared/api/logistic";
import { convertPolygonCoordsToCenterLatLng } from "shared/helpers";

import { configColumns } from "./config";
import { TableUI } from "./ui";

const Table = () => {
  const dispatch = useDispatch();
  const { projectInfo } = useSelector(
    (state) => state.rootReducer.currentProject,
  );
  const {
    mapCenterPoint,
    frontendFilters: { branchIds, groupIds },
    tableReportAreaId,
  } = useSelector((state) => state.rootReducer.areas);
  const { data: analyzePeriod } = useListAnalyzePeriodQuery(
    {
      projectId: projectInfo.id,
    },
    { skip: !projectInfo.id },
  );

  const { data: areas, isFetching } = useListAreasQuery(
    {
      projectId: projectInfo.id,
      branchIds,
      groupIds,
      monthFrom: analyzePeriod?.monthFrom,
      monthTo: analyzePeriod?.monthTo,
      yearFrom: analyzePeriod?.yearFrom,
      yearTo: analyzePeriod?.yearTo,
    },
    {
      skip:
        !projectInfo.id ||
        !analyzePeriod?.monthFrom ||
        !analyzePeriod?.monthTo ||
        !analyzePeriod?.yearFrom ||
        !analyzePeriod?.yearTo,
      refetchOnMountOrArgChange: true,
    },
  );

  const onRowClick = (row) => {
    const polygon = row.original;
    const polygonCenter = convertPolygonCoordsToCenterLatLng(
      polygon.coordinates,
    );
    dispatch(
      setMapCenterPoint({
        id: polygon.id,
        latitude: polygonCenter[0],
        longitude: polygonCenter[1],
        clickedIn: "table",
      }),
    );
  };

  const data = React.useMemo(() => areas, [areas]);
  const columns = React.useMemo(() => configColumns, []);

  if (isFetching) {
    return null;
  }

  return (
    <TableUI
      data={data}
      columns={columns}
      onRowClick={onRowClick}
      mapCenterPoint={mapCenterPoint}
      tableReportAreaId={tableReportAreaId}
    />
  );
};

export default Table;
