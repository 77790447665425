import { DefaultMarker, IndividualMarker } from "app/assets/icons";
import L from "leaflet";
import ReactDOMServer from "react-dom/server";
import { Marker } from "react-leaflet";

const MarkerIcon = ({
  point = {},
  onMarkerClicked = () => {},
  onPopupClosed = () => {},
  children,
}) => {
  const eventHandlers = {
    click: (e) => onMarkerClicked(e),
    popupclose: () => onPopupClosed(),
  };

  if (point?.individualArea) {
    return (
      <Marker
        position={[point.latitude, point.longitude]}
        icon={L.divIcon({
          className: "custom-icon",
          html: ReactDOMServer.renderToString(
            <IndividualMarker width={32} height={32} />,
          ),
        })}
        zIndexOffset={5000}
        eventHandlers={eventHandlers}
      >
        {children}
      </Marker>
    );
  }

  return (
    <Marker
      position={[point.latitude, point.longitude]}
      icon={L.divIcon({
        className: "custom-icon",
        html: ReactDOMServer.renderToString(<DefaultMarker />),
      })}
      zIndexOffset={1000}
      eventHandlers={eventHandlers}
    >
      {children}
    </Marker>
  );
};

export default MarkerIcon;
