/* eslint-disable no-unused-expressions */
/* eslint-disable no-alert */
/* eslint-disable no-console */
/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
/* eslint-disable no-bitwise */
/* eslint-disable default-param-last */
/* eslint-disable consistent-return */
import jwtDecode from "jwt-decode";
import L from "leaflet";

import {
  CYCLES,
  DAYS_OF_WEEK_LOGISTIC_CALENDAR_COLOR,
  DAYS_OF_WEEK_SHORT_RU,
  DEFAULT_BOUNDS,
  DISTANCE_DIFFERENCE,
  PROJECT_MODE,
} from "../config";

export function beautifyWeekDay(day) {
  switch (day) {
    case "Понедельник":
      return "ПН";
    case "Вторник":
      return "ВТ";
    case "Среда":
      return "СР";
    case "Четверг":
      return "ЧТ";
    case "Пятница":
      return "ПТ";
    case "Суббота":
      return "СБ";
    case "Воскресенье":
      return "ВС";
    case "N/A":
      return "N/A";
    default:
      return null;
  }
}

export function translateWeekDay(day) {
  switch (day) {
    case "Понедельник":
      return "MONDAY";
    case "Вторник":
      return "TUESDAY";
    case "Среда":
      return "WEDNESDAY";
    case "Четверг":
      return "THURSDAY";
    case "Пятница":
      return "FRIDAY";
    case "Суббота":
      return "SATURDAY";
    case "Воскресенье":
      return "SUNDAY";
    case "N/A":
      return "UNDEFINED";
    default:
      return null;
  }
}

export function beautifyPointAddress(point) {
  return `${point.pointCode} ${point.pointName} ${
    point.address !== "NULL" ? point.address : ""
  }`;
}

export function getMarkerColorByWeekDay(day) {
  switch (day) {
    case "Понедельник":
      return "#F50008";
    case "Вторник":
      return "#9B00F5";
    case "Среда":
      return "#0088C2";
    case "Четверг":
      return "#00B6C2";
    case "Пятница":
      return "#00C23A";
    case "Суббота":
      return "#787878";
    case "Воскресенье":
      return "#787878";
    default:
      return "#000000";
  }
}

export function beautifyCycleForBackend(cycle) {
  switch (cycle) {
    case "Каждая":
      return CYCLES.EVERY_WEEK;
    case "Чётная":
      return CYCLES.EVEN_WEEK;
    case "Нечётная":
      return CYCLES.ODD_WEEK;
    case "Первая":
      return CYCLES.FIRST_WEEK;
    case "Вторая":
      return CYCLES.SECOND_WEEK;
    case "Третья":
      return CYCLES.THIRD_WEEK;
    case "Четвёртая":
      return CYCLES.FOURTH_WEEK;
    default:
      return null;
  }
}

export function beautifyDistanceDifference(distance) {
  switch (distance) {
    case DISTANCE_DIFFERENCE.EMPTY:
      return ">";
    case DISTANCE_DIFFERENCE.UNDEFINED:
      return "Не определено";
    case DISTANCE_DIFFERENCE.MORE_OR_EQUALS_50:
      return ">50";
    case DISTANCE_DIFFERENCE.MORE_OR_EQUALS_100:
      return ">100";
    case DISTANCE_DIFFERENCE.MORE_OR_EQUALS_150:
      return ">150";
    case DISTANCE_DIFFERENCE.MORE_OR_EQUALS_300:
      return ">300";
    case DISTANCE_DIFFERENCE.MORE_OR_EQUALS_1000:
      return ">1000";
    default:
      return null;
  }
}

export const checkMarkerColor = (
  mapMarkersColoredBy,
  mapRoutePoint,
  projectMode,
) => {
  if (projectMode === PROJECT_MODE.ROUTES) {
    return mapMarkersColoredBy === PROJECT_MODE.ROUTES
      ? mapRoutePoint.color
      : getMarkerColorByWeekDay(mapRoutePoint.dayWeek);
  }
  if (projectMode === PROJECT_MODE.DAYS) {
    return getMarkerColorByWeekDay(mapRoutePoint.dayWeek);
  }
  if (
    projectMode === PROJECT_MODE.COORDINATES ||
    projectMode === PROJECT_MODE.LOGISTIC_CALENDAR
  ) {
    return mapRoutePoint.color;
  }
};

export const changePointsReportDataInStore = (stateArr, actionArr) => {
  const newArr = [];
  for (let i = 0; i < stateArr.length; i += 1) {
    newArr[i] = {
      DISTANCE: stateArr[i].DISTANCE,
      PR: actionArr[i].PR,
      TIME_IN_POINT: actionArr[i].TIME_IN_POINT,
      TO: actionArr[i].TO,
      TRAVEL_TIME: stateArr[i].TRAVEL_TIME,
      VISIT: actionArr[i].VISIT,
      WEEK_NUMBER: actionArr[i].WEEK_NUMBER,
    };
  }
  return newArr;
};

export const getHeadersWithToken = () => {
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Authorization", `Bearer ${localStorage.getItem("token")}`);
  return headers;
};

export const isOSX = /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform);

export const sortByField = (arr, field, type = "ASC") => {
  // ASC or DESC
  const sortedArr = [...arr].sort((a, b) => a[field].localeCompare(b[field]));

  if (type === "ASC") {
    return sortedArr;
  }

  return sortedArr.reverse();
};

export const pluralize = (num = 0, declensions) => {
  let count = +num % 100;
  if (count >= 5 && count <= 20) {
    return declensions[2];
  }
  count %= 10;
  if (count === 1) {
    return declensions[0];
  }

  if (count >= 2 && count <= 4) {
    return declensions[1];
  }
  return declensions[2];
};

export const hexToRgba = (hex, opacity = 1) => {
  let c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split("");
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = `0x${c.join("")}`;
    return `rgba(${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(
      ",",
    )},${opacity})`;
  }
  throw new Error("Bad Hex");
};

export const getFullName = (token) => {
  try {
    return jwtDecode(token).fullName;
  } catch {
    console.log("invalid token");
    return "";
  }
};

export const getUserRole = (token) => {
  try {
    return jwtDecode(token).role;
  } catch {
    console.log("invalid token");
    return "";
  }
};

export function scrollInTable(pointId) {
  document
    .getElementById(pointId)
    .scrollIntoView({ behavior: "smooth", block: "center" });
}

export function convertMapPointsToBounds(mapRoutePoints) {
  let mapBounds;

  if (!mapRoutePoints.length || mapRoutePoints.length > 1000) {
    // "mapRoutePoints.length > 1000" - because function work slow if a lot of points and points dont show up
    mapBounds = new L.LatLngBounds(DEFAULT_BOUNDS);
  } else {
    mapBounds = new L.LatLngBounds(
      mapRoutePoints.map((mp) => {
        if (mp.latitude !== "NULL" && mp.longitude !== "NULL") {
          return [mp.latitude, mp.longitude];
        }
        return [0, 0];
      }),
    );
  }

  return mapBounds;
}

export function convertMapPointsToPolyline(mapRoutePoints) {
  const polylineArr = mapRoutePoints
    .filter((point) => point.typePoint === "DEFAULT")
    .map((point) => {
      if (point.latitude !== "NULL" && point.longitude !== "NULL")
        return [point.latitude, point.longitude];
      return [0, 0];
    });

  return polylineArr;
}

export function convertMapPolyline(
  mapPolyline,
  calculatedMarkerCoords,
  latitude,
  longitude,
) {
  if (mapPolyline.length > 1 && calculatedMarkerCoords.length < 1) {
    mapPolyline.pop();
  } else if (mapPolyline.length > 2 && calculatedMarkerCoords.length > 0) {
    mapPolyline.pop();
  }

  return [...mapPolyline, [latitude, longitude]];
}

export function convertMapRoutePoints(mapRoutePoints, latitude, longitude) {
  if (mapRoutePoints.length > 1) {
    mapRoutePoints.pop();
  }

  return [
    ...mapRoutePoints,
    { latitude, longitude, color: mapRoutePoints[0].color },
  ];
}

export function convertPolygonCoordsToCenterLatLng(polygonCoords) {
  return polygonCoords.reduce(
    (x, y) => [
      x[0] + y[0] / polygonCoords.length,
      x[1] + y[1] / polygonCoords.length,
    ],
    [0, 0],
  );
}

export function convertPolygonCenterCoordsToBounds(polygons) {
  let mapBounds = [];
  if (polygons.length === 1) {
    mapBounds = new L.LatLngBounds(polygons[0].coordinates);
  } else if (polygons.length > 1) {
    const arrOfLatLng = polygons.map((polygon) =>
      convertPolygonCoordsToCenterLatLng(polygon.coordinates),
    );
    mapBounds = new L.LatLngBounds(arrOfLatLng);
  }

  return mapBounds;
}

export function beautifyDeliveryDays(deliveryDays) {
  return deliveryDays.map((day, index) => {
    if (index === deliveryDays.length - 1)
      return (
        <span
          key={day}
          style={{
            color: DAYS_OF_WEEK_LOGISTIC_CALENDAR_COLOR[day],
          }}
        >
          {DAYS_OF_WEEK_SHORT_RU[day]}
        </span>
      );
    return (
      <span
        key={day}
        style={{
          color: DAYS_OF_WEEK_LOGISTIC_CALENDAR_COLOR[day],
        }}
      >
        {DAYS_OF_WEEK_SHORT_RU[day]},{" "}
      </span>
    );
  });
}

export function beautifyRouteName(route) {
  if (route === undefined) return ''
  if (route?.length < 14) return route
  return route.slice(0, 11).concat('...')
}

export const calcZIndex = (
  mapRoutePoint,
  mapCenterPoint,
  selectedRoutePoints,
) => {
  if (
    mapCenterPoint.latitude &&
    mapCenterPoint.longitude &&
    mapRoutePoint.latitude === mapCenterPoint.latitude &&
    mapRoutePoint.longitude === mapCenterPoint.longitude
  )
    return 10000;
  if (selectedRoutePoints.includes(mapRoutePoint.id)) return 5000;
  return 1000;
};

export const calendarFiltersToString = (
  monthFrom,
  monthTo,
  yearFrom,
  yearTo,
) => {
  const dateFrom = new Date(yearFrom, monthFrom - 1);
  const dateTo = new Date(yearTo, monthTo - 1);

  return `${dateFrom.toLocaleString("ru", {
    month: "long",
  })}'${dateFrom.toLocaleString("ru", {
    year: "2-digit",
  })}-${dateTo.toLocaleString("ru", { month: "long" })}'${dateTo.toLocaleString(
    "ru",
    { year: "2-digit" },
  )}`;
};

export const convertAreasToBounds = (areas) => {
  let mapBounds;
  if (!areas.length) {
    mapBounds = new L.LatLngBounds(DEFAULT_BOUNDS);
  } else
    mapBounds = new L.LatLngBounds(areas.map((polygon) => polygon.coordinates));

  return mapBounds;
};
