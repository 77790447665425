import { VerticalDivider } from "app/assets/icons";
import {
  setMapCenterPoint,
  setSelectedRoutePoints,
} from "app/store/reducer/days";
import { useState } from "react";
import { Popup as LeafletPopup } from "react-leaflet";
import { useDispatch, useSelector } from "react-redux";
import { checkMarkerColor } from "shared/helpers";
import { Checkbox, Flex, Text } from "shared/ui";
import styled from "styled-components";

import Single from "./single";

const MarkerPopup = ({
  popupRef,
  mapRoutePoints,
  mapRoutePoint,
  mapMarkersColoredBy,
  projectMode,
}) => {
  const dispatch = useDispatch();
  const { selectedRoutePoints } = useSelector(
    (state) => state.rootReducer.days,
  );
  const [selectedMapRoutePoint, setSelectedMapRoutePoint] =
    useState(mapRoutePoint);
  const markerColor = checkMarkerColor(
    mapMarkersColoredBy,
    mapRoutePoint,
    projectMode,
  );

  let duplicatesMarkerArr = [];
  mapRoutePoints.map((marker) => {
    if (
      marker.latitude === mapRoutePoint.latitude &&
      marker.longitude === mapRoutePoint.longitude
    ) {
      return duplicatesMarkerArr.push(marker);
    }
    return null;
  });
  duplicatesMarkerArr = duplicatesMarkerArr.reverse();

  const duplicatesSelectedRoutePoints = [];
  for (let i = 0; i < selectedRoutePoints.length; i += 1) {
    for (let j = 0; j < duplicatesMarkerArr.length; j += 1) {
      if (selectedRoutePoints[i] === duplicatesMarkerArr[j].id) {
        duplicatesSelectedRoutePoints.push(duplicatesMarkerArr[j].id);
      }
    }
  }

  return (
    <LeafletPopup ref={popupRef} autoPan={false}>
      <Root color={markerColor}>
        {duplicatesMarkerArr.length > 1 &&
          selectedMapRoutePoint.typePoint !== "START" &&
          selectedMapRoutePoint.typePoint !== "FINISH" && (
            <>
              <Flex width="150px" maxHeight="100%" overflowY="scroll">
                {duplicatesMarkerArr.map((duplicate) => (
                  <Flex
                    key={duplicate.id}
                    flexDirection="row"
                    height="16px"
                    mt="5px"
                    mb="5px"
                  >
                    <Checkbox
                      checked={selectedRoutePoints.includes(duplicate.id)}
                      onChange={() => {
                        dispatch(setMapCenterPoint({}));
                        dispatch(setSelectedRoutePoints(duplicate.id));
                      }}
                    />
                    <Flex ml="6px" cursor="pointer">
                      <Text
                        variant={
                          selectedMapRoutePoint.pointCode ===
                          duplicate.pointCode
                            ? "body3bold"
                            : "body3"
                        }
                        onClick={() => setSelectedMapRoutePoint(duplicate)}
                      >
                        {duplicate.pointCode}
                      </Text>
                    </Flex>
                  </Flex>
                ))}
              </Flex>
              <Flex m="0 16px">
                <VerticalDivider />
              </Flex>
            </>
          )}
        <Single
          mapRoutePoint={selectedMapRoutePoint}
          markerColor={markerColor}
          duplicatesSelectedRoutePoints={duplicatesSelectedRoutePoints}
        />
      </Root>
    </LeafletPopup>
  );
};

const Root = styled.div`
  display: flex;
  max-height: 200px;
  position: relative;
  border: 2px solid ${(p) => p.color};
  border-radius: 20px;
  padding: 16px;
`;

export default MarkerPopup;
