import React from "react";
import styled from "styled-components";

const TextButton = ({
  disabled = false,
  loading = false,
  onClick = () => {},
  children,
  ...props
}) => (
  <Root
    type="button"
    disabled={disabled || loading}
    onClick={onClick}
    {...props}
  >
    <span>{children || "Текст..."}</span>
  </Root>
);

const Root = styled.button`
  display: flex;
  align-items: center;
  max-width: 100%;
  height: auto;
  background: none;
  padding: 0;
  border: 0;
  box-sizing: border-box;
  @include text-medium;
  transition: 0.3s;
  cursor: pointer;
`;

export default TextButton;
