/* eslint-disable consistent-return */
import {
  resetBranchFilter,
  resetContractFilter,
  setAddressFilter,
  setBranchFilter,
  setContractFilter,
  setDistanceFilter,
} from "app/store/reducer/logistic-coordinates";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useListFiltersBranchesContractsQuery } from "shared/api/filters";
import { DISTANCE_DIFFERENCE } from "shared/config";
import {
  Checkbox,
  DifferenceDropdown as DifferenceDropdownUI,
  Flex,
  Search,
  SearchDropdown,
  Text,
} from "shared/ui";

export const BranchDropdown = () => {
  const dispatch = useDispatch();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const { projectInfo } = useSelector(
    (state) => state.rootReducer.currentProject,
  );
  const {
    frontendFilters: { branchIds },
  } = useSelector((state) => state.rootReducer.logisticCoordinates);
  const { data: filters, isSuccess } = useListFiltersBranchesContractsQuery(
    {
      projectId: projectInfo.id,
    },
    { skip: !projectInfo.id, refetchOnMountOrArgChange: true },
  );

  const onSelect = (branch) => {
    dispatch(setBranchFilter(branch));
  };

  const onSearch = (value) => {
    setSearchValue(value);
  };

  const onToggle = (isOpen) => {
    if (isOpen === false) onSearch("");
    setIsDropdownOpen((prev) => !prev);
  };

  const onResetSearch = () => {
    onSearch("");
    setIsDropdownOpen(false);
  };

  const onResetAllCheckboxes = () => {
    dispatch(resetBranchFilter());
    setIsDropdownOpen(false);
  };

  if (isSuccess) {
    return (
      <SearchDropdown
        isDropdownOpen={isDropdownOpen}
        toggleText={
          branchIds?.length ? `Выбрано ${branchIds?.length}` : "Выбрать"
        }
        searchValue={searchValue}
        onToggle={onToggle}
        onSearch={onSearch}
        onResetSearch={onResetSearch}
        onResetAllCheckboxes={onResetAllCheckboxes}
        multiselect
        selectedText
        selectedCount={branchIds?.length}
      >
        {filters?.branches?.map(
          (item) =>
            item?.branchName
              .toLowerCase()
              .includes(searchValue.toLowerCase()) && (
              <SearchDropdown.Item key={item.id}>
                <Checkbox
                  checked={branchIds?.includes(item.id)}
                  onChange={() => onSelect(item)}
                />
                <Flex ml="7px">
                  <Text variant="body1">{item.branchName}</Text>
                </Flex>
              </SearchDropdown.Item>
            ),
        )}
      </SearchDropdown>
    );
  }

  return null;
};

export const ContractDropdown = () => {
  const dispatch = useDispatch();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const { projectInfo } = useSelector(
    (state) => state.rootReducer.currentProject,
  );
  const {
    frontendFilters: { contractIds },
  } = useSelector((state) => state.rootReducer.logisticCoordinates);
  const { data: filters, isSuccess } = useListFiltersBranchesContractsQuery(
    {
      projectId: projectInfo.id,
    },
    { skip: !projectInfo.id, refetchOnMountOrArgChange: true },
  );

  const onSelect = (contract) => {
    dispatch(setContractFilter(contract));
  };

  const onSearch = (value) => {
    setSearchValue(value);
  };

  const onToggle = (isOpen) => {
    if (isOpen === false) onSearch("");
    setIsDropdownOpen((prev) => !prev);
  };

  const onResetSearch = () => {
    onSearch("");
    setIsDropdownOpen(false);
  };

  const onResetAllCheckboxes = () => {
    dispatch(resetContractFilter());
    setIsDropdownOpen(false);
  };

  if (isSuccess) {
    return (
      <SearchDropdown
        isDropdownOpen={isDropdownOpen}
        toggleText={
          contractIds?.length ? `Выбрано ${contractIds?.length}` : "Выбрать"
        }
        searchValue={searchValue}
        onToggle={onToggle}
        onSearch={onSearch}
        onResetSearch={onResetSearch}
        onResetAllCheckboxes={onResetAllCheckboxes}
        multiselect
        selectedText
        selectedCount={contractIds?.length}
      >
        {filters?.contracts?.map(
          (item) =>
            item?.contractName
              .toLowerCase()
              .includes(searchValue.toLowerCase()) && (
              <SearchDropdown.Item key={item.id}>
                <Checkbox
                  checked={contractIds?.includes(item.id)}
                  onChange={() => onSelect(item)}
                />
                <Flex ml="7px">
                  <Text variant="body1">{item.contractName}</Text>
                </Flex>
              </SearchDropdown.Item>
            ),
        )}
      </SearchDropdown>
    );
  }

  return null;
};

export const AddressSearch = () => {
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [isMounted, setIsMounted] = useState(false);
  const { frontendFilters } = useSelector(
    (state) => state.rootReducer.logisticCoordinates,
  );

  useEffect(() => {
    if (isMounted && search !== frontendFilters.address) {
      const setFilterWithDelay = setTimeout(() => {
        dispatch(setAddressFilter(search));
      }, 300);

      return () => {
        clearTimeout(setFilterWithDelay);
      };
    }
  }, [search]);

  useEffect(() => {
    setSearch(frontendFilters.address);
  }, [frontendFilters.address]);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  return (
    <Search
      value={search}
      onChange={(e) => setSearch(e.target.value)}
      onReset={() => setSearch("")}
    />
  );
};

export const DifferenceDropdown = () => {
  const dispatch = useDispatch();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { frontendFilters } = useSelector(
    (state) => state.rootReducer.logisticCoordinates,
  );

  const onSelect = (value) => {
    dispatch(setDistanceFilter(value));
    setIsDropdownOpen(false);
  };

  const onToggle = () => {
    setIsDropdownOpen((prev) => !prev);
  };

  const onReset = () => {
    dispatch(setDistanceFilter(DISTANCE_DIFFERENCE.EMPTY));
  };

  const items = [
    {
      id: 0,
      text: ">",
      distance: DISTANCE_DIFFERENCE.EMPTY,
    },
    {
      id: 1,
      text: "Не определено",
      distance: DISTANCE_DIFFERENCE.UNDEFINED,
    },
    {
      id: 2,
      text: ">50",
      distance: DISTANCE_DIFFERENCE.MORE_OR_EQUALS_50,
    },
    {
      id: 3,
      text: ">100",
      distance: DISTANCE_DIFFERENCE.MORE_OR_EQUALS_100,
    },
    {
      id: 4,
      text: ">150",
      distance: DISTANCE_DIFFERENCE.MORE_OR_EQUALS_150,
    },
    {
      id: 5,
      text: ">300",
      distance: DISTANCE_DIFFERENCE.MORE_OR_EQUALS_300,
    },
    {
      id: 6,
      text: ">1000",
      distance: DISTANCE_DIFFERENCE.MORE_OR_EQUALS_1000,
    },
  ];

  return (
    <DifferenceDropdownUI
      isDropdownOpen={isDropdownOpen}
      items={items}
      distance={frontendFilters.distance}
      onToggle={onToggle}
      onSelect={onSelect}
      onReset={onReset}
    />
  );
};
