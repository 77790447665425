import { api } from "shared/api";

const areasApi = api.injectEndpoints({
  endpoints: (builder) => ({
    listAreas: builder.query({
      query: ({
        projectId,
        branchIds,
        groupIds,
        monthFrom,
        monthTo,
        yearFrom,
        yearTo,
      }) =>
        `projects/${projectId}/delivery-areas?branchIds=${branchIds}&groupIds=${groupIds}&monthFrom=${monthFrom}&monthTo=${monthTo}&yearFrom=${yearFrom}&yearTo=${yearTo}`,
      providesTags: ["Areas"],
    }),
    listAreasMap: builder.query({
      query: ({ projectId, branchIds = [], groupIds = [] }) =>
        `projects/${projectId}/delivery-areas/maps?branchIds=${branchIds}&groupIds=${groupIds}`,
      providesTags: ["Areas"],
    }),
    singleArea: builder.query({
      query: ({ projectId, areaId }) =>
        `projects/${projectId}/delivery-areas/${areaId}`,
    }),
    createArea: builder.mutation({
      query: ({ projectId, ...body }) => ({
        url: `projects/${projectId}/delivery-areas`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Areas"],
    }),
    editArea: builder.mutation({
      query: ({ projectId, areaId, ...body }) => ({
        url: `projects/${projectId}/delivery-areas/${areaId}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Areas"],
    }),
    editSeveralAreasCoordinates: builder.mutation({
      query: ({ projectId, body }) => ({
        url: `projects/${projectId}/delivery-areas/coordinates`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Areas"],
    }),
    deleteArea: builder.mutation({
      query: ({ projectId, areaId }) => ({
        url: `projects/${projectId}/delivery-areas/${areaId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Areas", "Logistic", "Filters"],
    }),
    listConsolidatedReport: builder.query({
      query: ({ projectId, areaId, monthFrom, monthTo, yearFrom, yearTo }) =>
        `projects/${projectId}/areas/${areaId}/reports/consolidated?monthFrom=${monthFrom}&monthTo=${monthTo}&yearFrom=${yearFrom}&yearTo=${yearTo}`,
    }),
    listDaysReport: builder.query({
      query: ({ projectId, areaId, monthFrom, monthTo, yearFrom, yearTo }) =>
        `projects/${projectId}/areas/${areaId}/reports/days?monthFrom=${monthFrom}&monthTo=${monthTo}&yearFrom=${yearFrom}&yearTo=${yearTo}`,
    }),
    listAreasNames: builder.query({
      query: ({ projectId, areaId = "" }) =>
        `projects/${projectId}/delivery-areas/names?areaId=${areaId}`,
    }),
  }),
});

export const {
  useListAreasQuery,
  useListAreasMapQuery,
  useSingleAreaQuery,
  useCreateAreaMutation,
  useEditAreaMutation,
  useEditSeveralAreasCoordinatesMutation,
  useDeleteAreaMutation,
  useListConsolidatedReportQuery,
  useListDaysReportQuery,
  useListAreasNamesQuery,
} = areasApi;
