import React from "react";
import styled from "styled-components";

import Flex from "./Flex";
import Text from "./Text";

const Select = React.forwardRef(
  (
    { title = "", errorText = "", options = [], placeholder = "", ...props },
    ref,
  ) => (
    <Wrapper>
      <Title>{title}</Title>
      <StyledSelect ref={ref} {...props}>
        {options.map((option) => (
          <option key={option.id} value={option.value}>
            {option.name}
          </option>
        ))}
        {placeholder && (
          <option disabled value="">
            {placeholder}
          </option>
        )}
      </StyledSelect>
      {errorText && (
        <Flex mt="4px">
          <Text variant="body3" color="color5">
            {errorText}
          </Text>
        </Flex>
      )}
    </Wrapper>
  ),
);

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Title = styled.div`
  font-size: 14px;
  line-height: 16px;
  color: #787878;
`;

const StyledSelect = styled.select`
  height: 48px;
  padding: 0 12px;
  margin-top: 4px;
  border: 1px solid #cccccc;
  outline: none;
  background: #ffffff;
`;

export default Select;
