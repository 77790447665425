import { SignInBackground } from "app/assets/icons";
import { plusIcon } from "icons/icons";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  useCreateProjectMutation,
  useListProjectsQuery,
} from "shared/api/projects";
import { USER_TYPE } from "shared/config";
import { getUserRole } from "shared/libs/jwt-decode";
import { Button, ErrorText, Flex, Loader, Text } from "shared/ui";
import styled from "styled-components";

import { configColumns } from "./config";
import { createDefaultProjectName } from "./libs/helpers";
import { useLoadUrgentPoints } from "./model";
import { Table } from "./ui";

const Projects = () => {
  const navigate = useNavigate();
  const role = getUserRole();
  const { actionsDropdownId } = useSelector(
    (state) => state.rootReducer.projects,
  );
  const { data: projects, isFetching, isError, error } = useListProjectsQuery();
  const [createProject] = useCreateProjectMutation();

  const [loadUrgentPoints, isUrgentPointsLoading] = useLoadUrgentPoints();

  const data = React.useMemo(() => projects, [projects]);
  const columns = React.useMemo(() => configColumns, []);

  const onCreateProject = async () => {
    await createProject({ name: createDefaultProjectName(projects) });
  };

  const onProjectOpen = async (project) => {
    if (role === USER_TYPE.LOGISTIC_USER) {
      if (project.name === "Срочные точки") {
        await loadUrgentPoints({ project });
      } else {
        navigate(`/areas/${project.id}`);
      }
    } else {
      navigate(`/routes/${project.id}`);
    }
  };

  if (isFetching || isUrgentPointsLoading) {
    return <Loader isLoading />;
  }

  if (isError) {
    return <ErrorText errorText={error?.data?.message} />;
  }

  return (
    <Root actionsDropdownId={actionsDropdownId}>
      <Flex position="absolute">
        <SignInBackground />
      </Flex>
      <ContentWrapper actionsDropdownId={actionsDropdownId}>
        <Flex mb="24px">
          <Text variant="title1">Проекты</Text>
        </Flex>
        <Table
          data={data}
          columns={columns}
          role={role}
          actionsDropdownId={actionsDropdownId}
          onProjectOpen={onProjectOpen}
        />
        <Button
          width="226px"
          icon={plusIcon}
          text="СОЗДАТЬ ПРОЕКТ"
          onClick={onCreateProject}
        />
      </ContentWrapper>
    </Root>
  );
};

const Root = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  background: ${({ actionsDropdownId }) =>
    actionsDropdownId ? "#e6e6e6" : "none"};
`;

const ContentWrapper = styled.div`
  width: 1240px;
  height: auto;
  padding: 32px;
  border-radius: 20px;
  box-sizing: border-box;
  background: ${({ actionsDropdownId }) =>
    actionsDropdownId ? "#e6e6e6" : "#FFFFFF"};
  z-index: 1;
  pointer-events: ${({ actionsDropdownId }) =>
    actionsDropdownId ? "none" : "auto"};
`;

export default Projects;
