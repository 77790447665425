import {
  DefaultMarker,
  Finish,
  IndividualMarker,
  Start,
  VerticalDivider,
} from "app/assets/icons";
import { Popup as LeafletPopup } from "react-leaflet";
import styled from "styled-components";

import Checkbox from "../Checkbox";
import Flex from "../Flex";
import Text from "../Text";

const tipIcon = (shownPoint, color) => {
  if (shownPoint?.individualArea) {
    return <IndividualMarker width={32} height={32} />;
  }
  if (shownPoint?.typePoint === "START") {
    return <Start />;
  }
  if (shownPoint?.typePoint === "FINISH") {
    return <Finish />;
  }

  return <DefaultMarker color={color} />;
};

const PointPopup = ({
  multipleList = [],
  selectedPoints = [],
  shownPoint = {},
  onSetShownPoint = () => {},
  isSelectable = false,
  onSelect = () => {},
  withTip = false,
  color = "#000000",
  children,
}) => (
  <LeafletPopup autoPan={false}>
    <Root color={color}>
      {multipleList?.length > 1 && (
        <>
          <Flex width="150px" maxHeight="100%" overflowY="scroll">
            {multipleList.map((item) => (
              <Flex
                key={item?.id}
                flexDirection="row"
                height="16px"
                mt="5px"
                mb="5px"
              >
                {isSelectable && (
                  <Checkbox
                    checked={selectedPoints.includes(item?.id)}
                    onChange={() => onSelect(item)}
                  />
                )}
                <Flex ml="6px" cursor="pointer">
                  <Text
                    variant={
                      shownPoint?.pointCode === item?.pointCode
                        ? "body3bold"
                        : "body3"
                    }
                    onClick={() => onSetShownPoint(item)}
                  >
                    {item?.pointCode}
                  </Text>
                </Flex>
              </Flex>
            ))}
          </Flex>
          <Flex m="0 16px">
            <VerticalDivider />
          </Flex>
        </>
      )}
      {children}
      {withTip && (
        <TipWrapper color={color}>
          <Flex
            width="44px"
            height="44px"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
          >
            {tipIcon(shownPoint, color)}
          </Flex>
        </TipWrapper>
      )}
    </Root>
  </LeafletPopup>
);

const Root = styled.div`
  display: flex;
  max-height: 200px;
  position: relative;
  border: ${({ color = "#000000" }) => `2px solid ${color}`};
  border-radius: 20px;
  padding: 16px;
`;

const TipWrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: -68px;
  left: calc(50% - 40px);
  width: 68px;
  height: 68px;
  background-color: #ffffff;
  border: ${({ color = "#000000" }) => `2px solid ${color}`};
  border-top: none;
  border-radius: 0 0 20px 20px;
`;

export default PointPopup;
