import { resetManagerFilter, setManagerFilter } from "app/store/reducer/routes";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useListFiltersRoutesUsersDuplicatesQuery } from "shared/api/filters";
import { sortByField } from "shared/helpers";
import { Checkbox, Flex, SearchDropdown, Text } from "shared/ui";

const ManagerDropdown = () => {
  const dispatch = useDispatch();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const { projectInfo } = useSelector(
    (state) => state.rootReducer.currentProject,
  );
  const { frontendFilters: {managerCodes} } = useSelector((state) => state.rootReducer.routes);
  const { data: filters, isSuccess } = useListFiltersRoutesUsersDuplicatesQuery(
    {
      projectId: projectInfo.id,
      managers: managerCodes,
    },
    { skip: !projectInfo.id, refetchOnMountOrArgChange: true },
  );

  function onManagerSelect(manager) {
    dispatch(setManagerFilter(manager));
  }

  const onSearch = (value) => {
    setSearchValue(value);
  };

  const onToggle = (isOpen) => {
    if (isOpen === false) onSearch("");
    setIsDropdownOpen((prev) => !prev);
  };

  const onResetSearch = () => {
    onSearch("");
    setIsDropdownOpen(false);
  };

  const onResetAllCheckboxes = () => {
    dispatch(resetManagerFilter());
    setIsDropdownOpen(false);
  };

  if (isSuccess) {
    return (
      <SearchDropdown
        isDropdownOpen={isDropdownOpen}
        toggleText={
          managerCodes?.length
            ? `Выбрано ${managerCodes?.length}`
            : "Выбрать"
        }
        searchValue={searchValue}
        onToggle={onToggle}
        onSearch={onSearch}
        onResetSearch={onResetSearch}
        onResetAllCheckboxes={onResetAllCheckboxes}
        multiselect
        selectedText
        selectedCount={managerCodes?.length}
      >
        {sortByField(filters?.users, "shortName").map(
          (item) =>
            item.shortName
              .toLowerCase()
              .includes(searchValue.toLowerCase()) && (
              <SearchDropdown.Item key={item.fullNameCode}>
                <Checkbox
                  checked={managerCodes?.includes(
                    item.fullNameCode,
                  )}
                  onChange={() => onManagerSelect(item)}
                />
                <Flex ml="7px">
                  <Text variant="body1">{item.shortName}</Text>
                </Flex>
              </SearchDropdown.Item>
            ),
        )}
      </SearchDropdown>
    );
  }

  return null;
};

export default ManagerDropdown
