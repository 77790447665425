import { PopupContext } from "app/context/PopupContext";
import { useContext, useState } from "react";
import { useDeleteProjectDataMutation } from "shared/api/projects";
import { Button, Checkbox, Flex, Popup, Text } from "shared/ui";
import styled from "styled-components";

export const ClearExcelData = ({ project }) => {
  const { hidePopup } = useContext(PopupContext);
  const [deleteData] = useDeleteProjectDataMutation();

  const [toDelete, setToDelete] = useState({
    routes: false,
    points: false,
    routePoints: false,
  });

  const toggleCheck = (key) => {
    setToDelete((prevState) => ({ ...prevState, [key]: !prevState[key] }));
  };

  const handleClearExcelData = async () => {
    await deleteData({
      id: project.id,
      dataTypes: toDelete,
    });
    hidePopup();
  };

  return (
    <StyledCommonPopup
      isCenter
      title="Очистка данных в проекте"
      className="clear-excel-data-popup__common-popup"
    >
      <Flex flexDirection="column" mt="20px">
        <Text variant="body1">Выберите, что удалить:</Text>
      </Flex>
      <Flex flexDirection="row" mt="10px">
        <Flex mr="7px">
          <Checkbox
            checked={toDelete.routes}
            onChange={() => toggleCheck("routes")}
          />
        </Flex>
        <Text>Маршруты</Text>
      </Flex>
      <Flex flexDirection="row" mt="10px">
        <Flex mr="7px">
          <Checkbox
            checked={toDelete.points}
            onChange={() => toggleCheck("points")}
          />
        </Flex>
        <Text>Точки</Text>
      </Flex>
      <Flex flexDirection="row" mt="10px">
        <Flex mr="7px">
          <Checkbox
            checked={toDelete.routePoints}
            onChange={() => toggleCheck("routePoints")}
          />
        </Flex>
        <Text>График посещений</Text>
      </Flex>
      <Flex mt="30px">
        <Button
          height="48px"
          color="#2D8AF6"
          text="УДАЛИТЬ"
          onClick={handleClearExcelData}
        />
      </Flex>
    </StyledCommonPopup>
  );
};

const StyledCommonPopup = styled(Popup)`
  &.clear-excel-data-popup__common-popup {
    min-width: 40vw;
    max-width: 60vw;
  }
`;

export default ClearExcelData;
