import * as React from "react";

const SvgMaximize = (props) => (
  <svg
    width={10}
    height={18}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5 2.83 8.17 6l1.41-1.41L5 0 .41 4.59 1.83 6 5 2.83zm0 12.34L1.83 12 .42 13.41 5 18l4.59-4.59L8.17 12 5 15.17z"
      fill="currentColor"
    />
  </svg>
);

export default SvgMaximize;
