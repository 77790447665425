export const routePointColumns = [
  {
    id: 100,
    table: "route_point",
    accessor: "routeCode",
    key: "routeCode",
    Header: "Маршрут.Код",
  },
  {
    id: 101,
    table: "route_point",
    accessor: "pointCode",
    key: "pointCode",
    Header: "Точка.Код",
  },
  {
    id: 102,
    table: "route_point",
    accessor: "dayWeek",
    key: "dayWeek",
    Header: "Визиты.День",
  },
  {
    id: 103,
    table: "route_point",
    accessor: "cycle",
    key: "cycle",
    Header: "Визиты.Цикл",
  },
  {
    id: 104,
    table: "route_point",
    accessor: "order",
    key: "order",
    Header: "Визиты.Порядок",
  },
  {
    id: 105,
    table: "route_point",
    accessor: "status",
    key: "status",
    Header: "Статус",
    width: 120,
  },
];

export const pointColumns = [
  {
    id: 200,
    table: "point",
    accessor: "pointCode",
    key: "pointCode",
    Header: "Точка.Код",
  },
  {
    id: 201,
    table: "point",
    accessor: "pointName",
    key: "pointName",
    Header: "Точка.Наименование",
    width: 200,
  },
  {
    id: 202,
    table: "point",
    accessor: "address",
    key: "address",
    Header: "Точка.Адрес",
  },
  {
    id: 203,
    table: "point",
    accessor: "longitude",
    key: "longitude",
    Header: "Точка.Долгота",
  },
  {
    id: 204,
    table: "point",
    accessor: "latitude",
    key: "latitude",
    Header: "Точка.Широта",
  },
  {
    id: 205,
    table: "point",
    accessor: "duration",
    key: "duration",
    Header: "Точка.ВремяВизита",
    width: 190,
  },
  {
    id: 206,
    table: "point",
    accessor: "visitFrequency",
    key: "visitFrequency",
    Header: "Точка.ЧастотаВизита",
    width: 190,
  },
  {
    id: 207,
    table: "point",
    accessor: "attributeCode1",
    key: "attributeCode1",
    Header: "Точка.Признак1",
  },
  {
    id: 208,
    table: "point",
    accessor: "attributeCode2",
    key: "attributeCode2",
    Header: "Точка.Признак2",
  },
  {
    id: 209,
    table: "point",
    accessor: "additionallyTable1",
    key: "additionallyTable1",
    Header: "Точка.ДопМера1",
  },
  {
    id: 210,
    table: "point",
    accessor: "additionallyTable2",
    key: "additionallyTable2",
    Header: "Точка.ДопМера2",
  },
  {
    id: 211,
    table: "point",
    accessor: "additionallyTable3",
    key: "additionallyTable3",
    Header: "Точка.ДопМера3",
  },
  {
    id: 212,
    table: "point",
    accessor: "command",
    key: "command",
    Header: "Точка.Команда",
  },
  {
    id: 213,
    table: "point",
    accessor: "status",
    key: "status",
    Header: "Статус",
    width: 120,
  },
];

export const routeColumns = [
  {
    id: 300,
    table: "route",
    accessor: "routeCode",
    key: "routeCode",
    Header: "Маршрут.Код",
    width: 200,
  },
  {
    id: 302,
    table: "route",
    accessor: "routeName",
    key: "routeName",
    Header: "Маршрут.Наименование",
    width: 220,
  },
  {
    id: 301,
    table: "route",
    accessor: "command",
    key: "command",
    Header: "Маршрут.Команда",
    width: 190,
  },
  {
    id: 303,
    table: "route",
    accessor: "pointStartCode",
    key: "pointStartCode",
    Header: "Маршрут.КодТочкиСтарта",
    width: 230,
  },
  {
    id: 304,
    table: "route",
    accessor: "pointFinishCode",
    key: "pointFinishCode",
    Header: "Маршрут.КодТочкиФиниша",
    width: 240,
  },
  {
    id: 305,
    table: "route",
    accessor: "status",
    key: "status",
    Header: "Статус",
    width: 120,
  },
];

export const findLabel = (table, value) => {
  if (value === "rowNumber") return "№ п/п";
  if (value === "status") return "Статус";

  if (table === "routeList") {
    return routeColumns.find((col) => col.value === value).label;
  }
  if (table === "pointList") {
    return pointColumns.find((col) => col.value === value).label;
  }
  if (table === "routePointList") {
    return routePointColumns.find((col) => col.value === value).label;
  }

  return "Неизвестный столбец";
};
