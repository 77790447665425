import { useContext } from "react";
import { Checkbox } from "shared/ui";

import RoutesOptimizationContext from "../context";

export const SetDay = ({ route, day }) => {
  const { daysObject, changeDaysObject } = useContext(
    RoutesOptimizationContext,
  );

  return (
    <Checkbox
      checked={daysObject[route.id]?.includes(day) || false}
      onChange={() => changeDaysObject(route.id, day)}
    />
  );
};
