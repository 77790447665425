import * as React from "react";

const SvgNavLogo = (props) => (
  <svg
    width={142}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13 23a.999.999 0 0 1-.707-.293l-1-1a.998.998 0 0 1 0-1.414l1.544-1.544a9.543 9.543 0 0 0 0-13.498l-1.544-1.544a.998.998 0 0 1 0-1.414l1-1a1 1 0 0 1 1.414 1.414L13.414 3l.837.837a11.541 11.541 0 0 1 0 16.326l-.837.837.293.293A1 1 0 0 1 13 23Z"
      fill="#2D8AF6"
    />
    <path d="M21 13H8a1 1 0 0 1 0-2h13a1 1 0 0 1 0 2Z" fill="#2D8AF6" />
    <path
      d="M20 15a1 1 0 0 1-.707-1.707L20.586 12l-1.293-1.293a1 1 0 0 1 1.414-1.414l2 2a1 1 0 0 1 0 1.414l-2 2A.997.997 0 0 1 20 15ZM9.5 19.5a.997.997 0 0 1-.707-.293l-6.5-6.5a1 1 0 0 1 0-1.414l6.5-6.5a1 1 0 1 1 1.414 1.414L4.414 12l5.793 5.793A1 1 0 0 1 9.5 19.5ZM43.768 21.712l-3.192.816-1.224-3.84h-6.36l-1.32 3.888-3.192-.888 7.488-19.872h.72l7.08 19.896Zm-5.352-6L36.28 8.92 34 15.712h4.416Zm21.138 4.824-2.328 1.992-5.712-6.456-2.472 1.848v4.104h-3.12l.024-20.472h.72l12.48 9.096-5.112 3.648 5.52 6.24Zm-5.496-9.912-5.016-3.672v7.56l5.016-3.888Zm17.209-5.616H66.49V22h-3.144V5.008H58.57V2.032h12.696v2.976Zm11.808-1.08-7.704 6.48h6.888v2.88h-6.912l7.656 6.576-2.04 2.664-11.256-10.632L80.723 1.48l2.352 2.448Zm21.01 17.88-3.288.672-2.592-11.424-3.576 11.184h-.864l-3.576-11.184-2.616 11.424-3.288-.672 5.16-20.016h.84l3.912 11.904 3.912-11.904h.816l5.16 20.016Zm5.573.192h-3.168V2.032h3.168V22Zm16.619-10.08-12.768 10.56h-.72V1.552h.72l12.768 10.368Zm-4.584 0-5.784-4.704v9.504l5.784-4.8Zm19.411 9.792-3.192.816-1.224-3.84h-6.36l-1.32 3.888-3.192-.888 7.488-19.872h.72l7.08 19.896Zm-5.352-6-2.136-6.792-2.28 6.792h4.416Z"
      fill="#2D8AF6"
    />
  </svg>
);

export default SvgNavLogo;
