import { updateHeader } from "app/store/reducer/import-excel-file";
import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import TableUI from "./ui";

const Table = ({ currentSheet }) => {
  const { headers, data } = useSelector(
    (state) => state.rootReducer.excelFileMapping,
  );
  const dispatch = useDispatch();

  const onColumnNameSelect = useCallback(
    (columnIndex, selectedOption) => {
      dispatch(
        updateHeader({
          sheetNumber: currentSheet,
          index: columnIndex,
          selectedOption,
        }),
      );
    },
    [currentSheet],
  );

  if (!data.length) {
    return null;
  }

  return (
    <TableUI
      data={data[currentSheet]}
      headers={headers[currentSheet]}
      onColumnNameSelect={onColumnNameSelect}
      sheetIndex={currentSheet}
    />
  );
};

export default Table;
