/* eslint-disable no-underscore-dangle */
import {
  resetExpandedMapPopupState,
  setExpandedMapPopupData,
} from "app/store/reducer/expanded-map-popup";
import {
  setAddressFilter,
  setPointFoundByMarker,
  setSelectedRoutePoints,
} from "app/store/reducer/routes";
import { useDispatch, useSelector } from "react-redux";
import { MARKER_COLOR_BY } from "shared/config";
import { getMarkerColorByWeekDay } from "shared/helpers";

import PointPopup from "../point-popup";
import { MarkerIcon } from "./ui";

const PointMarker = ({ data, point, highlightedPointId }) => {
  const dispatch = useDispatch();
  const { selectedRoutePoints, mapMarkersColoredBy } = useSelector(
    (state) => state.rootReducer.routes,
  );

  const onMarkerClicked = async () => {
    dispatch(
      setExpandedMapPopupData({
        point,
        isCluster: false,
        multipleList: data
          .filter(
            (item) =>
              Math.abs(item.latitude - point.latitude) < 0.00001 &&
              Math.abs(item.longitude - point.longitude) < 0.00001,
          )
          .sort((a, b) => a.id - b.id),
      }),
    );
    dispatch(
      setPointFoundByMarker({
        pointCode: point.pointCode,
        highlightedPointId: point.id,
      }),
    );
  };

  const onMarkerCtrlClick = () => {
    dispatch(setSelectedRoutePoints(point?.id));
  };

  const onPopupClosed = () => {
    dispatch(resetExpandedMapPopupState());
    dispatch(setAddressFilter(""));
  };

  return (
    <MarkerIcon
      point={point}
      selectedRoutePoints={selectedRoutePoints}
      highlightedPointId={highlightedPointId}
      onMarkerClicked={onMarkerClicked}
      onMarkerCtrlClick={onMarkerCtrlClick}
      onPopupClosed={onPopupClosed}
      color={
        mapMarkersColoredBy === MARKER_COLOR_BY.ROUTE
          ? point.color
          : getMarkerColorByWeekDay(point.dayWeek)
      }
    >
      <PointPopup
        multipleList={data
          .filter(
            (item) =>
              Math.abs(item.latitude - point.latitude) < 0.00001 &&
              Math.abs(item.longitude - point.longitude) < 0.00001,
          )
          .sort((a, b) => a.id - b.id)}
      />
    </MarkerIcon>
  );
};

export default PointMarker;
