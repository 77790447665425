import { PopupContext } from "app/context/PopupContext";
import { resetSelectedPoints } from "app/store/reducer/shedule";
import { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TABLE_FOOTER_HEIGHT } from "shared/config";
import { pluralize } from "shared/helpers";
import { Button, Checkbox, Flex, Text } from "shared/ui";
import styled, { css } from "styled-components";

import { CreateIndividualAreaPopup, SetIndividualAreaPopup } from "./popups";

const Footer = ({ inMap = false }) => {
  const dispatch = useDispatch();
  const { showPopup } = useContext(PopupContext);
  const { selectedPoints } = useSelector((state) => state.rootReducer.shedule);

  const onSelectedReset = () => {
    dispatch(resetSelectedPoints());
  };

  return (
    <Root display={selectedPoints.length ? "flex" : "none"} inMap={inMap}>
      <Flex flexDirection="row" alignItems="center">
        <Checkbox checked onChange={onSelectedReset} />
        <Flex ml="7px">
          <Text variant="body1">
            {selectedPoints.length}{" "}
            {pluralize(selectedPoints.length, ["точка", "точки", "точек"])}
          </Text>
        </Flex>
      </Flex>
      <Flex flexDirection="row" alignItems="center" ml="12px">
        <Flex mr="12px">
          <Button
            text="СОЗДАТЬ ГРАФИК"
            onClick={() => showPopup(CreateIndividualAreaPopup)}
          />
        </Flex>
        <Button
          text="ДОБАВИТЬ В ГРАФИК"
          onClick={() => showPopup(SetIndividualAreaPopup)}
        />
      </Flex>
    </Root>
  );
};

const Root = styled.div`
  display: ${({ display }) => display};
  align-items: center;
  padding: 12px 26px;
  height: ${TABLE_FOOTER_HEIGHT};
  background: #cee3fd;

  ${({ inMap }) =>
    inMap &&
    css`
      position: fixed;
      bottom: 0;
      left: 0;
      z-index: 9999;
      background: transparent;
    `}
`;

export default Footer;
