import { PopupContext } from "app/context/PopupContext";
import { setActionsDropdownId } from "app/store/reducer/projects";
import { editTextIcon, textIconSnippet } from "icons/icons";
import { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  useCreateContractsProjectJobMutation,
  useDeleteProjectMutation,
  useLazyGetProjectJobQuery,
} from "shared/api/projects";
import { USER_TYPE } from "shared/config";
import { getUserRole } from "shared/libs/jwt-decode";
import { ActionsDropdown, Flex, Text } from "shared/ui";

import { ExportExcelData } from "../../../widgets/popups";
import {
  ChangeProjectNamePopup,
  ExportLogisticPopup,
  ExportPopup,
  ImportExcelFile,
  LoadContractGroupsPopup,
  LoadRoutesPopup,
  ProjectOptimization,
  RoutesCalculation,
  UploadReportPopup,
} from "../popups";

export const EditProject = ({ project }) => {
  const [editIconVisible, setEditIconVisible] = useState(false);
  const { showPopup } = useContext(PopupContext);

  function editProjectName(e) {
    e.stopPropagation();
    showPopup(ChangeProjectNamePopup, { project });
  }

  if (project.name === "Срочные точки")
    return <Text variant="body1">{project.name}</Text>;

  return (
    <Flex
      flexDirection="row"
      alignItems="center"
      onMouseOver={() => setEditIconVisible(true)}
      onMouseOut={() => setEditIconVisible(false)}
    >
      <Flex
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
        mr="7px"
      >
        <Text variant="body1">{project.name}</Text>
      </Flex>
      <Flex
        opacity={!editIconVisible ? 0 : 1}
        width="18px"
        height="18px"
        cursor="pointer"
        onClick={(e) => editProjectName(e)}
      >
        {editTextIcon}
      </Flex>
    </Flex>
  );
};

export const ProjectReport = ({ project }) => {
  const { showPopup } = useContext(PopupContext);

  const onReportClick = (e) => {
    e.stopPropagation();
    showPopup(UploadReportPopup, { project });
  };

  return (
    <Flex flexDirection="row" alignItems="center" onClick={onReportClick}>
      <Flex
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
        mr="7px"
      >
        {textIconSnippet}
      </Flex>
      <Text variant="body1" color="color0">
        Отчёт
      </Text>
    </Flex>
  );
};

export const Actions = ({ project }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { showPopup } = useContext(PopupContext);
  const [deleteProject] = useDeleteProjectMutation();
  const role = getUserRole();
  const { status } = project;

  const onToggle = (show) => {
    if (show) {
      dispatch(setActionsDropdownId(project.id));
    } else dispatch(setActionsDropdownId(null));
  };

  const navigateToProject = () => {
    if (role === USER_TYPE.LOGISTIC_USER) {
      navigate(`/areas/${project.id}`);
    } else {
      navigate(`/routes/${project.id}`);
    }
  };

  const navigateToExcelImport = () => {
    navigate(`/import-excel-data/${project.id}`);
  };

  const onPopupOpen = (e, popup, toExcelImport) => {
    e.stopPropagation();
    dispatch(setActionsDropdownId(null));
    if (toExcelImport) {
      showPopup(popup, { project, navigateToExcelImport });
    } else {
      showPopup(popup, { project, navigateToProject });
    }
  };

  const onDeleteProject = async (e) => {
    e.stopPropagation();
    await deleteProject({ id: project.id });
  };

  const processDataActions = [
    {
      id: 100,
      onClick: (e) => onPopupOpen(e, RoutesCalculation),
      text: "Параметры расчета маршрутов",
    },
    {
      id: 101,
      onClick: (e) => onPopupOpen(e, ProjectOptimization),
      text: "Оптимизиция проекта",
    },
  ];
  const processLogisticDataActions = [
    {
      id: 200,
      onClick: (e) => onPopupOpen(e, LoadContractGroupsPopup),
      text: "Получить данные для проекта",
    },
    {
      id: 201,
      onClick: (e) => onPopupOpen(e, ExportLogisticPopup),
      text: "Выгрузить в УС",
    },
  ];

  const deleteDataActions = [
    {
      id: 300,
      onClick: (e) => onDeleteProject(e),
      text: "Удалить проект",
    },
  ];

  const importDataActions = [
    {
      id: 400,
      onClick: (e) => onPopupOpen(e, LoadRoutesPopup),
      text: "Загрузить данные из УС",
    },
    {
      id: 401,
      onClick: (e) => onPopupOpen(e, ImportExcelFile, true),
      text: "Загрузить данные из Excel",
    },
    {
      id: 402,
      onClick: (e) => onPopupOpen(e, ImportExcelFile, true),
      text: "Догрузить данные из Excel",
    },
  ];

  const exportDataActions = [
    {
      id: 500,
      onClick: (e) => onPopupOpen(e, ExportExcelData),
      text: "Выгрузить данные в Excel",
    },
    {
      id: 501,
      onClick: (e) => onPopupOpen(e, ExportPopup),
      text: "Выгрузить в УС",
    },
  ];

  const getActionsList = () => {
    switch (role) {
      case USER_TYPE.LOGISTIC_USER: {
        // Отключить возможность удалить проект "Срочные точки"
        if (project.name === "Срочные точки")
          return [
            ...processLogisticDataActions,
            ...deleteDataActions.map((item) => ({ ...item, disabled: true })),
          ];
        return [...processLogisticDataActions, ...deleteDataActions];
      }

      case USER_TYPE.ROUTE_ENG: {
        const isExcel = status === "Загружен из Excel";
        const empty = status === "Создан";
        // Если юзер еще не загружал данные
        if (empty) {
          return [
            ...processDataActions.map((item) => ({
              ...item,
              disabled: item.id === 101,
            })),
            ...importDataActions.filter((item) => item.id !== 402),
            ...deleteDataActions,
          ];
        }
        // Если юзер загрузил данные из Excel - он не может работать с УС и наоборот
        if (isExcel) {
          return [
            ...processDataActions,
            ...importDataActions.filter((item) => item.id === 402),
            ...exportDataActions.filter((item) => item.id === 500),
            ...deleteDataActions,
          ];
        }
        // Если юзер загрузил данные из УС
        return [
          ...processDataActions,
          ...importDataActions.filter((item) => item.id === 400),
          ...exportDataActions.filter((item) => item.id === 501),
          ...deleteDataActions,
        ];
      }

      default: {
        return [
          ...processDataActions,
          ...importDataActions.filter((item) => item.id === 400),
          ...exportDataActions.filter((item) => item.id === 501),
          ...deleteDataActions,
        ];
      }
    }
  };

  return <ActionsDropdown list={getActionsList()} onToggle={onToggle} />;
};

export const useLoadUrgentPoints = () => {
  const navigate = useNavigate();
  const [projectId, setProjectId] = useState(null);

  const [createProjectJob] = useCreateContractsProjectJobMutation();
  const [getProjectJob, projectState] = useLazyGetProjectJobQuery({
    pollingInterval: 3000,
  });

  const onLoad = async ({ project }) => {
    setProjectId(project.id);
    const response = await createProjectJob({
      projectId: project.id,
      branchContractItems: [],
    });
    if (response.error) return null;

    await getProjectJob({
      projectId: project.id,
      jobId: response.data?.id,
    });

    return null;
  };

  useEffect(() => {
    if (projectState?.data?.status === "DONE") {
      navigate(`/logistic-coordinates/${projectId}`);
    }
  }, [projectState?.data?.status]);

  return [onLoad, Boolean(projectState?.data?.status === "NEW")];
};
