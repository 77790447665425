import { DAYS_OF_WEEK } from "shared/config";
import { Text } from "shared/ui";

import { SetDay } from "../model";

export const configColumns = [
  {
    id: "0",
    Header: () => <Text>Маршрут</Text>,
    accessor: (data) => <Text variant="body1">{data.routeName}</Text>,
    w: "12.5%",
    bgColor: "#ffffff",
  },
  {
    id: "1",
    Header: () => <Text>Понедельник</Text>,
    accessor: (data) => <SetDay route={data} day={DAYS_OF_WEEK.MONDAY} />,
    w: "12.5%",
    bgColor: "rgba(245, 0, 8, 0.04)",
  },
  {
    id: "2",
    Header: () => <Text>Вторник</Text>,
    accessor: (data) => <SetDay route={data} day={DAYS_OF_WEEK.TUESDAY} />,
    w: "12.5%",
    bgColor: "rgba(245, 0, 171, 0.04)",
  },
  {
    id: "3",
    Header: () => <Text>Среда</Text>,
    accessor: (data) => <SetDay route={data} day={DAYS_OF_WEEK.WEDNESDAY} />,
    w: "12.5%",
    bgColor: "rgba(0, 136, 194, 0.04)",
  },
  {
    id: "4",
    Header: () => <Text>Четверг</Text>,
    accessor: (data) => <SetDay route={data} day={DAYS_OF_WEEK.THURSDAY} />,
    w: "12.5%",
    bgColor: "rgba(0, 182, 194, 0.1)",
  },
  {
    id: "5",
    Header: () => <Text>Пятница</Text>,
    accessor: (data) => <SetDay route={data} day={DAYS_OF_WEEK.FRIDAY} />,
    w: "12.5%",
    bgColor: "rgba(74, 171, 29, 0.04)",
  },
  {
    id: "6",
    Header: () => <Text>Суббота</Text>,
    accessor: (data) => <SetDay route={data} day={DAYS_OF_WEEK.SATURDAY} />,
    w: "12.5%",
    bgColor: "rgba(89, 89, 89, 0.08)",
  },
  {
    id: "7",
    Header: () => <Text>Воскресенье</Text>,
    accessor: (data) => <SetDay route={data} day={DAYS_OF_WEEK.SUNDAY} />,
    w: "12.5%",
    bgColor: "rgba(89, 89, 89, 0.08)",
  },
];
