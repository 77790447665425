export const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: {
    v: "5.6.5",
    fr: 25,
    ip: 0,
    op: 30,
    w: 300,
    h: 300,
    nm: "Comp 1",
    ddd: 0,
    assets: [
      {
        id: "comp_0",
        layers: [
          {
            ddd: 0,
            ind: 1,
            ty: 4,
            nm: "Vector",
            sr: 1,
            ks: {
              o: { a: 0, k: 100, ix: 11 },
              r: { a: 0, k: 0, ix: 10 },
              p: { a: 0, k: [1037.729, 540.015, 0], ix: 2 },
              a: { a: 0, k: [0, 0, 0], ix: 1 },
              s: { a: 0, k: [100, 100, 100], ix: 6 },
            },
            ao: 0,
            shapes: [
              {
                ty: "gr",
                it: [
                  {
                    ind: 0,
                    ty: "sh",
                    ix: 1,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [-1.495, -0.999],
                          [-1.798, 0],
                          [0, 0],
                          [-1.103, 0.457],
                          [-0.843, 0.846],
                          [0, 0],
                          [-0.457, 1.103],
                          [0, 1.194],
                          [0.457, 1.103],
                          [0.844, 0.844],
                          [0, 0],
                          [2.403, -0.006],
                          [1.699, -1.699],
                          [0.006, -2.403],
                          [-1.691, -1.707],
                          [0, 0],
                          [0, 0],
                          [0.351, -1.763],
                          [-0.688, -1.661],
                        ],
                        o: [
                          [1.495, 0.999],
                          [0, 0],
                          [1.194, 0.001],
                          [1.103, -0.457],
                          [0, 0],
                          [0.844, -0.844],
                          [0.457, -1.103],
                          [0, -1.194],
                          [-0.457, -1.103],
                          [0, 0],
                          [-1.707, -1.691],
                          [-2.403, 0.006],
                          [-1.699, 1.699],
                          [-0.006, 2.403],
                          [0, 0],
                          [0, 0],
                          [-1.271, 1.271],
                          [-0.351, 1.763],
                          [0.688, 1.661],
                        ],
                        v: [
                          [-14.142, 25.724],
                          [-9.092, 27.257],
                          [-9.092, 27.257],
                          [-5.613, 26.567],
                          [-2.665, 24.593],
                          [15.517, 6.412],
                          [17.489, 3.463],
                          [18.181, -0.016],
                          [17.489, -3.494],
                          [15.517, -6.443],
                          [-2.665, -24.625],
                          [-9.083, -27.257],
                          [-15.489, -24.595],
                          [-18.152, -18.189],
                          [-15.519, -11.77],
                          [-3.766, -0.016],
                          [-15.519, 11.739],
                          [-18.006, 16.393],
                          [-17.489, 21.645],
                        ],
                        c: true,
                      },
                      ix: 2,
                    },
                    nm: "Path 1",
                    mn: "ADBE Vector Shape - Group",
                    hd: false,
                  },
                  {
                    ty: "fl",
                    c: {
                      a: 0,
                      k: [0.175642311573, 0.539218306541, 0.966024339199, 1],
                      ix: 4,
                    },
                    o: { a: 0, k: 100, ix: 5 },
                    r: 1,
                    bm: 0,
                    nm: "Fill 1",
                    mn: "ADBE Vector Graphic - Fill",
                    hd: false,
                  },
                  {
                    ty: "tr",
                    p: { a: 0, k: [0, 0], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: "Transform",
                  },
                ],
                nm: "Vector",
                np: 2,
                cix: 2,
                bm: 0,
                ix: 1,
                mn: "ADBE Vector Group",
                hd: false,
              },
            ],
            ip: 0,
            op: 125,
            st: 0,
            bm: 0,
          },
          {
            ddd: 0,
            ind: 2,
            ty: 4,
            nm: "Vector",
            sr: 1,
            ks: {
              o: { a: 0, k: 100, ix: 11 },
              r: { a: 0, k: 0, ix: 10 },
              p: { a: 0, k: [978.637, 540, 0], ix: 2 },
              a: { a: 0, k: [0, 0, 0], ix: 1 },
              s: { a: 0, k: [100, 100, 100], ix: 6 },
            },
            ao: 0,
            shapes: [
              {
                ty: "gr",
                it: [
                  {
                    ind: 0,
                    ty: "sh",
                    ix: 1,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [-2.411, 0],
                          [0, 0],
                          [-1.705, 1.705],
                          [0, 2.411],
                          [1.705, 1.705],
                          [2.411, 0],
                          [0, 0],
                          [1.705, -1.705],
                          [0, -2.411],
                          [-1.705, -1.705],
                        ],
                        o: [
                          [0, 0],
                          [2.411, 0],
                          [1.705, -1.705],
                          [0, -2.411],
                          [-1.705, -1.705],
                          [0, 0],
                          [-2.411, 0],
                          [-1.705, 1.705],
                          [0, 2.411],
                          [1.705, 1.705],
                        ],
                        v: [
                          [-59.091, 9.091],
                          [59.091, 9.091],
                          [65.519, 6.428],
                          [68.182, 0],
                          [65.519, -6.428],
                          [59.091, -9.091],
                          [-59.091, -9.091],
                          [-65.519, -6.428],
                          [-68.182, 0],
                          [-65.519, 6.428],
                        ],
                        c: true,
                      },
                      ix: 2,
                    },
                    nm: "Path 1",
                    mn: "ADBE Vector Shape - Group",
                    hd: false,
                  },
                  {
                    ty: "fl",
                    c: {
                      a: 0,
                      k: [0.175642311573, 0.539218306541, 0.966024339199, 1],
                      ix: 4,
                    },
                    o: { a: 0, k: 100, ix: 5 },
                    r: 1,
                    bm: 0,
                    nm: "Fill 1",
                    mn: "ADBE Vector Graphic - Fill",
                    hd: false,
                  },
                  {
                    ty: "tr",
                    p: { a: 0, k: [0, 0], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: "Transform",
                  },
                ],
                nm: "Vector",
                np: 2,
                cix: 2,
                bm: 0,
                ix: 1,
                mn: "ADBE Vector Group",
                hd: false,
              },
            ],
            ip: 0,
            op: 125,
            st: 0,
            bm: 0,
          },
        ],
      },
    ],
    layers: [
      {
        ddd: 0,
        ind: 1,
        ty: 3,
        nm: "▽ Group 1",
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: { a: 0, k: [130.455, 150, 0], ix: 2 },
          a: { a: 0, k: [95.455, 100, 0], ix: 1 },
          s: { a: 0, k: [100, 100, 100], ix: 6 },
        },
        ao: 0,
        ip: 0,
        op: 125,
        st: 0,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 2,
        ty: 4,
        nm: "Line 1",
        parent: 1,
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: { a: 0, k: [44, 100.5, 0], ix: 2 },
          a: { a: 0, k: [0, 0, 0], ix: 1 },
          s: { a: 0, k: [100, 100, 100], ix: 6 },
        },
        ao: 0,
        ef: [
          {
            ty: 5,
            nm: "Bounce: Path - Path 1",
            np: 6,
            mn: "Pseudo/MDS Bounce Controller",
            ix: 1,
            en: 1,
            ef: [
              {
                ty: 0,
                nm: "Amplitude",
                mn: "Pseudo/MDS Bounce Controller-0001",
                ix: 1,
                v: { a: 0, k: 50, ix: 1 },
              },
              {
                ty: 0,
                nm: "Gravity",
                mn: "Pseudo/MDS Bounce Controller-0002",
                ix: 2,
                v: { a: 0, k: 15, ix: 2 },
              },
              {
                ty: 0,
                nm: "Max Jumps",
                mn: "Pseudo/MDS Bounce Controller-0003",
                ix: 3,
                v: { a: 0, k: 5, ix: 3 },
              },
              {
                ty: 7,
                nm: "Jump In/Out",
                mn: "Pseudo/MDS Bounce Controller-0004",
                ix: 4,
                v: { a: 0, k: 0, ix: 4 },
              },
            ],
          },
        ],
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      i: { x: 0.55, y: 1 },
                      o: { x: 1, y: 0 },
                      t: 0,
                      s: [
                        {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [35, -70],
                            [35.001, 1],
                            [35, 70],
                          ],
                          c: false,
                        },
                      ],
                    },
                    {
                      i: { x: 0.833, y: 1 },
                      o: { x: 0.45, y: 0 },
                      t: 10,
                      s: [
                        {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [35, -70],
                            [-50, 0],
                            [35, 70],
                          ],
                          c: false,
                        },
                      ],
                    },
                    {
                      i: { x: 0.833, y: 1 },
                      o: { x: 0.167, y: 0 },
                      t: 15,
                      s: [
                        {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [35, -70],
                            [-50, 0],
                            [35, 70],
                          ],
                          c: false,
                        },
                      ],
                    },
                    {
                      t: 18,
                      s: [
                        {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [35, -70],
                            [35.252, -1],
                            [35, 70],
                          ],
                          c: false,
                        },
                      ],
                    },
                  ],
                  ix: 2,
                },
                nm: "Path 1",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
              {
                ty: "rd",
                nm: "Round Corners 1",
                r: { a: 0, k: 4, ix: 1 },
                ix: 2,
                mn: "ADBE Vector Filter - RC",
                hd: false,
              },
              {
                ty: "st",
                c: {
                  a: 0,
                  k: [0.176470592618, 0.537254929543, 0.964705884457, 1],
                  ix: 3,
                },
                o: { a: 0, k: 100, ix: 4 },
                w: { a: 0, k: 8, ix: 5 },
                lc: 2,
                lj: 1,
                ml: 4,
                bm: 0,
                nm: "Stroke 1",
                mn: "ADBE Vector Graphic - Stroke",
                hd: false,
              },
              {
                ty: "tr",
                p: { a: 0, k: [-5, 0], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: "Transform",
              },
            ],
            nm: "Line 1",
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
        ip: 0,
        op: 125,
        st: 0,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 3,
        ty: 0,
        nm: "Pre-comp 1",
        refId: "comp_0",
        sr: 1,
        ks: {
          o: {
            a: 1,
            k: [
              {
                i: { x: [0.5], y: [1] },
                o: { x: [0.5], y: [0] },
                t: 0,
                s: [0],
              },
              {
                i: { x: [0], y: [1] },
                o: { x: [0.5], y: [0] },
                t: 5,
                s: [100],
              },
              {
                i: { x: [0.5], y: [1] },
                o: { x: [0.5], y: [0] },
                t: 15,
                s: [100],
              },
              { t: 20, s: [0] },
            ],
            ix: 11,
          },
          r: { a: 0, k: 0, ix: 10 },
          p: {
            a: 1,
            k: [
              {
                i: { x: 0.55, y: 1 },
                o: { x: 1, y: 0 },
                t: 0,
                s: [170, 150, 0],
                to: [-9.167, 0, 0],
                ti: [9.167, 0, 0],
              },
              {
                i: { x: 0, y: 0 },
                o: { x: 0.45, y: 0.45 },
                t: 10,
                s: [115, 150, 0],
                to: [0, 0, 0],
                ti: [0, 0, 0],
              },
              {
                i: { x: 0, y: 1 },
                o: { x: 0.167, y: 0.117 },
                t: 15,
                s: [115, 150, 0],
                to: [31.667, 0, 0],
                ti: [-31.667, 0, 0],
              },
              { t: 22, s: [305, 150, 0] },
            ],
            ix: 2,
          },
          a: { a: 0, k: [960, 540, 0], ix: 1 },
          s: { a: 0, k: [100, 100, 100], ix: 6 },
        },
        ao: 0,
        w: 1920,
        h: 1080,
        ip: 0,
        op: 125,
        st: 0,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 4,
        ty: 4,
        nm: "Vector",
        parent: 1,
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: { a: 0, k: [111.968, 100, 0], ix: 2 },
          a: { a: 0, k: [0, 0, 0], ix: 1 },
          s: { a: 0, k: [100, 100, 100], ix: 6 },
        },
        ao: 0,
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [-1.103, -0.457],
                      [-1.194, 0.001],
                      [-1.495, 0.999],
                      [-0.688, 1.661],
                      [0.351, 1.763],
                      [1.271, 1.271],
                      [0, 0],
                      [0, 0],
                      [-5.275, 12.733],
                      [0, 13.783],
                      [5.275, 12.733],
                      [9.747, 9.745],
                      [0, 0],
                      [0, 0],
                      [0, 2.411],
                      [1.705, 1.705],
                      [2.411, 0],
                      [1.705, -1.705],
                      [0, 0],
                      [0.457, -1.103],
                      [0, -1.194],
                      [-0.457, -1.103],
                      [-0.845, -0.844],
                      [0, 0],
                      [-4.361, -10.527],
                      [0, -11.395],
                      [4.361, -10.527],
                      [8.058, -8.057],
                      [0, 0],
                      [0.457, -1.103],
                      [0, -1.194],
                      [-0.457, -1.103],
                      [-0.845, -0.844],
                      [0, 0],
                    ],
                    o: [
                      [1.103, 0.457],
                      [1.798, 0],
                      [1.495, -0.999],
                      [0.688, -1.661],
                      [-0.351, -1.763],
                      [0, 0],
                      [0, 0],
                      [9.747, -9.745],
                      [5.275, -12.733],
                      [0, -13.783],
                      [-5.275, -12.733],
                      [0, 0],
                      [0, 0],
                      [1.705, -1.705],
                      [0, -2.411],
                      [-1.705, -1.705],
                      [-2.411, 0],
                      [0, 0],
                      [-0.845, 0.844],
                      [-0.457, 1.103],
                      [0, 1.194],
                      [0.457, 1.103],
                      [0, 0],
                      [8.058, 8.057],
                      [4.361, 10.527],
                      [0, 11.395],
                      [-4.361, 10.527],
                      [0, 0],
                      [-0.845, 0.844],
                      [-0.457, 1.103],
                      [0, 1.194],
                      [0.457, 1.103],
                      [0, 0],
                      [0.843, 0.846],
                    ],
                    v: [
                      [-15.447, 99.31],
                      [-11.967, 100],
                      [-6.918, 98.467],
                      [-3.57, 94.388],
                      [-3.053, 89.136],
                      [-5.54, 84.482],
                      [-8.203, 81.818],
                      [-0.594, 74.21],
                      [22.159, 40.163],
                      [30.149, 0],
                      [22.159, -40.164],
                      [-0.594, -74.211],
                      [-8.203, -81.819],
                      [-5.54, -84.483],
                      [-2.877, -90.91],
                      [-5.54, -97.338],
                      [-11.967, -100],
                      [-18.394, -97.338],
                      [-27.485, -88.247],
                      [-29.457, -85.298],
                      [-30.149, -81.819],
                      [-29.457, -78.34],
                      [-27.485, -75.392],
                      [-13.45, -61.355],
                      [5.362, -33.206],
                      [11.967, 0],
                      [5.362, 33.205],
                      [-13.45, 61.354],
                      [-27.485, 75.391],
                      [-29.457, 78.339],
                      [-30.149, 81.818],
                      [-29.457, 85.297],
                      [-27.485, 88.245],
                      [-18.394, 97.336],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "Path 1",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
              {
                ty: "fl",
                c: {
                  a: 0,
                  k: [0.175642311573, 0.539218306541, 0.966024339199, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: "Fill 1",
                mn: "ADBE Vector Graphic - Fill",
                hd: false,
              },
              {
                ty: "tr",
                p: { a: 0, k: [0, 0], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: "Transform",
              },
            ],
            nm: "Vector",
            np: 2,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
        ip: 0,
        op: 125,
        st: 0,
        bm: 0,
      },
    ],
    markers: [],
  },
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};
