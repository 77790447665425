import { Button, Flex } from "shared/ui";
import styled from "styled-components";

import { Group } from "./ui";

const SelectGroups = ({
  branchCode = "",
  groups = [],
  editBranch = () => {},
}) => (
  <Flex>
    <StyledGroupsList>
      {groups.map((group) => (
        <Group key={group.id} group={group} branchCode={branchCode} />
      ))}
    </StyledGroupsList>
    <Flex mt="15px">
      <Button
        text="РЕДАКТИРОВАТЬ"
        width="190px"
        color="#ECF4FE"
        textColor="#2D8AF6"
        onClick={() => editBranch()}
      />
    </Flex>
  </Flex>
);

const StyledGroupsList = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: auto;
  gap: 15px;
`;

export default SelectGroups;
