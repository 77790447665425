import { api } from "shared/api";

const dadataApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getCoordsByMapClick: builder.mutation({
      query: ({ ...body }) => ({
        url: "https://suggestions.dadata.ru/suggestions/api/4_1/rs/geolocate/address",
        headers: {
          authorization: "Token 6f65fdf0666c7d8947c171a1c5ecb2ed3575962f",
        },
        method: "POST",
        body,
      }),
    }),
  }),
});

export const { useGetCoordsByMapClickMutation } = dadataApi;
