import { useContext } from "react";
import { Flex, Text } from "shared/ui";

import ReportContext from "../context";

export const HeaderCell = ({ title = "" }) => {
  const { filter } = useContext(ReportContext);

  return (
    <Flex width="70%">
      <Flex flexDirection="row" justifyContent="center" mb="4px">
        <Text variant="body3" color="color7">
          {title}
        </Text>
      </Flex>
      <Flex flexDirection="row" justifyContent="space-between">
        <Flex mr="4px">
          <Text variant="body3" fontSize="12px">
            ТТ
          </Text>
        </Flex>
        <Text variant="body3" fontSize="12px">
          {filter === "PERCENT" ? "%" : filter}
        </Text>
      </Flex>
    </Flex>
  );
};

export const BodyCell = ({ value }) => {
  const { filter } = useContext(ReportContext);

  return (
    <Flex width="70%" flexDirection="row" justifyContent="space-between">
      <Flex>
        <Text>{value.TT}</Text>
      </Flex>
      <Flex>
        <Text>{value[filter]}</Text>
      </Flex>
    </Flex>
  );
};
