import { Cross, Maximize, Minimize, Refresh } from "app/assets/icons";
import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useListRoutesReportQuery } from "shared/api/routes";
import { Button, Flex, Loader, Text } from "shared/ui";
import styled from "styled-components";

import { configColumns } from "./config";
import ReportContext from "./context";
import Table from "./ui/table";

export const FILTERS = {
  TO: "TO",
  PR: "PR",
  PERCENT: "PERCENT",
};

const Report = ({ setIsReportOpen = () => {} }) => {
  const [isExpand, setIsExpand] = useState(false);
  const [filter, setFilter] = useState(FILTERS.TO);
  const { projectInfo } = useSelector(
    (state) => state.rootReducer.currentProject,
  );
  const {
    data: report,
    isFetching,
    refetch,
  } = useListRoutesReportQuery(
    { projectId: projectInfo.id },
    { skip: !projectInfo.id },
  );

  const data = React.useMemo(() => report, [report]);
  const columns = React.useMemo(() => configColumns, []);

  const contextValue = useMemo(() => ({ filter }), [filter]);

  if (isFetching) {
    return (
      <Root>
        <Header>
          <Text variant="title1">Маршруты дня по неделям</Text>
        </Header>
        <Flex flexDirection="row" height="200px">
          <Loader isLoading />
        </Flex>
      </Root>
    );
  }

  return (
    <Root>
      <Header>
        <Flex mr="10px">
          <Text variant="title1">Маршруты по дням</Text>
        </Flex>
        <Filters>
          <FilterItem
            isActive={filter === FILTERS.TO}
            onClick={() => setFilter(FILTERS.TO)}
          >
            <Text variant="body3">ТО</Text>
          </FilterItem>
          <FilterItem
            isActive={filter === FILTERS.PR}
            onClick={() => setFilter(FILTERS.PR)}
          >
            <Text variant="body3">PR</Text>
          </FilterItem>
          <FilterItem
            isActive={filter === FILTERS.PERCENT}
            onClick={() => setFilter(FILTERS.PERCENT)}
          >
            <Text variant="body3">%</Text>
          </FilterItem>
        </Filters>
      </Header>
      <ReportContext.Provider value={contextValue}>
        <Table data={data} columns={columns} isExpand={isExpand} />
      </ReportContext.Provider>
      <Flex flexDirection="row" mt="20px">
        <Button text="ОБНОВИТЬ" icon={<Refresh />} onClick={() => refetch()} />
        <CustomButton
          className="expandButton"
          onClick={() => setIsExpand(!isExpand)}
        >
          {isExpand ? <Minimize /> : <Maximize />}
        </CustomButton>
        <CustomButton
          className="closeButton"
          onClick={() => setIsReportOpen(false)}
        >
          <Cross />
        </CustomButton>
      </Flex>
    </Root>
  );
};

const Root = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 20px;
  background: #fff;
  z-index: 9999;
  box-shadow: 0px -8px 10px -4px rgba(0, 0, 0, 0.1);
  width: 50%;
  min-width: 40vw;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const Filters = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
`;

const FilterItem = styled.button`
  background: #fff;
  border: ${({ isActive }) =>
    isActive ? "2px solid #2D8AF6" : "1px solid #CCCCCC"};
  height: 32px;
  width: 40px;
  margin-right: 8px;

  &:last-child {
    margin-right: 0;
  }
`;

const CustomButton = styled.button`
  background: #ecf4fe;
  border-radius: 12px;
  width: 56px;
  height: 48px;
  border: none;
  outline: none;
  color: #2d8af6;

  &.expandButton {
    margin-left: auto;
  }

  &.closeButton {
    margin-left: 8px;
  }
`;

export default Report;
