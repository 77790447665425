export const configColumns = [
  {
    Header: "Маршрут",
    textAlign: "left",
    accessor: "NAME",
  },
  {
    Header: "Точки",
    accessor: "POINT_COUNTS",
  },
  {
    Header: "Доставка 24",
    accessor: "DELIVERY_24",
  },
  {
    Header: "Доставка 48",
    accessor: "DELIVERY_48",
  },
  {
    Header: "Доставка > 48",
    accessor: "DELIVERY_MORE_THAN_48",
  },
];
