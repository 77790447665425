import * as React from "react";

const SvgNavArrow = (props) => (
  <svg
    width={20}
    height={20}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#nav_arrow_svg__a)">
      <path
        d="M10 3.333 8.825 4.508l4.65 4.659H3.333v1.666h10.142l-4.65 4.659L10 16.667 16.667 10 10 3.333Z"
        fill="#CCC"
      />
    </g>
    <defs>
      <clipPath id="nav_arrow_svg__a">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgNavArrow;
