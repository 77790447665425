import {
  resetAttributeFilter,
  setAttributeFilter,
} from "app/store/reducer/routes";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useListFiltersRoutesCommandsDuplicatesQuery } from "shared/api/filters";
import { Checkbox, Flex, SearchDropdown, Text } from "shared/ui";

const AttributeDropdown = ({ attribute }) => {
  const dispatch = useDispatch();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const { projectInfo } = useSelector(
    (state) => state.rootReducer.currentProject,
  );
  const { frontendFilters } = useSelector((state) => state.rootReducer.routes);
  const { data: filters, isSuccess } =
    useListFiltersRoutesCommandsDuplicatesQuery(
      {
        projectId: projectInfo.id,
        commands: frontendFilters.commands,
      },
      { skip: !projectInfo.id, refetchOnMountOrArgChange: true },
    );


  function onAttributeSelect(item) {
    dispatch(setAttributeFilter({ attribute, newItem: item }));
  }

  const onSearch = (value) => {
    setSearchValue(value);
  };

  const onToggle = (isOpen) => {
    if (isOpen === false) onSearch("");
    setIsDropdownOpen((prev) => !prev);
  };

  const onResetSearch = () => {
    onSearch("");
    setIsDropdownOpen(false);
  };

  const onResetAllCheckboxes = () => {
    dispatch(resetAttributeFilter(attribute));
    setIsDropdownOpen(false);
  };

  if (isSuccess) {
    return (
      <SearchDropdown
        isDropdownOpen={isDropdownOpen}
        toggleText={
          frontendFilters[attribute].length
            ? `Выбрано ${frontendFilters[attribute].length}`
            : "Выбрать"
        }
        searchValue={searchValue}
        onToggle={onToggle}
        onSearch={onSearch}
        onResetSearch={onResetSearch}
        onResetAllCheckboxes={onResetAllCheckboxes}
        multiselect
        selectedText
        selectedCount={frontendFilters[attribute].length}
      >
        {filters[attribute] &&
          filters[attribute].map(
            (item) =>
              item.toLowerCase().includes(searchValue.toLowerCase()) && (
                <SearchDropdown.Item key={item}>
                  <Checkbox
                    checked={frontendFilters[attribute].includes(item)}
                    onChange={() => onAttributeSelect(item)}
                  />
                  <Flex ml="7px">
                    <Text variant="body3">{item}</Text>
                  </Flex>
                </SearchDropdown.Item>
              ),
          )}
      </SearchDropdown>
    );
  }

  return null;
};

export default AttributeDropdown;
