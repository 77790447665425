import * as React from "react";

const SvgSignInLogo = (props) => (
  <svg
    width={275}
    height={49}
    viewBox="0 0 266 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.2 18.2h12.4l-6-14.8h-.4l-6 14.8ZM21 0l11.4 28h-5.76L24 21.2H8.8L6.16 28H.4L11.8 0H21Zm29.013 0c2 0 3.786.227 5.36.68 1.6.427 2.946 1.067 4.04 1.92a8.3 8.3 0 0 1 2.52 3.08c.586 1.2.88 2.573.88 4.12 0 1.547-.294 2.933-.88 4.16a8.745 8.745 0 0 1-2.52 3.08c-1.094.827-2.44 1.467-4.04 1.92-1.574.427-3.36.64-5.36.64h-8.6V28h-5.8V0h14.4Zm-8.6 3v13.6h8.6c2.106 0 3.733-.547 4.88-1.64 1.173-1.12 1.76-2.84 1.76-5.16 0-2.32-.587-4.027-1.76-5.12C53.745 3.56 52.118 3 50.012 3h-8.6Zm49.2 0h-10.8v25h-5.8V3h-10.8V0h27.4v3Zm9.003 0v9.2h14.6v3h-14.6V25h18.6v3h-24.4V0h24.4v3h-18.6Zm41.598 21h.4l10.4-24h8.6v28h-5.8V5.6h-.4l-9.6 22.4h-6.8l-9.6-22.4h-.4V28h-5.8V0h8.6l10.4 24Zm47.984 4V5.2h-.4l-15 22.8h-7.6V0h5.8v22.8h.4l15-22.8h7.6v28h-5.8Zm11.015-3c.8 0 1.533-.373 2.2-1.12.693-.747 1.093-1.773 1.2-3.08l1.6-20.8h22.4v25h3.4v9.4h-4.8l-.8-6.4h-21.6l-.8 6.4h-4.8V25h2Zm9-4.2c-.054.693-.227 1.307-.52 1.84a5.86 5.86 0 0 1-.96 1.32c-.4.427-.84.773-1.32 1.04h15.4V3h-11.2l-1.4 17.8Zm33.604-2.6h12.4l-6-14.8h-.4l-6 14.8Zm10.8-18.2 11.4 28h-5.76l-2.64-6.8h-15.2l-2.64 6.8h-5.76l11.4-28h9.2Z"
      fill="#2D8AF6"
    />
  </svg>
);

export default SvgSignInLogo;
