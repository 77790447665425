import { useSelector } from "react-redux";
import { useListAnalyzePeriodQuery } from "shared/api/logistic";
import { calendarFiltersToString } from "shared/helpers";
import { Text } from "shared/ui";

export const ContractHeaderCell = () => {
  const { projectInfo } = useSelector(
    (state) => state.rootReducer.currentProject,
  );
  const { data: analyzePeriod, isSuccess } = useListAnalyzePeriodQuery(
    {
      projectId: projectInfo.id,
    },
    { skip: !projectInfo.id },
  );

  if (isSuccess) {
    return (
      <Text>
        В среднем в неделю за{" "}
        {calendarFiltersToString(
          analyzePeriod?.monthFrom,
          analyzePeriod?.monthTo,
          analyzePeriod?.yearFrom,
          analyzePeriod?.yearTo,
        )}
      </Text>
    );
  }

  return null;
};
