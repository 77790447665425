import React from "react";
import styled from "styled-components";

const IconButton = ({
  disabled = false,
  onClick,
  loading = false,
  children,
  ...props
}) => (
  <StyledButton
    type="button"
    disabled={disabled || loading}
    onClick={onClick}
    {...props}
  >
    {children || "Icon"}
  </StyledButton>
);

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  max-width: 100%;
  height: 20px;
  background: none;
  padding: 0;
  border: 0;
  box-sizing: border-box;
  transition: 0.3s;
  cursor: pointer;

  &:disabled {
    opacity: 0.3;
  }
`;

export default IconButton;
