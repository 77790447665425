import { setMapCenterPoint } from "app/store/reducer/days";
import React from "react";
import { DragDropContext } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import {
  useListRoutePointsDaysQuery,
  useReorderRoutePointsMutation,
} from "shared/api/days";
import {
  useListFiltersRoutesCommandsDuplicatesQuery,
  useListFiltersRoutesUsersDuplicatesQuery,
} from "shared/api/filters";
import { getUserRole } from "shared/libs/jwt-decode";

import { configColumns } from "./config";
import Footer from "./footer";
import { TableUI } from "./ui";

const Table = () => {
  const role = getUserRole();
  const dispatch = useDispatch();
  const { projectInfo } = useSelector(
    (state) => state.rootReducer.currentProject,
  );
  const { frontendFilters, selectedRoutePoints, mapCenterPoint, allPointsChecked } = useSelector(
    (state) => state.rootReducer.days,
  );
  const { data: userFilters } = useListFiltersRoutesUsersDuplicatesQuery(
    {
      projectId: projectInfo.id,
      managers: frontendFilters.managerCodes,
    },
    {
      skip: !projectInfo.id || role === "ROUTE_ENG",
      refetchOnMountOrArgChange: true,
    },
  );
  const { data: routeEngFilters } = useListFiltersRoutesCommandsDuplicatesQuery(
    {
      projectId: projectInfo.id,
      commands: frontendFilters.commands,
    },
    {
      skip: !projectInfo.id || role !== "ROUTE_ENG",
      refetchOnMountOrArgChange: true,
    },
  );
  const filters = role === "ROUTE_ENG" ? routeEngFilters : userFilters;

  const { data: routePoints, isFetching } = useListRoutePointsDaysQuery(
    {
      projectId: projectInfo.id,
      filters: frontendFilters,
    },
    {
      skip: !projectInfo.id || !frontendFilters.routeCodes.length,
      refetchOnMountOrArgChange: true,
    },
  );
  const [reorderRoutePoints] = useReorderRoutePointsMutation();

  const onRowClick = (row) => {
    const routePoint = row.original;
    if (selectedRoutePoints.includes(routePoint.id)) return;

    dispatch(
      setMapCenterPoint({
        id: routePoint.id,
        latitude: routePoint.point.latitude,
        longitude: routePoint.point.longitude,
        clickedIn: "table",
      }),
    );
  };

  const onDragEnd = async ({ source, destination }) => {
    const oldIndex = source.index;
    const newIndex = destination.index;
    if (oldIndex === newIndex) return;

    const routePointId = routePoints?.content[oldIndex].id;
    const dayOfWeek = routePoints?.content[oldIndex].dayWeek;
    const listSize = routePoints?.content.length;
    const { routeId } = filters.routes.find(
      (route) => route.routeCode === frontendFilters.routeCodes[0],
    );

    await reorderRoutePoints({
      projectId: projectInfo.id,
      routeId,
      routePointId,
      newIndex,
      dayOfWeek,
      listSize,
    });
  };

  const data = React.useMemo(() => routePoints, [routePoints]);
  const columns = React.useMemo(() => configColumns, []);

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <TableUI
        data={data?.content}
        columns={columns}
        isFooterVisible={selectedRoutePoints.length > 0 || allPointsChecked}
        onRowClick={onRowClick}
        mapCenterPoint={mapCenterPoint}
        isFetching={isFetching}
      />
      <Footer />
    </DragDropContext>
  );
};

export default Table;
